<template>
  <v-container class="pa-8">
    <v-overlay :absolute="true" :opacity="0.2" :value="customersLoading">
      <v-progress-circular
        :width="4"
        :size="70"
        color="#7db52f"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-row class="pa-2" justify="center">
      <v-col align="center">
        <v-row justify="center" cols="12">
          <v-col cols="12">
            <h3 class="text-center">Upcoming Walks</h3>
          </v-col>
          <v-col>
            <apexchart
              type="line"
              :options="chartOptions"
              :series="chartSeries"
              height="200"
            ></apexchart>
          </v-col>
        </v-row>
        <v-row justify="center" cols="12">
          <v-col cols="12">
            <h3 class="text-center">Widgets</h3>
          </v-col>
        </v-row>
        <v-row justify="start">
          <v-col cols="3">
            <!-- Widget for the company invites -->
            <v-card
              max-width="400"
              color="#7db52f"
              rounded="xl"
              to="/invites/company/overview"
            >
              <v-card-title>
                <v-icon color="white" class="mr-2">mdi-barcode-scan</v-icon>
                <span class="white--text no-wrap">Company Invites</span>
              </v-card-title>
              <v-card-text>
                <v-row justify="center">
                  <h1 class="ma-4">{{ companyInvites.length }}</h1>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <!-- Widget for the company invites -->
            <v-card
              max-width="400"
              color="#7db52f"
              rounded="xl"
              to="/invites/overview"
            >
              <!-- Loading if invitesLoading -->
              <v-overlay
                :absolute="true"
                :opacity="0.2"
                :value="invitesLoading"
              >
                <v-progress-circular
                  :width="4"
                  :size="70"
                  color="#7db52f"
                  indeterminate
                ></v-progress-circular>
              </v-overlay>
              <v-card-title>
                <v-icon color="white" class="mr-2">mdi-barcode-scan</v-icon>
                <span class="white--text">Customer Invites</span>
              </v-card-title>
              <v-card-text>
                <v-row justify="center">
                  <h1 class="ma-4">{{ invites.length }}</h1>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Widget for customers -->
          <v-col cols="3">
            <v-card
              max-width="400"
              color="#7db52f"
              rounded="xl"
              to="/company/customers"
            >
              <!-- Loading if customersLoading -->
              <v-overlay
                :absolute="true"
                :opacity="0.2"
                :value="customersLoading"
              >
                <v-progress-circular
                  :width="4"
                  :size="70"
                  color="#7db52f"
                  indeterminate
                ></v-progress-circular>
              </v-overlay>
              <v-card-title>
                <v-icon color="white" class="mr-2">mdi-account-group</v-icon>
                <span class="white--text">Customers</span>
              </v-card-title>
              <v-card-text>
                <v-row justify="center">
                  <h1 class="ma-4">{{ customers.length }}</h1>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Widget for services -->
          <v-col cols="3">
            <v-card
              max-width="400"
              color="#7db52f"
              rounded="xl"
              to="/services/overview"
            >
              <!-- Loading if servicesLoading -->
              <v-overlay
                :absolute="true"
                :opacity="0.2"
                :value="servicesLoading"
              >
                <v-progress-circular
                  :width="4"
                  :size="70"
                  color="#7db52f"
                  indeterminate
                ></v-progress-circular>
              </v-overlay>
              <v-card-title>
                <v-icon color="white" class="mr-2">mdi-cogs</v-icon>
                <span class="white--text">Services</span>
              </v-card-title>
              <v-card-text>
                <v-row justify="center">
                  <h1 class="ma-4">{{ services.length }}</h1>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "CustomerOverview",
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      // ApexCharts options
      chartOptions: {
        chart: {
          id: "gradientLine",
        },
        xaxis: {
          categories: [],
        },
        stroke: {
          curve: "smooth",
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            gradientToColors: ["#f72047", "#ffd200", "#1feaea"],
            type: "horizontal",
          },
        },
      },
      // ApexCharts series data
      chartSeries: [
        {
          name: "Upcoming Walks",
          data: [4, 2, 5, 1, 0, 2, 0], // The data for the series
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      selectedCompany: "company/selectedCompany",
      customers: "company/customers",
      customersLoading: "company/customersLoading",
      companies: "company/companies",
      invites: "invites/invites",
      invitesLoading: "invites/loading",
      services: "services/services",
      servicesLoading: "services/servicesLoading",
      customersLoading: "company/customersLoading",
      companyInvites: "invites/companyInvites",
    }),
  },
  created() {
    this.generateNextSevenDays();
  },
  methods: {
    ...mapActions({
      getCustomers: "company/getCustomers",
    }),
    generateNextSevenDays() {
      const today = moment();
      const nextSevenDays = [];

      for (let i = 0; i < 7; i++) {
        nextSevenDays.push(today.clone().add(i, "days").format("DD MMM"));
      }

      this.chartOptions.xaxis.categories = nextSevenDays;
    },
  },
  async mounted() {},
  watch: {},
};
</script>

<style></style>
