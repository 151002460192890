import axios from 'axios';
import moment from 'moment';

export default {
    namespaced: true,

    state: {
        calendar: null,
        events: [],
        loading: true,
        createEventLoading: false,
        createEventError: false,
        createEventSuccess: false,
        error: false,
        createEventShowOverlay: false,
    },

    mutations: {
        RESET_STATE(state) {
            state.calendar = null;
            state.events = [];
            state.loading = true;
            state.error = false;
            state.createEventLoading = false;
            state.createEventError = false;
            state.createEventSuccess = false;
            state.createEventShowOverlay = false;
        },
        SET_CALENDAR(state, calendar) {
            state.calendar = calendar;
        },
        SET_EVENTS(state, events) {
            var mappedEvents = []
            events.forEach(event => {
                mappedEvents.push({
                    name: event.name,
                    start: new Date(event.start),
                    details: event.details,
                    end: moment(new Date(event.start)).add(moment.duration(event.duration)).toDate(),
                    timed: event.start == event.end ? false : true,
                    color: 'blue'
                });
            });
            state.events = mappedEvents;
        },
        CALENDAR_LOADING(state, bool) {
            state.loading = bool;
        },
        CALENDAR_ERROR(state, bool) {
            state.error = bool;
        },
        CALENDAR_CREATE_EVENT_LOADING(state, bool) {
            state.createEventLoading = bool;
        },
        CALENDAR_CREATE_EVENT_ERROR(state, bool) {
            state.createEventError = bool;
        },
        CALENDAR_CREATE_EVENT_SUCCESS(state, bool) {
            state.createEventSuccess = bool;
        },
        CALENDAR_CREATE_EVENT_SHOW_OVERLAY(state, bool) {
            state.createEventShowOverlay = bool;
        },
    },

    getters: {
        calendar(state) {
            return state.calendar;
        },
        events(state) {
            return state.events;
        },
        loading(state) {
            return state.loading;
        },
        error(state) {
            return state.error;
        },
        createEventLoading(state) {
            return state.createEventLoading;
        },
        createEventError(state) {
            return state.createEventError;
        },
        createEventSuccess(state) {
            return state.createEventSuccess;
        },
        createEventShowOverlay(state) {
            return state.createEventShowOverlay;
        },
    },

    actions: {
        async getCalendar({
            dispatch,
            rootState,
            commit
        }) {
            commit('CALENDAR_LOADING', true);
            try {
                await axios
                    .get('calendar', {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.token,
                        },
                    })
                    .then((resp) => {
                        // Just use the first calendar returned for now
                        commit('SET_CALENDAR', resp.data[0]);
                        // Get the events for this calendar
                        dispatch('getEventsForCalendar', resp.data[0].id);
                    })
                    .catch(() => {
                        commit('CALENDAR_ERROR', true);
                    })
                    .finally(() => {
                        commit('CALENDAR_LOADING', false);
                    });
            } catch (e) {
                commit('CALENDAR_ERROR', true);
                commit('CALENDAR_LOADING', false);

                console.log(e);
            }
        },
        async getEventsForCalendar({
            commit,
            rootState
        }, calendarId) {
            commit('CALENDAR_LOADING', true);
            try {
                await axios.get(
                        'calendar/' + calendarId + '/event', {
                            headers: {
                                Authorization: 'Bearer ' + rootState.auth.token,
                            },
                        })
                    .then((resp) => {
                        commit('SET_EVENTS', resp.data);
                    })
                    .catch(() => {
                        commit('CALENDAR_ERROR', true);
                    })
                    .finally(() => {
                        commit('CALENDAR_LOADING', false);
                    });

            } catch (e) {
                commit('SET_ACCESS_TOKEN', null);
                commit('SET_USER', null);
            }
        },
        async setCreateEventShowOverlay({
            commit
        }, value) {
            commit('CALENDAR_CREATE_EVENT_SHOW_OVERLAY', value);
        },
        async setCreateEventLoading({
            commit
        }, value) {
            commit('CALENDAR_CREATE_EVENT_LOADING', value);
        },
        async setCreateEventError({
            commit
        }, value) {
            commit('CALENDAR_CREATE_EVENT_ERROR', value);
        },
        async setCreateEventSuccess({
            commit
        }, value) {
            commit('CALENDAR_CREATE_EVENT_SUCCESS', value);
        },
        async createCalendarEvent({
            rootState,
            commit
        }, payload) {
            commit('CALENDAR_CREATE_EVENT_LOADING', true);
            try {
                console.log("Payload:")
                console.log(payload);
                await axios
                    .post("calendar/" + payload.calendarId + "/event", payload.body, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.token,
                        },
                    })
                    .then(() => {
                        // Just use the first calendar returned for now
                        // commit('SET_CALENDAR', resp.data[0]);
                        commit('CALENDAR_CREATE_EVENT_SUCCESS', true);
                    })
                    .catch(() => {
                        commit('CALENDAR_CREATE_EVENT_ERROR', true);
                    })
                    .finally(() => {
                        commit('CALENDAR_CREATE_EVENT_LOADING', false);
                    });
            } catch (e) {
                commit('CALENDAR_CREATE_EVENT_ERROR', true);
                commit('CALENDAR_CREATE_EVENT_LOADING', false);

                console.log(e);
            }
        },
    },
};