<template>
  <v-container class="pa-8">
    <v-overlay :absolute="true" :opacity="0.2" :value="customersLoading">
      <v-progress-circular
        :width="4"
        :size="70"
        color="#7db52f"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-row class="pa-2" justify="center">
      <v-col align="center">
        <v-row justify="end">
          <v-col md="4" offset-md="4">Companies</v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card
              class="mb-2"
              v-for="company in companies"
              :key="company.id"
              elevation="3"
            >
              <v-card-text>
                <!-- {{ company }} -->
                <v-row justify="center" align="center">
                  <v-col cols="2">
                    <v-avatar size="100">
                      <img
                        v-if="company.logo != null"
                        :src="company.logo"
                        :alt="company.name"
                      />
                      <img
                        v-else
                        src="https://pawportal.blob.core.windows.net/images/PP_Black.png"
                        alt="Default"
                      />
                    </v-avatar>
                  </v-col>
                  <v-col align="start" cols="10">
                    <p
                      style="font-size: 1.5rem"
                      class="font-weight-medium pb-0 mb-0"
                    >
                      {{ company.name }}
                    </p>
                    <p class="pt-0 mt-0 mb-1">
                      {{ company.name }}
                    </p>
                    <p class="pb-0 mb-0">
                      <b>Phone:</b>
                    </p>
                    <p class="pt-0 mt-0 mb-1">
                      {{ company.name }}
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CustomerOverview",
  computed: {
    ...mapGetters({
      selectedCompany: "company/selectedCompany",
      customers: "company/customers",
      customersLoading: "company/customersLoading",
      companies: "company/companies",
    }),
  },
  methods: {
    ...mapActions({}),
    formatAddress(customer) {
      let addressFields = [
        "address_line_1",
        "address_line_2",
        "address_line_3",
        "address_line_4",
        "address_postcode",
      ];
      return addressFields
        .map((field) => customer[field])
        .filter(Boolean)
        .join(", ");
    },
  },
  async mounted() {},
  watch: {},
};
</script>

<style></style>
