<template>
  <div>
    <v-row class="fill-height" justify="center">
      <v-col align="center">
        <v-row>
          <v-col>
            <h1>&#60;h1>This is using the h1 tag</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="h1">Class - 'h1' This is using the h1 class</div>
          </v-col>
        </v-row>
        <v-row style="background-color: #cbd9c6">
          <v-col>
            <div class="h1-white-text">
              Class - 'h1-white-text' This is using the h1 with white text
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="h1-orange-text">
              Class - 'h1-orange-text' This is using the h1 with orange text
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2>&#60;h2>This is using the h2 tag</h2>
          </v-col>
        </v-row>
        <v-row style="background-color: #cbd9c6">
          <v-col>
            <div class="h2-white-text">
              Class - 'h2-white-text' This is using the h2 with white text
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h3>&#60;h3>This is using the h3 tag</h3>
          </v-col>
        </v-row>
        <v-row style="background-color: #cbd9c6">
          <v-col>
            <div class="h3-white-text">
              Class - 'h3-white-text' This is using the h3 with white text
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h4>&#60;h4>This is using the h4 tag</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="h4-orange-text">
              Class - 'h4-orange-text' This is using the h4 with orange text
            </div>
          </v-col>
        </v-row>
        <v-row style="background-color: #cbd9c6">
          <v-col>
            <div class="h4-white-text">
              Class - 'h4-white-text' This is using the h4 with white text
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h5>&#60;h5>This is using the h5 tag</h5>
          </v-col>
        </v-row>
        <v-row style="background-color: #cbd9c6">
          <v-col>
            <div class="h5-white-text">
              Class - 'h5-white-text' This is using the h5 with white text
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>&#60;p>This is using the p tag</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <b>&#60;b>This is using the b tag</b>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="b2">Class - 'b2' This is using the bold 2 class</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="b-small">
              Class - 'b-small' This is using the bold small class
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="description">
              Class - 'description' This is using the description class
            </div>
          </v-col>
        </v-row>
        <v-row style="background-color: #cbd9c6">
          <v-col>
            <div class="description-white-text">
              Class - 'description-white-text' This is using the
              description-white-text with white text
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="description-small">
              Class - 'description-small' This is using the description small
              class
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="alert">
              Class - 'alert' This is using the alert class
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="alert-small">
              Class - 'alert-small' This is using the alert-small class
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn class="button-white-text" depressed>
              Class - button-white-text
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Styles",
  data: () => ({}),
};
</script>
