<template>
  <v-container class="pa-8">
    <v-overlay :absolute="true" :opacity="0.2" :value="dogsLoading">
      <v-progress-circular
        :width="4"
        :size="70"
        color="#7db52f"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="showInvite" persistent :width="dialogWidth">
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#7db52f" fab small dark @click="showInvite = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <!-- Your form component here -->
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row class="pa-2" justify="center">
      <v-col align="center">
        <v-row justify="end">
          <v-col md="4" offset-md="4">Dogs</v-col>
          <v-col md="4" align="end">
            <v-btn
              color="#7db52f"
              @click="resetForm() && $router.push('/pets/dogs/create')"
            >
              <v-icon>mdi-plus</v-icon> Add New Dog
            </v-btn>
          </v-col>
        </v-row>
        <!-- Dialog to delete pet -->
        <v-dialog v-model="confirmDeleteDialog" max-width="400" opacity="0.9">
          <v-card>
            <v-card-title class="headline"> Delete Pet </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <span class="description">
                    Are you sure you want to delete the pet below?
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col align="center">
                  <span class="description">
                    <b>{{ petToDelete?.name }}</b>
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="description">
                    This action cannot be undone.
                  </span>
                </v-col>
              </v-row>
              <v-progress-linear
                :indeterminate="dogDeleteLoading"
                :color="dogDeleteLoading ? 'primary' : 'transparent'"
              ></v-progress-linear>
              <v-alert v-if="dogDeleteComplete" type="success">
                Pet successfully deleted.
              </v-alert>
              <v-alert v-if="dogDeleteError" type="warning">
                An error occurred while deleting the pet.
                <ul>
                  <li v-for="error in dogDeleteErrorItems" :key="error">
                    {{ error.item }} - {{ error.message }}
                  </li>
                </ul>
              </v-alert>
            </v-card-text>
            <v-card-actions v-if="!dogDeleteComplete && !dogDeleteLoading">
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="closeDialog">
                Cancel
              </v-btn>
              <v-btn color="red darken-1" text @click="submitDeletePet">
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showEditProfilePictureDialog" max-width="600px">
          <edit-profile-picture-dialog
            @click:outside="showEditProfilePictureDialog = false"
            @submit="updateProfilePicture"
            @close="showEditProfilePictureDialog = false"
          />
        </v-dialog>
        <v-row>
          <v-col>
            <v-card
              class="mb-2"
              v-for="dog in dogs"
              :key="dog.id"
              elevation="3"
            >
              <v-btn
                icon
                absolute
                top
                right
                @click="goToEdit(dog.id)"
                style="margin-right: 40px"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                icon
                absolute
                top
                right
                color="red"
                @click="showDeleteDialog(dog)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-card-text>
                <v-row justify="center" align="center">
                  <v-col align="start" md="3" class="pl-0 pr-1 mx-0">
                    <!-- <v-img
                      class="pa-4 rounded-xl elevation-6"
                      alt=""
                      max-width="100%"
                      :src="dog.image_url ? dog.image_url : ''"
                    ></v-img> -->
                    <v-hover v-slot="{ hover }">
                      <v-avatar size="150" :class="{ 'on-hover': hover }">
                        <v-overlay v-if="hover" absolute color="#036358">
                          <v-btn
                            @click="openEditProfilePictureDialog(dog)"
                            class="edit-button"
                            icon
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </v-overlay>
                        <img
                          v-if="dog.image_url != null && dog.name != null"
                          :src="dog.image_url"
                          :alt="dog.name"
                        />
                        <img
                          v-else
                          src="https://pawportal.blob.core.windows.net/images/PP_Black.png"
                          alt="Default"
                        />
                      </v-avatar>
                    </v-hover>
                  </v-col>
                  <v-col align="start" md="7">
                    <p
                      style="font-size: 1.5rem"
                      class="font-weight-medium pb-0 mb-0"
                    >
                      {{ dog.name }}
                    </p>
                    <p class="pt-0 mt-0 mb-1">
                      {{ capitalizeEachWord(dog.breed.breed) }}
                    </p>
                    <p class="pb-0 mb-0">
                      <b>GENDER:</b>
                    </p>
                    <p class="pt-0 mt-0 mb-1">
                      {{
                        dog.gender == null
                          ? "Unknown"
                          : dog.gender.charAt(0).toUpperCase() +
                            dog.gender.toLowerCase().slice(1)
                      }}
                    </p>
                    <p class="pb-0 mb-0">
                      <b>AGE:</b>
                    </p>
                    <p class="py-0 my-0">
                      {{
                        dog.dob == null ? "Unknown" : getYearsAndMonths(dog.dob)
                      }}
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn icon @click.stop="dog.show = !dog.show">
                  <v-icon>{{
                    dog.show ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-card-actions>
              <v-expand-transition>
                <div v-show="dog.show">
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row>
                      <v-col md="6">
                        <p>
                          <v-icon small>mdi-tag-outline</v-icon><b>Colour:</b>
                          {{ dog.colour ? dog.colour : "Unknown" }}
                        </p>
                        <p>
                          <v-icon small>mdi-paw-off</v-icon><b>Microchipped:</b>
                          {{ dog.microchipped ? "Yes" : "No" }}
                        </p>
                        <p>
                          <v-icon small>mdi-bandage</v-icon><b>Spayed:</b>
                          {{ dog.spayed ? "Yes" : "No" }}
                        </p>
                        <p>
                          <v-icon small>mdi-alert-outline</v-icon
                          ><b>Aggressive:</b>
                          {{ dog.is_aggresive ? "Yes" : "No" }}
                        </p>
                        <p>
                          <v-icon small>mdi-alert-outline</v-icon
                          ><b>Aggressive additional info:</b>
                          {{ dog.is_aggresive_more_info ? "Yes" : "No" }}
                        </p>
                        <p>
                          <v-icon small>mdi-shield-check-outline</v-icon
                          ><b>Needs muzzle:</b>
                          {{ dog.needs_muzzle ? "Yes" : "No" }}
                        </p>
                        <p>
                          <v-icon small>mdi-information-outline</v-icon
                          ><b>Needs muzzle additional info:</b>
                          {{
                            dog.needs_muzzle_more_info
                              ? dog.needs_muzzle_more_info
                              : "N/A"
                          }}
                        </p>
                        <p>
                          <v-icon small>mdi-alert-circle-outline</v-icon
                          ><b>Allergy information:</b>
                          {{
                            dog.allergy_information
                              ? dog.allergy_information
                              : "N/A"
                          }}
                        </p>
                      </v-col>
                      <v-col md="6">
                        <p>
                          <v-icon small>mdi-calendar-clock</v-icon><b>DOB:</b>
                          {{ dog.dob ? formatDob(dog.dob) : "Unknown" }}
                        </p>
                        <p>
                          <v-icon small>mdi-gender-male-female</v-icon
                          ><b>Gender:</b>
                          {{ dog.gender ? dog.gender : "Unknown" }}
                        </p>
                        <p>
                          <v-icon small>mdi-shield-check-outline</v-icon
                          ><b>Insurance company:</b>
                          {{
                            dog.insurance_company_name
                              ? dog.insurance_company_name
                              : "N/A"
                          }}
                        </p>
                        <p>
                          <v-icon small>mdi-shield-check-outline</v-icon
                          ><b>Insurance policy ID:</b>
                          {{
                            dog.insurance_policy_id
                              ? dog.insurance_policy_id
                              : "N/A"
                          }}
                        </p>
                        <p>
                          <v-icon small>mdi-leash</v-icon><b>Let off lead:</b>
                          {{ dog.let_off_lead ? "Yes" : "No" }}
                        </p>
                        <p>
                          <v-icon small>mdi-account-multiple</v-icon
                          ><b>Linked supervisors:</b>
                          {{
                            dog.linked_supervisors
                              ? dog.linked_supervisors
                              : "N/A"
                          }}
                        </p>
                        <p>
                          <v-icon small>mdi-pill</v-icon><b>Medication:</b>
                          {{
                            dog.medication && dog.medication.length
                              ? dog.medication.join(", ")
                              : "N/A"
                          }}
                        </p>
                        <p>
                          <v-icon small>mdi-note-text-outline</v-icon
                          ><b>Notes:</b>
                          {{
                            dog.additional_info ? dog.additional_info : "N/A"
                          }}
                        </p>

                        <!-- Recall -->
                        <p>
                          <v-icon small>mdi-whistle-outline</v-icon
                          ><b>Recall:</b>
                          {{ dog.recall ? dog.recall : "N/A" }}
                        </p>

                        <!-- Vaccinations -->
                        <p>
                          <v-icon small>mdi-needle</v-icon><b>Vaccinations:</b>
                        </p>
                        <p
                          v-for="vaccination in dog.vaccinations"
                          :key="vaccination.id"
                        >
                          <b>Name: </b> {{ vaccination.name }}
                          <br />
                          <b>Date Administered: </b>
                          {{ formatDate(vaccination.date_administered) }}
                        </p>
                        <p
                          v-if="
                            dog.vaccinations == null ||
                            dog.vaccinations.length == 0
                          "
                        >
                          No vaccinations recorded
                        </p>

                        <!-- Vet Info -->
                        <p>
                          <v-icon small>mdi-hospital-marker</v-icon
                          ><b>Vet info:</b>
                        </p>
                        <p
                          v-for="(vet_info, index) in dog.vet_info"
                          :key="index"
                        >
                          <b>Name: </b> {{ vet_info.name }}
                          <br />
                          <b>Address: </b> {{ vet_info.address }}
                          <br />
                          <b>Phone Number: </b> {{ vet_info.phone_number }}
                        </p>
                        <p
                          v-if="
                            dog.vet_info == null || dog.vet_info.length == 0
                          "
                        >
                          No vet info recorded
                        </p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import axios from "axios";
import EditProfilePictureDialog from "./EditProfilePictureDialog.vue";

export default {
  name: "Account",
  components: {
    EditProfilePictureDialog,
  },
  data() {
    return {
      showInvite: false,
      confirmDeleteDialog: false,
      petToDelete: null,
      showEditProfilePictureDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      dogs: "pets/dogs",
      dogsLoading: "pets/dogsLoading",
      dogDeleteLoading: "pets/dogDeleteLoading",
      dogDeleteComplete: "pets/dogDeleteComplete",
      dogDeleteError: "pets/dogDeleteError",
      dogDeleteErrorItems: "pets/dogDeleteErrorItems",
    }),
    dialogWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "80%";
        case "md":
          return "60%";
        case "lg":
          return "40%";
        case "xl":
          return "40%";
      }
      return "100%";
    },
  },
  watch: {
    uploadProfilePictureComplete(val) {
      if (val) {
        // Wait 2 seconds
        setTimeout(() => {
          this.showEditProfilePictureDialog = false;
        }, 2000);
      }
    },
    dogDeleteComplete(val) {
      if (val) {
        setTimeout(() => {
          this.confirmDeleteDialog = false;
        }, 2000);
      }
    },
  },
  methods: {
    ...mapActions({
      getDogs: "pets/getDogs",
      resetForm: "pets/resetForm",
      deleteDog: "pets/deleteDog",
      resetDeleteDog: "pets/resetDeleteDog",
      resetUploadProfilePicture: "pets/resetUploadProfilePicture",
      setUploadProfilePictureSelectedPet:
        "pets/setUploadProfilePictureSelectedPet",
    }),
    updateProfilePicture(newProfilePicture) {
      // Update the profile picture with the new data
      // this.profilePicture = newProfilePicture.src;
      // this.altText = newProfilePicture.alt;
      // Hide the dialog component
      this.showEditProfilePictureDialog = false;
    },
    openEditProfilePictureDialog(val) {
      this.resetUploadProfilePicture();
      this.setUploadProfilePictureSelectedPet(val);
      this.showEditProfilePictureDialog = true;
    },
    closeDialog() {
      this.$nextTick(() => {
        this.confirmDeleteDialog = false;
      });
    },
    submitDeletePet() {
      console.log(`Deleting pet ${this.petToDelete}`);
      this.deleteDog(this.petToDelete.id);
    },
    showDeleteDialog(val) {
      this.resetDeleteDog();
      console.log(`Showing delete dialog for pet:`, val);
      this.petToDelete = val;
      this.confirmDeleteDialog = true;
    },
    formatDate(date) {
      // convert Date to string in format YYYY-MM-DD
      return moment(date).format("DD-MM-YYYY");
    },
    getYearsAndMonths(val) {
      if (!val) {
        return "No DOB has been provided"; // or whatever you want to display when the date is not available
      }

      var start = moment(val, "YYYY-MM-DDTHH:mm:ss");
      var end = moment();

      var years = end.diff(start, "year");
      start.add(years, "years");

      var months = end.diff(start, "months");
      start.add(months, "months");

      var ageString = years === 1 ? years + " year, " : years + " years, ";
      ageString += months === 1 ? months + " month " : months + " months";

      return ageString;
    },
    formatDob(val) {
      if (!val) {
        return "No DOB has been provided"; // or whatever you want to display when the date is not available
      }

      var dob = moment(val, "YYYY-MM-DDTHH:mm:ss");

      return dob.format("DD/MM/YYYY");
    },
    capitalizeEachWord(str) {
      if (!str) return "";
      return str
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    goToEdit(id) {
      this.$router.push({
        path: `/pets/dogs/create`,
        query: { edit: id },
      });
    },
  },
  mounted() {
    if (this.dogs.length === 0) {
      this.getDogs();
    }
  },
};
</script>

<style></style>
