<template>
  <v-row>
    <v-col>
      <v-card max-width="450" class="mx-auto">
        <v-toolbar color="cyan" dark>
          <v-app-bar-nav-icon></v-app-bar-nav-icon>
          <v-toolbar-title>Upcoming Events</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn> -->
        </v-toolbar>

        <v-list three-line>
          <v-list-item v-if="futureEvents.length == 0"
            >You have no upcoming events</v-list-item
          >
          <template
            v-for="(item, index) in orderBy(
              futureEvents,
              (item) => item.service_booking.calendar_event.start
            )"
          >
            <v-list-item :key="item.id">
              <!-- <v-list-item-avatar>
                <v-img :src="item.avatar"></v-img>
              </v-list-item-avatar> -->

              <v-list-item-content>
                <v-list-item-title
                  >{{ convertDate(item.service_booking.calendar_event.start) }}
                  -
                  {{
                    item.service_booking.calendar_event.name
                  }}</v-list-item-title
                >

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-subtitle v-on="on" v-bind="attrs">
                      <div class="pre-formatted">
                        {{ item.service_booking.calendar_event.description }}
                      </div>
                    </v-list-item-subtitle>
                  </template>
                  <span>
                    <div class="pre-formatted">
                      {{ item.service_booking.calendar_event.notes }}
                    </div></span
                  >
                </v-tooltip>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="index" :inset="item.inset"></v-divider>
          </template>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import Vue2Filters from "vue2-filters";

export default {
  name: "CalendarEvents",
  mixins: [Vue2Filters.mixin],
  data: () => ({
    focus: "",
  }),
  components: {},
  computed: {
    ...mapGetters({
      authenticated: "auth/isAuthenticated",
      user: "auth/user",
      bookings: "bookings/bookings",
    }),
    futureEvents: function () {
      var mappedEvents = [];
      console.log("this.bookings", this.bookings);
      this.bookings.forEach((event) => {
        if (
          moment(event.service_booking.calendar_event.start).isAfter(moment())
        ) {
          mappedEvents.push(event);
        }
      });
      console.log("mappedEvents", mappedEvents);
      return mappedEvents;
    },
  },
  mounted() {},
  methods: {
    convertDate(item) {
      return moment(item).format("DD/MM - hh:mm");
    },
  },
};
</script>

<style>
.pre-formatted {
  white-space: pre;
}
</style>
