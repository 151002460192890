var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-overlay',{attrs:{"absolute":true,"opacity":0.2,"value":_vm.invitesLoading}},[_c('v-progress-circular',{attrs:{"width":4,"size":70,"color":"#7db52f","indeterminate":""}})],1),_c('v-row',{staticClass:"pa-2",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"12","align":"center"}},[_c('v-row',[_c('v-col',[_c('v-img',{attrs:{"src":"https://pawportal.blob.core.windows.net/images/280x60 5-02.png"}})],1)],1),_c('v-row',[_c('v-col',[_c('span',{staticClass:"text-h4"},[_vm._v(" Email Preferences ")])])],1),_c('v-row',[_c('v-col',[_vm._v(" If you wish to opt-out of recieving any future emails from PawPortal, please enter your email below. ")])],1),(_vm.createdInvite.length != 0)?_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('p',[_vm._v("Invite sent successfully!")]),_c('p',[_c('b',[_vm._v("Invite Code: ")]),_vm._v(" "+_vm._s(_vm.createdInvite.code))]),(
                  _vm.createdInvite.mobile_number != null &&
                  _vm.createdInvite.mobile_number != 0 &&
                  (_vm.createdInvite.email == '' || _vm.createdInvite.email == null)
                )?_c('span',[_c('p',[_vm._v("Your recipient should have recieved a text message.")]),_c('p',[_c('b',[_vm._v("Mobile Number: ")]),_vm._v(" "+_vm._s(_vm.createdInvite.mobile_number)+" ")])]):_vm._e(),(
                  _vm.createdInvite.email != null &&
                  _vm.createdInvite.email != '' &&
                  (_vm.createdInvite.mobile_number == 0 ||
                    _vm.createdInvite.mobile_number == null)
                )?_c('span',[_c('p',[_vm._v("Your recipient should have recieved an email.")]),_c('p',[_c('b',[_vm._v("Email: ")]),_vm._v(" "+_vm._s(_vm.createdInvite.email))])]):_vm._e(),(
                  _vm.createdInvite.email != null &&
                  _vm.createdInvite.email != '' &&
                  _vm.createdInvite.mobile_number != null &&
                  _vm.createdInvite.mobile_number != 0
                )?_c('span',[_c('p',[_vm._v(" Your recipient should have recieved an email and a text message. ")]),_c('p',[_c('b',[_vm._v("Email: ")]),_vm._v(" "+_vm._s(_vm.createdInvite.email))]),_c('p',[_c('b',[_vm._v("Mobile Number: ")]),_vm._v(" "+_vm._s(_vm.createdInvite.mobile_number)+" ")])]):_vm._e()])],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',[_c('v-form',[_c('v-text-field',{attrs:{"error-messages":_vm.emailErrors,"label":"Email address"},on:{"change":function($event){return _vm.$v.email.$touch()},"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-btn',{attrs:{"color":"#7db52f"},on:{"click":_vm.submit}},[_vm._v(" Opt-Out ")])],1)],1)],1)],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"md":"12"}},[_c('v-alert',{attrs:{"type":"warning","value":_vm.createInvitesError}},_vm._l((_vm.createInvitesErrorItems),function(item){return _c('li',{key:item.msg},[_vm._v(" "+_vm._s(item.message)+" "+_vm._s(item.item)+" ")])}),0),_c('v-alert',{attrs:{"type":"success","value":_vm.createInvitesComplete}},[_vm._v(" Invite Created Successfully! ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }