<template v-if="authenticated">
  <v-container class="pa-8">
    <v-overlay :absolute="true" :opacity="0.2" :value="acceptInviteloading">
      <v-progress-circular
        :width="4"
        :size="70"
        color="#7db52f"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="showAcceptInvite" persistent :width="dialogWidth">
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#7db52f" fab small dark @click="closeDialogue()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <AcceptInviteDialogue :key="acceptInviteCode" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row class="pa-2" justify="center">
      <v-col align="center">
        <v-sheet color="white" rounded="xl" class="pa-8">
          <v-row v-if="invites.length == 0">
            <v-col>
              <v-row>
                <v-col>
                  <p><b>You have no invites.</b></p>
                  <p>
                    If you are expecting one, please speak with your animal
                    supervisor.
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col>
              <v-row>
                <v-col>
                  <p>Good news! You have {{ invites.length }} invites.</p>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col md="10" sm="12">
                  <v-data-table
                    dense
                    :headers="invitesHeaders"
                    :items="invites"
                    :loading="invitesLoading"
                    item-key="name"
                    :items-per-page="-1"
                    :hide-default-footer="true"
                    class="elevation-1"
                    v-if="invites.length != 0"
                  >
                    <template v-slot:item.invite.code="{ item }">
                      <v-chip class="ma-2" label outlined color="#7db52f">
                        {{ item.invite.code }}
                      </v-chip>
                    </template>
                    <template v-slot:item.company_name="{ item }">
                      <span
                        class="body-2"
                        v-if="
                          item.company_name == null || item.company_name == ''
                        "
                      >
                        <i>No company name provided</i></span
                      >
                      <span v-else>{{ item.company_name }}</span>
                    </template>
                    <template v-slot:item.invite.mobile_number="{ item }">
                      <v-btn
                        color="#7db52f"
                        small
                        @click="acceptInviteShow(item.invite.code)"
                      >
                        <v-icon>mdi-plus</v-icon> Accept Invite
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="getInvitesError">
            <v-col>
              <v-alert type="error">
                There was a server error when loading your invites.
              </v-alert>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AcceptInviteDialogue from "./AcceptInviteDialogue.vue";
export default {
  name: "Account",
  data() {
    return {
      showAcceptInvite: false,
      invitesHeaders: [
        {
          text: "INVITE CODE",
          value: "invite.code",
        },
        {
          text: "COMPANY NAME",
          value: "company_name",
        },
        {
          text: "ACCEPT",
          value: "invite.mobile_number",
        },
      ],
    };
  },
  components: {
    AcceptInviteDialogue,
  },
  computed: {
    ...mapGetters({
      invites: "invites/invites",
      createInvitesComplete: "invites/createInvitesComplete",
      createInvitesError: "invites/createInvitesError",
      createInvitesErrorItems: "invites/createInvitesErrorItems",
      invitesLoading: "invites/loading",
      acceptInviteloading: "invites/acceptInviteloading",
      acceptInviteCode: "invites/acceptInviteCode",
      getInvitesError: "invites/getInvitesError",
      checkInviteResponse: "invites/checkInviteResponse",
    }),
    dialogWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "80%";
        case "md":
          return "60%";
        case "lg":
          return "40%";
        case "xl":
          return "40%";
      }
      return "100%";
    },
  },
  methods: {
    ...mapActions({}),
    acceptInviteShow(code) {
      // Push to inviteCode with query params
      this.$router.push({ name: "InviteCode", query: { c: code } });
      // this.$store.commit(
      //   "invites/SET_ACCEPT_INVITE_SUPERVISOR_ID",
      //   supervisor_id
      // );
      // this.$store.commit("invites/SET_ACCEPT_INVITE_CODE", code.toUpperCase());
      // this.showAcceptInvite = true;
    },
    closeDialogue() {
      this.$store.commit("invites/SET_ACCEPT_INVITE_CODE", "");
      this.$store.commit("invites/SET_ACCEPT_INVITE_SUPERVISOR_ID", "");
      this.showAcceptInvite = false;
    },
  },
  mounted: function () {
    this.getInvites({});
    const code = this.$route.query.c;
    if (code != undefined) {
      this.acceptInviteShow(code, this.checkInviteResponse.supervisor_id);
    }
    // this.getDogs();
  },
  watch: {
    invites() {
      // console.log("Invites changed");
      // console.log(val);
    },
  },
};
</script>

<style></style>
