import axios from 'axios';

export default {
    namespaced: true,

    state: {
        companies: [],
        selectedCompany: [],
        showSelectCompany: false,
        getCompaniesLoading: false,
        errorGettingCompanies: false,
        errors: [],
        createCompany: {
            registrationComplete: false,
            registrationError: false,
            registrationErrorItems: [],
            registrationLoading: false,
        },
        employees: {
            loading: false,
            error: false,
            items: [],
        },
        customers: {
            loading: false,
            error: false,
            errorItems: [],
            items: [],
        }
    },

    mutations: {
        RESET_STATE(state) {
            console.log("RESET_STATE called")
            state.companies = [];
        },
        SET_COMPANIES(state, companyData) {
            console.log(`SET_COMPANIES called with ${companyData.length} companies`)
            console.log(`companyData: ${JSON.stringify(companyData)}`)
            state.companies = companyData;
            // if there is only one company, set it as the selected company
            if (state.companies.length == 1) {
                state.selectedCompany = state.companies[0];
            }
        },
        SET_GET_COMPANIES_LOADING(state, loading) {
            state.getCompaniesLoading = loading;
        },
        SET_GET_COMPANIES_ERROR(state, errors) {
            state.errorGettingCompanies = true;
            try {
                errors = errors.data;
            }
            catch (error) {
                console.log("Error getting companies: ", error)
            }
            if (errors.length > 0) {
                state.errors = errors;
            } else {
                let errors = [];
                errors.push({
                    item: "Error",
                    message: "Error getting companies"
                });
                state.errors = errors;
            }
        },
        SET_CREATE_COMPANY_REGISTRATION_COMPLETE(state, complete) {
            state.createCompany.registrationComplete = complete;
        },
        SET_CREATE_COMPANY_REGISTRATION_ERROR(state, val) {
            state.createCompany.registrationError = true;
            state.createCompany.registrationErrorItems = [];
            try {
                val.detail.forEach(element => {
                    const item = {
                        item: element.loc[1].charAt(0).toUpperCase() + element.loc[1].slice(1).replace(/_/g, " "),
                        message: element.msg.charAt(0).toUpperCase() + element.msg.slice(1).replace(/_/g, " ")
                    }
                    state.createCompany.registrationErrorItems.push(item)
                });

            } catch (error) {
                console.log("Registration error message : ", error)
                state.createCompany.registrationErrorItems.push({
                    item: "Server error",
                    message: "Please try again later"
                })
            }
        },
        SET_CREATE_COMPANY_REGISTRATION_LOADING(state, loading) {
            state.createCompany.registrationLoading = loading;
        },
        RESET_CREATE_COMPANY_REGISTRATION(state) {
            state.createCompany.registrationComplete = false;
            state.createCompany.registrationError = false;
            state.createCompany.registrationErrorItems = [];
            state.createCompany.registrationLoading = false;
        },
        SET_EMPLOYEES_LOADING(state, loading) {
            state.employees.loading = loading;
        },
        SET_EMPLOYEES_ERROR(state, error) {
            state.employees.error = error;
        },
        SET_EMPLOYEES(state, employees) {
            state.employees.items = employees;
        },
        APPEND_EMPLOYEES(state, employees) {
            // foreach in items
            console.log("APPEND_EMPLOYEES called", employees)
            employees.forEach(employee => {
                console.log("APPEND_EMPLOYEES employee", employee)
                state.employees.items.push(employee);
            });
            console.log("APPEND_EMPLOYEES state.employees.items", state.employees.items)
        },
        SET_SELECTED_COMPANY(state, company) {
            console.log("SET_SELECTED_COMPANY called with company: ", company)
            state.selectedCompany = company;
            state.showSelectCompany = false;
            // Save the selected company ID to localStorage
            localStorage.setItem('selectedCompanyId', company.id);
        },
        SET_CUSTOMERS_LOADING(state, loading) {
            state.customers.loading = loading;
        },
        SET_CUSTOMERS_ERROR_ITEMS(state, errorItems) {
            state.customers.error = true;
            state.customers.errorItems = [];
            try {
                errorItems = errorItems.data;
                errorItems.detail.forEach(element => {
                    const item = {
                        item: element.loc[1].charAt(0).toUpperCase() + element.loc[1].slice(1).replace(/_/g, " "),
                        message: element.msg.charAt(0).toUpperCase() + element.msg.slice(1).replace(/_/g, " ")
                    }
                    state.customers.errorItems.push(item)
                });

            } catch (error) {
                console.log("Registration error message : ", error)
                state.customers.errorItems.push({
                    item: "Server error",
                    message: "Please try again later"
                })
            }
        },
        SET_CUSTOMERS(state, customers) {
            state.customers.items = customers;
        },
        SET_SHOW_SELECT_COMPANY(state, show) {
            state.showSelectCompany = show;
        },
        INITIALIZE_SELECTED_COMPANY(state) {
            const selectedCompanyId = localStorage.getItem('selectedCompanyId');
            if (selectedCompanyId) {
                const selectedCompany = state.companies.find(company => company.id === selectedCompanyId);
                if (selectedCompany) {
                    state.selectedCompany = selectedCompany;
                    state.showSelectCompany = false;
                }
            }
        },
    },

    getters: {
        all(state) {
            return state;
        },
        companies(state) {
            return state.companies;
        },
        getCompaniesLoading(state) {
            return state.getCompaniesLoading;
        },
        errorGettingCompanies(state) {
            return state.errorGettingCompanies;
        },
        errors(state) {
            return state.errors;
        },
        registrationComplete(state) {
            return state.createCompany.registrationComplete;
        },
        registrationError(state) {
            return state.createCompany.registrationError;
        },
        registrationErrorItems(state) {
            return state.createCompany.registrationErrorItems;
        },
        registrationLoading(state) {
            return state.createCompany.registrationLoading;
        },
        employeesLoading(state) {
            return state.employees.loading;
        },
        employeesError(state) {
            return state.employees.error;
        },
        employeeItems(state) {
            return state.employees.items;
        },
        selectedCompany(state) {
            return state.selectedCompany;
        },
        customersLoading(state) {
            return state.customers.loading;
        },
        customersError(state) {
            return state.customers.error;
        },
        customersErrorItems(state) {
            return state.customers.errorItems;
        },
        customers(state) {
            return state.customers.items;
        },
        showSelectCompany(state) {
            return state.showSelectCompany;
        }
    },

    actions: {
        async getCompanies({ rootState, commit, state, dispatch }) {
            commit('SET_GET_COMPANIES_LOADING', true);

            try {
                const response = await axios.get('company', {
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.token,
                    },
                });
                console.log("Companies: ", response.data)
                commit('SET_COMPANIES', response.data);

                // If accountType is PET_OWNER, return
                const accountType = rootState.account.accountDetails.type;
                if (accountType == 'PET_OWNER') {
                    return;
                }

                // Check localStorage for a selected company ID
                const selectedCompanyId = localStorage.getItem('selectedCompanyId');
                // If a selected company ID exists and it's among the retrieved companies, set the selected company
                if (selectedCompanyId) {
                    const selectedCompany = state.companies.find(company => company.id === selectedCompanyId);
                    if (selectedCompany) {
                        commit('SET_SELECTED_COMPANY', selectedCompany);
                        dispatch('onSelectedCompanyChanged');
                    }
                    else {
                        // If the selected company ID doesn't exist in the retrieved companies, show the select company dialog
                        console.log("Selected company ID doesn't exist in the retrieved companies, SHOW SELECT COMPANY")
                        commit('SET_SHOW_SELECT_COMPANY', true);
                    }
                }
                else {
                    // If there is only one company, set it as the selected company
                    if (state.companies.length == 1) {
                        console.log("There is only one company, SET SELECTED COMPANY")
                        commit('SET_SELECTED_COMPANY', state.companies[0]);
                        dispatch('onSelectedCompanyChanged');
                    }
                    else {
                        // If there is more than one company, show the select company dialog
                        console.log("There is more than one company, SHOW SELECT COMPANY")
                        commit('SET_SHOW_SELECT_COMPANY', true);
                    }
                }
            } catch (error) {
                console.error("Error with getting company:", error.message);
                commit('SET_GET_COMPANIES_ERROR', error.response);
            }
            finally {
                commit('SET_GET_COMPANIES_LOADING', false);
            }
        },
        // Used to update the list of employees
        async updateEmployees({
            rootState,
            commit
        }, companies) {
            try {
                console.log("Getting company employees")
                // Set SET_GET_COMPANIES_LOADING to true
                commit('SET_GET_COMPANIES_LOADING', true);
                let employees = [];
                // Loop through companies
                companies.forEach(async company => {
                    await axios
                        .get('company/' + company.id + "/employees", {
                            headers: {
                                Authorization: 'Bearer ' + rootState.auth.token,
                            },
                        })
                        .then((resp) => {
                            // Set company data
                            console.log("Employees data: ", resp.data)
                            // Append
                            resp.data.forEach(employee => {
                                employees.push(employee);
                            });
                            commit('SET_EMPLOYEES_LOADING', false);
                        })
                        .catch((error) => {
                            console.log("Error with getting employees: ", error.message);
                            commit('SET_EMPLOYEES_LOADING', false);
                            commit('SET_EMPLOYEES_ERROR', error.response);
                        })
                });
                commit('SET_EMPLOYEES', employees);
                commit('SET_EMPLOYEES_LOADING', false);
            } catch (e) {
                console.log("Error with getting employees: ", error.message);
                commit('SET_EMPLOYEES_LOADING', false);
                commit('SET_EMPLOYEES_ERROR', error.response.data);
            }
        },
        // Used to get a list of employees for 1 company
        async getEmployees({
            rootState,
            commit
        }, company_id) {
            try {
                console.log("Getting company employees")
                // Set SET_GET_COMPANIES_LOADING to true
                commit('SET_GET_COMPANIES_LOADING', true);
                await axios
                    .get('company/' + company_id + "/employees", {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.token,
                        },
                    })
                    .then((resp) => {
                        // Set company data
                        console.log("Employees data: ", resp.data)
                        // Set employees
                        commit('SET_EMPLOYEES', resp.data);
                        // Return
                        return resp.data;
                    })
                    .catch((error) => {
                        console.log("Error with getting employees: ", error.message);
                        // commit('SET_EMPLOYEES_LOADING', false);
                        // commit('SET_EMPLOYEES_ERROR', error.response.data);
                    })
                    .finally(() => {
                        commit('SET_EMPLOYEES_LOADING', false);
                    });
            } catch (e) {
                console.log(e);
                // commit('SET_EMPLOYEES_LOADING', false);
            }
        },
        async getEmployeeDetails({
            rootState,
            commit
        }, employee_id) {
            try {
                console.log("Getting employee details")
                // Set SET_GET_COMPANIES_LOADING to true
                commit('SET_GET_COMPANIES_LOADING', true);
                await axios
                    .get('user/' + employee_id, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.token,
                        },
                    })
                    .then((resp) => {
                        // Return the user
                        console.log("Employee details data: ", resp.data)
                        return resp.data;
                    })
                    .catch((error) => {
                        return "ERROR" + error;
                    })
            } catch (e) {
                console.log(e);
                return "ERROR" + e;
            }
        },
        async resetState({
            commit
        }) {
            commit('RESET_STATE', null);
        },
        async createCompany({
            rootState,
            commit,
            dispatch
        }, registerData) {
            try {
                commit('SET_CREATE_COMPANY_REGISTRATION_LOADING', true);
                await axios
                    .post('company/', registerData, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.token,
                        },
                    })
                    .then((response) => {
                        console.log("Create company response:", response);
                        commit('SET_CREATE_COMPANY_REGISTRATION_COMPLETE', true);
                        console.log("Refreshing companies")
                        dispatch('getCompanies')
                        commit('SET_CREATE_COMPANY_REGISTRATION_LOADING', false);
                    })
                    .catch((error) => {
                        // console.log(error.response.data);
                        console.log("ERROR when registering company: ", error.response.data)
                        commit('SET_CREATE_COMPANY_REGISTRATION_ERROR', error.response.data);
                        commit('SET_CREATE_COMPANY_REGISTRATION_LOADING', false);
                    });
            } catch (e) {
                console.log("ERROR when registering: ", e)
                commit('SET_REGISTRATION_ERROR', null);
                commit('SET_CREATE_COMPANY_REGISTRATION_LOADING', false);
            }
        },
        async updateProfile({
            rootState,
            commit,
            dispatch
        }, updateData) {
            try {
                commit('SET_UPDATE_PROFILE_LOADING', true);
                await axios
                    .put('user/', updateData, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.token,
                        },
                    })
                    .then(() => {
                        commit('SET_UPDATE_PROFILE_COMPLETE', true);
                        dispatch('getAccount')
                    })
                    .catch((error) => {
                        // console.log(error.response.data);
                        commit('SET_UPDATE_PROFILE_ERROR', error.response.data);
                    })
                    .finally(() => {
                        commit('SET_UPDATE_PROFILE_LOADING', false);
                    });
            } catch (e) {
                console.log("ERROR when updating profile: ", e)
                commit('SET_UPDATE_PROFILE_ERROR', null);
            }
        },
        async getCustomers({ rootState, commit }, company_id) {
            commit('SET_CUSTOMERS_LOADING', true);
            try {
                const response = await axios.get(`company/${company_id}/customers`, {
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.token,
                    },
                });

                commit('SET_CUSTOMERS', response.data);
            } catch (error) {
                console.log("Error with getting customers: ", error.message);
                console.log("Error with getting customers: ", error);
                commit('SET_CUSTOMERS_ERROR_ITEMS', error.response);

            } finally {
                commit('SET_CUSTOMERS_LOADING', false);
            }
        },
        async setCompany({
            commit,
            dispatch
        }, company) {
            commit('SET_SELECTED_COMPANY', company);
            dispatch('onSelectedCompanyChanged');
        },
        async setShowSelectCompany({
            commit
        }, show) {
            commit('SET_SHOW_SELECT_COMPANY', show);
        },
        async onSelectedCompanyChanged({
            dispatch,
            state,
            rootState
        }) {
            // Call getter account/accountDetails
            const accountDetails = rootState.account.accountDetails;
            const accountType = accountDetails.type;

            console.log("onSelectedCompanyChanged called for account type: ", accountType)
            console.log("CURRENT COMPANY: ", state.selectedCompany)
            ///// PET SUPERVISOR /////
            if (accountType == "PET_SUPERVISOR") {
                // Refresh employees
                dispatch('getEmployees', state.selectedCompany.id);
                // Refresh customers
                dispatch('getCustomers', state.selectedCompany.id);
                // Refresh services
                dispatch('services/getServices', state.selectedCompany.id, {
                    root: true
                });
                // Refresh customer invites
                dispatch('invites/getInvites', {
                    company_id: state.selectedCompany.id,
                }, {
                    root: true
                });
                // Refresh company invites
                dispatch('invites/getCompanyInvites', {
                    company_id: state.selectedCompany.id,
                }, {
                    root: true
                });
                // Refresh bookings
                dispatch('bookings/getBookings', {
                    company_id: state.selectedCompany.id,
                }, {
                    root: true
                });
            }

            ///// PET OWNER /////
            if (accountType == 'PET_OWNER') {
                // If not a company, get all invites
                dispatch('invites/getInvites', {
                }, {
                    root: true
                });
                // Refresh bookings
                dispatch('bookings/getBookings', {
                }, {
                    root: true
                });
            }
        }
    },
};