import { render, staticRenderFns } from "./InvitesSupervisor.vue?vue&type=template&id=8aac9d1c&v-if=authenticated"
import script from "./InvitesSupervisor.vue?vue&type=script&lang=js"
export * from "./InvitesSupervisor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports