<template v-if="authenticated">
  <v-container>
    <v-overlay :absolute="true" :opacity="0.2" :value="invitesLoading">
      <v-progress-circular
        :width="4"
        :size="70"
        color="#7db52f"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-row class="pa-2" justify="center">
      <v-col md="12" align="center">
        <v-row>
          <v-col>
            <v-img
              src="https://pawportal.blob.core.windows.net/images/280x60 5-02.png"
            ></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="text-h4"> Email Preferences </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            If you wish to opt-out of recieving any future emails from
            PawPortal, please enter your email below.
          </v-col>
        </v-row>
        <v-row v-if="createdInvite.length != 0">
          <v-col>
            <v-row>
              <v-col>
                <p>Invite sent successfully!</p>
                <p><b>Invite Code: </b> {{ createdInvite.code }}</p>
                <span
                  v-if="
                    createdInvite.mobile_number != null &&
                    createdInvite.mobile_number != 0 &&
                    (createdInvite.email == '' || createdInvite.email == null)
                  "
                >
                  <p>Your recipient should have recieved a text message.</p>
                  <p>
                    <b>Mobile Number: </b> {{ createdInvite.mobile_number }}
                  </p>
                </span>
                <span
                  v-if="
                    createdInvite.email != null &&
                    createdInvite.email != '' &&
                    (createdInvite.mobile_number == 0 ||
                      createdInvite.mobile_number == null)
                  "
                >
                  <p>Your recipient should have recieved an email.</p>
                  <p><b>Email: </b> {{ createdInvite.email }}</p>
                </span>
                <span
                  v-if="
                    createdInvite.email != null &&
                    createdInvite.email != '' &&
                    createdInvite.mobile_number != null &&
                    createdInvite.mobile_number != 0
                  "
                >
                  <p>
                    Your recipient should have recieved an email and a text
                    message.
                  </p>
                  <p><b>Email: </b> {{ createdInvite.email }}</p>
                  <p>
                    <b>Mobile Number: </b> {{ createdInvite.mobile_number }}
                  </p>
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-form>
              <v-text-field
                :error-messages="emailErrors"
                @change="$v.email.$touch()"
                @blur="$v.email.$touch()"
                v-model="email"
                label="Email address"
              ></v-text-field>

              <v-btn color="#7db52f" @click="submit"> Opt-Out </v-btn>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col md="12">
        <v-alert type="warning" :value="createInvitesError">
          <li v-bind:key="item.msg" v-for="item in createInvitesErrorItems">
            {{ item.message }}
            {{ item.item }}
          </li>
        </v-alert>
        <v-alert type="success" :value="createInvitesComplete">
          Invite Created Successfully!
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

export default {
  name: "Account",
  mixins: [validationMixin],
  validations: {
    mobile_number: {
      minLength: minLength(11),
      maxLength: maxLength(11),
      required(v) {
        return this.email || required(v);
      },
    },
    email: {
      email,
      required(v) {
        return this.mobile_number || required(v);
      },
    },
    form: function () {
      return required(this.email) || required(this.mobile_number);
    },
  },
  data() {
    return {
      mobile_number: null,
      email: null,
      invitesHeaders: [
        {
          text: "Invite Code",
          value: "InviteCode.code",
        },
        {
          text: "Email",
          value: "InviteCode.email",
        },
        {
          text: "Phone Number",
          value: "InviteCode.mobile_number",
        },
      ],
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      invites: "invites/invites",
      createInvitesComplete: "invites/createInvitesComplete",
      createdInvite: "invites/createdInvite",
      createInvitesError: "invites/createInvitesError",
      createInvitesErrorItems: "invites/createInvitesErrorItems",
      invitesLoading: "invites/loading",
    }),
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    mobile_numberErrors() {
      const errors = [];
      if (!this.$v.mobile_number.$dirty) return errors;
      !this.$v.mobile_number.minLength &&
        errors.push("Must be valid Phone Number");
      !this.$v.mobile_number.maxLength &&
        errors.push("Must be valid Phone Number");
      !this.$v.mobile_number.required &&
        errors.push("Phone Number is required");
      return errors;
    },
  },
  methods: {
    ...mapActions({
      resetState: "invites/resetState",
      createInvite: "invites/createInvite",
      resetInviteState: "invites/resetState",
    }),
    async logout() {
      console.log("Logging out...");
      await this.authLogout();
      this.$router.push({ name: "Login" });
    },
    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await this.createInvite({
          email: this.email == null ? "" : this.email.trim(),
          mobile_number:
            this.mobile_number == null ? 0 : this.mobile_number.trim(),
        });
        this.email = null;
        this.mobile_number = null;
        this.$v.$reset();
      }
    },
  },
  mounted: function () {
    this.resetInviteState();
    this.getInvites({});
    // this.getDogs();
  },
  watch: {
    invites(val) {
      console.log("Invites changed");
      console.log(val);
    },
  },
};
</script>

<style></style>
