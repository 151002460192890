<template>
  <v-container
    fluid
    class="pa-0 ma-0 d-flex flex-column fill-height"
    style="background: #cbd9c6"
  >
    <v-row justify="center" class="ma-0 pa-0">
      <v-col
        lg="8"
        md="10"
        sm="10"
        xs="12"
        class="ma-0 pa-0"
        align="center"
        style="background-color: #fffbf8"
      >
        <v-sheet color="white" class="pa-6">
          <v-row>
            <v-col align="center">
              <v-row>
                <v-col>
                  <h1>Welcome to Paw Portal</h1>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <p>
                    <span class="b2">ACCEPT AN INVITE CODE</span>
                  </p>
                  <p>
                    <span class="b2">ENTER YOUR INVITE CODE BELOW</span>
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col lg="6" md="6" sm="8" xs="12" cols="12">
              <v-otp-input
                :length="inviteCodeLength"
                v-model="enteredInviteCode"
                :readonly="loading"
                ref="otpinput"
                :color="
                  validCode == true
                    ? '#7db52f'
                    : '' || (executed && validCode == false)
                    ? 'red'
                    : ''
                "
              ></v-otp-input>
              <!-- Accept Invite code dialogue -->
              <v-dialog v-model="showAcceptInviteDialogue" max-width="50%">
                <AcceptInviteDialogue />
              </v-dialog>
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet
          color="#FFFBF8"
          class="mt-n16 d-flex flex-column position-relative"
        >
          <v-row justify="center" class="pt-10">
            <v-col lg="6" md="8" sm="12" xs="12" align="center">
              <v-form>
                <v-row justify="center">
                  <v-col lg="2" md="2" sm="2" xs="12" cols="12">
                    <v-progress-circular
                      v-if="executed && loading"
                      :width="4"
                      :size="40"
                      color="#7db52f"
                      indeterminate
                    ></v-progress-circular>
                    <v-icon :size="40" color="#7db52f" v-if="validCode"
                      >mdi-check</v-icon
                    >
                    <v-icon
                      :size="40"
                      color="red"
                      v-if="executed && loading == false && validCode == false"
                      >mdi-close</v-icon
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
          <v-row justify="center" class="ma-0 pa-0">
            <v-col align="center">
              <v-row
                justify="center"
                align="center"
                class="pa-0"
                v-if="validCode == true && !checkInviteResponse.invite_accepted"
                style="background-color: #fffbf8"
              >
                <v-col lg="6" md="8" sm="12" xs="12">
                  <v-form>
                    <v-row v-if="validCode == true" class="pa-0 ma-0">
                      <v-col>
                        This code belongs to
                        <b>
                          {{ checkInviteResponse.company_name }}
                        </b>
                      </v-col>
                    </v-row>
                    <v-row justify="center" class="pa-0 ma-0">
                      <v-col lg="6" md="8" sm="8" xs="8">
                        <!-- Display this if they are signed in -->
                        <v-row v-if="pawPortalAccount">
                          <v-col>
                            <v-btn
                              v-if="validCode == true"
                              color="#EA8C3C"
                              class="white--text"
                              depressed
                              @click="showAcceptInviteDialogue = true"
                              :disabled="loading"
                            >
                              Accept Invite Code
                            </v-btn>
                          </v-col>
                        </v-row>
                        <!-- Display this if they are not -->
                        <v-row v-if="!pawPortalAccount">
                          <v-col>
                            <v-btn
                              v-if="validCode == true"
                              color="#EA8C3C"
                              class="white--text"
                              depressed
                              @click="createAccount()"
                              :disabled="loading"
                            >
                              Create an account
                            </v-btn>
                          </v-col>
                          <v-col>
                            <v-btn
                              v-if="validCode == true"
                              color="#EA8C3C"
                              outlined
                              @click="signIn()"
                              :disabled="loading"
                            >
                              Sign In
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
              <v-row
                v-if="validCode == true && checkInviteResponse.invite_accepted"
              >
                <v-col> This invite code has already been accepted </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- reCAPTCHA row -->
        </v-sheet>
        <!-- Your existing code -->
        <!-- Add this line to push content to the bottom -->

        <!-- reCAPTCHA row -->
        <v-row
          no-gutters
          justify="center"
          class="pa-4"
          style="background-color: #fffbf8"
        >
          <v-col lg="4" md="6" sm="6" xs="6" align="center" class="footer">
            <p>
              <span>
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy" target="_blank"
                  >Privacy Policy</a
                >
                and
                <a href="https://policies.google.com/terms" target="_blank"
                  >Terms of Service</a
                >
                apply.
              </span>
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AcceptInviteDialogue from "./AcceptInviteDialogue.vue";

export default {
  name: "Account",
  data() {
    return {
      showAcceptInviteDialogue: false,
    };
  },
  components: {
    AcceptInviteDialogue,
  },
  computed: {
    ...mapGetters({
      inviteCode: "invites/checkInviteInviteCode",
      inviteCodeLength: "invites/checkInviteInviteCodeLength",
      loading: "invites/checkInviteLoading",
      validCode: "invites/checkInviteValidCode",
      error: "invites/checkInviteError",
      errorMessage: "invites/checkInviteErrorMessage",
      pawPortalAccount: "account/accountDetails",
      checkInviteInviteCode: "invites/checkInviteInviteCode",
      checkInviteResponse: "invites/checkInviteResponse",
    }),
    enteredInviteCode: {
      get() {
        return this.inviteCode;
      },
      set(value) {
        this.$store.commit("invites/SET_CHECK_INVITE_INVITE_CODE", value);
      },
    },
    // vh() {
    //   console.log(this.$vuetify.breakpoint);
    //   if (this.$vuetify.breakpoint.xsOnly) {
    //     return 21;
    //   } else if (this.$vuetify.breakpoint.smOnly) {
    //     return 22.9;
    //   } else if (this.$vuetify.breakpoint.mdOnly) {
    //     return 33.5;
    //   } else if (this.$vuetify.breakpoint.lgOnly) {
    //     return 33.5;
    //   } else if (this.$vuetify.breakpoint.xlOnly) {
    //     return 33.5;
    //   } else {
    //     return 33.5;
    //   }
    // },
    executed() {
      if (this.inviteCode.length == this.inviteCodeLength) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions({
      recaptcha: "invites/recaptcha",
      checkInvite: "invites/checkInvite",
    }),
    async callCheckInvite() {
      // ALSO USED AT REGISTER FORM
      // (optional) Wait until recaptcha has been loaded.dialog
      await this.$recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha("login");
      // console.log(token);
      await this.checkInvite(token);
      this.$refs.otpinput.focus();
    },
    signIn() {
      if (!this.pawPortalAccount) {
        this.$router.push({
          path: "/login",
          query: { from: "invitecode", c: this.checkInviteInviteCode },
        });
      } else {
        this.showAcceptInviteDialogue = true;
      }
    },
    createAccount() {
      this.$router.push({
        path: "/register",
        query: { t: "PET_OWNER", c: this.checkInviteInviteCode },
      });
    },
  },
  mounted: function () {
    // this.getInvites();
    this.$recaptchaLoaded();
    const code = this.$route.query.c;
    if (code != undefined) {
      this.$store.commit(
        "invites/SET_CHECK_INVITE_INVITE_CODE",
        code.toUpperCase()
      );
    }
  },
  watch: {
    inviteCode(val) {
      this.$store.commit(
        "invites/SET_CHECK_INVITE_INVITE_CODE",
        val.toUpperCase()
      );
      if (val.length == this.inviteCodeLength) {
        this.$store.commit("invites/SET_CHECK_INVITE_LOADING", true);
        // const token = this.$recaptcha("login");
        this.callCheckInvite();
      } else {
        this.$store.commit("invites/SET_CHECK_INVITE_LOADING", false);
        this.$store.commit("invites/SET_CHECK_INVITE_VALID_CODE", false);
      }
    },
  },
};
</script>

<style scoped></style>
