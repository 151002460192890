<template>
  <EarlyAccessComponent />
</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import EarlyAccessComponent from "../components/EarlyAccess.vue";

export default {
  name: "EarlyAccessPage",
  data() {
    return {
      dialog: false,
    };
  },
  components: {
    EarlyAccessComponent,
  },
  methods: {},
  mounted() {},
};
</script>

<style></style>
