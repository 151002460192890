<template>
  <h1>404'd</h1>
</template>

<script>
export default {
  name: 'PageNotFound',
};
</script>

<style></style>
