<template v-if="authenticated">
  <v-container class="pa-8">
    <v-overlay :absolute="true" :opacity="0.2" :value="invitesLoading">
      <v-progress-circular
        :width="4"
        :size="70"
        color="#7db52f"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="showInviteDialogue" persistent :width="dialogWidth">
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn class="mr-4" @click="showInviteDialogue = false"> Cancel </v-btn> -->
          <v-btn
            color="#7db52f"
            fab
            small
            dark
            @click="showInviteDialogue = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <CreateInviteForm v-if="showInviteDialogue" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDeleteDialogue" :width="dialogWidth">
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn class="mr-4" @click="showInviteDialogue = false"> Cancel </v-btn> -->
          <v-btn
            color="#7db52f"
            fab
            small
            dark
            @click="showDeleteDialogue = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text v-if="deleteCode != null">
          <v-row justify="center">
            <v-col align="center">
              <h2>Are you sure you want to delete this code?</h2>
              <br />
              <p class="alert">
                {{ deleteCode.invite.code }}
              </p>
            </v-col></v-row
          >
          <v-row>
            <v-col align="center">
              <p><b>Email:</b> {{ emailFormatted(deleteCode.invite.email) }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center">
              <p>
                <b>Phone Number:</b>
                {{ mobileFormatted(deleteCode.invite.mobile_number) }}
              </p>
            </v-col>
          </v-row>
          <v-row v-if="!deleteInviteComplete">
            <v-col align="center">
              <v-btn
                color="error"
                :loading="deleteInviteLoading"
                :disabled="deleteInviteLoading"
                @click="deleteInvite(deleteCode.invite.code)"
                >Delete</v-btn
              >
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="8">
              <v-alert type="success" :value="deleteInviteComplete">
                <h4>Code Deleted!</h4>
                <p class="py-0 my-0">Code deleted successfully!</p>
              </v-alert>
              <v-alert type="error" :value="deleteInviteError">
                <li
                  v-bind:key="item.msg"
                  v-for="item in deleteInviteErrorItems"
                >
                  {{ item.message }}
                  {{ item.item }}
                </li>
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row class="pa-2" justify="center">
      <v-col align="center">
        <v-sheet color="white" rounded="xl" class="pa-8">
          <v-row>
            <v-col>
              As a supervisor you can invite people into your account.
            </v-col>
          </v-row>
          <v-row v-if="selectedCompany == null">
            <v-col> Please select a company. </v-col>
          </v-row>
          <v-row justify="end" v-if="companies.length == 1 || selectedCompany">
            <v-col md="4" align="end">
              <v-btn
                color="#7db52f"
                @click="
                  showInviteDialogue =
                    true && setCreateInviteCompanyId(selectedCompany)
                "
              >
                <v-icon>mdi-plus</v-icon> Create Invite
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="getInvitesError">
            <v-col>
              <v-alert type="error">
                There was a server error when loading your invites.
              </v-alert>
            </v-col>
          </v-row>
          <v-row v-if="companies.length == 0">
            <v-col>
              <p>
                You do not have any companies. Please create a company to invite
                people.
              </p>
            </v-col>
          </v-row>
          <v-row v-if="companies.length == 1 || selectedCompany">
            <v-col>
              <v-data-table
                dense
                :headers="invitesHeaders"
                :items="invites"
                :loading="invitesLoading"
                item-key="name"
                class="elevation-1"
                no-data-text="You do not have any invites"
              >
                <template v-slot:item.code="{ item }">
                  <v-chip class="ma-2" label outlined color="#7db52f">
                    {{ item.invite.code }}
                  </v-chip>
                </template>
                <template v-slot:item.email="{ item }">
                  <span
                    class="body-2"
                    v-if="item.invite.email == null || item.invite.email == ''"
                  >
                    <i>No email provided</i></span
                  >
                  <span v-else>{{ item.invite.email }}</span>
                </template>
                <template v-slot:item.mobile_number="{ item }">
                  <span
                    class="body-2"
                    v-if="
                      item.invite.mobile_number == null ||
                      item.invite.mobile_number == 0
                    "
                  >
                    <i>No mobile number provided</i></span
                  >
                  <span v-else>{{ item.invite.mobile_number }}</span>
                </template>
                <template v-slot:item.invite_accepted="{ item }">
                  <span class="body-2">
                    <i>
                      {{ item.invite.invite_accepted ? "Yes" : "No" }}
                    </i>
                  </span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <!-- Flex container with centered items -->
                  <div style="display: flex; align-items: center; gap: 8px">
                    <!-- Tooltip for Copy -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <p
                          v-on="on"
                          @click="copyToClipboard(item)"
                          style="
                            cursor: pointer;
                            display: inline-flex;
                            align-items: center;
                          "
                        >
                          <v-icon class="mt-3 mb-0 pb-0"
                            >mdi-content-copy</v-icon
                          >
                        </p>
                      </template>
                      <span>{{ tooltipText }}</span>
                    </v-tooltip>

                    <!-- Tooltip for Delete -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="red darken-2"
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            resetForm();
                            deleteCode = item;
                            showDeleteDialogue = true;
                          "
                          style="cursor: pointer"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Delete this invite code</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import CreateInviteForm from "./CreateInviteForm.vue";

export default {
  name: "Account",
  mixins: [validationMixin],
  validations: {
    mobile_number: {
      minLength: minLength(11),
      maxLength: maxLength(11),
      required(v) {
        return this.email || required(v);
      },
    },
    email: {
      email,
      required(v) {
        return this.mobile_number || required(v);
      },
    },
    form: function () {
      return required(this.email) || required(this.mobile_number);
    },
  },
  data() {
    return {
      mobile_number: null,
      email: null,
      showInviteDialogue: false,
      showDeleteDialogue: false,
      deleteCode: null,
      tooltipText: "Copy link to clipboard",
      invitesHeaders: [
        {
          text: "Invite Code",
          value: "code",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Phone Number",
          value: "mobile_number",
        },
        {
          text: "Accepted",
          value: "invite_accepted",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          class: "px-0",
        },
      ],
    };
  },
  components: {
    CreateInviteForm,
  },
  computed: {
    ...mapGetters({
      invites: "invites/invites",
      createInvitesComplete: "invites/createInvitesComplete",
      createdInvite: "invites/createdInvite",
      createInvitesError: "invites/createInvitesError",
      createInvitesErrorItems: "invites/createInvitesErrorItems",
      invitesLoading: "invites/loading",
      getInvitesError: "invites/getInvitesError",
      deleteInviteComplete: "invites/deleteInviteComplete",
      deleteInviteError: "invites/deleteInviteError",
      deleteInviteErrorItems: "invites/deleteInviteErrorItems",
      deleteInviteLoading: "invites/deleteInviteLoading",
      companies: "company/companies",
      selectedCompany: "company/selectedCompany",
    }),
    dialogWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "80%";
        case "md":
          return "60%";
        case "lg":
          return "40%";
        case "xl":
          return "40%";
      }
      return "100%";
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    mobile_numberErrors() {
      const errors = [];
      if (!this.$v.mobile_number.$dirty) return errors;
      !this.$v.mobile_number.minLength &&
        errors.push("Must be valid Phone Number");
      !this.$v.mobile_number.maxLength &&
        errors.push("Must be valid Phone Number");
      !this.$v.mobile_number.required &&
        errors.push("Phone Number is required");
      return errors;
    },
  },
  methods: {
    ...mapActions({
      resetState: "invites/resetState",
      createInvite: "invites/createInvite",
      resetInviteState: "invites/resetState",
      resetForm: "invites/resetForm",
      deleteInvite: "invites/deleteInvite",
      setCreateInviteCompanyId: "invites/setCreateInviteCompanyId",
    }),
    emailFormatted(val) {
      if (val == null || val == "") {
        return "No email provided";
      } else {
        return val;
      }
    },
    mobileFormatted(val) {
      if (val == null || val == 0) {
        return "No mobile provided";
      } else {
        return val;
      }
    },
    async copyToClipboard(val) {
      try {
        console.log("Copying to clipboard: ", val.invite.code);
        const text =
          window.location.origin + "/invitecode/?c=" + val.invite.code;
        await navigator.clipboard.writeText(text);
        // You might want to give feedback to the user that the text was copied
        console.log("Text copied to clipboard");
        this.tooltipText = "Copied!";
        setTimeout(() => {
          this.tooltipText = "Copy to clipboard";
        }, 1000);
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    },
  },
  mounted: function () {
    if (this.selectedCompany.length) {
      this.getInvites({ company_id: this.selectedCompany.id });
    }
    // this.getDogs();
    console.log("Companies:", this.companies);
  },
  watch: {
    invites(val) {
      console.log("Invites changed");
      console.log(val);
    },
    selectedCompany(val) {
      console.log("Selected company changed");
      console.log(val);
      if (val != null || val != "" || val != undefined) {
        this.getInvites({ company_id: val.id });
      }
    },
  },
};
</script>
