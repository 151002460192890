import Vue from 'vue';
import Vuex from 'vuex';
import Vuelidate from 'vuelidate';
import auth from './store/auth';
import calendar from './store/calendar';
import account from './store/account';
import invites from './store/invites';
import pets from './store/pets';
import core from './store/core';
import bookings from './store/bookings';
import services from './store/services';
import company from './store/company';
import Home from './pages/Home.vue';
import Login from './pages/Login.vue';
import Register from './pages/registration/Register.vue';
import Calendar from './pages/Calendar.vue';
import Profile from './pages/profile/Profile.vue';
import PetsOverview from './pages/pets/Overview.vue';
import Privacy from './pages/PrivacyPolicy.vue';
import TermsOfService from './pages/TermsOfService.vue';
import PageNotFound from './pages/PageNotFound.vue';
import InviteCode from './pages/invites/InviteCode.vue';
import InviteCodeOverview from './pages/invites/Overview.vue';
import InviteCodeCompanyOverview from './pages/invites/CompanyInvites.vue';
import ContactUs from './pages/ContactUs.vue';
import EarlyAccess from './pages/EarlyAccess.vue';
import EmailPreferences from './pages/EmailPreferences.vue';
import VerifyMobile from './pages/verification/VerifyMobile.vue';
import CreateBooking from './pages/bookings/CreateBooking.vue';
import BookingsOverview from './pages/bookings/Overview.vue';
import ServicesOverview from './pages/services/Overview.vue';
import CreateService from './pages/services/CreateService.vue';
import CreateDog from './pages/pets/CreateDog.vue';
import CustomersOverview from './pages/customers/Overview.vue';
import CompaniesOverview from './pages/companies/Overview.vue';
import DashboardOverview from './pages/dashboard/Overview.vue';
import CompanyEdit from './pages/companies/Edit.vue';
import Styles from './pages/Styles.vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import vuetify from './plugins/vuetify';
import emitter from "tiny-emitter";
import axios from 'axios';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import {
    VueReCaptcha
} from 'vue-recaptcha-v3'
import {
    PublicClientApplication
} from "@azure/msal-browser";

axios.defaults.baseURL = "https://api.pawportal.co.uk"
// Set default timeout
axios.defaults.timeout = 10000;


Vue.use(VueReCaptcha, {
    siteKey: '6Ld-RcwhAAAAAM25u6iS07tyUeZq90XazwfkQPck',
    loaderOptions: {
        autoHideBadge: true
    }
});
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueTelInput);
Vue.use(Vuelidate);
Vue.config.productionTip = false;


const store = new Vuex.Store({
    // plugins: [(store) => {
    //     store.subscribeAction((action) => {
    //         if (!action.type.startsWith('auth')) {
    //             store.dispatch('auth/checkAuth')
    //         }
    //     })
    // }],
    state: {
        msalInstance: {},
        msalConfig: {
            auth: {
                clientId: "abc6e552-0b4b-453a-a84a-897348203d18", // This is the ONLY mandatory field; everything else is optional.
                authority: "https://login.pawportal.co.uk/pawportal.onmicrosoft.com/B2C_1_PAWPORTAL_LOGIN", // Choose sign-up/sign-in user-flow as your default.
                knownAuthorities: ["login.pawportal.co.uk"], // You must identify your tenant's domain as a known authority.
                // redirectUri: "https://pawportal.co.uk/login", // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
                redirectUri: "http://localhost:8080/login",
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: true
            },
            account: [],
            //MSAL access token
            accessToken: "",
        },
        EventType: {
            LOGIN_SUCCESS: ""
        },
    },
    mutations: {
        setAccount(state, account) {
            state.account = account;
        },
        setAccessToken(state, token) {
            state.accessToken = token;
        },
    },
    getters: {
        getAccount(state) {
            return state.account;
        }
    },
    actions: {
        resetState({
            commit
        }) {
            commit('auth/RESET_STATE');
            commit('calendar/RESET_STATE');
            // commit('account/RESET_STATE');
        },
    },
    modules: {
        auth,
        calendar,
        account,
        invites,
        pets,
        core,
        company,
        services,
        bookings,
    },
});

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'Home' }
},
{
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: 'Login' }
},
{
    path: '/register',
    name: 'Register',
    component: Register,
    meta: { title: 'Register' }
},
{
    path: '/calendar',
    name: 'Calendar',
    component: Calendar,
    meta: { title: 'Calendar' }
},
{
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: { title: 'Profile' }
},
{
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
    meta: { title: 'Privacy Policy' }
},
{
    path: '/email-preferences',
    name: 'EmailPreferences',
    component: EmailPreferences,
    meta: { title: 'Email Preferences' }
},
{
    path: '/tos',
    name: 'TermsOfService',
    component: TermsOfService,
    meta: { title: 'Terms of Service' }
},
{
    path: '*',
    component: PageNotFound,
    meta: { title: 'Page Not Found' }
},
{
    path: '/InviteCode',
    name: 'InviteCode',
    component: InviteCode,
    meta: { title: 'Invite Code' }
},
{
    path: '/invites/overview',
    name: 'InviteCodeOverview',
    component: InviteCodeOverview,
    meta: { title: 'Invite Code' }
},
{
    path: '/invites/company/overview',
    name: 'InviteCodeCompanyOverview',
    component: InviteCodeCompanyOverview,
    meta: { title: 'Invite Code' }
},
{
    path: '/contactus',
    name: 'ContactUs',
    component: ContactUs,
    meta: { title: 'Contact Us' }
},
{
    path: '/earlyaccess',
    name: 'EarlyAccess',
    component: EarlyAccess,
    meta: { title: 'Early Access' }
},
{
    path: '/Styles',
    name: 'Styles',
    component: Styles,
    meta: { title: 'Styles' }
},
{
    path: '/verifymobile/response',
    name: 'VerifyMobile',
    component: VerifyMobile,
    meta: { title: 'Verify Mobile' }
},
{
    path: '/bookings/create',
    name: 'CreateBooking',
    component: CreateBooking,
    meta: { title: 'Create Booking' }
},
{
    path: '/bookings/overview',
    name: 'BookingsOverview',
    component: BookingsOverview,
    meta: { title: 'Bookings Overview' }
},
{
    path: '/services/overview',
    name: 'ServicesOverview',
    component: ServicesOverview,
    meta: { title: 'Services Overview' }
},
{
    path: '/services/create',
    name: 'CreateService',
    component: CreateService,
    meta: { title: 'Create Service' }
},
{
    path: '/pets',
    name: 'PetsOverview',
    component: PetsOverview,
    meta: { title: 'Pets Overview' }
},
{
    path: '/pets/dogs/create',
    name: 'CreateDog',
    component: CreateDog,
    meta: { title: 'Create Dog' }
},
{
    path: '/company/customers',
    name: 'CustomersOverview',
    component: CustomersOverview,
    meta: { title: 'Customers Overview' }
},
{
    path: '/company/companies',
    name: 'CompaniesOverview',
    component: CompaniesOverview,
    meta: { title: 'Companies Overview' }
},
{
    path: '/company/edit',
    name: 'CompanyEdit',
    component: CompanyEdit,
    meta: { title: 'Companies Edit' }
},
{
    path: '/dashboard/',
    name: 'DashboardOverview',
    component: DashboardOverview,
    meta: { title: 'Dashboard Overview' }
},
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
});

store.state.msalInstance = new PublicClientApplication(
    store.state.msalConfig
);

router.beforeEach(async (to, from, next) => {
    console.log(`Navigating from ${from.path} to ${to.path}`);
    // Set document title
    document.title = to.meta.title || 'Paw Portal';  // Use the default "Paw Portal" if the route doesn't have a meta title

    if (to.path === '/services/create') {
        // Reset your state here
        store.dispatch('services/resetForm');
    }

    try {
        // Perform the check and wait for it to finish
        await store.dispatch('auth/checkAuth', to);
        // Now that the auth check is complete, proceed with the routing logic
        // ...
        if (to.name == 'Home' || to.name === 'Styles' || to.name === 'Privacy' || to.name === 'TermsOfService' || to.name == 'VerifyMobile' || to.name == 'ContactUs' || to.name == 'EarlyAccess' || to.name == 'InviteCode') {
            next();
        } else if (to.name !== 'Register' && store.getters['auth/isAuthenticated'] && store.getters['account/accountDetails'] == null) {
            console.log("Redirecting to Register")
            next({ name: 'Register', query: { from: to.fullPath } });
        } else if (to.name === 'Home' && store.getters['account/accountDetails']) {
            // If they are logged in, redirect to dashboard
            next({ name: 'DashboardOverview' });
        } else if (to.name !== 'Login' && !store.getters['auth/isAuthenticated']) {
            // If they are not authenticated, redirect to login
            console.log("Redirecting to Login")
            next({ name: 'Login', query: { from: to.fullPath } });
        } else {
            next();
        }
    } catch (error) {
        console.error('Error during auth check:', error);
        // Handle the error or redirect to a safe route
        next({ name: 'ErrorPage' }); // Redirect to an error page or similar
    }
});




new Vue({
    router,
    store,
    vuetify,
    emitter,
    render: (h) => h(App)
}).$mount('#app');


