<template v-if="authenticated">
  <v-container fluid class="pa-0 ma-0">
    <v-row class="fill-height pa-0 ma-0" no-gutters justify="center">
      <v-row style="background-color: #fce8d9" class="mr-0">
        <v-col>
          <v-row justify="center" class="pt-16 mt-16">
            <v-col align="center">
              <span class="description">INTERESTED IN</span>
            </v-col>
          </v-row>
          <v-row justify="center" class="pt-0 mt-0 pb-16 mb-16">
            <v-col align="center" cols="10" sm="8">
              <span class="h1-orange-text">6 MONTHS FREE, </span>
              <span class="h1"> EARLY ACCESS & </span>
              <span class="h1-orange-text"> 10% OFF </span>
              <span class="h1"> YOUR FIRST YEAR? </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="center" class="pt-12 mr-0">
        <v-col align="center" cols="12" sm="8">
          <v-row>
            <v-col>
              <h4>
                Sign-up for free today and help shape Paw Portal exactly for you
                and your business’s needs.
              </h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pl-6">
              <v-btn
                color="#EA8C3C"
                class="white--text py-4"
                depressed
                @click="scrollToEarlyAccess"
                block
                v-if="$vuetify.breakpoint.xsOnly"
              >
                EARLY ACCESS >
              </v-btn>
              <v-btn
                color="#EA8C3C"
                class="white--text py-4"
                depressed
                @click="scrollToEarlyAccess"
                v-else
              >
                EARLY ACCESS >
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="10">
              <span class="description-small">
                Our mission at Paw Portal is to build an unparalleled platform
                tailored to you and your business requirements. We understand
                that each business will have unique preferences, necessities,
                and aspirations when it comes to their operational and
                promotional needs.
              </span>
            </v-col>
          </v-row>
          <v-row justify="center" class="mb-10 ml-4 lm-sm-0">
            <v-col cols="10">
              <span class="description-small">
                That’s why we are on the hunt for prospective customers to be
                part of our development journey. By signing up today, you will
                get:
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row style="background-color: #cbd9c6" class="mx-0" justify="center">
        <v-col
          v-for="(benefit, index) in benefits"
          :key="index"
          cols="12"
          sm="4"
          lg="4"
          align="center"
          class="d-flex"
        >
          <v-sheet
            class="mx-0 mx-sm-0 mx-md-3 my-1 pl-0 pl-sm-3 my-sm-10 flex-grow-1 flex-column"
            color="white"
            max-width="600"
            elevation="0"
            rounded="xl"
          >
            <v-row justify="center" class="flex-grow-1 flex-column pa-4">
              <v-col align="center" cols="12" sm="11">
                <v-img
                  max-width="25"
                  :src="require(`../assets/early_access/${benefit.image}`)"
                  contain
                ></v-img>
              </v-col>

              <v-col align="center" cols="12" sm="11">
                <span class="h4-orange-text">{{ benefit.title }}</span>
              </v-col>

              <v-col align="center" cols="12" sm="11">
                <span class="description-small">{{ benefit.description }}</span>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
    </v-row>
    <v-row class="mr-0">
      <v-col md="6" class="pa-0 d-flex align-stretch" order="2" order-md="1">
        <v-img
          src="../assets/early_access/get_early_access.jpg"
          eager
          height="100%"
          class="align-self-stretch"
        ></v-img>
      </v-col>
      <v-col
        md="6"
        class="pa-0 d-flex align-stretch"
        order="1"
        order-md="2"
        style="position: relative"
      >
        <v-overlay
          :absolute="true"
          :opacity="0.4"
          :value="loading"
          style="position: absolute; z-index: 2"
        >
          <v-progress-circular
            :width="4"
            :size="70"
            color="#7db52f"
            indeterminate
          ></v-progress-circular>
        </v-overlay>
        <v-row
          class="pt-0 pt-sm-4 pt-lg-10 pb-2 px-0 ma-0"
          style="background-color: #28675d"
          justify="center"
          ref="earlyAccessRow"
        >
          <div id="auto-scroll-element"></div>
          <v-col class="py-10" align="start" cols="10">
            <form>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="first_names"
                    :error-messages="first_namesErrors"
                    :counter="20"
                    label="First Name*"
                    class="description"
                    dark
                    required
                    @input="$v.first_names.$touch()"
                    @blur="$v.first_names.$touch()"
                    v-if="success == false"
                  ></v-text-field>
                  <v-text-field
                    v-model="surname"
                    :error-messages="surnameErrors"
                    :counter="20"
                    label="Surname*"
                    class="description-white-text"
                    dark
                    required
                    @input="$v.surname.$touch()"
                    @blur="$v.surname.$touch()"
                    v-if="success == false"
                  ></v-text-field>
                  <v-text-field
                    v-model="email"
                    :error-messages="emailErrors"
                    label="Email*"
                    dark
                    required
                    dense
                    color="primary"
                    class="description"
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                    v-if="success == false"
                  ></v-text-field>
                  <v-row class="mb-3">
                    <v-col>
                      <vue-tel-input
                        class="description-white-text"
                        dark
                        v-model="phone_1"
                        :showDialCodeInSelection="true"
                        @validate="phone_1_validation = $event"
                        @blur="$v.phone_1_valid.$touch()"
                        v-bind="bindProps"
                        v-if="success == false"
                      ></vue-tel-input>
                      <div class="alert-small">{{ phone_1Errors[0] }}</div>
                    </v-col>
                  </v-row>
                  <v-checkbox
                    v-model="agreeToToC"
                    dark
                    class="description-white-text"
                    :error-messages="agreeToToCErrors"
                    required
                    @input="$v.agreeToToC.$touch()"
                    @blur="$v.agreeToToC.$touch()"
                    v-if="success == false"
                  >
                    <template v-slot:label>
                      I agree to the&nbsp;
                      <a
                        :href="$router.resolve('/privacy').href"
                        target="_blank"
                        rel="noopener noreferrer"
                        @click.stop
                        style="color: #ef851c"
                      >
                        Privacy Policy
                      </a>
                      *
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-alert
                v-if="error"
                type="error"
                border="left"
                elevation="2"
                colored-border
                class="ma-0"
              >
                {{ errorMessage }}
              </v-alert>
              <v-btn
                v-if="$vuetify.breakpoint.xsOnly && !success"
                color="#EA8C3C"
                class="white--text py-4"
                depressed
                block
                x-small
                :disabled="!formValid"
                @click="submit"
              >
                REQUEST EARLY ACCESS
              </v-btn>
              <v-btn
                v-else-if="success == false"
                color="#EA8C3C"
                class="white--text py-4"
                depressed
                small
                :disabled="!formValid"
                @click="submit"
              >
                REQUEST EARLY ACCESS
              </v-btn>
              <v-alert
                v-if="success"
                type="success"
                border="left"
                elevation="2"
                colored-border
                class="ma-0"
              >
                {{ successMessage }}
              </v-alert>
              <v-row class="pt-4 mr-0">
                <v-col>
                  <span class="description-small-white">
                    This site is protected by reCAPTCHA and the Google
                    <a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      style="color: #ef851c"
                      >Privacy Policy</a
                    >
                    and
                    <a
                      href="https://policies.google.com/terms"
                      target="_blank"
                      style="color: #ef851c"
                      >Terms of Service</a
                    >
                    apply.
                  </span>
                </v-col>
              </v-row>
            </form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Footer />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import axios from "axios";
import Footer from "@/components/Footer.vue";

import {
  required,
  minLength,
  maxLength,
  helpers,
  sameAs,
  email,
} from "vuelidate/lib/validators";

const postcodeRegex = helpers.regex(
  "postcodeRegex",
  /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/
);

export default {
  name: "EarlyAccessDialogue",
  mixins: [validationMixin],
  validations: {
    first_names: { required, maxLength: maxLength(20) },
    surname: { required, maxLength: maxLength(20) },
    email: { required, email },
    phone_1_valid: {
      phone_1_valid(val) {
        return val;
      },
    },
    agreeToToC: {
      checked(value) {
        return value;
      },
    },
  },
  data() {
    return {
      bindProps: {
        mode: "international",
        autoFormat: true,
        autoDefaultCountry: true,
      },
      test: null,
      first_names: null,
      surname: null,
      email: null,
      phone_1_type: null,
      phone_1: null,
      phone_1_validation: null,
      phone_2_type: null,
      phone_2: null,
      phone_3_type: null,
      phone_3: null,
      agreeToToC: false,
      error: false,
      errorMessage:
        "There was an error sending your request. Please try again.",
      success: false,
      successMessage: "Your request has been sent successfully.",
      loading: false,
      benefits: [
        {
          title: "Customised features and tailored solutions",
          image: "feature_1.jpg",
          description:
            "Being part of our journey from the start means you have a say in shaping the platform to meet your specific needs. Your input will directly impact the features, tools, and functionalities we develop, ensuring they align perfectly with your business requirements.",
        },
        {
          title: "Gain a competitive edge",
          image: "feature_2.jpg",
          description:
            "As an early adopter, you gain a competitive edge by getting exclusive access to cutting-edge features and innovations before they're widely available. This positions your business at the forefront of industry trends, giving you an upper hand in your market.",
        },
        {
          title: "Personalised support",
          image: "feature_3.jpg",
          description:
            "With a focus on a smaller client base during development, we will work closely with you to address any issues, provide guidance, and ensure that your experience with Paw Portal exceeds your expectations.",
        },
      ],
    };
  },
  created() {},
  components: {
    Footer,
  },
  computed: {
    ...mapGetters({}),
    first_namesErrors() {
      const errors = [];
      if (!this.$v.first_names.$dirty) return errors;
      !this.$v.first_names.maxLength &&
        errors.push("First name must be at most 20 characters long");
      !this.$v.first_names.required && errors.push("Name is required.");
      return errors;
    },
    surnameErrors() {
      const errors = [];
      if (!this.$v.surname.$dirty) return errors;
      !this.$v.surname.maxLength &&
        errors.push("Surname must be at most 20 characters long");
      !this.$v.surname.required && errors.push("Name is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("Email is required.");
      !this.$v.email.email && errors.push("Please provide a valid email.");
      return errors;
    },
    phone_1Errors() {
      let errors = [];
      if (!this.$v.phone_1_valid.$dirty) return errors;
      this.phone_1_validation == null ||
        (this.phone_1_validation.valid == false &&
          errors.push("This is not a valid phone number"));
      return errors;
    },
    phone_1_valid() {
      let test = this.phone_1Errors;
      // console.log("phone_1_validation", this.phone_1_validation);
      if (
        this.phone_1_validation == null ||
        this.phone_1_validation.valid == false
      ) {
        console.log("Phone 1 is not valid");
        return false;
      } else {
        console.log("Phone 1 is valid");
        return true;
      }
    },
    agreeToToCErrors() {
      const errors = [];
      if (!this.$v.agreeToToC.$dirty) return errors;
      !this.$v.agreeToToC.required && errors.push("Please agree to the ToC.");
      return errors;
    },
    formValid() {
      return !this.$v.$invalid;
    },
  },
  methods: {
    ...mapActions({}),
    scrollToEarlyAccess() {
      const elementSelector = "#auto-scroll-element";
      const element = document.querySelector(elementSelector);
      this.$vuetify.goTo(element, {
        duration: 500,
        offset: 0,
        easing: "easeInOutCubic",
      });
    },
    async submit() {
      if (!this.$v.$invalid) {
        this.loading = true;
        let telephone_number = "";
        if (
          this.phone_1_valid == true &&
          this.phone_1_validation.valid !== undefined
        ) {
          // console.log("Phone numbers length:", this.account.accountDetails.telephone_numbers.length)
          // Check if telephone number has changed
          telephone_number = this.phone_1_validation.number;
        }
        this.loading = true;
        var payload = {
          first_name: this.first_names,
          last_name: this.surname,
          email: this.email,
          message: "",
          telephone: telephone_number,
          contact_preference: "NONE",
        };
        const token = await this.$recaptcha("login");
        try {
          await axios
            .post("register-interest/", payload, {
              headers: {
                recaptcha: token,
              },
            })
            .then((resp) => {
              console.log(resp);
              if (resp.status == 200) {
                this.success = true;
                this.error = false;
              } else {
                this.success = false;
                this.error = true;
              }
              this.loading = false;
            })
            .catch((err) => {
              console.log(err);
              this.success = false;
              this.error = true;
              this.loading = false;
            });
        } catch (err) {
          console.log(err);
          this.success = false;
          this.error = true;
          this.loading = false;
        }
      } else {
        console.log("invalid");
      }
    },
    ping(message) {
      console.log(message);
    },
  },
  mounted: function () {
    console.log(this.$v);
    console.log(this.$v.first_names);
  },
  watch: {
    formValid() {
      console.log("formValid", this.formValid);
    },
    address_postcode() {
      // Force uppercase
      this.address_postcode = this.address_postcode.toUpperCase();
    },
    "$vuetify.breakpoint.name": {
      handler() {
        console.log("breakpoint", this.$vuetify.breakpoint.name);
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.vue-tel-input {
  font-family: "Roboto", sans-serif; /* Same as Vuetify default */
  border: none;
  border-bottom: 1px solid rgb(255, 255, 255); /* Assuming this is the border for your Vuetify theme */
  border-radius: 4px; /* Adjust as needed */
  font-size: 16px; /* Adjust as needed */
  padding: 5px; /* Adjust as needed */
  background-color: transparent; /* For the dark theme */
  color: #28675d; /* Text color for the dark theme */
}

.vue-tel-input .iti {
  color: white;
}

/* You might also want to adjust the style when the input is not valid (e.g., an invalid phone number) */
.vue-tel-input.invalid {
  border-bottom-color: #f44336; /* Example error color, adjust as needed */
}
</style>
