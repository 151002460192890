<template>
  <v-container fluid class="pa-0 ma-0" fill-height>
    <v-row class="fill-height pa-0 ma-0" no-gutters justify="center">
      <v-col class="ma-0 pa-0" align="center">
        <CheckInviteCodeForm />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import CheckInviteCodeForm from "../../components/invites/CheckInviteForm.vue";

export default {
  name: "Home",
  computed: {
    ...mapGetters({
      // authenticated: "auth/isAuthenticated",
      // account: "account/accountDetails",
      // account: "getAccount",
      // dogs: "account/dogs",
    }),
  },
  components: {
    CheckInviteCodeForm,
  },
  methods: {
    getNameInitials() {
      try {
        const nameArray = this.user.name.split(" ");
        console.log("name length: ", nameArray.length);
        if (nameArray.length > 1) {
          // Two name value, return first initial and last initial
          return (
            nameArray[0].substring(0, 1).toUpperCase() +
            nameArray[1].substring(0, 1).toUpperCase()
          );
        } else {
          return (
            nameArray[0].substring(0, 1).toUpperCase() +
            nameArray[0].substring(1, 2).toUpperCase()
          );
        }
      } catch (error) {
        // console.log(error)
      }
    },
    // ...mapActions({
    //   authLogout: "auth/logout",
    //   getAccount: "account/getAccount",
    //   getDogs: "account/getDogs",
    //   // checkAuthAction: "auth/checkAuth"
    // }),
    // async checkAuth() {
    //   await this.checkAutha
    // }
  },
  mounted: function () {
    // this.getAccount();
    // this.getDogs();
  },
};
</script>

<style></style>
