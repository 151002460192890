import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { InteractionRequiredAuthError } from "@azure/msal-browser";


export default {
    namespaced: true,

    state: {
        token: null,
        refreshToken: null,
        tokenExpiry: null,
        tokenExpired: true,
        user: null,
        loginFailed: false,
        isAuthCheckInProgress: false,
        authCheckPromise: null,
    },

    mutations: {
        RESET_STATE(state) {
            // console.log("STORE: RESETTING STATE!")
            state.token = null;
            state.refreshToken = null;
            state.tokenExpiry = null;
            state.tokenExpired = true;
            state.user = null;
            state.loginFailed = false;
            // Need to reset state for other stores
        },
        SET_ACCESS_TOKEN(state, response) {
            // console.log("STORE: Setting access token", response)
            state.token = response.idToken;
            const decodedToken = jwt_decode(response.idToken)
            state.tokenExpiry = decodedToken.exp
            state.tokenExpired = false;
            // this.commit('SET_REFRESH_TOKEN', token);
            state.refreshToken = response.idToken;
            // window.localStorage.setItem('refresh', response.idToken)
            state.user = response.account
            console.log("Account response: ", response.account)
        },
        SET_REFRESH_TOKEN(state, token) {
            // console.log("STORE: Setting refresh token")
            state.refreshToken = token;
        },
        SET_TOKEN_EXPIRED(state, bool) {
            // console.log("STORE: Setting token expired")
            state.tokenExpired = bool;
        },
        SET_USER(state, user) {
            // console.log("STORE: Setting user")
            state.user = user;
        },
        SET_LOGIN_FAILED(state, bool) {
            // console.log("STORE: Setting login failed")
            state.loginFailed = bool;
        },
        SET_AUTH_CHECK_IN_PROGRESS(state, bool) {
            // console.log("STORE: Setting auth check in progress")
            state.isAuthCheckInProgress = bool;
        },
        SET_AUTH_CHECK_PROMISE(state, promise) {
            // console.log("STORE: Setting auth check promise")
            state.authCheckPromise = promise;
        },
    },

    getters: {
        loginFailed(state) {
            return state.loginFailed;
        },
        token(state) {
            return state.token;
        },
        tokenExpiry(state) {
            return state.tokenExpiry;
        },
        refreshToken(state) {
            return state.refreshToken;
        },
        isAuthenticated(state) {
            // check if there is a token in the state
            if (state.token) {
                // Check if token has expired, if not return true
                return !(state.tokenExpired);
            }
            // No token in state, return false
            return false;
        },
        tokenExpired(state) {
            // console.log("Token expired: ", state.tokenExpired)
            return state.tokenExpired;
        },
        user(state) {
            return state.user;
        },
    },

    actions: {
        checkAuth({ state, commit, getters, dispatch, rootState, rootGetters }, route) {
            // Check if the authInProgress parameter is in the URL
            // console.log("to:", route)
            // Check if route is undefined

            const authInProgress = route?.query?.authInProgress === 'true';
            if (!authInProgress) {
                console.log("No authInProgress parameter in URL or it's not set to true");
            }
            // const authInProgress = route.query.authInProgress === 'true';
            // if (this.$route.query.authInProgress === 'true') {
            //     console.log("Auth in progress, setting authInProgress to true")
            //     authInProgress = true;
            // }

            if (authInProgress || state.isAuthCheckInProgress) {
                console.log("Auth check already in progress, returning existing promise");
                return state.authCheckPromise;
            }

            console.log("Starting new auth check");
            const authCheckPromise = new Promise(async (resolve, reject) => {
                commit('SET_AUTH_CHECK_IN_PROGRESS', true);

                try {
                    // Your existing checkAuth logic here
                    var myAccounts = await rootState.msalInstance.getAllAccounts();
                    if (myAccounts.length > 0) {
                        // Check if we have a token
                        if (getters.token) {
                            // Check if token has expired
                            if (getters.tokenExpiry < Date.now() / 1000) {
                                // Token has expired, try to refresh
                                await dispatch('refreshToken');
                                if (getters.tokenExpiry < Date.now() / 1000) {
                                    // Token refresh didn't work, resolve with false
                                    resolve(false);
                                } else {
                                    // Token is valid, return true
                                    console.log("Token is valid, returning true")
                                    // Check if we already have an account from the account store
                                    if (rootGetters['account/accountDetails'] == null) {
                                        // Dispatch to get account details
                                        await dispatch('account/getAccount', null, {
                                            root: true
                                        })
                                    }
                                    resolve(true);
                                }
                            } else {
                                // Token is valid, return true
                                console.log("Token is valid, returning true")
                                // Check if we already have an account from the account store
                                if (rootGetters['account/accountDetails'] == null) {
                                    // Dispatch to get account details
                                    await dispatch('account/getAccount', null, {
                                        root: true
                                    })
                                }
                                resolve(true);
                            }
                        } else {
                            // No token, trying to refreshToken
                            await dispatch('refreshToken');
                            if (getters.tokenExpiry < Date.now() / 1000) {
                                // Token has expired, resolve with false
                                resolve(false);
                            } else {
                                // Token is valid, return true
                                console.log("Token is valid, returning true")
                                // Check if we already have an account from the account store
                                if (rootGetters['account/accountDetails'] == null) {
                                    // Dispatch to get account details
                                    await dispatch('account/getAccount', null, {
                                        root: true
                                    })
                                }
                                resolve(true);
                            }
                        }
                    } else {
                        // No user, resolve with false
                        resolve(false);
                    }
                } catch (error) {
                    // Handle any errors
                    console.error('Error during checkAuth:', error);
                    return resolve(false)

                } finally {
                    commit('SET_AUTH_CHECK_IN_PROGRESS', false);
                }
            });

            commit('SET_AUTH_CHECK_PROMISE', authCheckPromise);
            return authCheckPromise;
        },
        async signIn({
            dispatch,
            // getters,
            commit,
            rootState
        }, query) {
            commit('SET_LOGIN_FAILED', false);
            console.log("signIn Called")
            console.log("Query:", query)

            const loginRequest = {
                extraQueryParameters: {
                    authInProgress: 'true'
                } // Add your custom parameter here
            };

            await rootState.msalInstance
                .loginRedirect(loginRequest)
                .then((tokenResponse) => {
                    console.log("tokenResponse: ", tokenResponse)
                    // If response is successful, set the token and account details in the state
                    commit("SET_ACCESS_TOKEN", tokenResponse);
                    try {
                        // Try to get the Paw Portal account details
                        dispatch('account/getAccount')
                    } catch (e) {
                        // Catch any errors and log them
                        console.log(e);
                    }

                    // Redirect to the page the user was on before they logged in
                    this.$router.push(query ? query : "/");
                })
                .catch((error) => {
                    // If there is an error, set the loginFailed state to true
                    commit('SET_LOGIN_FAILED', true);
                    // Log the error
                    console.log("ERROR when signing in: ", error)
                    dispatch('core/setBannerMessage', "Unable to connect to authentication server. Please try again later.", {
                        root: true
                    })
                    dispatch('core/setBannerType', "error", {
                        root: true
                    })
                    dispatch('core/setDisplayBanner', true, {
                        root: true
                    })
                });
        },
        async refreshToken({ commit, rootState, dispatch }) {
            console.log("Executing refresh token");

            var myAccounts = await rootState.msalInstance.getAllAccounts();
            if (myAccounts.length > 0) {
                console.log("User exists, setting active account");
                console.log("User: ", myAccounts[0]);
                rootState.msalInstance.setActiveAccount(myAccounts[0]);
            } else {
                console.log("No user found, resetting state");
                commit('RESET_STATE');
                return;
            }

            console.log("Attempting to refresh token");
            try {
                const tokenResponse = await rootState.msalInstance.acquireTokenSilent({
                    scopes: ["https://pawportal.onmicrosoft.com/pawportal-login/tasks.read"],
                    account: myAccounts[0],
                });

                console.log("Refresh token response: ", tokenResponse);
                commit("SET_ACCESS_TOKEN", tokenResponse);
                dispatch('account/getAccount', null, { root: true });
            } catch (error) {
                console.log("ERROR when refreshing token: ", error);
                if (error instanceof InteractionRequiredAuthError) {
                    // Redirect to an interactive method
                    console.log("Redirecting to interactive login");
                    await rootState.msalInstance.acquireTokenRedirect({
                        scopes: ["https://pawportal.onmicrosoft.com/pawportal-login/tasks.read"],
                        account: myAccounts[0],
                        prompt: "select_account" // or "login", "consent" as needed
                    });
                } else {
                    // Handle other types of errors
                    commit('RESET_STATE');
                    dispatch('account/resetState', null, { root: true });
                }
            }
        },
        async getUserInfo({
            commit,
            getters
        }) {
            try {
                const response = await axios.get(
                    'user', {
                    headers: {
                        Authorization: 'Bearer ' + getters.token,
                    },
                }
                );
                commit('SET_USER', response.data);
            } catch (e) {
                console.log("ERROR when getting user info: ", e)
            }
        },
        async signOut({ commit, rootState, dispatch }) {
            // Remove from local storage
            localStorage.removeItem("accountDetails");

            // Reset Vuex state
            dispatch('account/resetState', null, { root: true });
            commit("RESET_STATE");

            try {
                // Use logoutRedirect for redirect based logout
                await rootState.msalInstance.logoutRedirect({
                    postLogoutRedirectUri: "http://localhost:8080/"
                });

                // Alternatively, use logoutPopup for popup based logout
                // await rootState.msalInstance.logoutPopup({
                //     postLogoutRedirectUri: "http://localhost:8080/"
                // });

                // Redirect to home page after successful logout is handled by postLogoutRedirectUri
            } catch (error) {
                console.log("ERROR when signing out: ", error);
                // Handle error (e.g., logging, showing error messages)
            }
        }

    },
};

