<template>
  <v-sheet :elevation="10" class="mx-auto px-4 py-4">
    <v-form ref="signUpForm" lazy-validation>
      <!-- <v-container fluid id="registerAs">
        <v-row no-gutters justify="start" align="center">
          <v-col></v-col>
        </v-row>
      </v-container> -->
      <v-row>
        <v-col>
          <v-text-field
            v-model="eventName"
            label="Name"
            required
          ></v-text-field>
          <v-divider></v-divider>
          <!-- <v-text-field
            v-model="eventDetails"
            label="Details"
            required
          ></v-text-field> -->
          <v-textarea
            v-model="eventDetails"
            label="Message"
            counter
            maxlength="120"
            full-width
            single-line
          ></v-textarea>
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-menu
            ref="eventDate"
            v-model="eventDateShow"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="eventDate"
                label="Event Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="eventDate"
              @input="eventDateShow = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-menu
            ref="eventTime"
            v-model="eventTimeShow"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="eventTime"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="eventTime"
                label="Event Time"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="eventTimeShow"
              v-model="eventTime"
              full-width
              format="24h"
              @click:minute="$refs.eventTime.save(eventTime)"
            ></v-time-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            v-model="eventLength"
            label="Length (hours)"
            prepend-icon="mdi-clock-start"
            type="number"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-alert
            dense
            outlined
            type="error"
            icon="mdi-alert"
            v-if="this.createEventError"
          >
            {{ createEventErrorMsg }}
          </v-alert>
          <v-alert
            v-if="this.createEventSuccess"
            outlined
            type="success"
            icon="mdi-success"
            text
            >Your request has been sent!</v-alert
          >
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn @click="submit()">Request Event</v-btn>
        </v-col>
        <v-col align="right">
          <v-btn
            @click="
              $store.dispatch('calendar/setCreateEventShowOverlay', false)
            "
            >Cancel</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "CalendarEventsCreate",
  data() {
    return {
      valid: true,
      eventName: "",
      eventDetails: "",
      eventDate: null,
      eventDateShow: false,
      eventTime: null,
      eventTimeShow: false,
      eventLength: null,
      registrationComplete: false,
      progressBarText: "Please wait while we complete some things",
      createEventErrorMsg: "There was an error creating this event",
      registrationSuccess:
        "Registration successful! Please confirm your email address.",
      showPassword: false,
      showRepeatPassword: false,
      loginFailed: false,
      progressBarVisable: false,
    };
  },
  mounted() {
    console.log(moment().format());
  },
  components: {},
  computed: {
    ...mapGetters({
      createEventLoading: "calendar/createEventLoading",
      createEventError: "calendar/createEventError",
      createEventSuccess: "calendar/createEventSuccess",
      calendar: "calendar/calendar",
    }),
  },
  methods: {
    ...mapActions({
      createCalendarEvent: "calendar/createCalendarEvent",
    }),
    resetForm() {
      this.eventName = "";
      this.eventDetails = "";
      this.eventDate = null;
      this.eventTime = null;
      this.eventLength = null;
    },
    submit() {
      console.log("Submitting...");
      // console.log(this.calendar.id);
      // console.log(moment.utc(this.eventLength*3600*1000).format("HH:mm:ss"))

      console.log(this.$store);

      this.createCalendarEvent({
        calendarId: this.calendar.id,
        body: {
          name: this.eventName,
          details: this.eventDetails,
          start: moment(this.eventDate + " " + this.eventTime).format(),
          duration: moment
            .utc(this.eventLength * 3600 * 1000)
            .format("HH:mm:ss"),
        },
      });

      // this.$store.dispatch("calendar/getCalendar");
    },
  },
  watch: {
    createEventSuccess(val) {
      if (!val) return;
      this.$store.dispatch("calendar/getCalendar");
      this.resetForm();
      setTimeout(
        () => this.$store.dispatch("calendar/setCreateEventSuccess", false),
        10000
      );
    },
    createEventError(val) {
      if (!val) return;
      this.$store.dispatch("calendar/getCalendar");
      setTimeout(
        () => this.$store.dispatch("calendar/setCreateEventError", false),
        2000
      );
    },
  },
};
</script>

<style scoped>
#registerAsText {
  margin-bottom: 1.5em;
  margin-left: -1em;
}
</style>