<template>
  <v-row>
    <v-col class="pa-0">
      <v-container fluid>
        <PetsOverview v-if="pawPortalAccount.type == 'PET_OWNER'" />
      </v-container>
    </v-col>
  </v-row>
</template>


  <script>
import { mapGetters, mapActions } from "vuex";
import PetsOverview from "../../components/pets/Overview";

export default {
  name: "Profile",
  data() {
    return {
      tab: null,
    };
  },
  components: {
    PetsOverview,
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/isAuthenticated",
      user: "auth/user",
      pawPortalAccount: "account/accountDetails",
      account: "account/all",
    }),
  },
  methods: {
    ...mapActions({
      getAccount: "account/getAccount",
    }),
  },
  mounted: function () {},
};
</script>

  <style></style>
