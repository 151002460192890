import axios from 'axios';

export default {
    namespaced: true,

    state: {
        dogs: {
            dogs: [],
            loading: false,
            create_dog_complete: false,
            create_dog_loading: false,
            create_dog_error: false,
            create_dog_error_items: [],
            dogBreeds: {
                breeds: [],
                loading: false,
                complete: false,
                error: false,
                error_items: []
            },
            delete: {
                loading: false,
                complete: false,
                error: false,
                errorItems: [],
            },
        },
        petRelationship: {
            relationships: [],
            loading: false,
            complete: false,
            error: false,
            error_items: []
        },
        uploadProfilePicture: {
            complete: false,
            error: false,
            errorItems: [],
            loading: false,
            selected_pet: null,
        },
        loading: false
    },

    mutations: {
        RESET_STATE(state) {
            // console.log("RESET_STATE called");
            state.dogs.dogs = [];
            state.dogs.loading = false;
            state.dogs.create_dog_complete = false;
            state.dogs.create_dog_error = false;
            state.dogs.create_dog_error_items = [];
            state.loading = false;
            state.petRelationship.relationships = [];
            state.petRelationship.loading = false;
            state.petRelationship.complete = false;
            state.petRelationship.error = false;
            state.petRelationship.error_items = [];
            state.dogs.dogBreeds.breeds = [];
            state.dogs.dogBreeds.loading = false;
            state.dogs.dogBreeds.complete = false;
            state.dogs.dogBreeds.error = false;
            state.dogs.dogBreeds.error_items = [];

        },
        RESET_FORM(state) {
            // console.log("RESET_FORM called");
            state.dogs.loading = false;
            state.dogs.create_dog_complete = false;
            state.dogs.create_dog_error = false;
            state.dogs.create_dog_error_items = [];
            state.dogs.create_dog_loading = false;
            state.petRelationship.relationships = [];
            state.petRelationship.loading = false;
            state.petRelationship.complete = false;
            state.petRelationship.error = false;
            state.petRelationship.error_items = [];
            state.dogs.dogBreeds.breeds = [];
            state.dogs.dogBreeds.loading = false;
            state.dogs.dogBreeds.complete = false;
            state.dogs.dogBreeds.error = false;
            state.dogs.dogBreeds.error_items = [];
            state.dogs.delete.loading = false;
            state.dogs.delete.complete = false;
            state.dogs.delete.error = false;
            state.dogs.delete.errorItems = [];
        },
        SET_DOGS(state, val) {
            state.dogs.dogs = val;
        },
        SET_CREATE_DOG_COMPLETE(state, val) {
            state.dogs.create_dog_complete = val;
        },
        SET_CREATE_DOG_LOADING(state, val) {
            state.dogs.create_dog_loading = val;
        },
        SET_DOGS_LOADING(state, val) {
            state.dogs.loading = val;
        },
        SET_CREATE_DOG_ERROR(state, val) {
            state.dogs.create_dog_complete = false;
            state.dogs.create_dog_error = true;
            state.dogs.create_dog_error_items = [];
            try {
                val.detail.forEach(element => {
                    const item = {
                        item: element.loc[1].charAt(0).toUpperCase() + element.loc[1].slice(1).replace(/_/g, " "),
                        message: element.msg.charAt(0).toUpperCase() + element.msg.slice(1).replace(/_/g, " ")
                    }
                    state.dogs.create_dog_error_items.push(item)
                });
            } catch (error) {
                console.log("Create dog error message : ", error)
                state.dogs.create_dog_error_items.push({
                    item: "Server error",
                    message: "Please try again later"
                })
            }
            console.log("Create dog failed: ", state.dogs.create_dog_error_items)
        },
        SET_PET_RELATIONSHIP_RELATIONSHIPS(state, val) {
            state.petRelationship.relationships = val;
        },
        SET_PET_RELATIONSHIP_LOADING(state, val) {
            state.petRelationship.loading = val;
        },
        SET_PET_RELATIONSHIP_COMPLETE(state, val) {
            state.petRelationship.complete = val;
        },
        SET_PET_RELATIONSHIP_ERROR(state, val) {
            state.petRelationship.error = val;
        },
        SET_PET_RELATIONSHIP_ERROR_ITEMS(state, val) {
            state.petRelationship.complete = false;
            state.petRelationship.error = true;
            state.petRelationship.error_items = [];
            try {
                val.detail.forEach(element => {
                    const item = {
                        item: element.loc[1].charAt(0).toUpperCase() + element.loc[1].slice(1).replace(/_/g, " "),
                        message: element.msg.charAt(0).toUpperCase() + element.msg.slice(1).replace(/_/g, " ")
                    }
                    state.petRelationship.error_items.push(item)
                });
            } catch (error) {
                console.log("Create pet relationship error message : ", error)
                state.petRelationship.error_items.push({
                    item: "Server error",
                    message: "Please try again later"
                })
            }
            console.log("Create pet relationship failed: ", state.petRelationship.error_items)
        },
        SET_DOG_BREEDS(state, val) {
            state.dogs.dogBreeds.breeds = val;
        },
        SET_DOG_BREEDS_LOADING(state, val) {
            state.dogs.dogBreeds.loading = val;
        },
        SET_DOG_BREEDS_COMPLETE(state, val) {
            state.dogs.dogBreeds.complete = val;
        },
        SET_DOG_BREEDS_ERROR(state, val) {
            state.dogs.dogBreeds.error = val;
        },
        SET_DOG_BREEDS_ERROR_ITEMS(state, val) {
            state.dogs.dogBreeds.complete = false;
            state.dogs.dogBreeds.error = true;
            state.dogs.dogBreeds.error_items = [];
            try {
                val.detail.forEach(element => {
                    const item = {
                        item: element.loc[1].charAt(0).toUpperCase() + element.loc[1].slice(1).replace(/_/g, " "),
                        message: element.msg.charAt(0).toUpperCase() + element.msg.slice(1).replace(/_/g, " ")
                    }
                    state.dogs.dogBreeds.error_items.push(item)
                });
            } catch (error) {
                console.log("Create dog breeds error message : ", error)
                state.dogs.dogBreeds.error_items.push({
                    item: "Server error",
                    message: "Please try again later"
                })
            }
            console.log("Create dog breeds failed: ", state.dogs.dogBreeds.error_items)
        },
        RESET_CREATE_PET_RELATIONSHIP(state) {
            state.petRelationship.complete = false;
            state.petRelationship.error = false;
            state.petRelationship.error_items = [];
        },
        SET_DOG_DELETE_LOADING(state, val) {
            state.dogs.delete.loading = val;
        },
        SET_DOG_DELETE_COMPLETE(state, val) {
            state.dogs.delete.complete = val;
        },
        SET_DOG_DELETE_ERROR(state, val) {
            state.dogs.delete.complete = false;
            state.dogs.delete.error = true;
            state.dogs.delete.error_items = [];
            try {
                val.detail.forEach(element => {
                    const item = {
                        item: element.loc[1].charAt(0).toUpperCase() + element.loc[1].slice(1).replace(/_/g, " "),
                        message: element.msg.charAt(0).toUpperCase() + element.msg.slice(1).replace(/_/g, " ")
                    }
                    state.dogs.delete.error_items.push(item)
                });
            } catch (error) {
                console.log("Delete dog error message : ", error)
                state.dogs.delete.error_items.push({
                    item: "Server error",
                    message: "Please try again later"
                })
            }
            console.log("Delete dog failed: ", state.dogs.delete.error_items)
        },
        RESET_DELETE_DOG(state) {
            state.dogs.delete.complete = false;
            state.dogs.delete.error = false;
            state.dogs.delete.error_items = [];
        },
        SET_UPLOAD_PROFILE_PICTURE_COMPLETE(state, val) {
            state.uploadProfilePicture.complete = val;
        },
        SET_UPLOAD_PROFILE_PICTURE_ERROR(state, error) {
            state.uploadProfilePicture.error = true;
            state.uploadProfilePicture.errorItems = error;
        },
        SET_UPLOAD_PROFILE_PICTURE_ERROR_ITEMS(state, val) {
            state.uploadProfilePicture.complete = false;
            state.uploadProfilePicture.error = true;
            state.uploadProfilePicture.errorItems = [];
            try {
                console.log("Upload profile picture error message : ", val)
                val.detail.forEach(element => {
                    const item = {
                        item: element.loc[1].charAt(0).toUpperCase() + element.loc[1].slice(1).replace(/_/g, " "),
                        message: element.msg.charAt(0).toUpperCase() + element.msg.slice(1).replace(/_/g, " ")
                    }
                    state.uploadProfilePicture.errorItems.push(item)
                });
            } catch (error) {
                console.log("Upload profile picture error message : ", error)
                state.uploadProfilePicture.errorItems.push({
                    item: "Server error",
                    message: "Please try again later"
                })
            }
            console.log("Upload profile picture failed: ", state.uploadProfilePicture.errorItems)
        },
        SET_UPLOAD_PROFILE_PICTURE_LOADING(state, val) {
            state.uploadProfilePicture.loading = val;
        },
        RESET_UPLOAD_PROFILE_PICTURE_FORM(state) {
            state.uploadProfilePicture.complete = false;
            state.uploadProfilePicture.error = false;
            state.uploadProfilePicture.errorItems = [];
            state.uploadProfilePicture.loading = false;
            state.uploadProfilePicture.selected_pet = null;
        },
        SET_UPLOAD_PROFILE_PICTURE_SELECTED_PET(state, val) {
            state.uploadProfilePicture.selected_pet = val;
        }
    },

    getters: {
        all(state) {
            return state;
        },
        dogs(state) {
            return state.dogs.dogs;
        },
        createDogComplete(state) {
            return state.dogs.create_dog_complete;
        },
        createDogError(state) {
            return state.dogs.create_dog_error;
        },
        createDogErrorItems(state) {
            return state.dogs.create_dog_error_items;
        },
        createDogLoading(state) {
            return state.dogs.create_dog_loading;
        },
        dogsLoading(state) {
            return state.dogs.loading;
        },
        petRelationshipRelationships(state) {
            return state.petRelationship.relationships;
        },
        petRelationshipLoading(state) {
            return state.petRelationship.loading;
        },
        petRelationshipComplete(state) {
            return state.petRelationship.complete;
        },
        petRelationshipError(state) {
            return state.petRelationship.error;
        },
        petRelationshipErrorItems(state) {
            return state.petRelationship.error_items;
        },
        dogBreeds(state) {
            return state.dogs.dogBreeds.breeds;
        },
        dogBreedsLoading(state) {
            return state.dogs.dogBreeds.loading;
        },
        dogBreedsComplete(state) {
            return state.dogs.dogBreeds.complete;
        },
        dogBreedsError(state) {
            return state.dogs.dogBreeds.error;
        },
        dogBreedsErrorItems(state) {
            return state.dogs.dogBreeds.error_items;
        },
        dogDeleteLoading(state) {
            return state.dogs.delete.loading;
        },
        dogDeleteComplete(state) {
            return state.dogs.delete.complete;
        },
        dogDeleteError(state) {
            return state.dogs.delete.error;
        },
        dogDeleteErrorItems(state) {
            return state.dogs.delete.error_items;
        },
        uploadProfilePictureComplete(state) {
            return state.uploadProfilePicture.complete;
        },
        uploadProfilePictureError(state) {
            return state.uploadProfilePicture.error;
        },
        uploadProfilePictureErrorItems(state) {
            return state.uploadProfilePicture.errorItems;
        },
        uploadProfilePictureLoading(state) {
            return state.uploadProfilePicture.loading;
        },
        uploadProfilePictureSelectedPet(state) {
            return state.uploadProfilePicture.selected_pet;
        }
    },

    actions: {
        async getDogBreeds({
            commit,
            rootState
        }) {
            try {
                commit('SET_DOG_BREEDS_LOADING', true);
                await axios
                    .get('dog/breeds', {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.token,
                        },
                    })
                    .then((resp) => {
                        console.log("Dog breeds: ", resp.data)
                        commit('SET_DOG_BREEDS', resp.data);
                        commit('SET_DOG_BREEDS_COMPLETE', true);
                    })
                    .catch((error) => {
                        console.log(error);
                        commit('SET_DOG_BREEDS_ERROR', true);
                        commit('SET_DOG_BREEDS_ERROR_ITEMS', error.response.data);
                    });
            } catch (error) {
                console.log(error);
                commit('SET_DOG_BREEDS_ERROR', true);
                commit('SET_DOG_BREEDS_ERROR_ITEMS', error.response.data);
            } finally {
                commit('SET_DOG_BREEDS_LOADING', false);
            }
        },
        async getDogs({
            rootState,
            commit
        }) {
            try {
                commit('SET_DOGS_LOADING', true);
                await axios
                    .get('dog', {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.token,
                        },
                        timeout: 10000,
                    })
                    .then(async (resp) => {
                        console.log("Dogs: ", resp.data)
                        const response = resp.data;
                        const dogsWithShow = await Promise.all(response.map(async (dog) => {
                            // If img_url is null or empty, fetch the image
                            if (!dog.image_url) {
                                const breedWords = dog.breed.breed.split(' ');
                                let formattedBreed;

                                if (dog.breed.breed === 'LABRADOR RETRIEVER') {
                                    formattedBreed = 'labrador';
                                } else if (dog.breed.breed === 'LABRADOR HUSKY') {
                                    formattedBreed = 'husky';
                                }
                                else if (breedWords.length > 1) {
                                    formattedBreed = `${breedWords[1]}/${breedWords[0]}`.toLowerCase();
                                } else {
                                    formattedBreed = dog.breed.breed.toLowerCase();
                                }
                            }
                            // Add show property to the dog object
                            return { ...dog, show: false };
                        }));
                        console.log("Dogs with show: ", dogsWithShow)
                        commit('SET_DOGS', dogsWithShow);
                        // console.log(resp.data);
                    })
                    .catch((error) => {
                        console.log("ERROR getting Dogs: ", error.response.data)
                        commit('SET_DOGS', []);
                    })
                    .finally(() => {
                        commit('SET_DOGS_LOADING', false);
                    });
            } catch (e) {
                console.log(e);
                commit('SET_DOGS_LOADING', false);
            }
        },
        async createDog({
            rootState,
            commit,
            dispatch
        }, DogData) {
            try {
                commit('RESET_STATE');
                commit('SET_CREATE_DOG_LOADING', true);
                await axios
                    .post('dog', DogData, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.token,
                        },
                    })
                    .then((resp) => {
                        commit('SET_CREATE_DOG_COMPLETE', true);
                        setTimeout(() => commit('SET_CREATE_DOG_COMPLETE', false), 5000);
                        dispatch("getDogs");
                        commit('SET_CREATE_DOG_LOADING', false);
                    })
                    .catch((error) => {
                        commit('SET_CREATE_DOG_ERROR', error.response.data);
                        commit('SET_CREATE_DOG_LOADING', false);
                    })
                    .finally(() => { });
            } catch (e) {
                console.log("ERROR when creating Dog: ", e);
                commit('SET_CREATE_DOG_ERROR', null);
                commit('SET_CREATE_DOG_LOADING', false);
            }
        },
        async updateDog({
            rootState,
            commit,
            dispatch
        }, DogData) {
            try {
                commit('RESET_STATE');
                const id = DogData.id;
                delete DogData.id;
                // Everything here is referencing CREATE as it's the same form as UPDATE
                commit('SET_CREATE_DOG_LOADING', true);
                await axios
                    .patch(`dog/${id}`, DogData, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.token,
                        },
                    })
                    .then((resp) => {
                        commit('SET_CREATE_DOG_COMPLETE', true);
                        setTimeout(() => commit('SET_CREATE_DOG_COMPLETE', false), 5000);
                        dispatch("getDogs");
                        commit('SET_CREATE_DOG_LOADING', false);
                    })
                    .catch((error) => {
                        commit('SET_CREATE_DOG_ERROR', error.response.data);
                        commit('SET_CREATE_DOG_LOADING', false);
                    })
                    .finally(() => { });
            } catch (e) {
                console.log("ERROR when creating Dog: ", e);
                commit('SET_CREATE_DOG_ERROR', null);
                commit('SET_CREATE_DOG_LOADING', false);
            }
        },
        async createPetRelationship({
            rootState,
            commit,
        }, inputData) {
            try {
                // commit('RESET_STATE');
                commit('SET_PET_RELATIONSHIP_LOADING', true);
                await axios
                    .post('pet/relationship/', inputData, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.token,
                        },
                    })
                    .then(() => {
                        commit('SET_PET_RELATIONSHIP_COMPLETE', true);
                        commit('SET_PET_RELATIONSHIP_LOADING', false);
                    })
                    .catch((error) => {
                        commit('SET_PET_RELATIONSHIP_ERROR', error.response.data);
                        commit('SET_PET_RELATIONSHIP_LOADING', false);
                    })
                    .finally(() => { });
            } catch (e) {
                console.log("ERROR when creating pet relationship: ", e);
                commit('SET_PET_RELATIONSHIP_ERROR', null);
                commit('SET_PET_RELATIONSHIP_LOADING', false);
            }
        },
        async deleteDog({
            rootState,
            commit,
            dispatch
        }, dogId) {
            try {
                commit('SET_DOG_DELETE_LOADING', true);
                await axios
                    .delete(`dog/${dogId}`, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.token,
                        },
                    })
                    .then((resp) => {
                        commit('SET_DOG_DELETE_COMPLETE', true);
                        setTimeout(() => commit('SET_DOG_DELETE_COMPLETE', false), 3000);
                        dispatch("getDogs");
                        commit('SET_DOG_DELETE_LOADING', false);
                    })
                    .catch((error) => {
                        commit('SET_DOG_DELETE_ERROR', error.response.data);
                        commit('SET_DOG_DELETE_LOADING', false);
                    })
                    .finally(() => { });
            } catch (e) {
                console.log("ERROR when deleting Dog: ", e);
                commit('SET_DOG_DELETE_ERROR', null);
                commit('SET_DOG_DELETE_LOADING', false);
            }
        },
        async resetCreatePetRelationship({
            commit
        }) {
            commit('RESET_CREATE_PET_RELATIONSHIP', null);
        },
        async resetCreateDog({
            commit
        }) {
            commit('RESET_CREATE_DOG', null);
        },
        async resetDeleteDog({
            commit
        }) {
            commit('RESET_DELETE_DOG', null);
        },
        async resetState({
            commit
        }) {
            commit('RESET_STATE', null);
        },
        async resetForm({
            commit
        }) {
            commit('RESET_FORM', null);
        },
        async uploadProfilePicture({ rootState, commit, dispatch, state }, file) {
            try {
                commit('SET_UPLOAD_PROFILE_PICTURE_LOADING', true);
                console.log(file);
                const formData = new FormData();
                formData.append('dog_image', file);
                console.log(formData);
                console.log("Pet ID: ", state.uploadProfilePicture.selected_pet.id);
                await axios.post(`dog/${state.uploadProfilePicture.selected_pet.id}/image`, formData, {
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.token,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                commit('SET_UPLOAD_PROFILE_PICTURE_COMPLETE', true);
                dispatch('getDogs');
            } catch (error) {
                commit('SET_UPLOAD_PROFILE_PICTURE_ERROR_ITEMS', error.response ? error.response.data : []);
            } finally {
                commit('SET_UPLOAD_PROFILE_PICTURE_LOADING', false);
            }
        },
        async resetUploadProfilePicture({ commit }) {
            commit('RESET_UPLOAD_PROFILE_PICTURE_FORM', null);
        },
        async setUploadProfilePictureSelectedPet({ commit }, pet) {
            commit('SET_UPLOAD_PROFILE_PICTURE_SELECTED_PET', pet);
        }
    },
};