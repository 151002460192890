<template v-if="authenticated">
  <v-container fluid class="pa-0 ma-0">
    <v-overlay :absolute="true" :opacity="0.2" :value="loading">
      <v-progress-circular
        :width="4"
        :size="70"
        color="#7db52f"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-row class="pa-0 ma-0" no-gutters justify="center">
      <v-col
        class="background-image-row-main ma-0 pa-0"
        align="center"
        cols="12"
        md="6"
        v-if="!pawPortalAccount"
      >
        <!-- <v-img src="../assets/contact_us/main_image_1280.jpg" contain></v-img> -->
      </v-col>
      <v-col class="ma-0 pa-0" align="center">
        <v-row justify="center" class="mr-0">
          <v-col class="pt-14" align="start" cols="10">
            <h1>We are here to help.</h1>
          </v-col>
        </v-row>
        <v-row justify="center" class="mr-0">
          <v-col class="py-1" align="start" cols="10">
            <span class="description">
              Regardless of whether it’s a simple question, a query or a special
              request, don’t be afraid to reach out! We are here to support you.
            </span>
          </v-col>
        </v-row>
        <v-row justify="center" class="mr-0 pb-0 mb-0">
          <v-col class="pt-10 pb-0 mb-0" align="start" cols="10">
            <form>
              <v-row class="mr-0">
                <v-col>
                  <v-text-field
                    v-model="first_names"
                    :error-messages="first_namesErrors"
                    :counter="20"
                    label="First Name*"
                    class="description"
                    required
                    @input="$v.first_names.$touch()"
                    @blur="$v.first_names.$touch()"
                    v-if="success == false"
                  ></v-text-field>
                  <v-text-field
                    v-model="surname"
                    :error-messages="surnameErrors"
                    :counter="20"
                    label="Surname*"
                    class="description"
                    required
                    @input="$v.surname.$touch()"
                    @blur="$v.surname.$touch()"
                    v-if="success == false"
                  ></v-text-field>
                  <v-text-field
                    v-model="email"
                    :error-messages="emailErrors"
                    label="Email*"
                    required
                    dense
                    color="primary"
                    class="description"
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                    v-if="success == false"
                  ></v-text-field>
                  <v-textarea
                    v-model="message"
                    :error-messages="messageErrors"
                    color="primary"
                    label="Message*"
                    dense
                    required
                    rows="3"
                    class="description"
                    :counter="500"
                    @input="$v.message.$touch()"
                    @blur="$v.message.$touch()"
                    v-if="success == false"
                  ></v-textarea>
                  <v-checkbox
                    v-model="agreeToToC"
                    color="primary"
                    class="description"
                    :error-messages="agreeToToCErrors"
                    required
                    @input="$v.agreeToToC.$touch()"
                    @blur="$v.agreeToToC.$touch()"
                    v-if="success == false"
                  >
                    <template v-slot:label>
                      I agree to the&nbsp;
                      <a
                        :href="$router.resolve('/privacy').href"
                        target="_blank"
                        rel="noopener noreferrer"
                        @click.stop
                      >
                        Privacy Policy
                      </a>
                      *
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-alert
                v-if="error"
                type="error"
                border="left"
                elevation="2"
                colored-border
                class="ma-0"
              >
                {{ errorMessage }}
              </v-alert>

              <v-btn
                v-if="$vuetify.breakpoint.xsOnly && !success"
                color="#EA8C3C"
                class="white--text"
                depressed
                block
                small
                :disabled="!formValid"
                @click="submit"
              >
                SEND REQUEST
              </v-btn>
              <v-btn
                v-else-if="success == false"
                color="#EA8C3C"
                class="white--text"
                depressed
                small
                :disabled="!formValid"
                @click="submit"
              >
                SEND REQUEST
              </v-btn>
              <v-alert
                v-if="success"
                type="success"
                border="left"
                elevation="2"
                colored-border
                class="ma-0"
              >
                {{ successMessage }}
              </v-alert>
              <v-row class="pt-4 pb-6 mr-0">
                <v-col>
                  <span class="description-small">
                    This site is protected by reCAPTCHA and the Google
                    <a
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      >Privacy Policy</a
                    >
                    and
                    <a href="https://policies.google.com/terms" target="_blank"
                      >Terms of Service</a
                    >
                    apply.
                  </span>
                </v-col>
              </v-row>
            </form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" v-if="!pawPortalAccount">
      <v-col class="ma-0 pa-0">
        <Footer />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import axios from "axios";
import Footer from "@/components/Footer.vue";

import {
  required,
  minLength,
  maxLength,
  helpers,
  sameAs,
  email,
} from "vuelidate/lib/validators";

export default {
  name: "UpdateProfileDialogue",
  components: {
    Footer,
  },
  mixins: [validationMixin],
  validations: {
    first_names: { required, maxLength: maxLength(20) },
    surname: { required, maxLength: maxLength(20) },
    email: { required, email },
    message: { required, maxLength: maxLength(500) },
    agreeToToC: {
      checked(value) {
        return value;
      },
    },
  },
  data() {
    return {
      bindProps: {
        mode: "international",
        autoFormat: true,
        autoDefaultCountry: true,
      },
      first_names: null,
      surname: null,
      email: null,
      message: null,
      agreeToToC: false,
      error: false,
      errorMessage:
        "There was an error sending your request. Please try again.",
      success: false,
      successMessage: "Your request has been sent successfully.",
      loading: false,
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      pawPortalAccount: "account/accountDetails",
    }),
    first_namesErrors() {
      const errors = [];
      if (!this.$v.first_names.$dirty) return errors;
      !this.$v.first_names.maxLength &&
        errors.push("First name must be at most 20 characters long");
      !this.$v.first_names.required && errors.push("Name is required.");
      return errors;
    },
    surnameErrors() {
      const errors = [];
      if (!this.$v.surname.$dirty) return errors;
      !this.$v.surname.maxLength &&
        errors.push("Surname must be at most 20 characters long");
      !this.$v.surname.required && errors.push("Name is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("Email is required.");
      !this.$v.email.email && errors.push("Please provide a valid email.");
      return errors;
    },
    messageErrors() {
      const errors = [];
      if (!this.$v.message.$dirty) return errors;
      !this.$v.message.maxLength &&
        errors.push("Message must be at most 500 characters long");
      !this.$v.message.required && errors.push("Message is required.");
      return errors;
    },
    agreeToToCErrors() {
      const errors = [];
      if (!this.$v.agreeToToC.$dirty) return errors;
      !this.$v.agreeToToC.required && errors.push("Please agree to the ToC.");
      return errors;
    },
    formValid() {
      return !this.$v.$invalid;
    },
  },
  methods: {
    ...mapActions({}),
    async submit() {
      if (!this.$v.$invalid) {
        this.loading = true;
        var payload = {
          first_name: this.first_names,
          last_name: this.surname,
          email: this.email,
          message: this.message,
        };
        const token = await this.$recaptcha("login");
        try {
          await axios
            .post("contact-us/", payload, {
              headers: {
                recaptcha: token,
              },
            })
            .then((resp) => {
              console.log(resp);
              if (resp.status == 200) {
                this.success = true;
                this.error = false;
              } else {
                this.success = false;
                this.error = true;
              }
              this.loading = false;
            })
            .catch((err) => {
              console.log(err);
              this.success = false;
              this.error = true;
              this.loading = false;
            });
        } catch (err) {
          console.log(err);
          this.success = false;
          this.error = true;
          this.loading = false;
        }
      } else {
        console.log("invalid");
      }
    },
    ping(message) {
      console.log(message);
    },
  },
  mounted: function () {
    console.log(this.$v);
    console.log(this.$v.first_names);
    console.log("Paw Portal Account", this.pawPortalAccount);
    if (this.pawPortalAccount) {
      this.first_names = this.pawPortalAccount.first_names;
      this.surname = this.pawPortalAccount.surname;
      this.email = this.pawPortalAccount.email;
    }
  },
  watch: {
    formValid() {
      console.log("formValid", this.formValid);
    },
    // Watch breakpoint
    "$vuetify.breakpoint.name": {
      handler() {
        console.log("breakpoint", this.$vuetify.breakpoint.name);
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.background-image-row-main {
  background-image: url("../assets/contact_us/main_image_1280.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top center start;
  min-height: 89.3vh;
}

@media (min-width: 1380px) {
  .background-image-row-main {
    background-image: url("../assets/contact_us/main_image_1920.jpg");
  }
}

@media (max-width: 1165px) {
  .background-image-row-main {
    background-image: url("../assets/contact_us/main_image_1165.jpg");
  }
}

@media (max-width: 959px) {
  .background-image-row-main {
    background-image: url("../assets/contact_us/main_image_mobile.jpg");
    min-height: 60vw;
  }
}
</style>
