<template v-if="authenticated">
  <v-container>
    <v-overlay :absolute="true" :opacity="0.2" :value="getRegistrationLoading">
      <v-progress-circular
        :width="4"
        :size="70"
        color="#7db52f"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-row class="pa-2" justify="center">
      <v-col md="12" align="center">
        <v-row>
          <v-col>
            <h2>Create a new Company</h2>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col align="center">
        <form>
          <v-text-field
            v-model="company_name"
            :error-messages="company_nameErrors"
            :counter="100"
            label="Company Name*"
            required
            @input="$v.company_name.$touch()"
            @blur="$v.company_name.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="address_line_1"
            :error-messages="address_line_1Errors"
            :counter="50"
            label="Address Line 1"
            @input="$v.address_line_1.$touch()"
            @blur="$v.address_line_1.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="address_line_2"
            :error-messages="address_line_2Errors"
            :counter="50"
            label="Address Line 2"
            @input="$v.address_line_2.$touch()"
            @blur="$v.address_line_2.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="address_line_3"
            :error-messages="address_line_3Errors"
            :counter="50"
            label="Address Line 3"
            @input="$v.address_line_3.$touch()"
            @blur="$v.address_line_3.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="address_line_4"
            :error-messages="address_line_4Errors"
            :counter="50"
            label="Address Line 4"
            @input="$v.address_line_4.$touch()"
            @blur="$v.address_line_4.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="address_postcode"
            :error-messages="address_postcodeErrors"
            label="Postcode"
            @input="$v.address_postcode.$touch()"
            @blur="$v.address_postcode.$touch()"
          ></v-text-field>
          <!-- Phone 2 -->
          <!-- <span>
            {{ phone_2_type }}
          </span>
          <vue-tel-input
            v-model="phone_2"
            @validate="phone_2_validation = $event"
            :showDialCodeInSelection="true"
            v-bind="bindProps"
          ></vue-tel-input> -->
          <!-- Phone 3 -->
          <!-- <span>
            {{ phone_3_type }}
          </span>
          <vue-tel-input
            v-model="phone_3"
            @validate="phone_3_validation = $event"
            :showDialCodeInSelection="true"
            v-bind="bindProps"
          ></vue-tel-input> -->

          <v-alert type="warning" :value="getRegistrationError">
            <li v-bind:key="item.msg" v-for="item in getRegistrationErrorItems">
              {{ item.item }} -
              {{ item.message }}
            </li>
          </v-alert>
          <v-alert type="success" :value="getRegistrationComplete">
            Company created successfully!
          </v-alert>
          <v-btn color="#EA8C3C" class="white--text" depressed @click="submit">
            submit
          </v-btn>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";

import {
  required,
  minLength,
  maxLength,
  helpers,
  sameAs,
} from "vuelidate/lib/validators";

const postcodeRegex = helpers.regex(
  "postcodeRegex",
  /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/
);

export default {
  name: "UpdateProfileDialogue",
  mixins: [validationMixin],
  validations: {
    company_name: { required, maxLength: maxLength(100) },
    address_line_1: { maxLength: maxLength(50) },
    address_line_2: { maxLength: maxLength(50) },
    address_line_3: { maxLength: maxLength(50) },
    address_line_4: { maxLength: maxLength(50) },
    // postcode based on UK postcode regex
    address_postcode: {
      maxLength: maxLength(8),
      // Regex to match ([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})
      valid: postcodeRegex,
    },
  },
  data() {
    return {
      bindProps: {
        mode: "international",
        autoFormat: true,
        autoDefaultCountry: true,
      },
      test: null,
      company_name: null,
      address_line_1: null,
      address_line_2: null,
      address_line_3: null,
      address_line_4: null,
      address_postcode: null,
    };
  },
  created() {
    console.log(this.account);
    // this.company_name = this.account.accountDetails.company_name;
    // this.address_line_1 = this.account.accountDetails.address_line_1;
    // this.address_line_2 = this.account.accountDetails.address_line_2;
    // this.address_line_3 = this.account.accountDetails.address_line_3;
    // this.address_line_4 = this.account.accountDetails.address_line_4;
    // this.address_postcode = this.account.accountDetails.address_postcode;
  },
  components: {},
  computed: {
    ...mapGetters({
      getRegistrationComplete: "company/registrationComplete",
      getRegistrationError: "company/registrationError",
      getRegistrationErrorItems: "company/registrationErrorItems",
      getRegistrationLoading: "company/registrationLoading",
    }),
    company_nameErrors() {
      const errors = [];
      if (!this.$v.company_name.$dirty) return errors;
      !this.$v.company_name.maxLength &&
        errors.push("Company Name must be at most 100 characters long");
      !this.$v.company_name.required &&
        errors.push("Company Name is required.");
      return errors;
    },
    address_line_1Errors() {
      const errors = [];
      if (!this.$v.address_line_1.$dirty) return errors;
      !this.$v.address_line_1.maxLength &&
        errors.push("Address must be at most 50 characters long");
      return errors;
    },
    address_line_2Errors() {
      const errors = [];
      if (!this.$v.address_line_2.$dirty) return errors;
      !this.$v.address_line_2.maxLength &&
        errors.push("Address must be at most 50 characters long");
      return errors;
    },
    address_line_3Errors() {
      const errors = [];
      if (!this.$v.address_line_3.$dirty) return errors;
      !this.$v.address_line_3.maxLength &&
        errors.push("Address must be at most 50 characters long");
      return errors;
    },
    address_line_4Errors() {
      const errors = [];
      if (!this.$v.address_line_4.$dirty) return errors;
      !this.$v.address_line_4.maxLength &&
        errors.push("Address must be at most 50 characters long");
      return errors;
    },
    address_postcodeErrors() {
      const errors = [];
      if (!this.$v.address_postcode.$dirty) return errors;
      !this.$v.address_postcode.valid && errors.push("Postcode must be valid");
      return errors;
    },
  },
  methods: {
    ...mapActions({
      createCompany: "company/createCompany",
    }),
    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        console.log("valid");
        this.createCompany({
          name: this.company_name,
        });
      } else {
        console.log("invalid");
      }
    },
    ping(message) {
      console.log(message);
    },
  },
  mounted: function () {},
  watch: {},
};
</script>

<style></style>
