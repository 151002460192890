<template v-if="authenticated">
  <v-container>
    <v-overlay :absolute="true" :opacity="0.2" :value="invitesLoading">
      <v-progress-circular
        :width="4"
        :size="70"
        color="#7db52f"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-row class="pa-2" justify="center">
      <v-col md="12" align="center">
        <v-row v-if="inviteType == 'EMPLOYEE'">
          <v-col>
            <v-row>
              <v-col>
                <h3>Invite a staff member to join</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3>{{ selectedCompany.name }}</h3>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="inviteType == 'CUSTOMER'">
          <v-col>
            <v-row>
              <v-col>
                <h3>Create a customer invite code</h3>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="inviteType == 'EMPLOYEE'">
          <v-col>
            <v-row>
              <v-col>
                <span style="description"
                  >Inviting an employee will give them full access to your
                  company</span
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="inviteType == 'CUSTOMER'">
          <v-col>
            <v-row>
              <v-col>
                <span style="description"
                  >Inviting a customer will give them access to your company's
                  customer portal</span
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="createdInvite.length != 0">
          <v-col>
            <v-row>
              <v-col>
                <p>Invite sent successfully!</p>
                <p><b>Invite Code: </b> {{ createdInvite.code }}</p>
                <p>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <p
                        v-on="on"
                        @click="copyToClipboard(inviteCodeUrl)"
                        style="cursor: pointer"
                      >
                        {{ inviteCodeUrl }}
                        <v-icon>mdi-content-copy</v-icon>
                      </p>
                    </template>
                    <span>{{ tooltipText }}</span>
                  </v-tooltip>
                </p>
                <br /><br />
                <span
                  v-if="
                    createdInvite.mobile_number != null &&
                    createdInvite.mobile_number != 0 &&
                    (createdInvite.email == '' || createdInvite.email == null)
                  "
                >
                  <p>Your recipient should have recieved a text message.</p>
                  <p>
                    <b>Mobile Number: </b> {{ createdInvite.mobile_number }}
                  </p>
                </span>
                <span
                  v-if="
                    createdInvite.email != null &&
                    createdInvite.email != '' &&
                    (createdInvite.mobile_number == 0 ||
                      createdInvite.mobile_number == null)
                  "
                >
                  <p>Your recipient should have recieved an email.</p>
                  <p><b>Email: </b> {{ createdInvite.email }}</p>
                </span>
                <span
                  v-if="
                    createdInvite.email != null &&
                    createdInvite.email != '' &&
                    createdInvite.mobile_number != null &&
                    createdInvite.mobile_number != 0
                  "
                >
                  <p>
                    Your recipient should have recieved an email and a text
                    message.
                  </p>
                  <p><b>Email: </b> {{ createdInvite.email }}</p>
                  <p>
                    <b>Mobile Number: </b> {{ createdInvite.mobile_number }}
                  </p>
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="createdInvite.length == 0">
          <v-col>
            <v-form>
              <v-text-field
                :error-messages="emailErrors"
                @change="$v.email.$touch()"
                @blur="$v.email.$touch()"
                v-model="email"
                label="Email address"
              ></v-text-field>
              or
              <!-- <v-text-field
                :counter="11"
                :error-messages="mobile_numberErrors"
                @change="$v.mobile_number.$touch()"
                @blur="$v.mobile_number.$touch()"
                v-model="mobile_number"
                label="Phone Number"
              ></v-text-field> -->
              <v-row class="mb-3">
                <v-col>
                  <vue-tel-input
                    v-model="phone_1"
                    :showDialCodeInSelection="true"
                    @validate="phone_1_validation = $event"
                    @blur="$v.phone_1_valid.$touch()"
                    v-bind="bindProps"
                  ></vue-tel-input>
                  <div class="alert-small">{{ phone_1Errors[0] }}</div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" md="1" class="align-self-start text-right">
                  <v-checkbox
                    v-model="checkbox"
                    :error-messages="checkboxErrors"
                    required
                    @change="$v.checkbox.$touch()"
                    @blur="$v.checkbox.$touch()"
                    class="pa-0 ma-0"
                  ></v-checkbox>
                </v-col>
                <v-col cols="10" md="11" class="align-self-start text-left">
                  <span>
                    To create an invite, please accept our
                    <a href="/tos" target="_blank">Terms and Conditions</a> and
                    <a href="/privacy" target="_blank">Privacy Policy</a>.
                  </span>
                </v-col>
              </v-row>

              <v-row class="mt-2">
                <v-col>
                  <v-btn
                    color="#7db52f"
                    @click="submit"
                    :disabled="!formInvalid"
                  >
                    submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col md="12">
        <v-alert type="warning" :value="createInvitesError">
          <li v-bind:key="item.msg" v-for="item in createInvitesErrorItems">
            {{ item.item }} -
            {{ item.message }}
          </li>
        </v-alert>
        <v-alert type="success" :value="createInvitesComplete">
          Invite Created Successfully!
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

export default {
  name: "Account",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    inviteType: {
      type: String,
      default: "CUSTOMER",
    },
  },
  mixins: [validationMixin],
  validations: {
    checkbox: {
      checked(val) {
        return val;
      },
    },
    // mobile_number: {
    //   minLength: minLength(11),
    //   maxLength: maxLength(11),
    //   required(v) {
    //     return this.email || required(v);
    //   },
    // },
    phone_1_valid: {
      phone_1_valid(val) {
        return val;
      },
    },
    email: {
      email,
      required(v) {
        return this.phone_1_valid || required(v);
      },
    },
    form: function () {
      return required(this.email) || this.phone_1_valid == true;
    },
  },
  data() {
    return {
      mobile_number: null,
      email: null,
      checkbox: null,
      phone_1: null,
      phone_1_validation: null,
      tooltipText: "Copy to clipboard",
      bindProps: {
        mode: "international",
        autoFormat: true,
        autoDefaultCountry: true,
      },
      invitesHeaders: [
        {
          text: "Invite Code",
          value: "InviteCode.code",
        },
        {
          text: "Email",
          value: "InviteCode.email",
        },
        {
          text: "Phone Number",
          value: "InviteCode.mobile_number",
        },
      ],
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      createInvitesComplete: "invites/createInvitesComplete",
      createdInvite: "invites/createdInvite",
      createInvitesError: "invites/createInvitesError",
      createInvitesErrorItems: "invites/createInvitesErrorItems",
      invitesLoading: "invites/loading",
      createInviteCompanyId: "invites/createInviteCompanyId",
      selectedCompany: "company/selectedCompany",
    }),
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    // mobile_numberErrors() {
    //   const errors = [];
    //   if (!this.$v.mobile_number.$dirty) return errors;
    //   !this.$v.mobile_number.minLength &&
    //     errors.push("Must be valid Phone Number");
    //   !this.$v.mobile_number.maxLength &&
    //     errors.push("Must be valid Phone Number");
    //   !this.$v.mobile_number.required &&
    //     errors.push("Phone Number is required");
    //   return errors;
    // },
    phone_1Errors() {
      let errors = [];
      if (!this.$v.phone_1_valid.$dirty) return errors;
      this.phone_1_validation == null ||
        (this.phone_1_validation.valid == false &&
          errors.push("This is not a valid phone number"));
      return errors;
    },
    phone_1_valid() {
      let test = this.phone_1Errors;
      // console.log("phone_1_validation", this.phone_1_validation);
      if (
        this.phone_1_validation == null ||
        this.phone_1_validation.valid == false
      ) {
        console.log("Phone 1 is not valid");
        return false;
      } else {
        console.log("Phone 1 is valid");
        return true;
      }
    },
    checkboxErrors() {
      const errors = [];
      if (!this.$v.checkbox.$dirty) return errors;
      !this.$v.checkbox.checked && errors.push("You must agree to continue!");
      return errors;
    },
    formInvalid() {
      return true;
      // if (this.$v) {
      //   return this.$v.$invalid;
      // }
    },
    inviteCodeUrl() {
      return (
        window.location.origin + "/invitecode/?c=" + this.createdInvite.code
      );
    },
  },
  methods: {
    ...mapActions({
      resetState: "invites/resetState",
      createInvite: "invites/createInvite",
      resetInviteState: "invites/resetState",
    }),
    async logout() {
      console.log("Logging out...");
      await this.authLogout();
      this.$router.push({ name: "Login" });
    },
    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        console.log("Form is valid, submitting...");
        console.log("phone_1_validation:", this.phone_1_valid);
        await this.createInvite({
          email: this.email == null ? "" : this.email.trim(),
          mobile_number:
            this.phone_1_validation.number == undefined
              ? 0
              : this.phone_1_validation.number,
          company_id: this.selectedCompany.id,
          type: this.inviteType,
        });
        this.email = null;
        this.phone_1 = null;
        this.checkbox = null;
        this.phone_1_validation = null;
        this.$v.$reset();
      } else {
        console.log("Form is invalid, not submitting...");
      }
    },
    resetForm() {
      this.mobile_number = null;
      this.email = null;
      this.checkbox = null;
      this.phone_1 = null;
      this.phone_1_validation = null;
      // ...reset other data properties...
    },
    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
        // You might want to give feedback to the user that the text was copied
        console.log("Text copied to clipboard");
        this.tooltipText = "Copied!";
        setTimeout(() => {
          this.tooltipText = "Copy to clipboard";
        }, 1000);
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    },
  },
  mounted: function () {
    this.resetInviteState();
    // this.getInvites();
    // this.getDogs();
    // this.$v.$touch();
  },
  watch: {
    createInviteCompanyId: function (val) {
      console.log("Creating invite for:", val);
    },
    dialog(newVal) {
      console.log("Dialog changed to:", newVal);
      if (!newVal) {
        this.resetForm();
      }
    },
  },
};
</script>

<style></style>
