import axios from 'axios';

export default {
    namespaced: true,

    state: {
        accountType: null,
        accountDetails: null,
        registrationComplete: false,
        registrationError: false,
        registrationErrorItems: [],
        getAccountLoading: true,
        updateProfile: {
            complete: false,
            error: false,
            errorItems: [],
            loading: false
        },
        uploadProfilePicture: {
            complete: false,
            error: false,
            errorItems: [],
            loading: false
        }
    },

    mutations: {
        RESET_STATE(state) {
            console.log("account RESET_STATE called")
            state.accountType = null;
            state.accountDetails = null;
            state.registrationComplete = false;
            state.registrationError = false;
            state.registrationErrorItems = [];
            state.getAccountLoading = true;
            state.updateProfile.complete = false;
            state.updateProfile.error = false;
            state.updateProfile.errorItems = [];
            state.updateProfile.loading = false;
            state.uploadProfilePicture.complete = false;
            state.uploadProfilePicture.error = false;
            state.uploadProfilePicture.errorItems = [];
            state.uploadProfilePicture.loading = false;
        },
        SET_ACCOUNT_DATA(state, accountData) {
            state.accountDetails = accountData;
            localStorage.setItem("accountDetails", JSON.stringify(accountData));
        },
        SET_REGISTRATION_COMPLETE(state, val) {
            console.log("Setting registration complete to: ", val)
            state.registrationComplete = val;
        },
        SET_REGISTRATION_ERROR(state, val) {
            state.registrationComplete = false;
            state.registrationError = true;
            state.registrationErrorItems = [];
            try {
                val.detail.forEach(element => {
                    const item = {
                        item: element.loc[1].charAt(0).toUpperCase() + element.loc[1].slice(1).replace(/_/g, " "),
                        message: element.msg.charAt(0).toUpperCase() + element.msg.slice(1).replace(/_/g, " ")
                    }
                    state.registrationErrorItems.push(item)
                });

            } catch (error) {
                console.log("Registration error message : ", error)
                state.registrationErrorItems.push({
                    item: "Server error",
                    message: "Please try again later"
                })
            }
            console.log("Registration failed: ", state.registrationErrorItems)
        },
        SET_UPDATE_PROFILE_COMPLETE(state, val) {
            state.updateProfile.complete = val;
        },
        SET_UPDATE_PROFILE_ERROR(state, val) {
            state.updateProfile.complete = false;
            state.updateProfile.error = true;
            state.updateProfile.errorItems = [];
            try {
                if (val.detail == "telephone number already exists") {
                    state.updateProfile.errorItems.push({
                        item: "Telephone number",
                        message: "Telephone number is already in use"
                    })
                }
                else {
                    val.detail.forEach(element => {
                        const item = {
                            item: element.loc[1].charAt(0).toUpperCase() + element.loc[1].slice(1).replace(/_/g, " "),
                            message: element.msg.charAt(0).toUpperCase() + element.msg.slice(1).replace(/_/g, " ")
                        }
                        state.updateProfile.errorItems.push(item)
                    });
                }

            } catch (error) {
                console.log("Update profile error message : ", error)
                state.updateProfile.errorItems.push({
                    item: "Server error",
                    message: "Please try again later"
                })
            }
            console.log("Update profile failed: ", state.updateProfile.errorItems)
        },
        SET_UPDATE_PROFILE_LOADING(state, val) {
            state.updateProfile.loading = val;
        },
        RESET_UPDATE_PROFILE(state) {
            state.updateProfile.complete = false;
            state.updateProfile.error = false;
            state.updateProfile.errorItems = [];
            state.updateProfile.loading = false;
        },
        SET_GET_ACCOUNT_LOADING(state, val) {
            state.getAccountLoading = val;
        },
        SET_UPLOAD_PROFILE_PICTURE_COMPLETE(state, val) {
            state.uploadProfilePicture.complete = val;
        },
        SET_UPLOAD_PROFILE_PICTURE_ERROR(state, error) {
            state.uploadProfilePicture.error = true;
            state.uploadProfilePicture.errorItems = error;
        },
        SET_UPLOAD_PROFILE_PICTURE_LOADING(state, val) {
            state.uploadProfilePicture.loading = val;
        },
        RESET_FORM(state) {
            state.uploadProfilePicture.complete = false;
            state.uploadProfilePicture.error = false;
            state.uploadProfilePicture.errorItems = [];
            state.uploadProfilePicture.loading = false;
        }
    },

    getters: {
        all(state) {
            return state;
        },
        accountDetails(state) {
            // Check local storage
            if (localStorage.getItem("accountDetails") !== null) {
                state.accountDetails = JSON.parse(localStorage.getItem("accountDetails"));
            }
            return state.accountDetails;
        },
        registrationComplete(state) {
            return state.registrationComplete;
        },
        registrationError(state) {
            return state.registrationError;
        },
        registrationErrorItems(state) {
            return state.registrationErrorItems;
        },
        updateProfileComplete(state) {
            return state.updateProfile.complete;
        },
        updateProfileError(state) {
            return state.updateProfile.error;
        },
        updateProfileErrorItems(state) {
            return state.updateProfile.errorItems;
        },
        updateProfileLoading(state) {
            return state.updateProfile.loading;
        },
        getAccountLoading(state) {
            return state.getAccountLoading;
        },
        uploadProfilePictureComplete(state) {
            return state.uploadProfilePicture.complete;
        },
        uploadProfilePictureError(state) {
            return state.uploadProfilePicture.error;
        },
        uploadProfilePictureErrorItems(state) {
            return state.uploadProfilePicture.errorItems;
        },
        uploadProfilePictureLoading(state) {
            return state.uploadProfilePicture.loading;
        }
    },

    actions: {
        async getAccount({
            rootState,
            commit,
            dispatch
        }) {
            try {
                console.log("Getting account data")
                // Set the banner to hidden
                commit('core/SET_DISPLAY_BANNER', false, {
                    root: true
                });
                // Set SET_GET_ACCOUNT_LOADING to true
                commit('SET_GET_ACCOUNT_LOADING', true);
                // Set the loading bar to true
                commit('core/SET_LOADING', true, {
                    root: true
                });
                await axios
                    .get('user', {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.token,
                        },
                    })
                    .then(async (resp) => {
                        // Set account data
                        console.log("Account data: ", resp.data)
                        commit('SET_ACCOUNT_DATA', resp.data);
                        // Call the getCompanies
                        await dispatch('company/getCompanies', null, {
                            root: true
                        });
                        // Remove loading banner
                        commit('core/SET_LOADING', false, {
                            root: true
                        });
                        // Set SET_GET_ACCOUNT_LOADING to false
                        commit('SET_GET_ACCOUNT_LOADING', false);
                    })
                    .catch((error) => {
                        console.log("Error with getting user: ", error.message);
                        // Remove from local storage
                        localStorage.removeItem("accountDetails");
                        // Check if the connection timed out
                        if (error.message === "Network Error" || error.code === "ECONNABORTED") {
                            console.log("Connection issue")
                            commit('core/SET_LOADING', false, {
                                root: true
                            });
                            commit('core/SET_BANNER_MESSAGE', "Unable to connect to the server. Please try again later", {
                                root: true
                            });
                            commit('core/SET_BANNER_TYPE', "error", {
                                root: true
                            });
                            commit('core/SET_DISPLAY_BANNER', true, {
                                root: true
                            });
                            // set SET_GET_ACCOUNT_LOADING to false
                            commit('SET_GET_ACCOUNT_LOADING', false);
                            // Else, check if the error is not a 404, which means the user is not registered
                        } else if (error.response.status !== 404) {
                            // Reset state
                            commit('RESET_STATE', null);
                            commit('core/SET_LOADING', false, {
                                root: true
                            });
                            commit('SET_ACCOUNT_DATA', null);
                            commit('core/SET_BANNER_MESSAGE', 'Error getting user data, please try again later', {
                                root: true
                            });
                            commit('core/SET_BANNER_TYPE', 'error', {
                                root: true
                            });
                            commit('core/SET_DISPLAY_BANNER', true, {
                                root: true
                            });
                            // set SET_GET_ACCOUNT_LOADING to false
                            commit('SET_GET_ACCOUNT_LOADING', false);
                        } else {
                            // Else, some unknown error occurred
                            // Reset state
                            commit('RESET_STATE', null);
                            // Remove loading banner
                            commit('core/SET_LOADING', false, {
                                root: true
                            });
                            // Hide banner
                            commit('core/SET_DISPLAY_BANNER', false, {
                                root: true
                            });
                            // set SET_GET_ACCOUNT_LOADING to false
                            commit('SET_GET_ACCOUNT_LOADING', false);
                        }
                    })
            } catch (e) {
                console.log(e);
                // set SET_GET_ACCOUNT_LOADING to false
                commit('SET_GET_ACCOUNT_LOADING', false);
                commit('core/SET_BANNER_MESSAGE', 'There was a server error, please try again later', {
                    root: true
                });
                commit('core/SET_BANNER_TYPE', 'error', {
                    root: true
                });
                commit('core/SET_DISPLAY_BANNER', true, {
                    root: true
                });
            }
        },
        async resetState({
            commit
        }) {
            commit('RESET_STATE', null);
        },
        async register({
            rootState,
            commit,
            dispatch
        }, registerData) {
            try {
                await axios
                    .post('user/', registerData, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.token,
                        },
                    })
                    .then((response) => {
                        console.log("Registration response:", response);
                        commit('SET_REGISTRATION_COMPLETE', true);
                        console.log("Getting account data")
                        dispatch('getAccount')
                    })
                    .catch((error) => {
                        // console.log(error.response.data);
                        console.log("ERROR when registering caught: ", error.response.data)
                        commit('SET_REGISTRATION_ERROR', error.response.data);
                    });
            } catch (e) {
                console.log("ERROR when registering: ", e)
                commit('SET_REGISTRATION_ERROR', null);
            }
        },
        async updateProfile({
            rootState,
            commit,
            dispatch
        }, updateData) {
            try {
                commit('SET_UPDATE_PROFILE_LOADING', true);
                await axios
                    .put('user/', updateData, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.token,
                        },
                    })
                    .then(() => {
                        commit('SET_UPDATE_PROFILE_COMPLETE', true);
                        dispatch('getAccount')
                    })
                    .catch((error) => {
                        // console.log(error.response.data);
                        commit('SET_UPDATE_PROFILE_ERROR', error.response.data);
                    })
                    .finally(() => {
                        commit('SET_UPDATE_PROFILE_LOADING', false);
                    });
            } catch (e) {
                console.log("ERROR when updating profile: ", e)
                commit('SET_UPDATE_PROFILE_ERROR', null);
            }
        },
        async uploadProfilePicture({ rootState, commit, dispatch }, file) {
            try {
                commit('SET_UPLOAD_PROFILE_PICTURE_LOADING', true);
                console.log(file);
                const formData = new FormData();
                formData.append('profile_picture', file);
                console.log(formData);
                await axios.post('user/image', formData, {
                    headers: {
                        Authorization: 'Bearer ' + rootState.auth.token,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                commit('SET_UPLOAD_PROFILE_PICTURE_COMPLETE', true);
                dispatch('getAccount');
            } catch (error) {
                commit('SET_UPLOAD_PROFILE_PICTURE_ERROR', error.response.data);
            } finally {
                commit('SET_UPLOAD_PROFILE_PICTURE_LOADING', false);
            }
        },
        async resetForm({ commit }) {
            commit('RESET_FORM', null);
        }
    },
};