<template v-if="authenticated">
  <v-container class="pa-0 ma-0">
    <v-row justify="center">
      <v-col lg="12" md="12" sm="12" xs="12">
        <v-sheet color="white" rounded="xl" class="pa-1 ma-2">
          <v-row class="mb-0 pt-6" justify="center">
            <v-col align="center">
              <v-row>
                <v-col>
                  <v-hover v-slot="{ hover }">
                    <v-avatar size="150" :class="{ 'on-hover': hover }">
                      <v-overlay v-if="hover" absolute color="#036358">
                        <v-btn
                          @click="openEditProfilePictureDialog()"
                          class="edit-button"
                          icon
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </v-overlay>
                      <img
                        v-if="
                          pawPortalAccount != null &&
                          pawPortalAccount.profile_picture != null &&
                          pawPortalAccount.first_names != null
                        "
                        :src="pawPortalAccount.profile_picture"
                        :alt="pawPortalAccount.first_names"
                      />
                      <img
                        v-else
                        src="https://thumbs.dreamstime.com/b/vector-human-head-illustration-isolated-white-shoulders-simple-57912381.jpg"
                        alt="Default"
                      />
                    </v-avatar>
                  </v-hover>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-dialog
                    v-model="showEditProfilePictureDialog"
                    max-width="600px"
                  >
                    <edit-profile-picture-dialog
                      @click:outside="showEditProfilePictureDialog = false"
                      @submit="updateProfilePicture"
                      @close="showEditProfilePictureDialog = false"
                    />
                  </v-dialog>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row justify="center" class="pt-10">
            <v-col lg="6" md="8" sm="10" xs="12">
              <v-row>
                <v-col
                  align="center"
                  class="pa-0"
                  v-if="pawPortalAccount"
                  cols="12"
                >
                  <v-chip label outlined color="#7db52f">
                    {{ formatAccountType(pawPortalAccount.type) }}
                  </v-chip>
                </v-col>
              </v-row>
              <v-row align="center" class="pa-2">
                <v-col v-if="pawPortalAccount">
                  <h2>Welcome back, {{ pawPortalAccount.first_names }}</h2>
                </v-col>
                <v-col v-else>
                  <h2>Welcome back, {{ user.name.split(" ")[0] }}</h2>
                </v-col>
              </v-row>

              <v-row align="center" class="pl-2">
                <v-col align="start">
                  <b>YOUR PROFILE</b>
                  <span class="float-right" v-if="pawPortalAccount">
                    <!-- Call SET_UPDATE_PROFILE_LOADING -->
                    <v-icon @click="openProfileEditDialogue">mdi-pencil</v-icon>
                  </span>
                  <!-- Edit Profile Overlay -->
                  <v-dialog
                    v-model="showEditProfileOverlay"
                    persistent
                    :width="dialogWidth"
                  >
                    <v-card>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="#7db52f"
                          fab
                          small
                          dark
                          @click="showEditProfileOverlay = false"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-actions>
                      <v-card-text>
                        <UpdateProfileDialogue :key="componentKey" />
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row v-if="!pawPortalAccount" class="pl-2">
                <v-col>
                  <v-row>
                    <v-col> You have not completed your account setup. </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn @click="$router.push('/register')"
                        >Complete Registration</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="pawPortalAccount" class="py-0">
                <v-col>
                  <v-row>
                    <v-col>
                      <!-- Full Name -->
                      <v-row class="py-0 mb-0">
                        <v-col class="pb-0">
                          <v-sheet
                            color="#e6e6e6"
                            rounded="lg"
                            class="pl-1 py-1"
                          >
                            <v-row>
                              <v-col cols="1">
                                <v-icon>mdi-account</v-icon>
                              </v-col>
                              <v-col
                                cols="11"
                                justify="start"
                                class="overflow-auto"
                              >
                                {{ pawPortalAccount.first_names }}
                                {{ pawPortalAccount.surname }}
                              </v-col>
                            </v-row>
                          </v-sheet>
                        </v-col>
                      </v-row>
                      <!-- Email -->
                      <v-row class="py-0">
                        <v-col class="py-0">
                          <v-sheet
                            color="#e6e6e6"
                            rounded="lg"
                            class="pl-1 py-1"
                          >
                            <v-row>
                              <v-col cols="1">
                                <v-icon>mdi-email</v-icon>
                              </v-col>
                              <v-col
                                cols="11"
                                justify="start"
                                class="overflow-auto"
                              >
                                {{ pawPortalAccount.email }}
                              </v-col>
                            </v-row>
                          </v-sheet>
                        </v-col>
                      </v-row>
                      <!-- Phone Default -->
                      <v-row
                        class="py-0"
                        v-if="pawPortalAccount.telephone_numbers.length == 0"
                      >
                        <v-col>
                          <v-sheet
                            color="#e6e6e6"
                            rounded="lg"
                            class="pl-1 py-1"
                          >
                            <v-row>
                              <v-col cols="1">
                                <v-icon>mdi-phone</v-icon>
                              </v-col>
                              <v-col cols="11" justify="start">
                                <v-row>
                                  <v-col class="pb-0">
                                    <b>
                                      <!-- {{ phone.number_type }} -->
                                    </b>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col class="pt-0 overflow-auto">
                                    You have not set a phone number.
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-sheet>
                        </v-col>
                      </v-row>
                      <!-- For each phone in pawPortalAccount.telephone_numbers -->
                      <v-row
                        class="py-0"
                        v-for="phone in pawPortalAccount.telephone_numbers"
                        :key="phone.id"
                      >
                        <v-col>
                          <v-sheet
                            color="#e6e6e6"
                            rounded="lg"
                            class="pl-1 py-1"
                          >
                            <v-row>
                              <v-col cols="1">
                                <v-icon>mdi-phone</v-icon>
                              </v-col>
                              <v-col cols="11" justify="start">
                                <v-row>
                                  <v-col class="pb-0">
                                    <b>
                                      <!-- phone.number_type with upper case first letter -->
                                      <!-- TODO: Add number_type when creating Account -->
                                      {{
                                        phone.number_type != null
                                          ? phone.number_type
                                              .charAt(0)
                                              .toUpperCase() +
                                            phone.number_type.slice(1)
                                          : "Default"
                                      }}
                                    </b>
                                  </v-col>
                                  <v-col
                                    cols="auto"
                                    class="pb-0 mb-0 mt-1 mr-2"
                                    v-if="!phone.verified"
                                  >
                                    <v-chip
                                      label
                                      small
                                      outlined
                                      :color="phone.verified ? 'green' : 'red'"
                                    >
                                      <span>
                                        {{
                                          phone.verified
                                            ? "Verified"
                                            : "Not Verified"
                                        }}
                                      </span>
                                    </v-chip>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col class="pt-0 overflow-auto">
                                    {{ phone.number }}
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-sheet>
                        </v-col>
                      </v-row>
                      <!-- Home Address -->
                      <v-row class="py-0 my-0 pt-1">
                        <v-col>
                          <v-sheet
                            color="#e6e6e6"
                            rounded="lg"
                            class="pl-1 py-1"
                          >
                            <v-row>
                              <v-col cols="1">
                                <v-icon>mdi-home</v-icon>
                              </v-col>
                              <v-col
                                justify="start"
                                cols="11"
                                class="overflow-auto"
                                v-if="
                                  pawPortalAccount.address_line_1 ||
                                  pawPortalAccount.address_line_2 ||
                                  pawPortalAccount.address_line_3 ||
                                  pawPortalAccount.address_line_4 ||
                                  pawPortalAccount.address_postcode
                                "
                              >
                                <span v-if="pawPortalAccount.address_line_1">
                                  {{ pawPortalAccount.address_line_1 }} <br />
                                </span>
                                <span v-if="pawPortalAccount.address_line_2">
                                  {{ pawPortalAccount.address_line_2 }} <br />
                                </span>
                                <span v-if="pawPortalAccount.address_line_3">
                                  {{ pawPortalAccount.address_line_3 }} <br />
                                </span>
                                <span v-if="pawPortalAccount.address_line_4">
                                  {{ pawPortalAccount.address_line_4 }} <br />
                                </span>
                                <span v-if="pawPortalAccount.address_postcode">
                                  {{ pawPortalAccount.address_postcode }} <br />
                                </span>
                              </v-col>
                              <v-col v-else>
                                You have not set an address.
                              </v-col>
                            </v-row>
                          </v-sheet>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <!-- <v-row align="center" class="pl-2">
                    <v-col align="start">
                      <b>YOUR PAWS</b>
                      <span class="float-right">
                      </span>
                      <v-divider></v-divider>
                    </v-col>
                  </v-row> -->
                  <!-- <v-row v-for="item in dogs" :key="item.id">
                    <v-col cols="1" class="mx-5">
                      <v-avatar size="50">
                        <img
                          v-if="
                            pawPortalAccount != null &&
                            pawPortalAccount.profile_picture != null &&
                            pawPortalAccount.first_names != null
                          "
                          :src="pawPortalAccount.profile_picture"
                          :alt="pawPortalAccount.first_names"
                        />
                        <img
                          v-else
                          src="https://thumbs.dreamstime.com/b/vector-human-head-illustration-isolated-white-shoulders-simple-57912381.jpg"
                          alt="Default"
                        />
                      </v-avatar>
                    </v-col>
                    <v-col justify="start">
                      <v-row>
                        <v-col cols="1" class="pb-1">
                          <v-icon>mdi-paw</v-icon>
                        </v-col>
                        <v-col class="pb-1" justify="start">
                          <b>{{ item.name }}</b>
                        </v-col>
                      </v-row>
                      <v-row justify="start">
                        <v-col cols="1" class="pt-0">
                          <v-icon>mdi-dog-side</v-icon>
                        </v-col>
                        <v-col class="pt-0">
                          {{ item.breed.breed }}
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row> -->
                </v-col>
              </v-row>
              <v-row class="pa-2"> </v-row>
              <v-row class="pa-2">
                <!-- {{ accountDetails }} -->
              </v-row>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import UpdateProfileDialogue from "./overview/UpdateProfileDialogue.vue";
import EditProfilePictureDialog from "./EditProfilePictureDialog.vue";

export default {
  name: "Account",
  components: {
    UpdateProfileDialogue,
    EditProfilePictureDialog,
  },
  data() {
    return {
      showEditProfileOverlay: false,
      componentKey: 0,
      showEditProfilePictureDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/isAuthenticated",
      user: "auth/user",
      pawPortalAccount: "account/accountDetails",
      account: "account/all",
      dogs: "pets/dogs",
      uploadProfilePictureComplete: "account/uploadProfilePictureComplete",
      updateProfileComplete: "account/updateProfileComplete",
    }),
    dialogWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "80%";
        case "md":
          return "60%";
        case "lg":
          return "40%";
        case "xl":
          return "40%";
      }
      return "100%";
    },
  },
  watch: {
    uploadProfilePictureComplete(val) {
      if (val) {
        // Wait 2 seconds
        setTimeout(() => {
          this.showEditProfilePictureDialog = false;
        }, 2000);
      }
    },
    updateProfileComplete: function (val) {
      if (val) {
        // Sleep 2 seconds then hide the dialog
        setTimeout(() => {
          this.showEditProfileOverlay = false;
        }, 2000);
      }
    },
  },
  methods: {
    ...mapActions({
      authLogout: "auth/logout",
      getAccount: "account/getAccount",
      getDogs: "pets/getDogs",
      resetForm: "account/resetForm",
    }),
    updateProfilePicture(newProfilePicture) {
      // Update the profile picture with the new data
      // this.profilePicture = newProfilePicture.src;
      // this.altText = newProfilePicture.alt;
      // Hide the dialog component
      this.showEditProfilePictureDialog = false;
    },
    formatAccountType(val) {
      var words = val.split("_");
      var formatted_name = "";
      words.forEach((element) => {
        if (element.length < 1) {
          formatted_name += " " + element;
        }
        formatted_name +=
          " " + element.toLowerCase().replace(/^./, element[0].toUpperCase());
      });

      return formatted_name.trim();
    },
    openProfileEditDialogue() {
      this.$store.commit("account/RESET_UPDATE_PROFILE");
      this.componentKey++;
      this.showEditProfileOverlay = true;
    },
    openEditProfilePictureDialog() {
      this.resetForm();
      this.showEditProfilePictureDialog = true;
    },
  },
  mounted: function () {
    // this.getAccount();
    console.log(this.user);
    if (this.dogs.length === 0) {
      this.getDogs();
    }
    console.log(this.dogs);
  },
};
</script>

<style></style>
