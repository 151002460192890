<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col>
          <h1>Privacy Policy</h1>
          <p>Last updated: May 16, 2023</p>
          <p>
            This Privacy Policy describes our policies and procedures on the
            collection, use, and disclosure of your information when you use our
            service and tells you about your privacy rights and how the law
            protects you.
          </p>
          <p>
            We use your personal data to provide and improve the service. By
            using the service, you agree to the collection and use of
            information in accordance with this Privacy Policy.
          </p>
          <h1>Interpretation and Definitions</h1>
          <h2>Interpretation</h2>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h2>Definitions</h2>
          <p>For the purposes of this Privacy Policy:</p>
          <ul>
            <li>
              <p>
                <strong>Account</strong> means a unique account created for you
                to access our service or parts of our service.
              </p>
            </li>
            <li>
              <p>
                <strong>Company</strong> (referred to as either "the Company",
                "we", "us" or "our" in this Agreement) refers to Paw portal.
              </p>
            </li>
            <li>
              <p>
                <strong>Cookies</strong> are small files that are placed on your
                computer, mobile device, or any other device by a website,
                containing the details of your browsing history on that website
                among its many uses.
              </p>
            </li>
            <li>
              <p><strong>Country</strong> refers to: United Kingdom</p>
            </li>
            <li>
              <p>
                <strong>Device</strong> means any device that can access the
                service such as a computer, a cellphone, or a digital tablet.
              </p>
            </li>
            <li>
              <p>
                <strong>Personal Data</strong> is any information that relates
                to an identified or identifiable individual.
              </p>
            </li>
            <li>
              <p>
                <strong>Service</strong> refers to the https://pawportal.co.k.
              </p>
            </li>
            <li>
              <p>
                <strong>Service Provider</strong> means any natural or legal
                person who processes the data on behalf of the company. It
                refers to third-party companies or individuals employed by the
                company to facilitate the service, to provide the service on
                behalf of the company, to perform services related to the
                service, or to assist the company in analyzing how the service
                is used.
              </p>
            </li>
            <li>
              <p>
                <strong>Third-party Social Media Service</strong> refers to any
                website or any social network website through which a user can
                log in or create an account to use the service.
              </p>
            </li>
            <li>
              <p>
                <strong>Usage Data</strong> refers to data collected
                automatically, either generated by the use of the service or
                from the service infrastructure itself (for example, the
                duration of a page visit).
              </p>
            </li>
            <li>
              <p><strong>Website</strong> refers to https://pawportal.co.uk</p>
            </li>
            <li>
              <p>
                <strong>You</strong> means the individual accessing or using the
                service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the service, as
                applicable.
              </p>
            </li>
          </ul>
          <h1>Collecting and Using Your Personal Data</h1>
          <h2>Types of Data Collected</h2>
          <h3>Personal Data</h3>
          <p>
            While using our service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you. Personally identifiable information may include, but
            is not limited to:
          </p>
          <ul>
            <li>
              <p>Email address</p>
            </li>
            <li>
              <p>First name and last name</p>
            </li>
            <li>
              <p>Phone number</p>
            </li>
            <li>
              <p>Address, State, Province, ZIP/Postal code, City</p>
            </li>
            <li>
              <p>Usage Data</p>
            </li>
          </ul>
          <h3>Usage Data</h3>
          <p>Usage Data is collected automatically when using the service.</p>
          <p>
            Usage Data may include information such as your device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our service that you visit, the time and date of your
            visit, the time spent on those pages, unique device identifiers, and
            other diagnostic data.
          </p>
          <p>
            When you access the service by or through a mobile device, we may
            collect certain information automatically, including, but not
            limited to, the type of mobile device you use, your mobile device
            unique ID, the IP address of your mobile device, your mobile
            operating system, the type of mobile Internet browser you use,
            unique device identifiers, and other diagnostic data.
          </p>
          <p>
            We may also collect information that your browser sends whenever you
            visit our service or when you access the service by or through a
            mobile device.
          </p>
          <h3>Information from Third-Party Social Media Services</h3>
          <p>
            The company allows you to create an account and log in to use the
            service through the following Third-party Social Media Services:
          </p>
          <ul>
            <li>Google</li>
            <li>Facebook</li>
            <li>Twitter</li>
          </ul>
          <p>
            If you decide to register through or otherwise grant us access to a
            Third-Party Social Media Service, we may collect personal data that
            is already associated with your Third-Party Social Media Service's
            account, such as your name, your email address, your activities, or
            your contact list associated with that account.
          </p>
          <p>
            You may also have the option of sharing additional information with
            the company through your Third-Party Social Media Service's account.
            If you choose to provide such information and personal data, during
            registration or otherwise, you are giving the company permission to
            use, share, and store it in a manner consistent with this Privacy
            Policy.
          </p>
          <h3>Tracking Technologies and Cookies</h3>
          <p>
            We use cookies and similar tracking technologies to track the
            activity on our service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze our service. The
            technologies we use may include:
          </p>
          <ul>
            <li>
              <strong>Cookies or Browser Cookies.</strong> A cookie is a small
              file placed on your device. You can instruct your browser to
              refuse all cookies or to indicate when a cookie is being sent.
              However, if you do not accept cookies, you may not be able to use
              some parts of our service. Unless you have adjusted your browser
              setting so that it will refuse cookies, our service may use
              cookies.
            </li>
            <li>
              <strong>Flash Cookies.</strong> Certain features of our service
              may use local stored objects (or Flash Cookies) to collect and
              store information about your preferences or your activity on our
              service. Flash Cookies are not managed by the same browser
              settings as those used for Browser Cookies.
            </li>
            <li>
              <strong>Web Beacons.</strong> Certain sections of our service and
              our emails may contain small electronic files known as web beacons
              (also referred to as clear gifs, pixel tags, and single-pixel
              gifs) that permit the company, for example, to count users who
              have visited those pages or opened an email and for other related
              website statistics (for example, recording the popularity of a
              certain section and verifying system and server integrity).
            </li>
          </ul>
          <p>
            Cookies can be "Persistent" or "Session" Cookies. Persistent cookies
            remain on your personal computer or mobile device when you go
            offline, while Session cookies are deleted as soon as you close your
            web browser.
          </p>
          <p>
            We use both session and persistent cookies for the purposes set out
            below:
          </p>
          <ul>
            <li>
              <p><strong>Necessary / Essential Cookies</strong></p>
              <p>Type: Session Cookies</p>
              <p>Administered by: Us</p>
              <p>
                Purpose: These cookies are essential to provide you with
                services available through the website and to enable you to use
                some of its features. They help to authenticate users and
                prevent fraudulent use of user accounts. Without these cookies,
                the services that you have asked for cannot be provided, and we
                only use these cookies to provide you with those services.
              </p>
            </li>
            <li>
              <p>
                <strong>Cookies Policy / Notice Acceptance Cookies</strong>
              </p>
              <p>Type: Persistent Cookies</p>
              <p>Administered by: Us</p>
              <p>
                Purpose: These cookies identify if users have accepted the use
                of cookies on the website.
              </p>
            </li>
            <li>
              <p><strong>Functionality Cookies</strong></p>
              <p>Type: Persistent Cookies</p>
              <p>Administered by: Us</p>
              <p>
                Purpose: These cookies allow us to remember choices you make
                when you use the website, such as remembering your login details
                or language preference. The purpose of these cookies is to
                provide you with a more personal experience and to avoid you
                having to re-enter your preferences every time you use the
                website.
              </p>
            </li>
          </ul>
          <h2>Use of Your Personal Data</h2>
          <p>The company may use personal data for the following purposes:</p>
          <ul>
            <li>
              <p>
                <strong>To provide and maintain our service</strong>, including
                to monitor the usage of our service.
              </p>
            </li>
            <li>
              <p>
                <strong>To manage your account:</strong> to manage your
                registration as a user of the service. The personal data you
                provide can give you access to different functionalities of the
                service that are available to you as a registered user.
              </p>
            </li>
            <li>
              <p>
                <strong>For the performance of a contract:</strong> the
                development, compliance, and undertaking of the purchase
                contract for the products, items, or services you have purchased
                or of any other contract with us through the service.
              </p>
            </li>
            <li>
              <p>
                <strong>To contact you:</strong> To contact you by email,
                telephone calls, SMS, or other equivalent forms of electronic
                communication, such as a mobile application's push notifications
                regarding updates or informative communications related to the
                functionalities, products, or contracted services, including the
                security updates, when necessary or reasonable for their
                implementation.
              </p>
            </li>
            <li>
              <p>
                <strong>To provide you</strong> with news, special offers, and
                general information about other goods, services, and events
                which we offer that are similar to those that you have already
                purchased or enquired about, unless you have opted not to
                receive such information.
              </p>
            </li>
            <li>
              <p>
                <strong>To manage your requests:</strong> To attend and manage
                your requests to us.
              </p>
            </li>
            <li>
              <p>
                <strong>For business transfers:</strong> We may use your
                information to evaluate or conduct a merger, divestiture,
                restructuring, reorganization, dissolution, or other sale or
                transfer of some or all of our assets, whether as a going
                concern or as part of bankruptcy, liquidation, or similar
                proceeding, in which personal data held by us about our service
                users is among the assets transferred.
              </p>
            </li>
            <li>
              <p>
                <strong>For other purposes</strong>: We may use your information
                for other purposes, such as data analysis, identifying usage
                trends, determining the effectiveness of our promotional
                campaigns, and to evaluate and improve our service, products,
                services, marketing, and your experience.
              </p>
            </li>
          </ul>
          <p>
            We may share your personal information in the following situations:
          </p>
          <ul>
            <li>
              <strong>With Service Providers:</strong> We may share your
              personal information with service providers to monitor and analyze
              the use of our service, to contact you.
            </li>
            <li>
              <strong>For business transfers:</strong> We may share or transfer
              your personal information in connection with, or during
              negotiations of, any merger, sale of company assets, financing, or
              acquisition of all or a portion of our business to another
              company.
            </li>
            <li>
              <strong>With Affiliates:</strong> We may share your information
              with our affiliates, in which case we will require those
              affiliates to honor this Privacy Policy. Affiliates include our
              parent company and any other subsidiaries, joint venture partners,
              or other companies that we control or that are under common
              control with us.
            </li>
            <li>
              <strong>With business partners:</strong> We may share your
              information with our business partners to offer you certain
              products, services, or promotions.
            </li>
            <li>
              <strong>With other users:</strong> When you share personal
              information or otherwise interact in the public areas with other
              users, such information may be viewed by all users and may be
              publicly distributed outside. If you interact with other users or
              register through a Third-Party Social Media Service, your contacts
              on the Third-Party Social Media Service may see your name,
              profile, pictures, and description of your activity. Similarly,
              other users will be able to view descriptions of your activity,
              communicate with you, and view your profile.
            </li>
            <li>
              <strong>With your consent:</strong> We may disclose your personal
              information for any other purpose with your consent.
            </li>
          </ul>
          <h2>Retention of Your Personal Data</h2>
          <p>
            The company will retain your personal data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use your personal data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </p>
          <p>
            The company will also retain usage data for internal analysis
            purposes. Usage data is generally retained for a shorter period,
            except when this data is used to strengthen the security or to
            improve the functionality of our service, or we are legally
            obligated to retain this data for longer time periods.
          </p>
          <h2>Transfer of Your Personal Data</h2>
          <p>
            Your information, including personal data, may be transferred to -
            and maintained on - computers located outside of your state,
            province, country, or other governmental jurisdiction where the data
            protection laws may differ from those of your jurisdiction.
          </p>
          <p>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p>
            The company will take all the steps reasonably necessary to ensure
            that your data is treated securely and in accordance with this
            Privacy Policy and no transfer of your personal data will take place
            to an organization or a country unless there are adequate controls
            in place including the security of your data and other personal
            information.
          </p>
          <h2>Security of Your Personal Data</h2>
          <p>
            The security of your personal data is important to us, but remember
            that no method of transmission over the Internet or method of
            electronic storage is 100% secure. While we strive to use
            commercially acceptable means to protect your personal data, we
            cannot guarantee its absolute security.
          </p>
          <h1>Children's Privacy</h1>
          <p>
            Our service does not address anyone under the age of 18
            ("Children").
          </p>
          <p>
            We do not knowingly collect personally identifiable information from
            anyone under the age of 18. If you are a parent or guardian and you
            are aware that your Children has provided us with personal data,
            please contact us. If we become aware that we have collected
            personal data from children without verification of parental
            consent, we take steps to remove that information from our servers.
          </p>
          <h1>Changes to This Privacy Policy</h1>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            We will let you know via email and/or a prominent notice on our
            service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <h1>Contact Us</h1>
          <p>
            If you have any questions about this Privacy Policy, you can contact
            us:
          </p>
          <ul>
            <li>By email: privacy@pawportal.co.uk</li>
            <li>
              By visiting this page on our website: https://pawportal.co.uk
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "Login",
  components: {
    //Calendar
  },
  data() {
    return {
      name: "PrivacyPolicy",
    };
  },

  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
