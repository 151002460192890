import axios from 'axios';

export default {
    namespaced: true,

    state: {
        invites: [],
        getInvitesError: false,
        createInvite: {
            error_items: [],
            error: false,
            invite: [],
            complete: false,
            company_id: "",
        },
        loading: false,
        checkInvite: {
            invite_code: "",
            invite_code_length: 8,
            loading: false,
            validCode: false,
            error: false,
            errorMessage: "",
            response: null
        },
        acceptInvite: {
            complete: false,
            error: false,
            errorItems: [],
            loading: false,
            code: "",
            supervisor_id: ""
        },
        deleteInvite: {
            complete: false,
            error: false,
            errorItems: [],
            loading: false,
        },
        companyInvites: {
            invites: [],
            complete: false,
            error: false,
            errorItems: [],
            loading: false,
        }
    },

    mutations: {
        RESET_STATE(state) {
            console.log("RESET_STATE INVITES called");
            state.invites = [];
            state.getInvitesError = false;
            state.createInvite.complete = false;
            state.createInvite.invite = [];
            state.createInvite.error = false;
            state.createInvite.error_items = [];
            state.acceptInvite.complete = false;
            state.acceptInvite.error = false;
            state.acceptInvite.errorItems = [];
            state.acceptInvite.loading = false;
            state.acceptInvite.supervisor_id = "";
            state.loading = false;
            state.checkInvite.invite_code = "";
            state.checkInvite.invite_code_length = 8;
            state.checkInvite.loading = false;
            state.checkInvite.validCode = false;
            state.checkInvite.error = false;
            state.checkInvite.errorMessage = "";
            state.checkInvite.response = null;
            state.deleteInvite.complete = false;
            state.deleteInvite.error = false;
            state.deleteInvite.errorItems = [];
            state.deleteInvite.loading = false;
            state.companyInvites.complete = false;
            state.companyInvites.error = false;
            state.companyInvites.errorItems = [];
            state.companyInvites.loading = false;
        },
        RESET_FORM(state) {
            // console.log("RESET_FORM called");
            state.createInvite.complete = false;
            state.createInvite.invite = [];
            state.createInvite.error = false;
            state.createInvite.error_items = [];
            state.createInvite.company_id = "";
            state.acceptInvite.complete = false;
            state.acceptInvite.error = false;
            state.acceptInvite.errorItems = [];
            state.deleteInvite.complete = false;
            state.deleteInvite.error = false;
            state.deleteInvite.errorItems = [];
            state.deleteInvite.loading = false;
        },
        RESET_FORM_ACCEPT_INVITE_CODE(state) {
            state.acceptInvite.complete = false;
            state.acceptInvite.error = false;
            state.acceptInvite.errorItems = [];
            state.acceptInvite.loading = false;
            state.loading = false;
        },
        SET_GET_INVITES(state, val) {
            state.invites = val;
        },
        SET_GET_INVITES_ERROR(state, val) {
            state.getInvitesError = val;
        },
        SET_CREATE_INVITES_COMPLETE(state, val) {
            state.createInvite.complete = val;
        },
        SET_CREATED_INVITE(state, val) {
            state.createInvite.invite = val;
        },
        SET_LOADING(state, val) {
            state.loading = val;
        },
        SET_CHECK_INVITE_INVITE_CODE(state, val) {
            state.checkInvite.invite_code = val;
        },
        SET_CHECK_INVITE_INVITE_CODE_LENGTH(state, val) {
            state.checkInvite.invite_code_length = val;
        },
        SET_CHECK_INVITE_LOADING(state, val) {
            state.checkInvite.loading = val;
        },
        SET_CHECK_INVITE_VALID_CODE(state, val) {
            state.checkInvite.validCode = val;
        },
        SET_CHECK_INVITE_ERROR(state, val) {
            state.checkInvite.error = val;
        },
        SET_CHECK_INVITE_ERROR_MESSAGE(state, val) {
            state.checkInvite.errorMessage = val;
        },
        SET_CHECK_INVITE_RESPONSE(state, val) {
            state.checkInvite.response = val;
        },
        SET_CREATE_INVITES_ERROR(state, val) {
            state.createInvite.complete = false;
            state.createInvite.error = true;
            state.createInvite.error_items = [];
            try {
                val.detail.forEach(element => {
                    const item = {
                        item: element.loc[1].charAt(0).toUpperCase() + element.loc[1].slice(1).replace(/_/g, " "),
                        message: element.msg.charAt(0).toUpperCase() + element.msg.slice(1).replace(/_/g, " ")
                    }
                    state.createInvite.error_items.push(item)
                });
            } catch (error) {
                console.log("Create invite error message : ", error)
                state.createInvite.error_items.push({
                    item: "Server error",
                    message: "Please try again later"
                })
            }
            console.log("Create invite failed: ", state.createInvite.error_items)
        },
        SET_ACCEPT_INVITE_COMPLETE(state, val) {
            state.acceptInvite.complete = val;
        },
        SET_ACCEPT_INVITE_LOADING(state, val) {
            state.acceptInvite.loading = val;
        },
        SET_ACCEPT_INVITE_CODE(state, val) {
            state.acceptInvite.code = val;
        },
        SET_ACCEPT_INVITE_SUPERVISOR_ID(state, val) {
            console.log("SET SUPERVISOR ID: ", val)
            state.acceptInvite.supervisor_id = val;
        },
        SET_ACCEPT_INVITE_ERROR_MESSAGE(state, val) {
            state.acceptInvite.complete = false;
            state.acceptInvite.error = true;
            state.acceptInvite.errorItems = [];
            try {
                val.detail.forEach(element => {
                    const item = {
                        item: element.loc[1].charAt(0).toUpperCase() + element.loc[1].slice(1).replace(/_/g, " "),
                        message: element.msg.charAt(0).toUpperCase() + element.msg.slice(1).replace(/_/g, " ")
                    }
                    state.acceptInvite.errorItems.push(item)
                });
            } catch (error) {
                try {
                    console.log("Accept invite error message : ", error)
                    if (val.detail == 'current user is already an employee of this company') {
                        state.acceptInvite.errorItems.push({
                            item: "Invite code",
                            message: "You are already a member of this company"
                        })
                    }
                    else {
                        state.acceptInvite.errorItems.push({
                            item: "Server error",
                            message: "Please try again later"
                        })
                    }
                } catch (error) {
                    console.log("Accept invite error message : ", error)
                    state.acceptInvite.errorItems.push({
                        item: "Server error",
                        message: "Please try again later"
                    })
                }
                console.log("Accept invite failed: ", state.acceptInvite.errorItems)
            }
        },
        SET_DELETE_INVITE_COMPLETE(state, val) {
            state.deleteInvite.complete = val;
        },
        SET_DELETE_INVITE_ERROR(state, val) {
            state.deleteInvite.error = val;

            state.deleteInvite.complete = false;
            state.deleteInvite.error = true;
            state.deleteInvite.errorItems = [];
            try {
                val.detail.forEach(element => {
                    const item = {
                        item: element.loc[1].charAt(0).toUpperCase() + element.loc[1].slice(1).replace(/_/g, " "),
                        message: element.msg.charAt(0).toUpperCase() + element.msg.slice(1).replace(/_/g, " ")
                    }
                    state.deleteInvite.errorItems.push(item)
                });
            } catch (error) {
                console.log("Delete invite error message : ", error)
                state.deleteInvite.errorItems.push({
                    item: "Server error",
                    message: "Please try again later"
                })
            }
            console.log("Accept invite failed: ", state.deleteInvite.errorItems)
        },
        SET_DELETE_INVITE_LOADING(state, val) {
            state.deleteInvite.loading = val;
        },
        SET_CREATE_INVITE_COMPANY_ID(state, val) {
            state.createInvite.company_id = val;
        },
        SET_COMPANY_INVITES(state, val) {
            state.companyInvites.invites = val;
        },
        SET_COMPANY_INVITES_COMPLETE(state, val) {
            state.companyInvites.complete = val;
        },
        SET_COMPANY_INVITES_ERROR(state, val) {
            state.companyInvites.error = val;

            if (val) {
                state.companyInvites.complete = false;
                state.companyInvites.error = true;
                state.companyInvites.errorItems = [];
                try {
                    val.detail.forEach(element => {
                        const item = {
                            item: element.loc[1].charAt(0).toUpperCase() + element.loc[1].slice(1).replace(/_/g, " "),
                            message: element.msg.charAt(0).toUpperCase() + element.msg.slice(1).replace(/_/g, " ")
                        }
                        state.companyInvites.errorItems.push(item)
                    });
                } catch (error) {
                    console.log("Set company invites invite error message : ", error)
                    state.companyInvites.errorItems.push({
                        item: "Server error",
                        message: "Please try again later"
                    })
                }
                console.log("Set company invites failed: ", state.companyInvites.errorItems)
            }
        }
    },

    getters: {
        all(state) {
            return state;
        },
        invites(state) {
            return state.invites;
        },
        getInvitesError(state) {
            return state.getInvitesError;
        },
        createInvitesComplete(state) {
            return state.createInvite.complete;
        },
        createdInvite(state) {
            return state.createInvite.invite;
        },
        createInvitesError(state) {
            return state.createInvite.error;
        },
        createInvitesErrorItems(state) {
            return state.createInvite.error_items;
        },
        createInviteCompanyId(state) {
            console.log("GET COMPANY ID: ", state.createInvite.company_id)
            return state.createInvite.company_id;
        },
        loading(state) {
            return state.loading;
        },
        checkInviteInviteCode(state) {
            return state.checkInvite.invite_code;
        },
        checkInviteInviteCodeLength(state) {
            return state.checkInvite.invite_code_length;
        },
        checkInviteLoading(state) {
            return state.checkInvite.loading;
        },
        checkInviteValidCode(state) {
            return state.checkInvite.validCode;
        },
        checkInviteError(state) {
            return state.checkInvite.error;
        },
        checkInviteErrorMessage(state) {
            return state.checkInvite.errorMessage;
        },
        checkInviteResponse(state) {
            return state.checkInvite.response;
        },
        acceptInviteCode(state) {
            return state.acceptInvite.code;
        },
        acceptInviteComplete(state) {
            return state.acceptInvite.complete;
        },
        acceptInviteError(state) {
            return state.acceptInvite.error;
        },
        acceptInviteErrorItems(state) {
            return state.acceptInvite.errorItems;
        },
        acceptInviteloading(state) {
            return state.acceptInvite.loading;
        },
        acceptInviteSupervisorId(state) {
            return state.acceptInvite.supervisor_id;
        },
        deleteInviteComplete(state) {
            return state.deleteInvite.complete;
        },
        deleteInviteError(state) {
            return state.deleteInvite.error;
        },
        deleteInviteErrorItems(state) {
            return state.deleteInvite.errorItems;
        },
        deleteInviteLoading(state) {
            return state.deleteInvite.loading;
        },
        getInviteCodeError(state) {
            return state.getInviteCodeError;
        },
        getInviteCodeErrorMessage(state) {
            return state.getInviteCodeErrorMessage;
        },
        companyInvites(state) {
            return state.companyInvites.invites;
        },
        companyInvitesComplete(state) {
            return state.companyInvites.complete;
        },
        companyInvitesError(state) {
            return state.companyInvites.error;
        },
        companyInvitesErrorItems(state) {
            return state.companyInvites.errorItems;
        },
        companyInvitesLoading(state) {
            return state.companyInvites.loading;
        }
    },

    actions: {
        async getInvites({
            rootState,
            commit
        }, payload) { // Not destructuring here

            // Provide default values
            const { company_id = null, type = null } = payload || {};

            try {
                commit('SET_LOADING', true);
                console.log("GET INVITES for: ", company_id);
                var url = 'invite';

                // Create an array to hold query parameters
                let queryParams = [];

                if (company_id) {
                    queryParams.push(`company_id=${company_id}`);
                }

                if (type) {
                    queryParams.push(`type=${type}`);
                }

                // If there are any query parameters, append them to the URL
                if (queryParams.length > 0) {
                    url = `${url}?${queryParams.join('&')}`;
                }

                await axios
                    .get(url, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.token,
                        },
                    })
                    .then((resp) => {
                        console.log("INVITES: ", resp.data);
                        commit('SET_GET_INVITES', resp.data);
                        commit('SET_GET_INVITES_ERROR', false);
                        commit('SET_LOADING', false);
                    })
                    .catch(() => {
                        console.log("ERROR getting invites");
                        commit('SET_GET_INVITES', []);
                        commit('SET_GET_INVITES_ERROR', true);
                        commit('SET_LOADING', false);
                    });
            } catch (e) {
                console.log(e);
                commit('SET_LOADING', false);
            }
        },
        async getCompanyInvites({
            rootState,
            commit
        }, payload) { // Not destructuring here

            // Provide default values
            const { company_id = null, type = 'EMPLOYEE' } = payload || {};

            try {
                commit('SET_LOADING', true);
                console.log("GET COMPANY INVITES for: ", company_id);
                var url = 'invite';

                // Create an array to hold query parameters
                let queryParams = [];

                if (company_id) {
                    queryParams.push(`company_id=${company_id}`);
                }

                if (type !== 'none') {
                    queryParams.push(`type=${type}`);
                }

                // If there are any query parameters, append them to the URL
                if (queryParams.length > 0) {
                    url = `${url}?${queryParams.join('&')}`;
                }

                await axios
                    .get(url, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.token,
                        },
                    })
                    .then((resp) => {
                        console.log("COMPANY INVITES: ", resp.data);
                        commit('SET_COMPANY_INVITES', resp.data);
                        commit('SET_COMPANY_INVITES_ERROR', false);
                        commit('SET_LOADING', false);
                    })
                    .catch(() => {
                        console.log("ERROR getting company invites");
                        commit('SET_COMPANY_INVITES', []);
                        commit('SET_COMPANY_INVITES_ERROR', true);
                        commit('SET_LOADING', false);
                    });
            } catch (e) {
                console.log(e);
                commit('SET_LOADING', false);
            }
        },
        async createInvite({
            rootState,
            commit,
            dispatch
        }, inviteData) {
            try {
                commit('SET_LOADING', true);
                console.log("CREATE INVITE PAYLOAD: ", inviteData)
                await axios
                    .post('invite', inviteData, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.token,
                        },
                    })
                    .then((resp) => {
                        commit('SET_CREATE_INVITES_COMPLETE', true);
                        commit('SET_CREATED_INVITE', resp.data);
                        console.log("INVITE CREATED: ", resp.data)
                        commit('SET_LOADING', false);
                    })
                    .catch((error) => {
                        commit('SET_CREATE_INVITES_ERROR', error.response.data);
                        commit('SET_LOADING', false);
                    })
                    .finally(() => {
                        dispatch("getInvites", { company_id: inviteData.company_id });
                    });
            } catch (e) {
                console.log("ERROR when creating invite: ", e);
                commit('SET_CREATE_INVITES_ERROR', null);
                commit('SET_LOADING', false);
                // Refresh invites
                dispatch("getInvites", { company_id: inviteData.company_id });
            }
        },
        async deleteInvite({
            rootState,
            commit,
            dispatch
        }, inviteCode) {
            try {
                commit('RESET_STATE');
                commit('SET_DELETE_INVITE_LOADING', true);
                await axios
                    .delete('invite/' + inviteCode, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.token,
                        },
                    })
                    .then(() => {
                        commit('SET_DELETE_INVITE_COMPLETE', true);
                        dispatch("getInvites", {});
                        commit('SET_DELETE_INVITE_LOADING', false);
                    })
                    .catch((error) => {
                        commit('SET_DELETE_INVITE_ERROR', error.response.data);
                        commit('SET_DELETE_INVITE_LOADING', false);
                    })
                    .finally(() => { });
            } catch (e) {
                console.log("ERROR when deleting invite: ", e);
                commit('SET_DELETE_INVITE_ERROR', null);
                commit('SET_DELETE_INVITE_LOADING', false);
            }
        },
        async acceptInvite({
            rootState,
            commit,
            dispatch
        }, inputData) {
            try {
                commit('SET_ACCEPT_INVITE_LOADING', true);
                console.log("recaptchaToken:", inputData.recaptchaToken)
                console.log("ACCEPT CODE:", inputData.inviteCode)
                await axios
                    .post('invite/' + inputData.inviteCode + '/accept', null, {
                        headers: {
                            Authorization: 'Bearer ' + rootState.auth.token,
                            recaptcha: inputData.recaptchaToken,
                        },
                    })
                    .then((response) => {
                        commit('SET_ACCEPT_INVITE_COMPLETE', true);
                        dispatch("getInvites", {});
                        // TODO - REFRESH LINKED ACCOUNTS
                        commit('SET_ACCEPT_INVITE_LOADING', false);
                    })
                    .catch((error) => {
                        commit('SET_ACCEPT_INVITE_ERROR_MESSAGE', error.response.data);
                        commit('SET_ACCEPT_INVITE_LOADING', false);
                    })
            } catch (e) {
                console.log("ERROR when accepting invite: ", e);
                commit('SET_ACCEPT_INVITE_ERROR_MESSAGE', null);
                commit('SET_ACCEPT_INVITE_LOADING', false);
            }
        },
        async recaptcha() {
            // (optional) Wait until recaptcha has been loaded.
            await this.$recaptchaLoaded();

            // Execute reCAPTCHA with action "login".
            const token = await this.$recaptcha("login");
            return token;
            // console.log("reCAPTCHA token: ", token);
            // Do stuff with the received token.
        },
        async checkInvite({
            commit,
            state
        }, token) {
            // Get a reCAPTCHA code
            // await this.$recaptchaLoaded();
            commit('SET_CHECK_INVITE_ERROR', false);
            commit('SET_CHECK_INVITE_LOADING', true);
            commit('SET_CHECK_INVITE_VALID_CODE', false);
            // console.log("reCAPTCHA token: ", token);
            try {
                await axios
                    .get("invite/" + state.checkInvite.invite_code + "/exists", {
                        headers: {
                            recaptcha: token,
                        },
                    })
                    .then((resp) => {
                        console.log("Check invite: ", resp.data);
                        commit('SET_CHECK_INVITE_RESPONSE', resp.data);
                        if (resp.data.exists) {
                            commit('SET_CHECK_INVITE_LOADING', false);
                            commit('SET_CHECK_INVITE_VALID_CODE', true);
                            commit('SET_CHECK_INVITE_RESPONSE', resp.data);
                            commit('SET_ACCEPT_INVITE_CODE', state.checkInvite.invite_code)
                        } else {
                            commit('SET_CHECK_INVITE_LOADING', false);
                            commit('SET_CHECK_INVITE_VALID_CODE', false);
                        }
                    })
                    .catch((error) => {
                        console.log("ERROR checking invite: ", error.response.data);
                        commit('SET_CHECK_INVITE_ERROR', true);
                        commit('SET_CHECK_INVITE_LOADING', false);
                        commit('SET_CHECK_INVITE_ERROR_MESSAGE', "There was an issue checking this code, please try again or contact support.");
                    })
                    .finally(() => { });
            } catch (e) {
                console.log(e);
                commit('SET_CHECK_INVITE_ERROR', true);
                commit('SET_CHECK_INVITE_LOADING', false);
                commit('SET_CHECK_INVITE_ERROR_MESSAGE', "There was an issue checking this code, please try again or contact support.");
            }
        },
        async resetState({
            commit
        }) {
            commit('RESET_STATE', null);
        },
        async resetForm({
            commit
        }) {
            commit('RESET_FORM', null);
        },
        async resetFormAcceptInvite({
            commit
        }) {
            commit('RESET_FORM_ACCEPT_INVITE_CODE', null);
        },
        async setCreateInviteCompanyId({
            commit
        }, companyId) {
            console.log("SET CREATE INVITE COMPANY ID: ", companyId)
            commit('SET_CREATE_INVITE_COMPANY_ID', companyId);
        },
        async setCheckInviteInviteCode({
            commit
        }, inviteCode) {
            commit('SET_CHECK_INVITE_INVITE_CODE', inviteCode);
        },
    },
};