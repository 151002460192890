<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col md="6">
        <v-row>
          <v-col>
            <EmailPreferences />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import EmailPreferences from "../components/invites/EmailPreferences.vue";

export default {
  name: "CalendarPage",
  data() {
    return {
      dialog: false,
    };
  },
  components: {
    EmailPreferences,
  },
  methods: {},
  computed: {},
  mounted() {},
};
</script>

<style></style>
