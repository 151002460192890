<template v-if="authenticated">
  <v-container class="pa-0 ma-0">
    <v-row justify="center">
      <v-col lg="12" md="12" sm="12" xs="12">
        <v-sheet color="white" rounded="xl" class="pa-1 ma-2">
          <v-row justify="start" class="pt-10">
            <v-col lg="6" md="8" sm="10" xs="12">
              <v-row align="center" class="pa-2">
                <v-col v-if="pawPortalAccount">
                  <h2>Make an Appointment</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row v-if="!createComplete">
                <v-col>
                  <v-row align="center">
                    <v-col v-if="pawPortalAccount">
                      <b>Select company</b>
                    </v-col>
                  </v-row>
                  <v-row v-if="companies.length > 1">
                    <v-col>
                      <!-- v-list block with selected item in orange to select a company -->
                      <v-list-item-group color="primary">
                        <v-list-item
                          v-for="(company, index) in companies"
                          :key="index"
                          @click="selectedCompany = company.id"
                          class="my-3"
                          v-ripple="false"
                        >
                          <v-list-item-content>
                            <v-list-item-title>{{
                              company.name
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-col>
                  </v-row>
                  <v-row v-if="!createComplete">
                    <!-- Overlay for create booking loading -->
                    <v-overlay :value="createLoading" absolute>
                      <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                      ></v-progress-circular>
                    </v-overlay>
                    <v-col>
                      <v-form
                        ref="form"
                        @submit.prevent="submitForm"
                        v-if="selectedCompany"
                      >
                        <v-row>
                          <v-col>
                            <v-divider></v-divider>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col v-if="pawPortalAccount">
                            <b>Select service</b>
                          </v-col>
                        </v-row>
                        <!-- Primary Service Search Bar -->
                        <v-row class="pb-0 mb-0">
                          <v-col class="pb-0 mb-0">
                            <v-text-field
                              v-model="searchQuery"
                              filled
                              label="Search for a service"
                              prepend-inner-icon="mdi-magnify"
                              dense
                              class="py-0 my-0"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <!-- Loading Services -->
                        <v-row v-if="servicesLoading" justify="center">
                          <v-col align="center">
                            <v-progress-circular
                              indeterminate
                              size="64"
                              color="green"
                            ></v-progress-circular>
                          </v-col>
                        </v-row>
                        <!-- No Services Message -->
                        <v-row
                          v-if="!servicesLoading && services.length == 0"
                          class="pt-0 mt-0"
                        >
                          <v-col cols="10" class="pt-0 mt-0">
                            <span class="description-small">
                              This company has no services available, please
                              contact our Support Team
                            </span>
                          </v-col>
                        </v-row>
                        <!-- Select Primary Service -->
                        <v-row class="pt-0 mt-0">
                          <v-col class="pt-0 mt-0">
                            <v-list-item-group
                              color="primary"
                              v-model="selectedServiceIndex"
                            >
                              <v-list-item
                                v-for="item in filteredServices"
                                :key="item.id"
                                @click="selectedService = item"
                                class="my-2"
                                v-ripple="false"
                              >
                                <v-list-item-content>
                                  <v-list-item-title
                                    ><b
                                      >{{ item.name }} ({{
                                        item.duration_type == "ALL_DAY"
                                          ? "All Day"
                                          : item.duration_in_mins + " mins"
                                      }})</b
                                    >
                                    ({{
                                      convertCurrencyUnitToSymbol(
                                        item.current_price.currency
                                      )
                                    }}{{
                                      formatPrice(
                                        item.current_price.amount_per_unit
                                      )
                                    }})</v-list-item-title
                                  >
                                  <v-list-item-subtitle>{{
                                    item.description
                                  }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-col>
                        </v-row>
                        <!------ Primary Service Questions ------>
                        <!-- Selected Date -->
                        <v-row
                          class="pa-0 ma-0"
                          v-if="selectedService && selectedServiceIndex != null"
                        >
                          <v-col class="pa-0 ma-0">
                            <v-row>
                              <v-col>
                                <v-divider></v-divider>
                              </v-col>
                            </v-row>
                            <v-row align="center">
                              <v-col v-if="pawPortalAccount">
                                <b>Select date and time</b>
                              </v-col>
                            </v-row>
                            <v-row class="pt-0 mt-0">
                              <v-col class="pt-0 mt-0">
                                <v-date-picker
                                  v-model="selectedDate"
                                  :min="new Date().toISOString().substr(0, 10)"
                                  :max="
                                    new Date(
                                      new Date().setDate(
                                        new Date().getDate() + 365
                                      )
                                    )
                                      .toISOString()
                                      .substr(0, 10)
                                  "
                                  color="primary"
                                  no-title
                                  scrollable
                                  v-ripple="false"
                                  first-day-of-week="1"
                                ></v-date-picker>
                              </v-col>
                            </v-row>
                            <v-row
                              v-if="selectedService.duration_type != 'ALL_DAY'"
                              class="pt-0 mt-0"
                            >
                              <v-col>
                                <!-- Show time picker if not an all day event -->
                                <!-- Time picker in dialog -->
                                <v-dialog
                                  ref="dialog"
                                  v-model="modal2"
                                  :return-value.sync="selectedTime"
                                  persistent
                                  width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="selectedTime"
                                      label="Picker in dialog"
                                      prepend-icon="mdi-clock-time-four-outline"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-time-picker
                                    v-if="modal2"
                                    v-model="selectedTime"
                                    full-width
                                    format="24hr"
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="modal2 = false"
                                    >
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="$refs.dialog.save(selectedTime)"
                                    >
                                      OK
                                    </v-btn>
                                  </v-time-picker>
                                </v-dialog>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <!-- Select Dogs(s) -->
                        <v-row class="pa-0 ma-0" v-if="selectedService">
                          <v-col class="pa-0 ma-0">
                            <v-row>
                              <v-col>
                                <v-divider></v-divider>
                              </v-col>
                            </v-row>
                            <v-row align="center">
                              <v-col v-if="pawPortalAccount">
                                <b>Select dog(s)</b>
                              </v-col>
                            </v-row>
                            <v-row class="pt-0 mt-0" v-if="dogs.length == 0">
                              <v-col class="pt-0 mt-0">
                                <span class="description-small">
                                  You do not have any dogs yet, please head over
                                  to
                                  <router-link to="/account/pets"
                                    >Your Pets</router-link
                                  >
                                  to add a dog.
                                </span>
                              </v-col>
                            </v-row>
                            <v-row class="pt-0 mt-0">
                              <v-col class="pt-0 mt-0">
                                <v-list-item-group color="primary">
                                  <v-list-item
                                    v-for="item in dogs"
                                    :key="item.id"
                                    @click="
                                      selectedPrimaryServicePetsIds.push(
                                        item.id
                                      )
                                    "
                                    class="my-2"
                                    v-ripple="false"
                                  >
                                    <v-list-item-avatar>
                                      <v-img
                                        :src="
                                          item.image_url != null
                                            ? item.image_url
                                            : 'https://pawportal.blob.core.windows.net/images/PP_Black.png'
                                        "
                                        :alt="item.name"
                                        width="50"
                                        height="50"
                                        contain
                                      ></v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                      <v-list-item-title>{{
                                        item.name
                                      }}</v-list-item-title>
                                      <v-list-item-subtitle>
                                        {{ item.breed.breed }}
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <!-- Query Fields -->
                        <v-row
                          class="pa-0 ma-0"
                          v-if="
                            selectedService &&
                            selectedPrimaryServicePetsIds.length > 0 &&
                            selectedService.query_fields.length > 0
                          "
                        >
                          <v-col class="pa-0 ma-0">
                            <v-row>
                              <v-col>
                                <v-divider></v-divider>
                              </v-col>
                            </v-row>
                            <v-row align="center">
                              <v-col v-if="pawPortalAccount">
                                <b>Query Fields</b>
                              </v-col>
                            </v-row>
                            <!-- For each in selectedService.query_fields, show a question and an answer box and push the question and answer into an object -->
                            <v-row class="pt-0 mt-0">
                              <v-col class="pt-0 mt-0">
                                <v-text-field
                                  v-for="item in selectedService.query_fields"
                                  :key="item"
                                  :label="item"
                                  @change="
                                    updatePrimaryServiceQueryFieldResponses(
                                      item,
                                      $event
                                    )
                                  "
                                  v-ripple="false"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>

                        <!------ End of Primary Service Questions ------>
                        <!-- Select Additional Services -->
                        <v-row
                          class="pa-0 ma-0"
                          v-if="
                            selectedService &&
                            selectedService.additional_services.length > 0 &&
                            selectedPrimaryServicePetsIds.length > 0
                          "
                        >
                          <v-col class="pa-0 ma-0">
                            <v-row>
                              <v-col>
                                <v-divider></v-divider>
                              </v-col>
                            </v-row>
                            <v-row align="center">
                              <v-col v-if="pawPortalAccount">
                                <b>Select additional service(s)</b>
                              </v-col>
                            </v-row>
                            <v-row v-if="servicesLoading" justify="center">
                              <v-col align="center">
                                <v-progress-circular
                                  indeterminate
                                  size="64"
                                  color="green"
                                ></v-progress-circular>
                              </v-col>
                            </v-row>
                            <v-row class="pt-0 mt-0">
                              <v-col class="pt-0 mt-0">
                                <v-list-item-group
                                  color="primary"
                                  v-model="selectedAdditionalServiceIds"
                                  multiple
                                >
                                  <v-list-item
                                    v-for="item in selectedService.additional_services ||
                                    []"
                                    :key="item.id"
                                    @click="toggleSelection(item)"
                                    class="my-2"
                                    v-ripple="false"
                                    :class="{
                                      highlighted:
                                        selectedAdditionalServices.includes(
                                          item
                                        ),
                                    }"
                                  >
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        <b>{{ item.name }}</b>
                                        (+{{
                                          convertCurrencyUnitToSymbol(
                                            item.current_price.currency
                                          )
                                        }}{{
                                          formatPrice(
                                            item.current_price.amount_per_unit
                                          )
                                        }})
                                      </v-list-item-title>
                                      <v-list-item-subtitle>{{
                                        item.description
                                      }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>

                        <!-- For each additional service, show query_fields and select dog for each -->
                        <div
                          v-if="
                            selectedService &&
                            selectedService.additional_services.length > 0 &&
                            selectedPrimaryServicePetsIds.length > 0
                          "
                        >
                          <v-row
                            class="pa-0 ma-0"
                            v-for="item in selectedAdditionalServices"
                            :key="item.id"
                          >
                            <v-col class="pa-0 ma-0">
                              <v-row>
                                <v-col>
                                  <v-divider></v-divider>
                                </v-col>
                              </v-row>
                              <v-row align="center">
                                <v-col>
                                  <b>Additional Service: {{ item.name }}</b>
                                </v-col>
                              </v-row>
                              <!-- Select Dog(s) -->
                              <v-row class="pt-0 mt-0">
                                <v-col class="pt-0 mt-0">
                                  <p>
                                    Please select a dog for the additional
                                    service
                                  </p>
                                  <v-list-item-group color="primary">
                                    <v-list-item
                                      v-for="dog in dogs"
                                      :key="dog.id"
                                      :active-cass="
                                        item.pet_ids.includes(dog.id)
                                          ? 'active'
                                          : ''
                                      "
                                      @click="
                                        updateAdditionalServicePetIds(
                                          item,
                                          dog.id
                                        )
                                      "
                                      class="my-2"
                                      v-ripple="false"
                                    >
                                      <v-list-item-avatar>
                                        <v-img
                                          :src="dog.image_url"
                                          :alt="dog.name"
                                          width="50"
                                          height="50"
                                          contain
                                        ></v-img>
                                      </v-list-item-avatar>
                                      <v-list-item-content>
                                        <v-list-item-title>{{
                                          dog.name
                                        }}</v-list-item-title>
                                        <v-list-item-subtitle>
                                          {{ dog.breed.breed }}
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list-item-group>
                                </v-col>
                              </v-row>
                              <!-- Query Fields -->
                              <v-row class="pt-0 mt-0">
                                <v-col class="pt-0 mt-0">
                                  <p>Query Fields</p>
                                </v-col>
                              </v-row>
                              <!-- For each item.query_fields, show a question and an answer box and push the question and answer into an object -->
                              <v-row class="pt-0 mt-0">
                                <v-col class="pt-0 mt-0">
                                  <v-text-field
                                    v-for="query in item.query_fields"
                                    :key="query"
                                    :label="query"
                                    v-ripple="false"
                                    @change="
                                      updateAdditionalServiceQueryFieldResponses(
                                        item,
                                        query,
                                        $event
                                      )
                                    "
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </div>
                        <!-- Notes -->
                        <v-row
                          class="pa-0 ma-0"
                          v-if="
                            selectedService &&
                            selectedPrimaryServicePetsIds.length > 0
                          "
                        >
                          <v-col class="pa-0 ma-0">
                            <v-row>
                              <v-col>
                                <v-divider></v-divider>
                              </v-col>
                            </v-row>
                            <v-row align="center">
                              <v-col v-if="pawPortalAccount">
                                <b>Notes</b>
                              </v-col>
                            </v-row>
                            <v-row class="pt-0 mt-0">
                              <v-col class="pt-0 mt-0">
                                <v-textarea
                                  v-model="notes"
                                  label="Please enter any additional information here"
                                  auto-grow
                                  rows="3"
                                />
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>

                        <!-- <div v-if="selectedCompany && selectedService">
                          <v-row>
                            <v-col>
                              <v-dialog
                                ref="dialog"
                                v-model="dialog"
                                :return-value.sync="selectedDateTime"
                                width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="selectedDateTime"
                                    label="Start Date and Time"
                                    append-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  />
                                </template>
                                <v-date-picker v-model="selectedDate" scrollable>
                                  <v-time-picker
                                    v-model="selectedTime"
                                    full-width
                                  />
                                </v-date-picker>
                              </v-dialog>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col>
                              <v-select
                                v-model="selectedPet"
                                :items="dogs"
                                item-text="name"
                                item-value="id"
                                label="Select a pet"
                                required
                              />
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col>
                              <v-textarea
                                v-model="notes"
                                label="Notes"
                                auto-grow
                                rows="3"
                              />
                            </v-col>
                          </v-row>

                        </div> -->
                        <v-row>
                          <v-col>
                            <v-btn
                              type="submit"
                              color="primary"
                              @click="submitForm"
                              >Request</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="createComplete">
                <v-col>
                  <v-alert type="success" :value="createComplete">
                    Booking requested successfully
                  </v-alert>
                  <!-- Link to reset form -->
                  Click here to
                  <a href="#" @click="resetForm">create another booking</a>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-alert type="warning" :value="createError">
                    <li
                      v-bind:key="item.message"
                      v-for="item in createErrorItems"
                    >
                      {{ item.item }} -
                      {{ item.message }}
                    </li>
                  </v-alert>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-alert type="error" :value="dialog">
                    <li
                      v-bind:key="item.message"
                      v-for="item in createErrorItems"
                    >
                      {{ item.item }} -
                      {{ item.message }}
                    </li>
                  </v-alert>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength, minValue } from "vuelidate/lib/validators";
import moment from "moment";

export default {
  data() {
    return {
      selectedService: null,
      selectedServiceIndex: null,
      selectedPrimaryServicePetsIds: [],
      primaryServiceQueryFieldResponses: [],
      selectedAdditionalServiceIds: [],
      selectedDate: new Date().toISOString().substr(0, 10),
      selectedTime: null,
      dialog: false,
      notes: "",
      selectedCompany: null,
      serviceName: "",
      description: "",
      charge_amount: null,
      max_pets_per_booking: null,
      allDayEvent: false,
      duration: "",
      searchQuery: null,
      menu: false,
      modal2: false,
      selectedAdditionalServices: [],
      additionalServiceQueryFieldResponses: [],
    };
  },
  computed: {
    ...mapGetters({
      services: "services/services",
      dogs: "pets/dogs",
      companies: "company/companies",
      authenticated: "auth/isAuthenticated",
      pawPortalAccount: "account/accountDetails",
      createError: "bookings/createError",
      createErrorItems: "bookings/createErrorItems",
      createComplete: "bookings/createComplete",
      createLoading: "bookings/createLoading",
      servicesLoading: "services/servicesLoading",
      bookings: "bookings/bookings",
    }),
    filteredServices() {
      // Assuming 'services' is a computed property that gets its value from Vuex
      // Replace it with your actual services computed property if different
      if (!this.searchQuery) {
        return this.services; // Return all services if searchQuery is empty
      }
      // Return the filtered list of services
      return this.services.filter((service) =>
        service.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    selectedDateTime: {
      get() {
        return this.selectedDate + "T" + this.selectedTime + "Z";
      },
      set(val) {
        this.selectedDate = val.substr(0, 10);
        this.selectedTime = val.substr(11, 5);
      },
    },
  },
  mixins: [validationMixin],
  validations: {
    serviceName: {
      required,
      maxLength: maxLength(100),
    },
    description: {
      required,
      maxLength: maxLength(500),
    },
    charge_amount: {
      required,
      minValue: minValue(0),
    },
    max_pets_per_booking: {
      required,
      minValue: minValue(1),
    },
    duration: {
      validDuration(value) {
        if (this.allDayEvent && value === "") {
          return true;
        } else if (
          !this.allDayEvent &&
          moment(value, "HH:mm", true).isValid()
        ) {
          const [hours, minutes] = value.split(":");
          return hours <= 24 && minutes < 60;
        }
        return false;
      },
    },
  },
  methods: {
    ...mapActions({
      getServices: "services/getServices",
      getDogs: "pets/getDogs",
      createBooking: "bookings/createBooking",
      resetForm: "bookings/resetForm",
      getBookings: "bookings/getBookings",
    }),
    async submitForm() {
      const allDay = this.selectedService.duration_type === "ALL_DAY";
      const payload = {
        service_id: this.selectedService.id,
        pet_ids: this.selectedPrimaryServicePetsIds,
        all_day: allDay,
        // Create start in format: 2023-11-30T08:22:12.086Z using moment
        start:
          allDay != true
            ? moment(this.selectedDateTime).format()
            : moment(this.selectedDate).startOf("day").format(),
        // Create end in format: 2023-11-30T08:22:12.086Z using moment + this.selectedService.duration_in_mins
        end:
          allDay != true
            ? moment(this.selectedDateTime)
                .add(this.selectedService.duration_in_mins, "minutes")
                .format()
            : moment(this.selectedDate).endOf("day").format(),
        query_field_responses: this.primaryServiceQueryFieldResponses,
        additional_services: [], // Placeholder for a method to format additional services
        notes: this.notes,
        has_paid: false, // Assuming default as false, adjust as needed
      };
      // Add additional services to payload
      this.selectedAdditionalServices.forEach((service) => {
        payload.additional_services.push({
          additional_service_id: service.id,
          pet_ids: service.pet_ids,
          query_field_responses: service.query_field_responses,
        });
      });
      console.log("Payload: ", payload);

      // Add additional duration for each additional service
      this.selectedAdditionalServices.forEach((service) => {
        payload.end = moment(payload.end)
          .add(service.additional_duration_in_mins, "minutes")
          .format();
      });

      // Call your API method to submit the payload
      // For example, using an action from Vuex store
      await this.createBooking(payload);

      // if (this.$v.$invalid) {
      //   console.log("Form is invalid");
      //   this.$v.$touch();
      // } else {
      //   const durationInMinutes = moment
      //     .duration(this.duration, "HH:mm")
      //     .asMinutes();
      //   const payload = {
      //     service_id: this.selectedService,
      //     /*   "pet_ids": [
      //           "3fa85f64-5717-4562-b3fc-2c963f66afa6"
      //       ], */
      //     notes: this.notes,
      //   };

      //   this.createService(payload)
      //     .then(() => {
      //       // this.getServices(this.selectedCompany);
      //     })
      //     .catch((error) => {
      //       console.log(`Error creating service: ${error}`);
      //     });
      // }
    },
    toggleSelection(item) {
      const index = this.selectedAdditionalServices.findIndex(
        (service) => service.id === item.id
      );
      if (index !== -1) {
        // Item is already selected, remove it
        this.selectedAdditionalServices.splice(index, 1);
      } else {
        // Item is not selected, add it
        item.pet_ids = [];
        this.selectedAdditionalServices.push(item);
      }
    },
    onServiceChange() {
      // Add any code to execute when the service selection changes
      console.log("Selected company: " + this.selectedCompany);
      this.getServices(this.selectedCompany);
    },
    updatePrimaryServiceQueryFieldResponses(query, response) {
      // Check if already exists, if so update, otherwise add
      console.log("Updating question:", query, "with answer:", response);
      const index = this.primaryServiceQueryFieldResponses.findIndex(
        (item) => item.query === query
      );
      if (index === -1) {
        console.log("Adding question:", query, "with answer:", response);
        this.primaryServiceQueryFieldResponses.push({
          query: query,
          response: response,
        });
      } else {
        console.log("Updating question:", query, "with answer:", response);
        this.primaryServiceQueryFieldResponses.splice(index, 1, {
          query: query,
          response: response,
        });
      }
    },
    updateAdditionalServiceQueryFieldResponses(service, queryField, response) {
      console.log("Updating question:", queryField, "with answer:", response);
      let locatedService = this.selectedAdditionalServices.find(
        (item) => item.id === service.id
      );
      if (locatedService.query_field_responses) {
        const index = locatedService.query_field_responses.findIndex(
          (item) => item.query.id === queryField.id
        );
        if (index === -1) {
          console.log("Adding question:", queryField, "with answer:", response);
          locatedService.query_field_responses.push({
            query: queryField,
            response: response,
          });
        } else {
          console.log(
            "Updating question:",
            queryField,
            "with answer:",
            response
          );
          locatedService.query_field_responses.splice(index, 1, {
            query: queryField,
            response: response,
          });
        }
      } else {
        console.log("Adding question:", queryField, "with answer:", response);
        locatedService.query_field_responses = [
          {
            query: queryField,
            response: response,
          },
        ];
      }
      // Update selectedAdditionalServices
      const index = this.selectedAdditionalServices.findIndex(
        (item) => item.id === locatedService.id
      );
      this.selectedAdditionalServices.splice(index, 1, locatedService);
    },
    updateAdditionalServicePetIds(service, petId) {
      console.log("Service:", service);
      console.log("Updating pet id:", petId);
      let locatedService = this.selectedAdditionalServices.find(
        (item) => item.id === service.id
      );
      const index = locatedService.pet_ids.findIndex((item) => item === petId);
      if (service.pet_ids.includes(petId)) {
        // If dog is selected
        if (index === -1) {
          // Add petId only if it doesn't exist
          console.log("Adding pet id:", petId);
          locatedService.pet_ids.push(petId);
        }
        const dogIndex = service.pet_ids.indexOf(petId);
        service.pet_ids.splice(dogIndex, 1); // Remove petId from selectedDogs
      } else {
        // If dog is not selected
        if (index !== -1) {
          // Remove petId only if it exists
          console.log("Removing pet id:", petId);
          locatedService.pet_ids.splice(index, 1);
        }
        service.pet_ids.push(petId); // Add petId to selectedDogs
      }
      // Update selectedAdditionalServices
      const serviceIndex = this.selectedAdditionalServices.findIndex(
        (item) => item.id === locatedService.id
      );
      this.selectedAdditionalServices.splice(serviceIndex, 1, locatedService);
    },
    convertCurrencyUnitToSymbol(currencyUnit) {
      switch (currencyUnit) {
        case "GBP":
          return "£";
        case "USD":
          return "$";
        case "EUR":
          return "€";
        default:
          return "";
      }
    },
    formatPrice(price) {
      // Format int as 00.00
      return price.toFixed(2);
    },
  },
  async mounted() {
    await this.getDogs();
    this.resetForm();

    // Check if we're editing a service
    if (this.$route.query.edit) {
      // Check if we have Services
      if (this.services.length == 0) {
        // Get the booking
        const booking = this.bookings.find(
          (booking) => booking.id === this.$route.query.edit
        );
        // Now we have the booking, we can get services for that company_id
        await this.getServices(booking.service_booking.company_id);
      }
    }
  },
  watch: {
    selectedCompany: function (val) {
      this.selectedService = null;
      this.selectedServiceIndex = null;
      this.getServices(val);
      this.selectedPrimaryServicePetsIds = [];
      this.primaryServiceQueryFieldResponses = [];
      this.selectedAdditionalServiceIds = [];
    },
    searchQuery(val) {
      this.selectedService = null;
      this.selectedServiceIndex = null;
    },
    primaryServiceQueryFieldResponses(val) {
      console.log("Primary Service Query Field Responses: ", val);
    },
    selectedAdditionalServices(val) {
      console.log("Selected Additional Services: ", val);
    },
  },
};
</script>

<style scoped>
/* Override default Vuetify active class */
.v-list-item {
  border: 1px solid rgba(154, 165, 182, 0.4);
  background: white;
}

/* When the item is active, add the custom border style */
.v-list-item--active {
  border: 1px solid #ef851c;
  background: #fce8d9 !important; /* Override any background color */
  color: black !important; /* Override any text color */
}
.v-list-item--link:before {
  background: #fce8d9 !important; /* Override any background color */
}

/* The rest of your list styling */
</style>
