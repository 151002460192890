<template>
  <v-container fluid class="pa-0 ma-0" fill-height style="height: 85vh">
    <v-row class="fill-height pa-0 ma-0" no-gutters justify="center">
      <v-col class="ma-0 pa-0" align="center">
        <v-row
          justify="center"
          class="ma-0 pa-0 fill-height"
          style="height: 100%"
        >
          <!-- <v-overlay color="FFFFFF">
        <v-row class="ma-n16">
          <img src="../../../assets/dog_cut_out.png" width="25%" />
        </v-row>
      </v-overlay> -->
          <v-col
            lg="8"
            md="10"
            sm="10"
            xs="12"
            class="ma-0 pa-0"
            align="center"
          >
            <!-- <v-row class="pa-2" justify="center" align="center"> -->
            <v-sheet color="white" rounded="xl" class="pa-6">
              <v-row>
                <v-col align="center">
                  <v-row style="height: 15vh">
                    <v-col>
                      <p>
                        <span v-if="loading" class="b2"
                          >VERIFYING YOUR MOBILE NUMBER</span
                        >
                      </p>
                      <p>
                        <span v-if="loading" class="b2">PLEASE WAIT...</span>
                      </p>
                      <p>
                        <span v-if="success" class="b2">SUCCESS</span>
                      </p>
                      <p>
                        <span v-if="success" class="b2"
                          >YOUR MOBILE NUMBER HAS BEEN VERIFIED</span
                        >
                      </p>
                      <p>
                        <span v-if="error" class="b2"
                          >UNABLE TO VERIFY MOBILE NUMBER</span
                        >
                      </p>
                      <p>
                        <span v-if="error" class="b2">{{ errorMessage }}</span>
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-sheet>
            <v-sheet color="white" class="mt-n16">
              <v-row
                justify="center"
                class="pt-10"
                :style="{ height: vh + 'vh' }"
              >
                <v-col lg="6" md="8" sm="12" xs="12" align="center">
                  <v-form>
                    <v-row justify="center">
                      <v-col lg="12" md="12" sm="12" xs="12" cols="12">
                        <v-progress-circular
                          v-if="loading"
                          :width="4"
                          :size="40"
                          color="#7db52f"
                          indeterminate
                        ></v-progress-circular>
                        <v-icon :size="40" color="#7db52f" v-if="success"
                          >mdi-check</v-icon
                        >
                        <v-icon
                          :size="40"
                          color="red"
                          v-if="loading == false && error == true"
                          >mdi-close</v-icon
                        >
                        <!-- v-img for Not_Verified.png -->
                        <v-img
                          v-if="loading == false && error == true"
                          :src="
                            require('../../assets/verify_mobile/Not_Verified.png')
                          "
                          width="200"
                          contain
                        ></v-img>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
              <v-row justify="center" class="pa-0 ma-0" style="height: 23vh">
                <v-col
                  lg="4"
                  md="6"
                  sm="6"
                  xs="6"
                  align="center"
                  class="align-self-end"
                >
                  <p>
                    <span>
                      This site is protected by reCAPTCHA and the Google
                      <a
                        href="https://policies.google.com/privacy"
                        target="_blank"
                        >Privacy Policy</a
                      >
                      and
                      <a
                        href="https://policies.google.com/terms"
                        target="_blank"
                        >Terms of Service</a
                      >
                      apply.
                    </span>
                  </p>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "VerifyMobile",
  components: {},
  computed: {
    ...mapGetters({
      pawPortalAccount: "account/accountDetails",
    }),
    // Computed value for this.$route.query.t
    verification_code() {
      return this.$route.query.c;
    },
    vh() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return 53.5;
        //   } else if (this.$vuetify.breakpoint.smOnly) {
        //     return 34.1;
        //   } else if (this.$vuetify.breakpoint.mdOnly) {
        //     return 34.1;
        //   } else if (this.$vuetify.breakpoint.lgOnly) {
        //     return 34.1;
        //   } else if (this.$vuetify.breakpoint.xlOnly) {
        //     return 34.1;
      } else {
        return 49.1;
      }
    },
  },
  data() {
    return {
      verification_response: null,
      error: false,
      errorMessage: "",
      errorType: "Warning",
      success: false,
      successMessage: "",
      loading: true,
    };
  },
  async mounted() {
    console.log("verification_code", this.verification_code);
    await this.$recaptchaLoaded();

    // Execute reCAPTCHA with action "login".
    const token = await this.$recaptcha("login");

    if (this.verification_code && this.verification_code != undefined) {
      // API call to verify mobile number
      axios
        .post("/verifymobile?c=" + this.verification_code, null, {
          headers: {
            recaptcha: token,
          },
        })
        .then((response) => {
          console.log("response", response);
          this.verification_response = response.data;
          console.log("verification_response", this.verification_response);
          if (response.status == 200) {
            this.loading = false;
            this.success = true;
            this.successMessage = this.verification_response.message;
          } else if (response.status == 208) {
            this.loading = false;
            this.success = true;
            this.successMessage =
              "This mobile number has already been verified.";
          } else {
            this.loading = false;
            this.error = true;
            this.errorMessage = this.verification_response.message;
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.loading = false;
          this.error = true;
          this.errorMessage = "The code provided is invalid.";
        });
    } else {
      this.loading = false;
      this.error = true;
      this.errorMessage = "No verification code has been provided.";
    }
  },
  methods: {},
  watch: {},
};
</script>

<style></style>
