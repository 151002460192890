<template>
  <v-container class="pa-8">
    <v-row justify="center">
      <v-col lg="12" md="12" sm="12" xs="12">
        <v-sheet color="white" rounded="xl" class="pa-1 ma-2">
          <v-row justify="start" class="pt-10">
            <v-col lg="6" md="8" sm="10" xs="12">
              <v-row align="center" class="pa-2">
                <v-col v-if="pawPortalAccount">
                  <h2>Update Company Info</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row class="pt-0 mt-4">
                <v-col class="pt-0 mt-0">
                  <v-text-field
                    v-model="companyName"
                    label="Company Name"
                    v-ripple="false"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CustomerOverview",
  data() {
    return {
      companyName: "",
    };
  },
  computed: {
    ...mapGetters({
      selectedCompany: "company/selectedCompany",
      customers: "company/customers",
      customersLoading: "company/customersLoading",
      companies: "company/companies",
      pawPortalAccount: "account/accountDetails",
      selectedCompany: "company/selectedCompany",
    }),
  },
  methods: {
    ...mapActions({}),
    formatAddress(customer) {
      let addressFields = [
        "address_line_1",
        "address_line_2",
        "address_line_3",
        "address_line_4",
        "address_postcode",
      ];
      return addressFields
        .map((field) => customer[field])
        .filter(Boolean)
        .join(", ");
    },
  },
  async mounted() {
    console.log("Selected company", this.selectedCompany);
    this.companyName = this.selectedCompany.name;
  },
  watch: {},
};
</script>

<style></style>
