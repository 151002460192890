<template>
  <v-card>
    <v-card-title>Upload New Profile Picture</v-card-title>
    <v-card-text>
      <v-form @submit.prevent="submitForm">
        <v-row v-if="!uploadProfilePictureComplete">
          <v-col cols="12">
            <!-- Overlay for loading -->
            <v-overlay
              :absolute="true"
              :opacity="0.2"
              :value="uploadProfilePictureLoading"
            >
              <v-progress-circular
                :width="4"
                :size="70"
                color="#7db52f"
                indeterminate
              ></v-progress-circular>
            </v-overlay>
            <v-file-input
              v-model="newProfilePicture"
              label="Select a file"
              accept="image/*"
              @change="previewImage"
              ref="fileInput"
            ></v-file-input>
          </v-col>
          <v-col cols="12" align="center">
            <!-- <v-avatar size="150">
              <img :src="croppedPreview" />
            </v-avatar> -->
            <v-row>
              <v-col>
                <img
                  width="150"
                  height="150"
                  :src="previewSrc"
                  ref="image"
                  v-if="previewSrc"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-avatar size="150" v-if="previewSrc">
                  <img :src="croppedPreview ? croppedPreview : previewSrc" />
                </v-avatar>
                <v-avatar size="150" v-else>
                  <img
                    v-if="
                      pawPortalAccount != null &&
                      pawPortalAccount.profile_picture != null &&
                      pawPortalAccount.first_names != null
                    "
                    :src="pawPortalAccount.profile_picture"
                    :alt="pawPortalAccount.first_names"
                  />
                  <img
                    v-else
                    src="https://thumbs.dreamstime.com/b/vector-human-head-illustration-isolated-white-shoulders-simple-57912381.jpg"
                    alt="Default"
                  />
                </v-avatar>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-btn
              type="submit"
              color="#7db52f"
              :disabled="
                uploadProfilePictureLoading || newProfilePicture == null
              "
              >Update</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-alert type="warning" :value="uploadProfilePictureError">
              <li
                v-bind:key="item.message"
                v-for="item in uploadProfilePictureErrorItems"
              >
                {{ item.item }} -
                {{ item.message }}
              </li>
            </v-alert>
            <v-alert type="success" :value="uploadProfilePictureComplete">
              Profile picture updated successfully!
            </v-alert>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  data() {
    return {
      dialogVisible: true,
      newProfilePicture: null,
      altText: "",
      previewSrc: null,
      file: null,
      cropper: null,
      croppedPreview: null,
    };
  },
  computed: {
    ...mapGetters({
      pawPortalAccount: "account/accountDetails",
      uploadProfilePictureComplete: "account/uploadProfilePictureComplete",
      uploadProfilePictureInProgress: "account/uploadProfilePictureInProgress",
      uploadProfilePictureError: "account/uploadProfilePictureError",
      uploadProfilePictureErrorItems: "account/uploadProfilePictureErrorItems",
      uploadProfilePictureLoading: "account/uploadProfilePictureLoading",
    }),
  },
  mounted() {
    console.log(
      `uploadProfilePictureLoading: ${this.uploadProfilePictureLoading}`
    );
    console.log(`newProfilePicture: ${this.newProfilePicture}`);
  },
  methods: {
    ...mapActions({
      uploadProfilePicture: "account/uploadProfilePicture",
    }),
    async submitForm() {
      if (this.newProfilePicture) {
        const croppedImage = await this.getCroppedImage();
        this.uploadProfilePicture(croppedImage)
          .then(() => {
            this.$refs.fileInput.reset(); // Reset the file input after a successful upload
            this.previewSrc = null; // Clear the preview
          })
          .catch((error) => {
            console.error("Error uploading profile picture:", error);
          })
          .finally(() => {
            this.cropper.destroy();
            this.cropper = null;
            this.previewSrc = null;
          });
      } else {
        console.warn("No file selected");
      }
    },
    previewImage() {
      if (this.newProfilePicture) {
        this.previewSrc = URL.createObjectURL(this.newProfilePicture);
        this.$nextTick(() => {
          if (this.$refs.image) {
            this.cropper = new Cropper(this.$refs.image, {
              aspectRatio: 1, // Set the aspect ratio as needed
              // additional options...
            });
          }
        });
      } else {
        this.previewSrc = null;
      }
    },
    getCroppedImage() {
      return new Promise((resolve, reject) => {
        if (!this.cropper) {
          reject(new Error("Cropper is not initialized"));
          return;
        }
        this.cropper.getCroppedCanvas().toBlob((blob) => {
          resolve(blob);
        }, "image/jpeg");
      });
    },
    previewImage() {
      if (this.newProfilePicture) {
        this.previewSrc = URL.createObjectURL(this.newProfilePicture);
        this.$nextTick(() => {
          if (this.$refs.image) {
            this.cropper = new Cropper(this.$refs.image, {
              aspectRatio: 1,
              viewMode: 1,
              crop: () => {
                const canvas = this.cropper.getCroppedCanvas({
                  width: 300,
                  height: 300,
                });
                if (canvas) {
                  this.croppedPreview = canvas.toDataURL("image/jpeg");
                }
              },
              // additional options...
            });
          }
        });
      } else {
        this.previewSrc = null;
        this.croppedPreview = null;
      }
    },
  },
};
</script>
