<template>
  <v-container>
    <v-row justify="center">
      <v-col align="center" cols="6">
        {{ loadingText }}
      </v-col>
    </v-row>
    <v-row justify="center" v-if="isDevelopment == true">
      <v-col align="center" cols="6">
        <v-progress-linear indeterminate class="mb-0"></v-progress-linear>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
// import { PublicClientApplication } from "@azure/msal-browser";
//import Calendar from "../components/Calendar";

export default {
  name: "Login",
  components: {
    //Calendar
  },
  data() {
    return {
      loadingText: "Please wait while we log you in...",
    };
  },
  computed: {
    ...mapGetters(["msalConfig"]),
    ...mapGetters({
      loginFailed: "auth/loginFailed",
      authenticated: "auth/isAuthenticated",
      user: "auth/user",
      pawPortalAccount: "account/accountDetails",
    }),
    isDevelopment() {
      return process.env.VUE_APP_IS_DEVELOPMENT === "true";
    },
  },
  created() {
    // this.$store.state.msalInstance = new PublicClientApplication(
    //   this.$store.state.msalConfig
    // );
    // this.$emit("login", (account) => {
    //   this.account = account;
    // }),
    //   this.$emit("logout", () => {
    //     console.log("logging out");
    //     this.account = undefined;
    //   });
  },
  async mounted() {
    console.log("Login mounted");
    console.log("DEV:", this.isDevelopment);

    if (this.isDevelopment == false) {
      console.log("Login not allowed at the moment");
      this.loadingText =
        "Login not allowed at the moment, please request early access.";
      return;
    }

    // Handle redirected requests from MSAL
    await this.$store.state.msalInstance
      .handleRedirectPromise()
      .then((authResult) => {
        // Check if user signed in
        console.log("Login redirect auth response", authResult);
        // const account = this.$store.state.msalInstance.getActiveAccount();
        if (!authResult) {
          console.log(
            "No login being processed, redirecting to B2C login page"
          );
          console.log(
            "Query:",
            this.$route.query.from,
            "with params:",
            this.$route.query
          );
          this.loadingText = "Redirecting to Azure B2C login page...";
          this.SignIn(this.$route.query.from);
        } else {
          this.loadingText = "Thank you, please wait...";
          console.log("Account found, setting");
          console.log("auth result: ", authResult);
          this.$store.commit("auth/SET_ACCESS_TOKEN", authResult);
          this.$store.dispatch("account/getAccount").then(() => {
            console.log("pawportalAccount: ", this.pawPortalAccount);
            if (this.pawPortalAccount == null) {
              if (this.$route.query.from === undefined) {
                this.loadingText =
                  "Thanks for creating your account, let's finish setting up your profile.";
                this.$router.push("/register");
              } else {
                this.loadingText =
                  "Thanks for creating your account, let's finish setting up your profile.";
                console.log(
                  "Pushing to register with params:",
                  this.$route.query
                );
                // Use the full query object to maintain all query parameters
                this.$router.push({
                  path: this.$route.query.from,
                  query: this.$route.query,
                });
              }
            } else {
              const fromPath = this.$route.query.from;
              const queryParams = { ...this.$route.query };
              delete queryParams.from; // Remove the 'from' parameter since it's used as the path
              if (fromPath != "/" && fromPath != undefined) {
                console.log(
                  "pawportalAccount found, redirecting back to " + fromPath
                );
                this.$router.push({ path: fromPath, query: queryParams });
              } else {
                console.log(
                  "pawportalAccount found, redirecting to dashboard."
                );
                this.$router.push("/dashboard");
              }
            }
          });
        }
      })
      .catch((err) => {
        // TODO: Handle errors
        console.log(err);
        this.loadingText =
          "Login failed or cancelled, please refresh the page to try again.";
      });

    // }

    // // this.$emit("login", this.account);
    // console.log(this.user);
    // console.log("Authenticated: ", this.isAuthenticated);
  },
  methods: {
    // ...mapMutations(["setAccessToken"]),
    // ...mapActions({
    //   signIn: "auth/signIn",
    // }),
    async SignIn(query) {
      this.$store.dispatch("auth/signIn", query);
    },
    async refreshToken() {
      this.$store.dispatch("auth/refreshToken");
    },
  },
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
