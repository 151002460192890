<template>
  <v-container class="pa-8">
    <v-overlay :absolute="true" :opacity="0.2" :value="customersLoading">
      <v-progress-circular
        :width="4"
        :size="70"
        color="#7db52f"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-row class="pa-2" justify="center">
      <v-col align="center">
        <v-row justify="end">
          <v-col md="4" offset-md="4">Customers</v-col>
          <v-col md="4" align="end">
            <!-- Here, you could provide some action, like inviting a new customer -->
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card
              class="mb-2"
              v-for="customer in customers"
              :key="customer.id"
              elevation="3"
            >
              <v-card-text>
                <!-- {{ customer }} -->
                <v-row justify="center" align="center">
                  <v-col cols="2">
                    <v-avatar size="100">
                      <img
                        v-if="customer.profile_picture != null"
                        :src="customer.profile_picture"
                        :alt="customer.first_names + ' ' + customer.surname"
                      />
                      <img
                        v-else
                        src="https://pawportal.blob.core.windows.net/images/PP_Black.png"
                        alt="Default"
                      />
                    </v-avatar>
                  </v-col>
                  <v-col align="start" cols="10">
                    <v-row class="pa-0 ma-0">
                      <v-col class="pa-1 ma-0">
                        <p
                          style="font-size: 1.5rem"
                          class="font-weight-medium pb-0 mb-0"
                        >
                          {{ customer.first_names }} {{ customer.surname }}
                        </p>
                      </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col class="pa-1 ma-0">
                        <p class="pb-0 mb-0">
                          <b>Email: </b>
                        </p>
                        <p class="pt-0 mt-0 mb-1">
                          {{ customer.email }}
                        </p>
                      </v-col>
                    </v-row>
                    <v-row
                      class="pa-0 ma-0"
                      v-for="number in customer.telephone_numbers"
                      :key="number.id"
                    >
                      <v-col class="pa-1 ma-0">
                        <p class="pb-0 mb-0">
                          <b>Phone: ({{ formatNumberType(number) }})</b>
                        </p>
                        <p class="pt-0 mt-0 mb-1">
                          {{ number.number }}
                          <span v-if="number.is_mobile">
                            <v-chip
                              v-if="number.verified"
                              color="green"
                              small
                              outlined
                              >Verified</v-chip
                            >
                            <v-chip v-else color="red" small outlined
                              >Unverified</v-chip
                            >
                          </span>
                        </p>
                      </v-col>
                    </v-row>
                    <v-row class="pa-0 ma-0">
                      <v-col class="pa-1 ma-0">
                        <p class="pb-0 mb-0">
                          <b>Address:</b>
                        </p>
                        <p class="pt-0 mt-0 mb-1">
                          {{ formatAddress(customer) }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CustomerOverview",
  computed: {
    ...mapGetters({
      selectedCompany: "company/selectedCompany",
      customers: "company/customers",
      customersLoading: "company/customersLoading",
      companies: "company/companies",
    }),
  },
  methods: {
    ...mapActions({
      getCustomers: "company/getCustomers",
    }),
    formatAddress(customer) {
      let addressFields = [
        "address_line_1",
        "address_line_2",
        "address_line_3",
        "address_line_4",
        "address_postcode",
      ];
      return addressFields
        .map((field) => customer[field])
        .filter(Boolean)
        .join(", ");
    },
    formatNumberType(numberType) {
      if (numberType.is_mobile) {
        return "Mobile";
      }
      if (numberType.number_type == null) {
        return "Other";
      }
      return numberType.number_type;
    },
  },
  async mounted() {
    if (this.selectedCompany) {
      this.getCustomers(this.selectedCompany.id);
    }
  },
  watch: {
    selectedCompany: function () {
      this.getCustomers(this.selectedCompany.id);
    },
  },
};
</script>

<style></style>
