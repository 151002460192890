<template v-if="authenticated">
  <v-container class="pa-0 ma-0">
    <v-row justify="center">
      <v-col lg="12" md="12" sm="12" xs="12">
        <v-sheet color="white" rounded="xl" class="pa-1 ma-2">
          <v-row justify="center" class="pt-10">
            <v-col lg="6" md="8" sm="10" xs="12">
              <v-row align="center" class="pa-2">
                <v-col v-if="pawPortalAccount">
                  <h2>Services - {{ editingService ? "Edit" : "Create" }}</h2>
                  <h3>{{ this.selectedCompany.name }}</h3>
                </v-col>
              </v-row>
              <v-row v-if="selectedCompany" class="ma-0 pa-0">
                <v-col class="ma-0 pa-0">
                  <v-row align="center" class="pl-2">
                    <v-col align="start">
                      <b>{{ editingService ? "Edit" : "Create" }} a Service</b>
                      <span class="float-right">
                        <!-- <v-icon>mdi-pencil</v-icon> -->
                      </span>
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="!createComplete && !updateComplete">
                <!-- Overlay for loading -->
                <v-overlay
                  :value="createLoading || loading || updateLoading"
                  absolute
                >
                  <v-progress-circular
                    indeterminate
                    size="64"
                    color="primary"
                  ></v-progress-circular>
                </v-overlay>
                <v-col>
                  <form @submit.prevent="submit">
                    <v-text-field
                      v-model="serviceName"
                      :error-messages="nameErrors"
                      :counter="100"
                      label="Name*"
                      required
                      @input="$v.serviceName.$touch()"
                      @blur="
                        $v.serviceName.$touch();
                        isNameTaken = !isNameUnique(serviceName);
                      "
                    ></v-text-field>
                    <v-textarea
                      v-model="description"
                      :error-messages="descriptionErrors"
                      :counter="500"
                      label="Description*"
                      required
                      @input="$v.description.$touch()"
                      @blur="$v.description.$touch()"
                    ></v-textarea>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-switch
                          v-model="allDayEvent"
                          :error-messages="durationErrors"
                          label="All day event"
                          v-bind="attrs"
                          v-on="on"
                        ></v-switch>
                      </template>
                      <span>Enable if this is an all day event</span>
                    </v-tooltip>

                    <v-text-field
                      v-if="!allDayEvent"
                      v-model="duration"
                      :error-messages="durationErrors"
                      label="Duration Per Pet (HH:mm)*"
                      type="time"
                      required
                      @input="$v.duration.$touch()"
                      @blur="$v.duration.$touch()"
                    ></v-text-field>

                    <v-text-field
                      v-model="charge_amount"
                      :error-messages="chargeAmountErrors"
                      :counter="10"
                      label="Charge Amount Per Pet (GBP)*"
                      required
                      type="number"
                      step="0.01"
                      @input="$v.charge_amount.$touch()"
                      @blur="$v.charge_amount.$touch()"
                    ></v-text-field>
                    <v-text-field
                      v-model="max_pets_per_booking"
                      :error-messages="maxPetsPerBookingErrors"
                      :counter="9"
                      label="Maximum Pets per Booking*"
                      required
                      type="number"
                      step="1"
                      @input="$v.max_pets_per_booking.$touch()"
                      @blur="$v.max_pets_per_booking.$touch()"
                    ></v-text-field>
                    <v-textarea
                      v-model="terms_and_conditions"
                      label="Terms and Conditions (optional)"
                    ></v-textarea>

                    <!-- Query Fields -->
                    <h3>Query Fields</h3>
                    <p>
                      Query fields are for additional free-text questions that
                      you need answers to as part of the service.
                    </p>
                    <div
                      v-for="(field, index) in query_fields"
                      :key="'query_field-' + index"
                    >
                      <v-row align="center">
                        <v-col cols="11" class="pr-0 mr-0">
                          <v-text-field
                            v-model="field.question"
                            label="Question*"
                            :error-messages="
                              additionalQueryQuestionErrors[index]
                            "
                            required
                            @input="
                              $v.query_fields.$each[index].question.$touch()
                            "
                            @blur="
                              () => {
                                $v.query_fields.$each[index].question.$touch();
                                formatQueryFields();
                              }
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col cols="1" class="pl-0 ml-0">
                          <v-btn
                            icon
                            color="red"
                            @click="removeQueryField(index)"
                          >
                            <v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                    <v-btn @click="addQueryField" small color="primary">
                      Add Question
                    </v-btn>

                    <!-- Additional Services -->
                    <h3>Additional Services</h3>
                    <p>
                      Additional services are optional extras for your service.
                      For example, you may want the option to have an additional
                      dog at a reduced rate.
                    </p>
                    <div
                      v-for="(service, index) in additional_services"
                      :key="'additional_service-' + index"
                    >
                      <v-text-field
                        v-model="service.name"
                        label="Additional Service Name*"
                        required
                        :error-messages="additionalServiceNameErrors[index]"
                        @input="
                          $v.additional_services.$each[index].name.$touch()
                        "
                        @blur="
                          $v.additional_services.$each[index].name.$touch()
                        "
                      ></v-text-field>
                      <v-textarea
                        v-model="service.description"
                        label="Description*"
                        :counter="500"
                        required
                        :error-messages="
                          additionalServiceDescriptionErrors[index]
                        "
                        @input="
                          $v.additional_services.$each[
                            index
                          ].description.$touch()
                        "
                        @blur="
                          $v.additional_services.$each[
                            index
                          ].description.$touch()
                        "
                      ></v-textarea>
                      <v-text-field
                        v-model="service.current_price.amount_per_unit"
                        label="Charge Amount (GBP)*"
                        required
                        type="number"
                        step="0.01"
                        :error-messages="
                          additionalServiceChargeAmountErrors[index]
                        "
                        @input="
                          $v.additional_services.$each[
                            index
                          ].current_price.amount_per_unit.$touch()
                        "
                        @blur="
                          $v.additional_services.$each[
                            index
                          ].current_price.amount_per_unit.$touch()
                        "
                      ></v-text-field>
                      <v-text-field
                        v-if="!allDayEvent"
                        v-model="service.additional_duration_in_mins"
                        label="Additional Duration (HH:mm)*"
                        type="time"
                        required
                        :error-messages="additionalServiceDurationErrors[index]"
                        @input="
                          $v.additional_services.$each[
                            index
                          ].additional_duration_in_mins.$touch()
                        "
                        @blur="
                          $v.additional_services.$each[
                            index
                          ].additional_duration_in_mins.$touch()
                        "
                      ></v-text-field>
                      <v-text-field
                        v-model="service.max_pets_per_booking"
                        required
                        label="Max Pets per Booking*"
                        type="number"
                        :error-messages="
                          additionalServiceMaxPetsPerBookingErrors[index]
                        "
                        @input="
                          $v.additional_services.$each[
                            index
                          ].max_pets_per_booking.$touch()
                        "
                        @blur="
                          $v.additional_services.$each[
                            index
                          ].max_pets_per_booking.$touch()
                        "
                      ></v-text-field>
                      <v-text-field
                        v-model="service.max_per_booking"
                        required
                        label="Max per Booking*"
                        type="number"
                        :error-messages="
                          additionalServiceMaxPerBookingErrors[index]
                        "
                        @input="
                          $v.additional_services.$each[
                            index
                          ].max_per_booking.$touch()
                        "
                        @blur="
                          $v.additional_services.$each[
                            index
                          ].max_per_booking.$touch()
                        "
                      ></v-text-field>

                      <v-switch
                        v-model="service.same_pets_only"
                        label="Is the customer allowed to select the same pet for this service?"
                      ></v-switch>

                      <v-btn
                        @click="removeAdditionalService(index)"
                        color="error"
                      >
                        Remove
                      </v-btn>
                    </div>

                    <v-btn @click="addAdditionalService" small color="primary">
                      Add Service
                    </v-btn>
                    <!-- Submit -->
                    <p></p>
                    <div>
                      <v-btn type="submit" color="primary">{{
                        editingService ? "Update" : "Submit"
                      }}</v-btn>
                    </div>
                  </form>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-alert type="warning" :value="createError">
                    <li
                      v-bind:key="item.message"
                      v-for="item in createErrorItems"
                    >
                      {{ item.item }} -
                      {{ item.message }}
                    </li>
                  </v-alert>
                  <v-alert type="warning" :value="updateError">
                    <li
                      v-bind:key="item.message"
                      v-for="item in updateErrorItems"
                    >
                      {{ item.item }} -
                      {{ item.message }}
                    </li>
                  </v-alert>
                  <v-alert
                    type="success"
                    :value="createComplete || updateComplete"
                  >
                    Service
                    {{ editingService ? "edited" : "created" }} successfully!
                  </v-alert>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import moment from "moment";
import {
  required,
  maxLength,
  minValue,
  between,
  helpers,
  integer,
} from "vuelidate/lib/validators";

const isDuration = helpers.regex("isDuration", /^(\d{2}):(\d{2})$/);

export default {
  name: "Account",
  components: {},
  data() {
    return {
      serviceId: null,
      serviceName: "",
      isNameTaken: false,
      description: "",
      charge_amount: null,
      max_pets_per_booking: null,
      allDayEvent: false,
      duration: "",
      duration_in_mins: null,
      min_duration_in_mins: null,
      max_duration_in_mins: null,
      terms_and_conditions: "",
      is_active: true,
      loading: false,
      editingService: false,
      editingServiceOriginal: null,
      query_fields: [],
      additional_services: [],
    };
  },
  mixins: [validationMixin],
  validations: {
    serviceName: {
      required,
      maxLength: maxLength(100),
      unique: {
        validator: function (value) {
          return !this.isNameTaken;
        },
      },
    },
    description: { required, maxLength: maxLength(500) },
    charge_amount: { required },
    max_pets_per_booking: {
      required,
      integer,
      minValue: minValue(1),
      between: between(1, 999999999),
    },
    duration_in_mins: {},
    min_duration_in_mins: {},
    max_duration_in_mins: {},
    terms_and_conditions: { required },
    is_active: {},
    terms_and_conditions: "",
    query_fields: {
      $each: {
        question: { required },
      },
    },
    additional_services: {
      $each: {
        name: { required, maxLength: maxLength(100) },
        description: { required, maxLength: maxLength(500) },
        max_per_booking: { required },
        same_pets_only: {},
        max_pets_per_booking: { required },
        additional_duration_in_mins: {
          validDuration(value) {
            console.log("Validating duration", value);
            if (this.allDayEvent) {
              console.log("Returning true");
              return true;
            } else if (moment(value, "HH:mm", true).isValid()) {
              const [hours, minutes] = value.split(":");
              console.log("hours and minutes", hours, minutes);
              console.log("Returning:", hours <= 24 && minutes < 60);
              return hours <= 24 && minutes < 60;
            }
            console.log("Returning false");
            return false;
          },
        },
        current_price: {
          amount_per_unit: { required, between: between(0.01, 9999.99) },
        },
      },
    },
    duration: {
      validDuration(value) {
        if (this.allDayEvent && value === "") {
          return true;
        } else if (
          !this.allDayEvent &&
          moment(value, "HH:mm", true).isValid()
        ) {
          const [hours, minutes] = value.split(":");
          return hours <= 24 && minutes < 60;
        }
        return false;
      },
    },
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/isAuthenticated",
      pawPortalAccount: "account/accountDetails",
      services: "services/services",
      servicesLoading: "services/servicesLoading",
      selectedCompany: "company/selectedCompany",
      createError: "services/createError",
      createErrorItems: "services/createErrorItems",
      createComplete: "services/createComplete",
      createLoading: "services/createLoading",
      updateLoading: "services/updateLoading",
      updateError: "services/updateError",
      updateErrorItems: "services/updateErrorItems",
      updateComplete: "services/updateComplete",
    }),
    // price_modified() {
    //   let editingServiceOrig = JSON.parse(this.editingServiceOriginal);
    //   console.log("PRICE MODIFIED CHECK", this.editingServiceOrig);
    //   if (this.editingServiceOrig != null) {
    //     currency_changed =
    //       this.editingServiceOrig.current_price.currency != "GBP";
    //     amount_changed =
    //       this.editingServiceOrig.current_price.amount_per_unit !=
    //       this.charge_amount;
    //     return currency_changed || amount_changed;
    //   } else {
    //     return false;
    //   }
    // },
    dialogWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "80%";
        case "md":
          return "60%";
        case "lg":
          return "40%";
        case "xl":
          return "40%";
      }
      return "100%";
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.serviceName.$dirty) return errors;
      !this.$v.serviceName.maxLength &&
        errors.push("Name must be at most 100 characters long");
      !this.$v.serviceName.required && errors.push("Name is required.");
      this.isNameTaken && errors.push("Name is already in use.");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      !this.$v.description.maxLength &&
        errors.push("Description must be at most 500 characters long");
      !this.$v.description.required && errors.push("Description is required.");
      return errors;
    },
    chargeAmountErrors() {
      const errors = [];
      if (!this.$v.charge_amount.$dirty) return errors;
      !this.$v.charge_amount.required &&
        errors.push("Charge amount is required.");
      return errors;
    },
    maxPetsPerBookingErrors() {
      const errors = [];
      if (!this.$v.max_pets_per_booking.$dirty) return errors;
      !this.$v.max_pets_per_booking.required &&
        errors.push("Maximum pets per booking is required.");
      return errors;
    },
    durationInMinsErrors() {
      const errors = [];
      if (!this.$v.duration_in_mins.$dirty) return errors;
      if (this.$v.duration_in_mins.$error)
        errors.push("Duration in minutes must be a number.");
      return errors;
    },
    minDurationInMinsErrors() {
      const errors = [];
      if (!this.$v.min_duration_in_mins.$dirty) return errors;
      if (this.$v.min_duration_in_mins.$error)
        errors.push("Minimum duration in minutes must be a number.");
      return errors;
    },
    maxDurationInMinsErrors() {
      const errors = [];
      if (!this.$v.max_duration_in_mins.$dirty) return errors;
      if (this.$v.max_duration_in_mins.$error)
        errors.push("Maximum duration in minutes must be a number.");
      return errors;
    },
    termsAndConditionsErrors() {
      const errors = [];
      if (!this.$v.terms_and_conditions.$dirty) return errors;
      !this.$v.terms_and_conditions.required &&
        errors.push("Terms and conditions are required.");
      return errors;
    },
    durationErrors() {
      const errors = [];
      if (!this.$v.duration.$dirty) return errors;
      !this.$v.duration.validDuration &&
        errors.push(
          "Invalid duration format. Please enter duration in HH:mm format and less than 24 hours, or select all day event."
        );
      return errors;
    },
    additionalServiceNameErrors() {
      return this.additional_services.map((service, index) => {
        const errors = [];
        if (!this.$v.additional_services.$each[index].name.$dirty)
          return errors;
        !this.$v.additional_services.$each[index].name.maxLength &&
          errors.push("Name must be at most 100 characters long.");
        !this.$v.additional_services.$each[index].name.required &&
          errors.push("Name is required.");
        return errors;
      });
    },
    additionalServiceDescriptionErrors() {
      return this.additional_services.map((service, index) => {
        const errors = [];
        if (!this.$v.additional_services.$each[index].description.$dirty)
          return errors;
        !this.$v.additional_services.$each[index].description.maxLength &&
          errors.push("Description must be at most 500 characters long.");
        !this.$v.additional_services.$each[index].description.required &&
          errors.push("Description is required.");
        return errors;
      });
    },
    additionalServiceChargeAmountErrors() {
      return this.additional_services.map((service, index) => {
        const errors = [];
        if (
          !this.$v.additional_services.$each[index].current_price
            .amount_per_unit.$dirty
        )
          return errors;
        !this.$v.additional_services.$each[index].current_price.amount_per_unit
          .required && errors.push("Charge amount is required.");
        return errors;
      });
    },
    additionalServiceMaxPetsPerBookingErrors() {
      return this.additional_services.map((service, index) => {
        const errors = [];
        if (
          !this.$v.additional_services.$each[index].max_pets_per_booking.$dirty
        )
          return errors;
        !this.$v.additional_services.$each[index].max_pets_per_booking
          .required && errors.push("Maximum pets per booking is required.");
        return errors;
      });
    },
    additionalServiceMaxPerBookingErrors() {
      return this.additional_services.map((service, index) => {
        const errors = [];
        if (!this.$v.additional_services.$each[index].max_per_booking.$dirty)
          return errors;
        !this.$v.additional_services.$each[index].max_per_booking.required &&
          errors.push("Maximum per booking is required.");
        return errors;
      });
    },
    additionalServiceDurationErrors() {
      return this.additional_services.map((service, index) => {
        const errors = [];
        if (
          !this.$v.additional_services.$each[index] ||
          !this.$v.additional_services.$each[index].additional_duration_in_mins
            .$dirty
        )
          return errors;
        !this.$v.additional_services.$each[index].additional_duration_in_mins
          .validDuration &&
          errors.push(
            "Invalid duration format. Please enter duration in HH:mm format and less than 24 hours."
          );
        return errors;
      });
    },
    additionalQueryQuestionErrors() {
      return this.query_fields.map((service, index) => {
        const errors = [];
        if (!this.$v.query_fields.$each[index].question.$dirty) return errors;
        !this.$v.query_fields.$each[index].question.required &&
          errors.push("Question is required.");
        return errors;
      });
    },
  },
  methods: {
    ...mapActions({
      authLogout: "auth/logout",
      getAccount: "account/getAccount",
      getServices: "services/getServices",
      createService: "services/createService",
      updateService: "services/updateService",
      resetForm: "services/resetForm",
    }),
    isNameUnique(name) {
      return !this.services.some((service) => service.name === name);
    },
    printValidationErrors(v, parentKey = "") {
      Object.keys(v).forEach((key) => {
        if (key === "$model" || key.startsWith("$")) {
          return;
        }

        let subV = v[key];
        let fullKey = parentKey ? `${parentKey}.${key}` : key;

        // check if subV is defined
        if (subV) {
          if (subV.$invalid) {
            if (subV.$error) {
              console.log(`Error in field ${fullKey}`);
            }

            if (Array.isArray(subV)) {
              for (let i = 0; i < subV.length; i++) {
                this.printValidationErrors(subV[i], `${fullKey}[${i}]`);
              }
            } else {
              this.printValidationErrors(subV, fullKey);
            }
          }
        } else {
          console.log(`Undefined field in validation: ${fullKey}`);
        }
      });
    },

    async submit() {
      if (this.$v.$invalid) {
        console.log("Form is invalid");
        console.log(
          "Flattened params: ",
          JSON.stringify(this.$v.$flattenParams())
        );
        console.log(
          "Validation errors: ",
          this.$v.$flattenParams().filter((param) => {
            const invalidState = param.path.reduce(
              (prev, curr) => prev[curr],
              this.$v
            );
            return invalidState && invalidState.$invalid;
          })
        );

        // Touch all validation fields to show errors if needed
        this.$v.$touch();
      } else {
        console.log("Company selected: " + this.selectedCompany);
        const durationInMinutes = moment
          .duration(this.duration, "HH:mm")
          .asMinutes();
        let now = new Date();

        // We now need to only send the questions and the services
        let additionalServices = Array.isArray(this.additional_services)
          ? this.additional_services.map((service) =>
              // when we receive the services payload, store the sercice id -> price map
              // for each of these services go and get the original price via service.id

              // if there are service.prices, ensure that price[0] is different from stored current price
              // if it is, push price[0] into existing prices array

              ({
                ...service,
                permitted_pet_types: ["DOG"],
                current_price: {
                  amount_per_unit: parseFloat(
                    service.current_price.amount_per_unit
                  ),
                  currency: "GBP",
                },
                max_per_booking: service.max_per_booking,
                max_pets_per_booking: service.max_pets_per_booking,
                same_pets_only: service.same_pets_only,
                additional_duration_in_mins: this.allDayEvent
                  ? null
                  : moment
                      .duration(service.additional_duration_in_mins, "HH:mm")
                      .asMinutes(),
              })
            )
          : [];

        // // Loop through additional services, if the service with the same id, push the current prices, check if the price has been modified, if so add the new price to the payload
        // if (this.editingServiceOriginal != null) {
        //   this.editingServiceOriginal.additional_services.forEach((service) => {
        //     const foundAdditionalService = additionalServices.find(
        //       (s) => s.id === service.id
        //     );
        //     if (foundAdditionalService) {
        //       // Add the current price to the payload
        //       foundAdditionalService.price = service.price;
        //       if (
        //         service.current_price.amount_per_unit !=
        //           foundAdditionalService.current_price.amount_per_unit ||
        //         service.current_price.currency !=
        //           foundAdditionalService.current_price.currency
        //       ) {
        //         foundAdditionalService.current_price.push({
        //           amount_per_unit: parseFloat(this.charge_amount),
        //           currency: "GBP",
        //           effective_from: currentTime,
        //         });
        //       }
        //     }
        //   });
        // }

        console.log(this.prices);
        const payload = {
          name: this.serviceName,
          description: this.description,
          permitted_pet_types: ["DOG"],
          current_price: {
            amount_per_unit: parseFloat(this.charge_amount),
            currency: "GBP",
          },
          max_pets_per_booking: this.max_pets_per_booking,
          company_id: this.selectedCompany.id,
          duration_in_mins: this.allDayEvent ? 0 : durationInMinutes,
          duration_type: this.allDayEvent ? "ALL_DAY" : "DURATION_IN_MINS",
          terms_and_conditions: this.terms_and_conditions, // added
        };

        // Add the current price to the payload
        payload.current_price = {
          amount_per_unit: parseFloat(this.charge_amount),
          currency: "GBP",
        };

        // Check if additional services are enabled, then add them to the payload
        if (additionalServices.length > 0) {
          payload.additional_services = additionalServices;
        }

        // Check if query fields are populated, then add them to the payload
        console.log("QUERY FIELDS: " + this.query_fields);
        if (this.query_fields.length > 0) {
          const queryFields = this.query_fields.map((field) => field.question);
          payload.query_fields = queryFields;
        }

        console.log(`Creating service with payload ${JSON.stringify(payload)}`);
        console.log(`Creating service with payload:`, payload);

        if (this.editingService) {
          // Add the service id to the payload
          payload.id = this.serviceId;
          this.updateService(payload)
            .then(() => {
              // Success, refresh the services list before redirect
              this.getServices(this.selectedCompany.id);
            })
            .catch((error) => {
              // Error
              console.log(`Error updating service: ${error}`);
            });
        } else {
          this.createService(payload)
            .then(() => {
              // Success, refresh the services list before redirect
              this.getServices(this.selectedCompany.id);
            })
            .catch((error) => {
              // Error
              console.log(`Error creating service: ${error}`);
            });
        }
      }
    },

    loadEditService() {
      console.log("Editing service");
      this.editingService = true;
      this.loading = true;
      // Get the service id from the url query params
      const serviceId = this.$route.query.edit;
      // Get the service from the store
      console.log(`Services: ${JSON.stringify(this.services)}`);
      const service = this.services.find((s) => s.id === serviceId);
      if (!service) {
        // Service not found, no action required
        console.log("Service not found");
      } else {
        // Set the original value
        this.editingServiceOriginal = this.services.find(
          (s) => s.id === serviceId
        );

        // Convert to JSON
        this.editingServiceOriginal = JSON.stringify(service);

        // Set the form values
        this.serviceId = service.id;
        this.serviceName = service.name;
        this.description = service.description;
        this.charge_amount = service.current_price.amount_per_unit;
        this.max_pets_per_booking = service.max_pets_per_booking;
        this.terms_and_conditions = service.terms_and_conditions;
        this.allDayEvent = service.duration_type == "ALL_DAY" ? true : false;
        this.duration = moment
          .utc(
            moment
              .duration(service.duration_in_mins, "minutes")
              .asMilliseconds()
          )
          .format("HH:mm");
      }
      // Add query fields
      if (service && service.query_fields.length > 0) {
        this.query_fields = service.query_fields.map((field) => ({
          question: field,
        }));
      }
      // Add additional services
      if (service && service.additional_services) {
        this.additional_services = service.additional_services.map(
          (service) => {
            const { prices, ...serviceWithoutPrices } = service;
            return {
              ...serviceWithoutPrices,
              additional_duration_in_mins: moment
                .utc(
                  moment
                    .duration(service.additional_duration_in_mins, "minutes")
                    .asMilliseconds()
                )
                .format("HH:mm"),
            };
          }
        );
      }
      this.loading = false;
    },
    resetForm() {
      this.$v.$reset();
      this.serviceName = "";
      this.description = "";
      this.charge_amount = null;
      this.max_pets_per_booking = null;
      this.allDayEvent = false;
      this.duration = "";
      this.duration_in_mins = null;
      this.min_duration_in_mins = null;
      this.max_duration_in_mins = null;
      this.terms_and_conditions = "";
      this.is_active = true;
      this.additional_services = [];
      this.query_fields = [];
    },
    addQueryField() {
      this.query_fields.push({ question: "" });
    },
    removeQueryField(index) {
      this.query_fields.splice(index, 1);
    },
    addAdditionalService() {
      this.additional_services.push({
        name: "",
        description: "",
        current_price: { amount_per_unit: null },
        additional_duration_in_mins: null,
        current_price: {},
        max_per_booking: 1,
        same_pets_only: false, // or true, depending on your requirements
      });
    },
    removeAdditionalService(index) {
      this.additional_services.splice(index, 1);
    },
    formatQueryFields() {
      this.query_fields.forEach((field) => {
        console.log(`Checking field ${field.question}`);
        if (field.question == "" || field.question == null) {
          return;
        }
        field.question = field.question.trim();
        // Check if it starts with a capital letter
        if (field.question[0] === field.question[0].toLowerCase()) {
          // If so, make it uppercase
          field.question =
            field.question[0].toUpperCase() + field.question.slice(1);
        }
        // Check if it ends with a question mark
        if (field.question[field.question.length - 1] !== "?") {
          // If not, add one
          field.question = `${field.question}?`;
        }
      });
    },
    // getAdditionalServicePrices(additionalService) {
    //   console.log("Additional Service", additionalService);
    //   let now = new Date();
    //   let price = null;
    //   if (additionalService.id == null) {
    //     console.log("New additional service, push price as is");
    //     price =
    //       {
    //         amount_per_unit: parseFloat(additionalService.amount_per_unit),
    //         currency: "GBP",
    //       },
    //     ;
    //   } else {
    //     let originalService = JSON.parse(this.editingServiceOriginal);
    //     // console.log("Original service", originalService);

    //     // Find the additional_service from the original service
    //     let foundAdditionalService = originalService.additional_services.find(
    //       (s) => s.id === additionalService.id
    //     );

    //     // Check if the price has been modified
    //     if (
    //       foundAdditionalService.current_price.amount_per_unit !=
    //         additionalService.current_price.amount_per_unit ||
    //       foundAdditionalService.current_price.currency !=
    //         additionalService.current_price.currency
    //     ) {
    //       console.log("Price has been modified, push new price");
    //       prices = additionalService.prices;
    //       prices.push({
    //         amount_per_unit: parseFloat(
    //           additionalService.current_price.amount_per_unit
    //         ),
    //         currency: "GBP",
    //         effective_from: currentTime,
    //       });
    //     } else {
    //       console.log("Price has not been modified, push original price");
    //       prices = additionalService.prices;
    //     }
    //     price =
    //   }
    //   console.log("Returning prices", prices);
    //   return price;
    // },
    getServicePrices(servicePrices) {
      console.log("Service prices", servicePrices);
      let prices = null;
      if (servicePrices.length == 0) {
        console.log("New service, push price as is");
        prices = [
          {
            amount_per_unit: parseFloat(this.charge_amount),
            currency: "GBP",
          },
        ];
      } else {
        let originalService = JSON.parse(this.editingServiceOriginal);
        // Check if the price has been modified
        if (
          originalService.current_price.amount_per_unit != this.charge_amount ||
          originalService.current_price.currency != "GBP"
        ) {
          console.log("Price has been modified, push new price");
          prices = servicePrices;
          prices.push({
            amount_per_unit: parseFloat(this.charge_amount),
            currency: "GBP",
            effective_from: currentTime,
          });
        } else {
          console.log("Price has not been modified, push original price");
          prices = servicePrices;
        }
      }
      console.log("Returning prices", prices);
      return prices;
    },
    deepClone(obj) {
      if (obj === null || typeof obj !== "object") {
        return obj;
      }

      if (Array.isArray(obj)) {
        const arrCopy = [];
        for (const item of obj) {
          arrCopy.push(this.deepClone(item));
        }
        return arrCopy;
      }

      const objCopy = {};
      for (const key in obj) {
        objCopy[key] = this.deepClone(obj[key]);
      }
      return objCopy;
    },
  },
  mounted: function () {
    this.resetForm();
    // Check if we have edit in the url query params
    if (this.$route.query.edit) {
      // If so, load the service to edit
      this.loadEditService();
    }
  },
  watch: {
    selectedCompany: async function (val) {
      console.log(`Getting services for company ${val.id}`);
      this.loading = true;
      await this.getServices(val.id);
      // Check if we have edit in the url query params
      if (this.$route.query.edit) {
        // If so, load the service to edit
        this.loadEditService();
      }
      this.loading = false;
    },
    createComplete: function (val) {
      if (val) {
        // Sleep for 2 seconds to allow the user to see the success message and call resetForm
        setTimeout(() => {
          this.resetForm();
          this.$router.push({ name: "ServicesOverview" });
        }, 2000);
      }
    },
    updateComplete: function (val) {
      if (val) {
        // Sleep for 2 seconds to allow the user to see the success message and call resetForm
        setTimeout(() => {
          this.resetForm();
          this.$router.push({ name: "ServicesOverview" });
        }, 2000);
      }
    },
    allDayEvent: function (val) {
      if (val) {
        this.duration = "";
      }
    },
    query_fields: function () {
      this.formatQueryFields();
    },
  },
};
</script>

<style></style>
