<template>
  <v-row>
    <v-col class="pa-0">
      <v-container fluid>
        <CreateDog />
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CreateDog from "../../components/pets/CreateDog.vue";

export default {
  name: "CreateDogPage",
  data() {
    return {
      tab: null,
    };
  },
  components: {
    CreateDog,
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/isAuthenticated",
      user: "auth/user",
      pawPortalAccount: "account/accountDetails",
    }),
  },
  methods: {
    ...mapActions({
      getAccount: "account/getAccount",
    }),
  },
  mounted: function () {},
};
</script>

<style></style>
