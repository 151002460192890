export default {
    namespaced: true,

    state: {
        displayBanner: false,
        bannerMessage: "",
        // Types : success, info, warning, error
        bannerType: "warning",
        loading: false,
        // showNavigationBar: true,
        navigationBarPermanent: false,
        bannerDismissible: false,
        allowTrackingCookies: false,
        displayCookieBanner: false
    },

    mutations: {
        RESET_STATE(state) {
            console.log("core: RESET_STATE called")
            state.displayBanner = null;
        },
        SET_DISPLAY_BANNER(state, val) {
            state.displayBanner = val;
        },
        SET_BANNER_MESSAGE(state, val) {
            state.bannerMessage = val;
        },
        SET_BANNER_TYPE(state, val) {
            state.bannerType = val;
        },
        SET_LOADING(state, val) {
            state.loading = val;
        },
        // SET_SHOW_NAVIGATION_BAR(state, val) {
        //     state.showNavigationBar = val;
        // },
        SET_NAVIGATION_BAR_PERMANENT(state, val) {
            state.navigationBarPermanent = val;
        },
        SET_BANNER_DISMISSABLE(state, val) {
            state.bannerDismissible = val;
        },
        SET_ALLOW_TRACKING_COOKIES(state, val) {
            state.allowTrackingCookies = val;
        },
        SET_DISPLAY_COOKIE_BANNER(state, val) {
            state.displayCookieBanner = val;
        }
    },

    getters: {
        displayBanner(state) {
            return state.displayBanner;
        },
        bannerMessage(state) {
            return state.bannerMessage;
        },
        bannerType(state) {
            return state.bannerType;
        },
        bannerDismissible(state) {
            return state.bannerDismissible;
        },
        loading(state) {
            return state.loading;
        },
        // showNavigationBar(state) {
        //     console.log(state.showNavigationBar)
        //     return state.showNavigationBar;
        // },
        navigationBarPermanent(state) {
            console.log("Change navigation bar permanent to:", state.navigationBarPermanent)
            return state.navigationBarPermanent;
        },
        allowTrackingCookies(state) {
            return state.allowTrackingCookies;
        },
        displayCookieBanner(state) {
            return state.displayCookieBanner;
        }
    },

    actions: {
        async resetState({
            commit
        }) {
            commit('RESET_STATE', null);
        },
        async setDisplayBanner({
            commit
        }, val) {
            commit('SET_DISPLAY_BANNER', val);
        },
        async setBannerMessage({
            commit
        }, val) {
            commit('SET_BANNER_MESSAGE', val);
        },
        async setBannerType({
            commit
        }, val) {
            commit('SET_BANNER_TYPE', val);
        },
        // async setShowNavigationBar({
        //     commit
        // }, val) {
        //     console.log("Set show navigation bar to " + val)
        //     commit('SET_SHOW_NAVIGATION_BAR', val);
        // },
        async setNavigationBarPermanent({
            commit
        }, val) {
            console.log("Set navigation bar permanent to " + val)
            commit('SET_NAVIGATION_BAR_PERMANENT', val);
        },
        async setbannerDismissible({
            commit
        }, val) {
            commit('SET_BANNER_DISMISSABLE', val);
        },
        async setAllowTrackingCookies({
            commit
        }, val) {
            commit('SET_DISPLAY_COOKIE_BANNER', false)
            commit('SET_ALLOW_TRACKING_COOKIES', val);
            localStorage.setItem("allowTrackingCookies", val);
            // Reload the page if the user declines tracking cookies
            if (val == false) {
                location.reload();
            }
        },
        async checkAllowTrackingCookies({
            commit
        }) {
            if (localStorage.getItem("allowTrackingCookies") == "true") {
                commit('SET_ALLOW_TRACKING_COOKIES', true);
                commit('SET_DISPLAY_COOKIE_BANNER', false)
            }
            else if (localStorage.getItem("allowTrackingCookies") == "false") {
                commit('SET_ALLOW_TRACKING_COOKIES', false);
                commit('SET_DISPLAY_COOKIE_BANNER', false)
            }
            else {
                commit('SET_DISPLAY_COOKIE_BANNER', true)
            }
        }
    },
};