<template v-if="authenticated">
  <v-container class="pa-0 ma-0">
    <v-row justify="center">
      <v-col lg="12" md="12" sm="12" xs="12">
        <v-sheet color="white" rounded="xl" class="pa-1 ma-2">
          <v-row justify="center" class="pt-10">
            <v-col lg="6" md="8" sm="10" xs="12">
              <v-row align="center" class="pa-2">
                <v-col v-if="pawPortalAccount">
                  <h2>Dogs - {{ editingDog ? "Edit" : "Create" }}</h2>
                  <h3>{{ this.selectedCompany.name }}</h3>
                </v-col>
              </v-row>
              <v-row v-if="selectedCompany" class="ma-0 pa-0">
                <v-col class="ma-0 pa-0">
                  <v-row align="center" class="pl-2">
                    <v-col align="start">
                      <b>{{ editingDog ? "Edit" : "Create" }} a Dog</b>
                      <span class="float-right"></span>
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="!createComplete">
                <!-- Overlay for loading -->
                <v-overlay :value="createLoading || loading" absolute>
                  <v-progress-circular
                    indeterminate
                    size="64"
                    color="primary"
                  ></v-progress-circular>
                </v-overlay>
                <v-col>
                  <form @submit.prevent="submit">
                    <v-text-field
                      v-model="name"
                      :error-messages="nameErrors"
                      label="Name*"
                      required
                    ></v-text-field>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dob"
                          label="Date of Birth*"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dob"
                        :max="new Date().toISOString().substr(0, 10)"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                    <v-select
                      :items="['Male', 'Female']"
                      :error-messages="genderErrors"
                      v-model="gender"
                      label="Gender*"
                      required
                    ></v-select>
                    <v-select
                      :items="dogBreedItems"
                      item-text="breed"
                      item-value="id"
                      v-model="dog_details.breed_id"
                      label="Dog Breed*"
                      required
                    ></v-select>
                    <v-textarea
                      v-model="additional_info"
                      label="Additional Info"
                    ></v-textarea>
                    <v-text-field
                      v-model="insurance_company_name"
                      label="Insurance Company Name"
                    ></v-text-field>
                    <v-text-field
                      v-model="insurance_policy_id"
                      label="Insurance Policy ID"
                    ></v-text-field>
                    <v-text-field
                      v-model="dog_details.colour"
                      label="Colour"
                    ></v-text-field>
                    <v-checkbox
                      v-model="dog_details.spayed"
                      :label="gender == 'Male' ? 'Neutered' : 'Spayed'"
                      prepend-icon="mdi-dog"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="dog_details.microchipped"
                      label="Microchipped"
                      prepend-icon="mdi-chip"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="dog_details.let_off_lead"
                      label="Let Off Lead"
                      prepend-icon="mdi-dog-service"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="dog_details.allow_treats"
                      label="Allow Treats"
                      prepend-icon="mdi-bone"
                    ></v-checkbox>
                    <v-text-field
                      v-if="dog_details.allow_treats"
                      v-model="dog_details.allow_treats_more_info"
                      label="More Info If Allow Treats"
                    ></v-text-field>
                    <v-checkbox
                      v-model="dog_details.is_aggresive"
                      label="Is Aggresive"
                      prepend-icon="mdi-paw-off"
                    ></v-checkbox>
                    <v-text-field
                      v-if="dog_details.is_aggresive"
                      v-model="dog_details.is_aggresive_more_info"
                      label="More Info If Aggresive"
                    ></v-text-field>
                    <v-checkbox
                      v-model="dog_details.needs_muzzle"
                      label="Needs Muzzle"
                      prepend-icon="mdi-alert-octagon-outline"
                    ></v-checkbox>
                    <v-text-field
                      v-if="dog_details.needs_muzzle"
                      v-model="dog_details.needs_muzzle_more_info"
                      label="More Info If Needs Muzzle"
                    ></v-text-field>
                    <v-text-field
                      v-model="dog_details.recall_details"
                      label="Recall Details"
                    ></v-text-field>
                    <v-text-field
                      v-model="dog_details.allergy_information"
                      label="Allergy Information"
                    ></v-text-field>

                    <!-- Medication Fields -->
                    <h3>Medication Fields</h3>
                    <p>
                      Medication fields are for the medication that needs to be
                      administered to the dog.
                    </p>

                    <div
                      v-for="(medication, index) in medications"
                      :key="index"
                    >
                      <v-row align="center">
                        <v-col>
                          <v-text-field
                            v-model="medication.name"
                            :label="`Name`"
                            required
                            :rules="[(v) => !!v || 'Name is required']"
                          ></v-text-field>
                          <v-text-field
                            v-model="medication.amount"
                            :label="`Amount to be Administered`"
                            required
                            :rules="[(v) => !!v || 'Amount is required']"
                          ></v-text-field>
                          <v-text-field
                            v-model="medication.when_to_administer"
                            :label="`When to Administer`"
                            required
                            :rules="[
                              (v) => !!v || 'When to Administer is required',
                            ]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="2" class="pl-0 ml-0">
                          <v-btn
                            icon
                            color="red"
                            @click="removeMedication(index)"
                          >
                            <v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>

                    <v-btn color="primary" small @click="addMedication"
                      >Add Medication</v-btn
                    >

                    <!-- Veterinarian Information -->
                    <h3>Veterinarian Information</h3>
                    <p>
                      Enter the name, address, and contact information of the
                      veterinarian.
                    </p>

                    <div
                      v-for="(vet, index) in veterinarians"
                      :key="'vet-' + index"
                    >
                      <v-row align="center">
                        <v-col cols="12" class="pr-0 mr-0">
                          <v-text-field
                            v-model="vet.name"
                            label="Veterinarian Name*"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pr-0 mr-0">
                          <v-text-field
                            v-model="vet.address"
                            label="Veterinarian Address*"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="10">
                          <v-row>
                            <v-col>
                              <vue-tel-input
                                v-model="vet.phone_number"
                                :showDialCodeInSelection="true"
                                @validate="vet.phone_number_validation = $event"
                                @blur="
                                  $v.veterinarians.$each[
                                    index
                                  ].phone_number_valid.$touch();
                                  vet.phone_number_valid =
                                    getPhoneValidity(vet);
                                "
                                v-bind="vet.bindProps"
                              ></vue-tel-input>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <div
                                class="alert-small"
                                v-if="getPhoneErrors(vet, index).length > 0"
                              >
                                {{ getPhoneErrors(vet, index)[0] }}
                              </div>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="2" class="pl-0 ml-0">
                          <v-btn
                            icon
                            color="red"
                            @click="removeVeterinarian(index)"
                          >
                            <v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>

                    <v-btn @click="addVeterinarian" small color="primary">
                      Add Veterinarian
                    </v-btn>

                    <!-- Vaccination Fields -->
                    <h3>Vaccination Fields</h3>
                    <p>
                      Vaccination fields are for the names of vaccinations and
                      their up-to-date status.
                    </p>

                    <div
                      v-for="(vaccination, index) in vaccinations"
                      :key="'vaccination-' + index"
                    >
                      <v-row align="center">
                        <v-col cols="5" class="pr-0 mr-0">
                          <v-text-field
                            v-model="vaccination.name"
                            label="Vaccination Name*"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="5" class="pr-0 mr-0">
                          <v-dialog
                            ref="menu"
                            v-model="vaccination.menu"
                            :close-on-content-click="false"
                            :return-value.sync="vaccination.date_administered"
                            transition="scale-transition"
                            offset-y
                            width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="vaccination.date_administered_str"
                                label="Administered Date"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="vaccination.date_administered_str"
                              :max="new Date().toISOString().substr(0, 10)"
                              @input="
                                updateVaccinationDateAdministered(index, $event)
                              "
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="setToday(index)"
                                >Today</v-btn
                              >
                            </v-date-picker>
                          </v-dialog>
                        </v-col>
                        <v-col cols="2" class="pl-0 ml-0">
                          <v-btn
                            icon
                            color="red"
                            @click="removeVaccination(index)"
                          >
                            <v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>

                    <v-btn @click="addVaccination" small color="primary">
                      Add Vaccination
                    </v-btn>
                    <p></p>
                    <!-- :disabled="!formValid" -->
                    <v-btn type="submit" color="primary">{{
                      editingDog ? "Update" : "Submit"
                    }}</v-btn>
                  </form>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-alert type="warning" :value="createError">
                    <li
                      v-bind:key="item.message"
                      v-for="item in createErrorItems"
                    >
                      {{ item.item }} -
                      {{ item.message }}
                    </li>
                  </v-alert>
                  <v-alert type="success" :value="createComplete">
                    Dog {{ editingDog ? "edited" : "created" }} successfully!
                  </v-alert>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  maxLength,
  minLength,
  between,
  helpers,
} from "vuelidate/lib/validators";
import moment from "moment";

export default {
  name: "CreateDog",
  components: {},
  data() {
    return {
      menu: false,
      name: "",
      id: null,
      dob: null,
      gender: "",
      additional_info: "",
      insurance_company_name: "",
      insurance_policy_id: "",
      veterinarians: [],
      vaccinations: [],
      medications: [],
      dog_details: {
        breed_id: null,
        colour: "",
        spayed: false,
        microchipped: false,
        let_off_lead: false,
        is_aggresive: false,
        is_aggresive_more_info: "",
        allow_treats: false,
        allow_treats_more_info: "",
        needs_muzzle: false,
        needs_muzzle_more_info: "",
        recall_details: "",
        allergy_information: "",
      },
      editingDog: false,
      loading: false,
    };
  },
  mixins: [validationMixin],
  validations: {
    name: {
      required,
      maxLength: maxLength(100),
    },
    dob: {
      required,
    },
    gender: {
      required,
    },
    additional_info: {
      maxLength: maxLength(500),
    },
    insurance_company_name: {
      maxLength: maxLength(100),
    },
    insurance_policy_id: {
      maxLength: maxLength(100),
    },
    medications: {
      $each: {
        name: {
          required,
        },
        amount: {
          required,
        },
        when_to_administer: {
          required,
        },
      },
    },
    veterinarians: {
      $each: {
        name: { required },
        address: { required },
        phone_number: { required },
        phone_number_valid: { required },
      },
    },
    vaccination_details: {
      $each: {
        vaccination_name: {
          required,
          maxLength: maxLength(100),
        },
      },
    },
    dog_details: {
      breed_id: {
        required,
      },
      colour: {
        maxLength: maxLength(100),
      },
      spayed: {},
      microchipped: {},
      let_off_lead: {},
      is_aggresive: {},
      is_aggresive_more_info: {},
      allow_treats: {},
      allow_treats_more_info: {},
      needs_muzzle: {},
      needs_muzzle_more_info: {},

      recall_details: {
        maxLength: maxLength(500),
      },
      allergy_information: {
        maxLength: maxLength(500),
      },
    },
  },

  computed: {
    ...mapGetters({
      authenticated: "auth/isAuthenticated",
      pawPortalAccount: "account/accountDetails",
      selectedCompany: "company/selectedCompany",
      createError: "pets/createDogError",
      createErrorItems: "pets/createDogErrorItems",
      createComplete: "pets/createDogComplete",
      createLoading: "pets/createDogLoading",
      dogBreeds: "pets/dogBreeds",
      dogs: "pets/dogs",
    }),
    dogBreedItems() {
      return this.dogBreeds.map((breed) => ({
        ...breed,
        breed: this.titleCase(breed.breed),
      }));
    },
    formValid() {
      return !this.$v.$invalid;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength &&
        errors.push("Name must be at most 100 characters long.");
      !this.$v.name.required && errors.push("Name is required.");
      return errors;
    },
    dobErrors() {
      const errors = [];
      if (!this.$v.dob.$dirty) return errors;
      !this.$v.dob.required && errors.push("Date of Birth is required.");
      return errors;
    },

    genderErrors() {
      const errors = [];
      if (!this.$v.gender.$dirty) return errors;
      !this.$v.gender.required && errors.push("Gender is required.");
      return errors;
    },

    additionalInfoErrors() {
      const errors = [];
      if (!this.$v.additional_info.$dirty) return errors;
      !this.$v.additional_info.maxLength &&
        errors.push("Additional Info must be at most 500 characters long.");
      return errors;
    },

    insuranceCompanyNameErrors() {
      const errors = [];
      if (!this.$v.insurance_company_name.$dirty) return errors;
      !this.$v.insurance_company_name.maxLength &&
        errors.push(
          "Insurance Company Name must be at most 100 characters long."
        );
      !this.$v.insurance_company_name.required &&
        errors.push("Insurance Company Name is required.");
      return errors;
    },

    insurancePolicyIdErrors() {
      const errors = [];
      if (!this.$v.insurance_policy_id.$dirty) return errors;
      !this.$v.insurance_policy_id.maxLength &&
        errors.push("Insurance Policy ID must be at most 100 characters long.");
      !this.$v.insurance_policy_id.required &&
        errors.push("Insurance Policy ID is required.");
      return errors;
    },

    breedIdErrors() {
      const errors = [];
      if (!this.$v.dog_details.breed_id.$dirty) return errors;
      !this.$v.dog_details.breed_id.required &&
        errors.push("Breed is required.");
      return errors;
    },

    colourErrors() {
      const errors = [];
      if (!this.$v.dog_details.colour.$dirty) return errors;
      !this.$v.dog_details.colour.maxLength &&
        errors.push("Colour must be at most 100 characters long.");
      !this.$v.dog_details.colour.required &&
        errors.push("Colour is required.");
      return errors;
    },

    isAggresiveMoreInfoErrors() {
      const errors = [];
      if (!this.$v.dog_details.is_aggresive_more_info.$dirty) return errors;
      !this.$v.dog_details.is_aggresive_more_info.required &&
        this.dog_details.is_aggresive &&
        errors.push("This field is required when 'Is Aggresive' is checked.");
      return errors;
    },

    allowTreatsMoreInfoErrors() {
      const errors = [];
      if (!this.$v.dog_details.allow_treats_more_info.$dirty) return errors;
      !this.$v.dog_details.allow_treats_more_info.required &&
        this.dog_details.allow_treats &&
        errors.push("This field is required when 'Is Aggresive' is checked.");
      return errors;
    },

    needsMuzzleMoreInfoErrors() {
      const errors = [];
      if (!this.$v.dog_details.needs_muzzle_more_info.$dirty) return errors;
      !this.$v.dog_details.needs_muzzle_more_info.required &&
        this.dog_details.needs_muzzle &&
        errors.push("This field is required when 'Needs Muzzle' is checked.");
      return errors;
    },
    recallDetailsErrors() {
      const errors = [];
      if (!this.$v.dog_details.recall_details.$dirty) return errors;
      !this.$v.dog_details.recall_details.maxLength &&
        errors.push("Recall details must be at most 500 characters long.");
      return errors;
    },

    allergyInformationErrors() {
      const errors = [];
      if (!this.$v.dog_details.allergy_information.$dirty) return errors;
      !this.$v.dog_details.allergy_information.maxLength &&
        errors.push("Allergy information must be at most 500 characters long.");
      return errors;
    },
    vetNameErrors() {
      const errors = [];
      if (!this.$v.vet_details.vet_name.$dirty) return errors;
      !this.$v.vet_details.vet_name.maxLength &&
        errors.push("Vet name must be at most 100 characters long.");
      return errors;
    },

    vetAddressErrors() {
      const errors = [];
      if (!this.$v.vet_details.vet_address.$dirty) return errors;
      !this.$v.vet_details.vet_address.maxLength &&
        errors.push("Vet address must be at most 200 characters long.");
      return errors;
    },

    vetPhoneNumberErrors() {
      const errors = [];
      if (!this.$v.vet_details.vet_phone_number.$dirty) return errors;
      !this.$v.vet_details.vet_phone_number.maxLength &&
        errors.push("Vet phone number must be at most 20 characters long.");
      return errors;
    },

    vaccinationNameErrors() {
      const errors = [];
      if (!this.$v.vaccination_details.vaccination_name.$dirty) return errors;
      !this.$v.vaccination_details.vaccination_name.maxLength &&
        errors.push("Vaccination name must be at most 100 characters long.");
      return errors;
    },

    formErrors() {
      return !this.$v.$invalid && this.submitted;
    },
  },
  methods: {
    ...mapActions({
      authLogout: "auth/logout",
      getAccount: "account/getAccount",
      createDog: "pets/createDog",
      resetForm: "pets/resetForm",
      getDogBreeds: "pets/getDogBreeds",
      getDogs: "pets/getDogs",
      updateDog: "pets/updateDog",
    }),
    addVeterinarian() {
      this.veterinarians.push({
        name: "",
        address: "",
        phone_number: "",
        phone_number_validation: null,
        phone_number_valid: false,
        bindProps: {
          mode: "international",
          autoFormat: true,
          autoDefaultCountry: true,
        },
      });
    },
    removeVeterinarian(index) {
      this.veterinarians.splice(index, 1);
    },
    getPhoneValidity(vet) {
      return vet.phone_number_validation && vet.phone_number_validation.valid;
    },
    getPhoneErrors(vet, index) {
      let errors = [];
      // Check if the index is valid
      if (this.$v.veterinarians.$each[index]) {
        if (!this.$v.veterinarians.$each[index].phone_number_valid.$dirty) {
          return errors;
        }
        vet.phone_number_validation == null ||
          (vet.phone_number_validation.valid == false &&
            errors.push("This is not a valid phone number"));
      }
      return errors;
    },
    addVaccination() {
      this.vaccinations.push({
        name: "",
        date_administered: null,
      });
    },
    removeVaccination(index) {
      this.vaccinations.splice(index, 1);
    },
    addMedication() {
      this.medications.push({
        name: "",
        amount: "",
        when_to_administer: "",
      });
    },
    removeMedication(index) {
      this.medications.splice(index, 1);
    },
    updateVaccinationDateAdministered(index, dateStr) {
      // convert Date string to Date object
      this.vaccinations[index].date_administered = moment(
        dateStr,
        "DD-MM-YYYY"
      ).toDate();
      this.vaccinations[index].menu = false;
      console.log("Updated date:");
      console.log(this.vaccinations[index].date_administered);
    },

    // Method for the Today button
    setToday(index) {
      const today = moment().format("YYYY-MM-DD");
      this.vaccinations[index].date_administered_str = today;
      this.updateVaccinationDateAdministered(index, today);
    },
    formatDate(date) {
      let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    titleCase(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.replace(word[0], word[0].toUpperCase());
        })
        .join(" ");
    },
    async submit() {
      console.log(
        "Validation errors: ",
        this.$v.$flattenParams().filter((param) => {
          const invalidState = param.path.reduce(
            (prev, curr) => prev[curr],
            this.$v
          );
          return invalidState && invalidState.$invalid;
        })
      );
      if (this.$v.$invalid) {
        console.log("Form invalid");
        // Touch all validation fields to show errors if needed
        this.$v.$touch();
      } else {
        console.log("Company selected: " + this.selectedCompany);

        // Vet Info
        const vet_info = this.veterinarians.map((vet) => {
          return {
            name: vet.name,
            address: vet.address,
            phone_number: vet.phone_number,
          };
        });

        // Vaccinations
        console.log("Vaccinations: ", this.vaccinations);
        const vaccinations = this.vaccinations.map((vaccine) => {
          console.log("Vaccine: ", vaccine);
          console.log(
            "Date: ",
            moment(vaccine.date_administered_str).format(
              "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
            )
          );
          return {
            name: vaccine.name,
            date_administered: moment(vaccine.date_administered_str).format(
              "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
            ),
          };
        });

        // Medication
        const medications = this.medications.map((med) => {
          return {
            id: med.id,
            name: med.name,
            amount: med.amount,
            when_to_administer: med.when_to_administer,
          };
        });

        var payload = {
          name: this.name.trim(),
          dob: moment(this.dob).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          gender: this.gender.toUpperCase(),
          additional_info: this.additional_info.trim(),
          insurance_company_name: this.insurance_company_name.trim(),
          insurance_policy_id: this.insurance_policy_id.trim(),
          vet_info,
          vaccinations,
          medications,
          breed_id: this.dog_details.breed_id,
          colour: this.dog_details.colour.trim(),
          spayed: this.dog_details.spayed,
          microchipped: this.dog_details.microchipped,
          let_off_lead: this.dog_details.let_off_lead,
          is_aggresive: this.dog_details.is_aggresive,
          is_aggresive_more_info:
            this.dog_details.is_aggresive_more_info.trim(),
          allow_treats: this.dog_details.allow_treats,
          allow_treats_more_info:
            this.dog_details.allow_treats_more_info.trim(),
          needs_muzzle: this.dog_details.needs_muzzle,
          needs_muzzle_more_info:
            this.dog_details.needs_muzzle_more_info.trim(),
          recall_details: this.dog_details.recall_details.trim(),
          allergy_information: this.dog_details.allergy_information.trim(),
        };

        if (this.editingDog) {
          // Editing an existing dog
          // Add the dog id to the payload
          payload.id = this.id;

          console.log(`Updating dog with payload ${JSON.stringify(payload)}`);
          this.updateDog(payload)
            .then(() => {
              // Success, refresh the dog list before redirect
              this.getDogs();
            })
            .catch((error) => {
              // Error
              console.log(`Error updating dog: ${error}`);
            });
        } else {
          // Creating a new dog
          console.log(`Creating dog with payload ${JSON.stringify(payload)}`);

          this.createDog(payload)
            .then(() => {
              // Success, refresh the dog list before redirect
              this.getDogs();
            })
            .catch((error) => {
              // Error
              console.log(`Error creating dog: ${error}`);
            });
        }
      }
    },
    async loadEditDog() {
      console.log("Editing Dog");
      this.editingDog = true;
      this.loading = true;
      // Get the dog id from the url query params
      const dogId = this.$route.query.edit;
      console.log(`Editing dog with id ${dogId}`);
      const dog = this.dogs.find((dog) => dog.id == dogId);
      if (!dog) {
        console.log(`Dog with id ${dogId} not found`);
      } else {
        console.log(`Editing dog:`, dog);
        // Set the form from values
        this.name = dog.name;
        this.id = dog.id;
        this.dob = moment(dog.dob).format("YYYY-MM-DD");
        this.gender =
          dog.gender.charAt(0).toUpperCase() +
          dog.gender.slice(1).toLowerCase();
        this.additional_info = dog.additional_info ? dog.additional_info : "";
        this.insurance_company_name = dog.insurance_company_name
          ? dog.insurance_company_name
          : "";
        this.insurance_policy_id = dog.insurance_policy_id
          ? dog.insurance_policy_id
          : "";
        this.dog_details.breed_id = dog.breed.id;
        this.dog_details.colour = dog.colour ? dog.colour : "";
        this.dog_details.spayed = dog.spayed ? true : false;
        this.dog_details.microchipped = dog.microchipped ? true : false;
        this.dog_details.let_off_lead = dog.let_off_lead ? true : false;
        this.dog_details.is_aggresive = dog.is_aggresive ? true : false;
        this.dog_details.is_aggresive_more_info = dog.is_aggresive_more_info
          ? dog.is_aggresive_more_info
          : "";
        this.dog_details.allow_treats = dog.allow_treats ? true : false;
        this.dog_details.allow_treats_more_info = dog.allow_treats_more_info
          ? dog.allow_treats_more_info
          : "";
        this.dog_details.needs_muzzle = dog.needs_muzzle ? true : false;
        this.dog_details.needs_muzzle_more_info = dog.needs_muzzle_more_info
          ? dog.needs_muzzle_more_info
          : "";
        this.dog_details.recall_details = dog.recall_details
          ? dog.recall_details
          : "";
        this.dog_details.allergy_information = dog.allergy_information
          ? dog.allergy_information
          : "";
        dog.vaccinations.forEach((vaccination) => {
          this.vaccinations.push({
            name: vaccination.name,
            // date_administered: vaccination.date_administered,
            // Add date_administered as new Date().toISOString().substr(0, 10)
            date_administered: moment(vaccination.date_administered).format(
              "DD-MM-YYYY"
            ),
            date_administered_str: moment(vaccination.date_administered).format(
              "YYYY-MM-DD"
            ),
            menu: false,
          });
        });
        dog.vet_info.forEach((vet) => {
          this.veterinarians.push({
            name: vet.name,
            phone_number: vet.phone_number,
            address: vet.address,
            phone_number_validation: null,
            phone_number_valid: true,
            bindProps: {
              mode: "international",
              autoFormat: true,
              autoDefaultCountry: true,
            },
          });
        });
        dog.medications.forEach((medication) => {
          this.medications.push({
            id: medication.id,
            name: medication.name,
            amount: medication.amount,
            when_to_administer: medication.when_to_administer,
          });
        });
      }
      this.loading = false;
    },
  },
  mounted: async function () {
    this.loading = true;
    this.resetForm();
    // Get the dog breeds
    await this.getDogBreeds();
    // Check if we are editing a dog
    if (this.$route.query.edit) {
      // Check if we have the dogs already
      if (this.dogs.length == 0) {
        // If not, refresh the dogs list
        await this.getDogs();
      }
      // Load the dog to edit
      await this.loadEditDog();
    }
    this.loading = false;
  },
  watch: {
    medications: function (val) {
      console.log(`Medications changed: ${JSON.stringify(val)}`);
    },
    createComplete: function (val) {
      if (val) {
        // Sleep for 2 seconds to allow the user to see the success message and call resetForm
        setTimeout(() => {
          this.resetForm();
          this.$router.push("/pets");
        }, 2000);
      }
    },
    vaccinations: function (val) {
      console.log(`Vaccinations changed: ${JSON.stringify(val)}`);
    },
  },
};
</script>
