<template>
  <v-app>
    <v-container fluid>
      <v-row>
        <v-col>
          <h1>Terms and Conditions</h1>
          <p>Last updated: May 16, 2023</p>
          <p>
            Please read these terms and conditions carefully before using our
            service.
          </p>
          <h1>Interpretation and Definitions</h1>
          <h2>Interpretation</h2>
          <p>
            The words capitalized in these terms and conditions have specific
            meanings outlined in this section. The definitions provided below
            apply regardless of whether they appear in singular or plural form.
          </p>
          <h2>Definitions</h2>
          <p>For the purposes of these Terms and Conditions:</p>
          <ul>
            <li>
              <p>
                <strong>Affiliate</strong> means an entity that controls, is
                controlled by, or is under common control with our company,
                where "control" means ownership of 50% or more of the shares,
                equity interest, or other securities entitled to vote for the
                election of directors or other managing authority.
              </p>
            </li>
            <li>
              <p><strong>Country</strong> refers to the United Kingdom.</p>
            </li>
            <li>
              <p>
                <strong>Company</strong> (referred to as "we," "us," or "our" in
                this Agreement) refers to our company, which provides services
                to assist dog walkers in their daily business activities,
                including invoicing and scheduling. Our company operates under
                the name Paw Portal.
              </p>
            </li>
            <li>
              <p>
                <strong>Device</strong> means any device, such as a computer, a
                cellphone, or a digital tablet, that can access our service.
              </p>
            </li>
            <li>
              <p>
                <strong>Service</strong> refers to our website and platform.
              </p>
            </li>
            <li>
              <p>
                <strong>Terms and Conditions</strong> (also referred to as
                "Terms") mean these terms and conditions that form the entire
                agreement between you and our company regarding the use of our
                service. These Terms and Conditions have been created to comply
                with UK regulations.
              </p>
            </li>
            <li>
              <p>
                <strong>Third-party Social Media Service</strong> means any
                services or content (including data, information, products, or
                services) provided by a third party that may be displayed,
                included, or made available through our service.
              </p>
            </li>
            <li>
              <p>
                <strong>Website</strong> refers to our website, accessible from
                <a
                  href="https://pawportal.co.uk"
                  rel="external nofollow noopener"
                  target="_blank"
                  >https://pawportal.co.uk</a
                >.
              </p>
            </li>
            <li>
              <p>
                <strong>You</strong> means the individual accessing or using our
                service, or the company or other legal entity on whose behalf
                you are accessing or using the service, as applicable.
              </p>
            </li>
          </ul>
          <h1>Acknowledgment</h1>
          <p>
            These Terms and Conditions govern your use of our service and
            constitute an agreement between you and our company. They set out
            the rights and obligations of all users in relation to the use of
            our service.
          </p>
          <p>
            Your access to and use of our service is subject to your acceptance
            of and compliance with these Terms and Conditions. These Terms and
            Conditions apply to all visitors, users, and others who access or
            use our service.
          </p>
          <p>
            By accessing or using our service, you agree to be bound by these
            Terms and Conditions. If you disagree with any part of these Terms
            and Conditions, you may not access or use our service.
          </p>
          <p>
            You represent that you are over the age of 18. Our service is not
            intended for individuals under 18 years of age.
          </p>
          <p>
            Your access to and use of our service is also subject to your
            acceptance of and compliance with our Privacy Policy. Our Privacy
            Policy describes our policies and procedures regarding the
            collection, use, and disclosure of your personal information when
            you use our application or website and informs you about your
            privacy rights and how the law protects you. Please read our Privacy
            Policy carefully before using our service.
          </p>
          <h1>Links to Other Websites</h1>
          <p>
            Our service may contain links to third-party websites or services
            that are not owned or controlled by our company.
          </p>
          <p>
            Our company has no control over, and assumes no responsibility for,
            the content, privacy policies, or practices of any third-party
            websites or services. You further acknowledge and agree that our
            company shall not be responsible or liable, directly or indirectly,
            for any damage or loss caused or alleged to be caused by or in
            connection with the use of or reliance on any such content, goods,
            or services available on or through any such websites or services.
          </p>
          <p>
            We strongly advise you to read the terms and conditions and privacy
            policies of any third-party websites or services that you visit.
          </p>
          <h1>Termination</h1>
          <p>
            We may terminate or suspend your access to our service immediately,
            without prior notice or liability, for any reason whatsoever,
            including without limitation if you breach these Terms and
            Conditions.
          </p>
          <p>
            Upon termination, your right to use our service will cease
            immediately.
          </p>
          <p>
  Additionally, if your account remains inactive for a continuous period of 365 days, we reserve the right to automatically delete your account. Inactivity is defined as a lack of any logins or usage of the service during this period. Prior to the deletion, we will make reasonable efforts to notify you via the contact information associated with your account.
</p>
          <h1>Limitation of Liability</h1>
          <p>
To the maximum extent permitted by applicable law, our company and its suppliers shall not be liable for any damages that you might incur. Your exclusive remedy for any claims or disputes arising out of or in connection with these Terms and your use of the service shall be limited to resolving the issue or discontinuing your use of the service. Our company and its suppliers shall have no obligation to provide any monetary compensation for any damages, losses, or issues, regardless of the circumstances.
          </p>
          <p>
            To the maximum extent permitted by applicable law, in no event shall
            our company or its suppliers be liable for any special, incidental,
            indirect, or consequential damages whatsoever (including, but not
            limited to, damages for loss of profits, loss of data or other
            information, business interruption, or losses to your company
            arising from incorrect billing), whether in contract, warranty, tort
            (including negligence), or otherwise, arising out of or in
            connection with the use of or inability to use our service, any
            third-party software or hardware used with our service, or otherwise
            in connection with any provision of these Terms, even if our company
            or any of its suppliers has been advised of the possibility of such
            damages and even if the remedy fails of its essential purpose.
          </p>
          <p>
            Some jurisdictions do not allow the exclusion of implied warranties
            or limitation of liability for incidental or consequential damages,
            which means that some of the above limitations may not apply to you.
            In these jurisdictions, each party's liability will be limited to
            the greatest extent permitted by law.
          </p>
          <h1>"AS IS" and "AS AVAILABLE" Disclaimer</h1>
          <p>
            Our service is provided to you "AS IS" and "AS AVAILABLE" and with
            all faults and defects without warranty of any kind. To the maximum
            extent permitted under applicable law, our company, on its own
            behalf and on behalf of its affiliates, licensors, and service
            providers, expressly disclaims all warranties, whether express,
            implied, statutory, or otherwise, with respect to our service,
            including all implied warranties of merchantability, fitness for a
            particular purpose, title, and non-infringement, and warranties
            arising out of course of dealing, course of performance, usage, or
            trade practice. Without limitation to the foregoing, our company
            provides no warranty or undertaking and makes no representation of
            any kind that our service will meet your requirements, achieve any
            intended results, be compatible or work with any other software,
            applications, systems, or services, operate without interruption,
            meet any performance or reliability standards, or be error-free, or
            that any errors or defects can or will be corrected.
          </p>
          <p>
            Without limiting the foregoing, neither our company nor any of its
            providers makes any representation or warranty of any kind, express
            or implied, regarding the operation or availability of our service,
            the information, content, and materials or products included
            thereon, the accuracy, reliability, or currency of any information
            or content provided through our service, or that our service, its
            servers, the content, or e-mails sent from or on behalf of our
            company are free of viruses, scripts, trojan horses, worms, malware,
            timebombs, or other harmful components.
          </p>
          <p>
            Some jurisdictions do not allow the exclusion of certain types of
            warranties or limitations on applicable statutory rights of a
            consumer, so some or all of the above exclusions and limitations may
            not apply to you. But in such a case, the exclusions and limitations
            set forth in this section shall be applied to the greatest extent
            enforceable under applicable law.
          </p>
          <h1>Governing Law</h1>
          <p>
            These Terms and Conditions shall be governed by the laws of the
            United Kingdom, excluding its conflicts of law rules. Your use of
            our service may also be subject to other local, state, national, or
            international laws.
          </p>
          <h1>Disputes Resolution</h1>
          <p>
            If you have any concerns or disputes regarding our service, you
            agree to first try to resolve the dispute informally by contacting
            our company.
          </p>
                    <h1>GDPR Compliance and Use of Information</h1>
          <p>
            We are committed to ensuring that your personal information is
            protected in accordance with applicable data protection laws,
            including the General Data Protection Regulation (GDPR). By using
            our service, you acknowledge and agree that we may collect, store,
            and use your personal information as described in our Privacy
            Policy.
          </p>
          <p>
            We may collect and process personal information that you provide to
            us, such as your name, email address, phone number, and other
            relevant details. This information may be used to provide and
            improve our service, communicate with you, and fulfill our legal or
            contractual obligations.
          </p>
          <p>
            By using our service, you consent to the collection, storage, and
            use of your personal information as outlined in our Privacy Policy.
            You have the right to access, rectify, or erase your personal
            information, as well as the right to restrict or object to certain
            processing activities. Please refer to our Privacy Policy for more
            information on how to exercise your rights.
          </p>
          <p>
            While we take reasonable measures to protect the security of your
            personal information, please be aware that no method of electronic
            transmission or storage is 100% secure. Therefore, we cannot
            guarantee absolute security and shall not be liable for any
            unauthorized access, loss, or disclosure of your personal
            information.
          </p>
          <h1>Severability and Waiver</h1>
          <h2>Severability</h2>
          <p>
            If any provision of these Terms is held to be unenforceable or
            invalid, such provision will be changed and interpreted to
            accomplish the objectives of such provision to the greatest extent
            possible under applicable law, and the remaining provisions will
            continue in full force and effect.
          </p>
          <h2>Waiver</h2>
          <p>
            Except as provided herein, the failure to exercise a right or to
            require performance of an obligation under these Terms shall not
            affect a party's ability to exercise such right or require such
            performance at any time thereafter, nor shall the waiver of a breach
            constitute a waiver of any subsequent breach.
          </p>
          <h1>Translation Interpretation</h1>
          <p>
            These Terms and Conditions may have been translated if we have made
            them available to you on our service. You agree that the original
            English text shall prevail in the case of a dispute.
          </p>
          <h1>Changes to These Terms and Conditions</h1>
          <p>
            We reserve the right, at our sole discretion, to modify or replace
            these Terms and Conditions at any time. If a revision is material,
            we will make reasonable efforts to provide at least 30 days' notice
            prior to any new terms taking effect. What constitutes a material
            change will be determined at our sole discretion.
          </p>
          <p>
            By continuing to access or use our service after those revisions
            become effective, you agree to be bound by the revised terms. If you
            do not agree to the new terms, in whole or in part, please stop
            using the website and our service.
          </p>
          <h1>Contact Us</h1>
          <p>
            If you have any questions about these Terms and Conditions, you can
            contact us:
          </p>
          <ul>
            <li>By email: toc@pawportal.co.uk</li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>


    <script>
      import { mapMutations, mapGetters } from "vuex";
      import { PublicClientApplication } from "@azure/msal-browser";
      //import Calendar from "../components/Calendar";

      export default {
        name: "Login",
        components: {
          //Calendar
        },
        data() {
          return {
          };
        },
      };
    </script>

    <style scoped>
      h3 {
        margin: 40px 0 0;
      }
      ul {
        list-style-type: none;
        padding: 0;
      }
      li {
        display: inline-block;
        margin: 0 10px;
      }
      a {
        color: #42b983;
      }
    </style>
  </v-app>
</template>
