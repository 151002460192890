<template v-if="authenticated">
  <v-container>
    <v-overlay :absolute="true" :opacity="0.2" :value="updateProfileLoading">
      <v-progress-circular
        :width="4"
        :size="70"
        color="#7db52f"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-row class="pa-2" justify="center">
      <v-col md="12" align="center">
        <v-row>
          <v-col>
            <h2>Update Profile</h2>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col align="center">
        <form>
          <v-row v-if="!updateProfileComplete">
            <v-col>
              <v-text-field
                v-model="first_names"
                :error-messages="first_namesErrors"
                :counter="20"
                label="First Name*"
                required
                @input="$v.first_names.$touch()"
                @blur="$v.first_names.$touch()"
              ></v-text-field>
              <v-text-field
                v-model="surname"
                :error-messages="surnameErrors"
                :counter="20"
                label="Surname*"
                required
                @input="$v.surname.$touch()"
                @blur="$v.surname.$touch()"
              ></v-text-field>
              <v-text-field
                v-model="address_line_1"
                :error-messages="address_line_1Errors"
                :counter="50"
                label="Address Line 1"
                @input="$v.address_line_1.$touch()"
                @blur="$v.address_line_1.$touch()"
              ></v-text-field>
              <v-text-field
                v-model="address_line_2"
                :error-messages="address_line_2Errors"
                :counter="50"
                label="Address Line 2"
                @input="$v.address_line_2.$touch()"
                @blur="$v.address_line_2.$touch()"
              ></v-text-field>
              <v-text-field
                v-model="company_address_city_or_town"
                :error-messages="company_address_city_or_townErrors"
                :counter="50"
                label="City/Town"
                @input="$v.company_address_city_or_town.$touch()"
                @blur="$v.company_address_city_or_town.$touch()"
              ></v-text-field>
              <v-text-field
                v-model="company_address_county_or_state"
                :error-messages="company_address_county_or_stateErrors"
                :counter="50"
                label="County/State"
                @input="$v.company_address_county_or_state.$touch()"
                @blur="$v.company_address_county_or_state.$touch()"
              ></v-text-field>
              <v-text-field
                v-model="address_postcode"
                :error-messages="address_postcodeErrors"
                label="Postcode/Zip Code"
                @input="$v.address_postcode.$touch()"
                @blur="$v.address_postcode.$touch()"
              ></v-text-field>
              <!-- Phone1 -->
              <!-- <v-row class="mb-3">
                <v-col>
                  <vue-tel-input
                    v-model="phone_1"
                    :showDialCodeInSelection="true"
                    @validate="phone_1_validation = $event"
                    @blur="$v.phone_1_valid.$touch()"
                    v-bind="bindProps"
                  ></vue-tel-input>
                  <div class="alert-small">{{ phone_1Errors[0] }}</div>
                </v-col>
              </v-row> -->
              <v-row
                v-for="(telephone, index) in telephone_numbers"
                :key="index"
                class="mb-3"
              >
                <v-col>
                  <vue-tel-input
                    v-model="telephone.number"
                    :showDialCodeInSelection="true"
                    @validate="telephone.validation = $event"
                    v-bind="bindProps"
                  ></vue-tel-input>
                  <div class="alert-small">{{ telephoneErrors[index][0] }}</div>
                  <v-select
                    v-model="telephone.type"
                    :items="['Mobile', 'Home', 'Work', 'Other']"
                    @change="handleTypeChange(index, $event)"
                    @blur="$v.telephone_numbers.$touch()"
                  ></v-select>
                  <v-text-field
                    v-if="telephone.type === 'Other'"
                    v-model="telephone.customType"
                    label="Custom Type"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-btn icon @click="removePhone(index)">
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-btn @click="addPhone">Add Phone</v-btn>
            </v-col>
          </v-row>
          <v-alert type="warning" :value="updateProfileError">
            <li v-bind:key="item.msg" v-for="item in updateProfileErrorItems">
              {{ item.message }}
              {{ item.item }}
            </li>
          </v-alert>
          <v-alert type="success" :value="updateProfileComplete">
            Profile updated successfully!
          </v-alert>
          <v-btn
            color="#EA8C3C"
            class="white--text"
            depressed
            @click="submit"
            :disabled="!formValid"
            v-if="!updateProfileComplete"
          >
            submit
          </v-btn>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";

import {
  required,
  minLength,
  maxLength,
  helpers,
  sameAs,
} from "vuelidate/lib/validators";

const postcodeRegex = helpers.regex(
  "postcodeRegex",
  /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/
);

export default {
  name: "UpdateProfileDialogue",
  mixins: [validationMixin],
  validations: {
    first_names: { required, maxLength: maxLength(20) },
    surname: { required, maxLength: maxLength(20) },
    address_line_1: { maxLength: maxLength(50) },
    address_line_2: { maxLength: maxLength(50) },
    company_address_city_or_town: { maxLength: maxLength(50) },
    company_address_county_or_state: { maxLength: maxLength(50) },
    // postcode based on UK postcode regex
    address_postcode: {
      maxLength: maxLength(8),
      // Regex to match ([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})
      valid: postcodeRegex,
    },
    telephone_numbers: {
      $each: {
        validation: {
          customValid: function (val) {
            console.log("customValid", val);
            if (val === null || val.valid === undefined) {
              console.log("Returning:", false);
              return false;
            } else {
              console.log("Returning:", val.valid);
              return val.valid;
            }
          },
        },
        type: {
          required,
        },
      },
    },
  },
  data() {
    return {
      bindProps: {
        mode: "international",
        autoFormat: true,
        autoDefaultCountry: true,
      },
      test: null,
      first_names: null,
      surname: null,
      address_line_1: null,
      address_line_2: null,
      company_address_city_or_town: null,
      company_address_county_or_state: null,
      address_postcode: null,
      telephone_numbers: [],
    };
  },
  created() {
    console.log(this.account);
    this.first_names = this.account.accountDetails.first_names;
    this.surname = this.account.accountDetails.surname;
    this.address_line_1 = this.account.accountDetails.address_line_1;
    this.address_line_2 = this.account.accountDetails.address_line_2;
    this.company_address_city_or_town =
      this.account.accountDetails.address_line_3;
    this.company_address_county_or_state =
      this.account.accountDetails.address_line_4;
    this.address_postcode = this.account.accountDetails.address_postcode;
    // this.phone_1_type =
    //   this.account.accountDetails.telephone_numbers.length > 0
    //     ? this.account.accountDetails.telephone_numbers[0].number_type
    //     : "";
    // this.phone_1 =
    //   this.account.accountDetails.telephone_numbers.length > 0
    //     ? this.account.accountDetails.telephone_numbers[0].number
    //     : "";
    // this.telephone_numbers = this.account.accountDetails.telephone_numbers;
    this.account.accountDetails.telephone_numbers.forEach((element) => {
      this.telephone_numbers.push({
        number: element.number,
        type: element.is_mobile ? "Mobile" : element.number_type,
        customType: "",
        validation: null,
      });
    });
  },
  components: {},
  computed: {
    ...mapGetters({
      updateProfileComplete: "account/updateProfileComplete",
      updateProfileError: "account/updateProfileError",
      updateProfileErrorItems: "account/updateProfileErrorItems",
      updateProfileLoading: "account/updateProfileLoading",
      account: "account/all",
      checkInviteResponse: "invites/checkInviteResponse",
      acceptInviteSupervisorId: "invites/acceptInviteSupervisorId",
      petRelationshipError: "pets/petRelationshipError",
    }),
    first_namesErrors() {
      const errors = [];
      if (!this.$v.first_names.$dirty) return errors;
      !this.$v.first_names.maxLength &&
        errors.push("First name must be at most 20 characters long");
      !this.$v.first_names.required && errors.push("Name is required.");
      return errors;
    },
    surnameErrors() {
      const errors = [];
      if (!this.$v.surname.$dirty) return errors;
      !this.$v.surname.maxLength &&
        errors.push("Surname must be at most 20 characters long");
      !this.$v.surname.required && errors.push("Name is required.");
      return errors;
    },
    address_line_1Errors() {
      const errors = [];
      if (!this.$v.address_line_1.$dirty) return errors;
      !this.$v.address_line_1.maxLength &&
        errors.push("Address must be at most 50 characters long");
      return errors;
    },
    address_line_2Errors() {
      const errors = [];
      if (!this.$v.address_line_2.$dirty) return errors;
      !this.$v.address_line_2.maxLength &&
        errors.push("Address must be at most 50 characters long");
      return errors;
    },
    company_address_city_or_townErrors() {
      const errors = [];
      if (!this.$v.company_address_city_or_town.$dirty) return errors;
      !this.$v.company_address_city_or_town.maxLength &&
        errors.push("Address must be at most 50 characters long");
      return errors;
    },
    company_address_county_or_stateErrors() {
      const errors = [];
      if (!this.$v.company_address_county_or_state.$dirty) return errors;
      !this.$v.company_address_county_or_state.maxLength &&
        errors.push("Address must be at most 50 characters long");
      return errors;
    },
    address_postcodeErrors() {
      const errors = [];
      if (!this.$v.address_postcode.$dirty) return errors;
      !this.$v.address_postcode.valid && errors.push("Postcode must be valid");
      return errors;
    },
    telephone_valid() {
      return this.telephone_numbers.map((telephone, index) => {
        if (
          telephone.validation == null ||
          telephone.validation.valid == false
        ) {
          console.log(`Phone ${index + 1} is not valid`);
          return false;
        } else {
          console.log(`Phone ${index + 1} is valid`);
          return true;
        }
      });
    },
    telephoneErrors() {
      return this.telephone_numbers.map((telephone, index) => {
        let errors = [];
        if (
          telephone.validation == null ||
          telephone.validation.valid == false ||
          telephone.validation.valid == undefined
        ) {
          if (telephone.number === "") {
            errors.push("Please enter a phone number");
          } else {
            errors.push("This is not a valid phone number");
          }
        }
        return errors;
      });
    },

    formValid() {
      console.log("FORM $V", this.$v);
      return !this.$v.$invalid;
    },
  },
  methods: {
    ...mapActions({
      createInvite: "invites/createInvite",
      resetInviteForm: "invites/resetForm",
      resetPetsForm: "pets/resetForm",
      getDogs: "pets/getDogs",
      acceptInvite: "invites/acceptInvite",
      createPetRelationship: "pets/createPetRelationship",
      checkInvite: "invites/checkInvite",
    }),
    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let telephone_numbers = [];
        this.telephone_numbers.forEach((element) => {
          telephone_numbers.push({
            number: element.number,
            is_mobile: element.type === "Mobile" ? true : false,
            number_type: element.type,
          });
        });
        this.$store.dispatch("account/updateProfile", {
          first_names: this.first_names,
          surname: this.surname,
          address_line_1: this.address_line_1,
          address_line_2: this.address_line_2,
          address_line_3: this.company_address_city_or_town,
          address_line_4: this.company_address_county_or_state,
          address_postcode: this.address_postcode,
          telephone_numbers: telephone_numbers,
        });
      } else {
        console.log("invalid");
      }
    },
    addPhone() {
      this.telephone_numbers.push({
        number: null,
        type: "Mobile",
        customType: null,
        validation: null,
      });
    },
    removePhone(index) {
      this.telephone_numbers.splice(index, 1);
    },
    handleTypeChange(index, selectedType) {
      console.log("handleTypeChange", index, selectedType);
      this.telephone_numbers[index].type = selectedType;
      if (selectedType !== "Other") {
        this.telephone_numbers[index].customType = "";
      }
    },
    showValidationErrors() {
      // Check if $v is null before looping through its properties
      console.log("VALIDATION", this.$v);
      if (this.$v !== null) {
        for (const key in this.$v) {
          // Check if $v[key] is null before accessing its properties
          if (this.$v[key] !== null && this.$v[key].$invalid) {
            this.$v[key].$touch();
            console.log(`Validation error in ${key} component:`, this.$v[key]);
          } else {
            console.log(
              `No validation error in ${key} component:`,
              this.$v[key]
            );
          }
        }
      }
    },
  },
  mounted: function () {},
  watch: {
    formValid() {
      console.log("formValid", this.formValid);
    },
    address_postcode() {
      // Force uppercase
      this.address_postcode = this.address_postcode.toUpperCase();
    },
    telephone_numbers: {
      handler: function (val, oldVal) {
        console.log("telephone_numbers changed", val);
      },
      deep: true,
    },
    "$v.$invalid": {
      immediate: true,
      handler(newVal, oldVal) {
        console.log("invalid changed", newVal, oldVal);
        if (newVal !== oldVal) {
          this.showValidationErrors();
        }
      },
    },
  },
};
</script>

<style></style>
