<template>
  <v-row class="pa-0 ma-0 full-width-row">
    <v-col justify="center" align="center" class="pa-0 ma-0 full-width-col">
      <v-row class="background-image-row-main pt-1 px-0 mx-0" justify="end">
        <v-col
          cols="9"
          sm="7"
          md="6"
          align="center"
          class="pr-md-6 pr-lg-6 pr-0 py-0 my-0"
        >
          <v-row class="pt-sm-16 pt-6 pb-md-4 mr-0">
            <v-col>
              <span class="h1-white-text">
                Streamline and grow your pet business with Paw Portal.
              </span>
            </v-col>
          </v-row>
          <v-row
            justify="center"
            class="pb-md-4 pt-0 pt-sm-4 mt-0 mt-sm-4 mr-0"
            :style="$vuetify.breakpoint.width < 335 ? 'display: none;' : ''"
          >
            <v-col md="10" sm="10" cols="9">
              <span class="description-white-text">
                Paw Portal is your new affordable, modern software for
                hassle-free admin management.
              </span>
            </v-col>
          </v-row>
          <v-row class="pr-2 pr-sm-0 mr-0 pt-0 pt-sm-12">
            <v-col class="pa-1 pa-sm-0 mx-0">
              <v-btn
                v-if="$vuetify.breakpoint.xsOnly"
                color="#EA8C3C"
                class="white--text py-3 px-3"
                depressed
                x-small
                @click="$router.push('/earlyaccess')"
              >
                EARLY ACCESS
              </v-btn>
              <v-btn
                v-else
                color="#EA8C3C"
                class="white--text py-6 px-8 px-sm-6"
                depressed
                @click="$router.push('/earlyaccess')"
              >
                EARLY ACCESS
              </v-btn>
            </v-col>
            <v-col class="pa-1 pa-sm-0 mx-0">
              <v-btn
                v-if="$vuetify.breakpoint.xsOnly"
                color="#EA8C3C"
                class="white--text py-3 px-3"
                depressed
                x-small
                @click="scrollToElement('scroll-to-find-out-more')"
              >
                FIND OUT MORE
              </v-btn>
              <v-btn
                v-else
                color="#EA8C3C"
                class="white--text py-6 px-8 px-sm-6"
                depressed
                @click="scrollToElement('scroll-to-find-out-more')"
              >
                FIND OUT MORE
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Organisation made easy with Paw Portal -->
      <v-row class="py-6 my-6 pt-sm-6 mt-sm-1 pb-4 pb-sm-8 mr-0 pl-1">
        <div id="scroll-to-find-out-more"></div>
        <v-col>
          <h1>
            Organisation made <br />
            easy with Paw Portal
          </h1>
        </v-col>
      </v-row>
      <v-row class="mr-0 px-10 pt-sm-6">
        <v-col>
          <v-row justify="center">
            <v-col offset-md="2" md="8" offset-sm="2" cols="12" align="start">
              <span class="description">
                Say farewell to admin hassles and open the door to pursuing your
                passion and growing your business, supported by our all-in-one
                pet business organisation platform.
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>
                <v-col
                  offset-md="3"
                  md="8"
                  offset-sm="2"
                  cols="12"
                  align="start"
                >
                  <h4>Features include...</h4>
                </v-col>
              </v-row>
              <v-row
                class="description"
                v-for="feature in featuresInclude"
                :key="feature"
              >
                <v-col align="end" md="4" sm="3" cols="1" class="py-2 pr-6">
                  <v-icon color="black">mdi-paw</v-icon>
                </v-col>
                <v-col align="start" class="py-2">
                  <span class="description">
                    {{ feature }}
                  </span>
                </v-col>
              </v-row>
              <v-row class="py-4">
                <v-col align="start" offset-md="3" offset-sm="2" cols="12">
                  <v-btn
                    color="#EA8C3C"
                    class="white--text py-6"
                    depressed
                    block
                    v-if="$vuetify.breakpoint.xsOnly"
                    @click="scrollToElement('scroll-to-explore-features')"
                  >
                    EXPLORE FEATURES
                  </v-btn>
                  <v-btn
                    color="#EA8C3C"
                    class="white--text py-6"
                    depressed
                    v-else
                    @click="scrollToElement('scroll-to-explore-features')"
                  >
                    EXPLORE FEATURES
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="6" class="align-end d-none d-sm-flex">
          <v-img
            src="../assets/home/dog_looking_up_and_left.png"
            eager
            contain
          ></v-img>
        </v-col>
      </v-row>

      <!-- Does this sound like you? -->
      <v-row class="mr-0">
        <v-col class="py-6 my-6 py-sm-12 my-sm-12">
          <h1>Does this sound like <br />you?...</h1>
        </v-col>
      </v-row>
      <v-row justify="center" class="mr-0">
        <v-col
          md="6"
          sm="8"
          align="start"
          class="px-10 px-sm-0 px-md-6 pb-10 mb-0 pb-sm-12 mb-sm-12 pb-md-0 mb-md-0 pb-lg-0 mb-lg-0 d-flex flex-column"
        >
          <v-row justify="center" align="center" class="mr-0 pl-4 pl-sm-0">
            <v-col class="mt-n6">
              <v-row justify="center">
                <v-col lg="10" md="12">
                  <span class="description">
                    <b>1.</b> You’re a
                    <b>busy pet business owner,</b>
                    looking to streamline admin work.
                  </span>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col lg="10" md="12">
                  <span class="description">
                    <b>2.</b>

                    You want a UK-based
                    <b>modern platform with affordable solutions</b>, with
                    on-demand support.
                  </span>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col lg="10" md="12">
                  <span class="description">
                    <b>3.</b>
                    You want software that is
                    <b>easy-to-use for both you and your clients.</b>
                  </span>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col lg="10" md="12">
                  <span class="description">
                    <b>4.</b>
                    You want to
                    <b>
                      professionalise your marketing and branding without high
                      costs.</b
                    >
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col lg="6" md="6" class="pa-0">
          <v-img
            src="../assets/home/does_this_sound_like_you.jpg"
            eager
            contain
          ></v-img>
        </v-col>
      </v-row>

      <!-- Good news! -->
      <v-row
        class="mr-0 py-6 my-6 py-sm-12 my-sm-12 py-md-0 my-md-0 mt-md-3"
        align="center"
        justify="center"
      >
        <v-col md="6" class="pa-0" order="2" order-md="1">
          <v-img src="../assets/home/good_news_md.jpg" eager contain></v-img>
        </v-col>
        <v-col
          md="6"
          class="px-0 px-sm-2 px-md-6 pb-0 mb-0 pb-sm-12 mb-sm-12 pb-md-0 mb-md-0 pb-lg-0 mb-lg-0 d-flex"
          order="1"
          order-md="2"
          align="center"
          justify="center"
        >
          <v-row class="pb-10 pb-sm-1 px-10 px-sm-0 mx-0" justify="center">
            <v-col cols="12" md="12" sm="8" lg="10" align="start">
              <h1>Good news!</h1>
            </v-col>
            <v-col cols="12" md="12" sm="8" lg="10" align="start">
              <h2>We are here to help.</h2>
            </v-col>
            <v-col cols="12" md="12" sm="8" lg="10" align="start">
              <span class="description">
                With Paw Portal, you’ll gain the tools needed to effectively
                manage and grow your pet business, without spending hours on
                tasks you want to automate.
              </span>
            </v-col>
            <v-col cols="12" md="12" sm="8" lg="10" align="start">
              <span class="description">
                <b
                  >Save time, boost profits, streamline your admin and unleash
                  your pet business success</b
                >
                with Paw Portal. Join us today!
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Ready to get organised & transform your business? -->
      <v-row
        justify="center"
        class="mr-0 py-6 my-6 py-sm-10 my-sm-10 px-12 px-sm-2"
      >
        <v-col class="" md="6" sm="8">
          <h1>Ready to get organised & transform your business?</h1>
        </v-col>
      </v-row>
      <v-row class="mr-0 px-12 px-sm-2" justify="center">
        <v-col class="pb-16 pt-8">
          <v-btn
            color="#EA8C3C"
            class="white--text py-6"
            depressed
            block
            @click="$router.push('/earlyaccess')"
            v-if="$vuetify.breakpoint.xsOnly"
          >
            GET STARTED
          </v-btn>
          <v-btn
            color="#EA8C3C"
            class="white--text py-6"
            depressed
            @click="$router.push('/earlyaccess')"
            v-else
          >
            GET STARTED
          </v-btn>
        </v-col>
      </v-row>

      <!-- Interested, but want to find out more first? -->
      <v-row class="mr-0">
        <v-col
          style="background-color: #efdacb"
          class="pa-0 d-flex flex-column"
        >
          <v-row justify="center" align="center" class="px-0 mx-0">
            <v-col md="12" sm="8">
              <v-row class="pt-16 mt-4 pt-md-0 px-12 px-sm-2">
                <v-col align="left">
                  <h1>Interested, but want to find out more first?</h1>
                </v-col>
              </v-row>
              <v-row class="mt-0 pt-0 px-12 px-sm-2 py-md-0">
                <v-col align="left">
                  <span class="description">
                    We provide free full demos, Q&A’s or just someone to talk
                    through your business needs. We can help you understand what
                    you are looking for and tell you a bit more about us and our
                    services.
                  </span>
                </v-col>
              </v-row>
              <v-row
                class="mr-0 px-12 px-sm-2 py-md-0 mb-md-4"
                justify="center"
              >
                <v-col class="pb-16 pb-md-0 pt-8" align="start">
                  <v-btn
                    color="#EA8C3C"
                    class="white--text py-6"
                    depressed
                    block
                    @click="$router.push('/contactus')"
                    v-if="$vuetify.breakpoint.xsOnly"
                  >
                    FREE DEMO
                  </v-btn>
                  <v-btn
                    color="#EA8C3C"
                    class="white--text py-6"
                    depressed
                    @click="$router.push('/contactus')"
                    v-else
                  >
                    FREE DEMO
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="6" class="pa-0">
          <v-img
            src="../assets/home/interested_but_want_to_find_out_more_first.jpg"
            eager
            height="100%"
          ></v-img>
        </v-col>
      </v-row>

      <!-- Explore how Paw Portal can help you -->
      <v-row justify="center" class="mr-0">
        <div id="scroll-to-explore-features"></div>
        <v-col class="pt-16 mt-4" md="6">
          <h1>
            Explore how Paw Portal <br />
            can help you
          </h1>
        </v-col>
      </v-row>
      <v-row class="pa-10 mr-0" justify="center">
        <v-col cols="4" class="pa-0 d-flex flex-column">
          <v-row justify="center" class="flex-grow-1">
            <v-col cols="8" md="6">
              <v-img
                src="../assets/home/running_business.png"
                eager
                contain
              ></v-img>
            </v-col>
          </v-row>
          <v-row class="align-end">
            <v-col>
              <span class="description"> Run your business with ease </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4" class="pa-0 d-flex flex-column">
          <v-row justify="center" class="flex-grow-1">
            <v-col cols="8" md="6">
              <v-img
                src="../assets/home/growing_clients.png"
                eager
                contain
              ></v-img>
            </v-col>
          </v-row>
          <v-row class="align-end">
            <v-col>
              <span class="description"> Grow your client base </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4" class="pa-0 d-flex flex-column">
          <v-row justify="center" class="flex-grow-1">
            <v-col cols="8" md="6">
              <v-img
                src="../assets/home/marketing_and_branding.png"
                eager
                contain
              ></v-img>
            </v-col>
          </v-row>
          <v-row class="align-end">
            <v-col>
              <span class="description">
                Improve your marketing & branding
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- CONTACT US -->
      <v-row class="mr-0">
        <v-col
          style="background-color: #28675d"
          class="pa-0 d-flex flex-column"
        >
          <v-row justify="center" align="center" class="px-0 mx-0">
            <v-col md="7" sm="7">
              <v-row class="pt-16 mt-4 pt-md-0 px-12 px-sm-2">
                <v-col align="left">
                  <span class="h2-white-text">CONTACT US</span>
                </v-col>
              </v-row>
              <v-row class="mt-0 pt-0 px-12 px-sm-2 py-md-0">
                <v-col align="left">
                  <span class="description-white-text">
                    We’d love to hear from you. Please get in touch with us
                    using the form below and we’ll get back to you as soon as
                    possible.
                  </span>
                </v-col>
              </v-row>
              <v-row
                class="mr-0 px-12 px-sm-2 py-md-0 mb-md-4"
                justify="center"
              >
                <v-col class="pb-16 pb-md-0 pt-8" align="start">
                  <v-btn
                    color="#EA8C3C"
                    class="white--text py-6"
                    depressed
                    block
                    @click="$router.push('/contactus')"
                    v-if="$vuetify.breakpoint.xsOnly"
                  >
                    GET IN TOUCH
                  </v-btn>
                  <v-btn
                    color="#EA8C3C"
                    class="white--text py-6"
                    depressed
                    @click="$router.push('/contactus')"
                    v-else
                  >
                    GET IN TOUCH
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="6" class="pa-0">
          <v-img
            src="../assets/home/contact_us_footer.jpg"
            eager
            height="100%"
            v-if="$vuetify.breakpoint.mdAndUp"
          ></v-img>
          <v-img
            v-else
            src="../assets/home/contact_us_footer_xs.jpg"
            eager
            height="100%"
          ></v-img>
        </v-col>
      </v-row>

      <!-- <v-row class="pt-0 pt-sm-16 pb-0 mr-0" justify="start">
        <v-col
          class="d-flex flex-column"
          style="background-color: #28675d"
          cols="12"
          md="6"
          align="start"
          order="2"
          order-md="1"
        >
          <v-row justify="center" align="center" class="py-16 py-md-0">
            <v-col cols="7">
              <v-row>
                <v-col>
                  <span class="h3-white-text">CONTACT US</span>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col>
                  <span class="description-white-text">
                    We’d love to hear from you. Please get in touch with us
                    using the form below and we’ll get back to you as soon as
                    possible.
                  </span>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col>
                  <v-btn
                    color="#EA8C3C"
                    class="white--text py-6"
                    depressed
                    block
                    @click="$router.push('/contactus')"
                    v-if="$vuetify.breakpoint.xsOnly"
                  >
                    GET IN TOUCH
                  </v-btn>
                  <v-btn
                    color="#EA8C3C"
                    class="white--text py-6"
                    depressed
                    @click="$router.push('/contactus')"
                    v-else
                  >
                    GET IN TOUCH
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="pa-0 d-flex align-stretch"
          order="1"
          order-md="2"
        >
          <v-img
            src="../assets/home/contact_us_footer.jpg"
            eager
            height="100%"
            v-if="$vuetify.breakpoint.mdAndUp"
          ></v-img>
          <v-img
            v-else
            src="../assets/home/contact_us_footer_xs.jpg"
            eager
            height="100%"
          ></v-img>
        </v-col>
      </v-row> -->

      <!-- Footer -->
      <Footer />
    </v-col>
  </v-row>
  <!-- <li><a v-on:click="checkAuth" href="#">Check Auth</a></li> -->
</template>
<!-- <Calendar /> -->

<script>
import { mapGetters } from "vuex";
import Footer from "@/components/Footer.vue";
export default {
  name: "Home",
  components: {
    Footer,
  },
  data() {
    return {
      featuresInclude: [
        "Scheduling & Invoicing",
        "Integrated Payments",
        "Calendar Management",
        "Customer Management System",
        "Custom features for your clients",
        "Custom Marketing & Branding for your business",
      ],
      menus: [
        {
          title: "Overview",
          items: [
            { text: "Solutions", link: "/#" },
            { text: "Pricing & Plans", link: "/#" },
            { text: "How do I join?", link: "/#" },
            { text: "Customers", link: "/#" },
          ],
        },
        {
          title: "Company",
          items: [
            { text: "About Us", link: "/#" },
            { text: "Why Paw Portal?", link: "/#" },
            { text: "News", link: "/#" },
          ],
        },
        {
          title: "Support",
          items: [
            { text: "Contact Us", link: "/#" },
            { text: "Privacy Policy", link: "/#" },
            { text: "FAQs", link: "/#" },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/isAuthenticated",
      account: "account/accountDetails",
      // account: "getAccount",
      // dogs: "account/dogs",
    }),
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
  },
  methods: {
    getNameInitials() {
      try {
        const nameArray = this.user.name.split(" ");
        console.log("name length: ", nameArray.length);
        if (nameArray.length > 1) {
          // Two name value, return first initial and last initial
          return (
            nameArray[0].substring(0, 1).toUpperCase() +
            nameArray[1].substring(0, 1).toUpperCase()
          );
        } else {
          return (
            nameArray[0].substring(0, 1).toUpperCase() +
            nameArray[0].substring(1, 2).toUpperCase()
          );
        }
      } catch (error) {
        // console.log(error)
      }
    },
    scrollToElement(val) {
      const elementSelector = "#" + val;
      const element = document.querySelector(elementSelector);
      this.$vuetify.goTo(element, {
        duration: 500,
        offset: 0,
        easing: "easeInOutCubic",
      });
    },
    // ...mapActions({
    //   authLogout: "auth/logout",
    //   getAccount: "account/getAccount",
    //   getDogs: "account/getDogs",
    //   // checkAuthAction: "auth/checkAuth"
    // }),
    // async checkAuth() {
    //   await this.checkAutha
    // }
  },
  mounted: function () {
    // this.getAccount();
    // this.getDogs();
  },
  watch: {
    breakpoint: {
      deep: true,
      handler(breakpoint) {
        // breakpoint object contains details about the current breakpoint
        console.log("Breakpoint changed:", breakpoint.name);
      },
    },
  },
};
</script>

<style scoped>
.background-image-row-contact-us {
  background-image: url("../assets/home/contact_us_footer.jpg");
  background-color: #cbd9c6;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top center start;
  min-height: 58vw;
}
.background-image-row-main {
  background-image: url("../assets/home/dog_and_person_kiss_wide.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top center start;
  min-height: 50vw;
}

/* @media (max-width: 370px) {
  .description-white-text {
    font-size: 0.6rem !important;
  }
} */

@media (max-width: 650px) {
  .background-image-row-contact-us {
    background-image: url("../assets/home/contact_us_footer.jpg");
    min-height: 58vw;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .background-image-row-main {
    background-image: url("../assets/home/dog_and_person_kiss_sm.jpg");
    min-height: 90vw;
  }
  .background-image-row-contact-us {
    background-image: url("../assets/home/contact_us_footer.jpg");
    min-height: 58vw;
  }
}

@media (max-width: 1000px) {
  .background-image-row-main {
    background-image: url("../assets/home/dog_and_person_kiss_sm.jpg");
    min-height: 90vw;
  }
  .background-image-row-contact-us {
    background-image: url("../assets/home/contact_us_footer.jpg");
    min-height: 58vw;
  }
  .description-footer {
    font-size: 0.8rem;
  }
}

.no-link-decoration {
  text-decoration: none;
  color: inherit; /* or specify a specific color */
}

/* .full-width-row {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

.full-width-col {
  width: 100%;
  max-width: 100%;
} */
</style>
