<template>
  <v-app style="background: white" v-if="!pageLoading">
    <!--  && pawPortalAccount == null) ||
        (authenticated && pawPortalAccount != null) -->
    <v-app-bar
      v-if="!authenticated"
      app
      flat
      clipped-left
      color="#cbd9c6"
      :height="headerHeight"
      v-cloak
    >
      <template
        v-if="this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm"
      >
        <v-row justify="center">
          <v-col align="start" cols="2">
            <v-app-bar-nav-icon
              @click.stop="showNavigationBar = !showNavigationBar"
            ></v-app-bar-nav-icon>
          </v-col>
          <v-col align="center">
            <router-link :to="'/'">
              <v-img
                class="mt-1 ml-n16 clickable-image"
                contain
                max-width="120"
                src="https://pawportal.blob.core.windows.net/images/Dog_Top_Black.png"
              ></v-img>
            </router-link>
          </v-col>
        </v-row>
      </template>
      <template
        v-if="!this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm"
      >
        <v-row justify="center" v-cloak>
          <v-col align="start" lg="2" md="2" sm="2">
            <router-link :to="'/'">
              <v-img
                contain
                class="ml-1 mb-2"
                max-width="120"
                src="https://pawportal.blob.core.windows.net/images/Dog_Top_Black.png"
              ></v-img>
            </router-link>
          </v-col>
          <v-col
            align="center"
            lg="5"
            offset-lg="1"
            offset-md="1"
            offset-sm="3"
          >
            <v-btn text to="/"
              ><span class="header-btn-font"> ABOUT US </span></v-btn
            >
            <v-btn text to="/contactus"
              ><span class="header-btn-font"> CONTACT </span></v-btn
            >
          </v-col>
          <v-col v-if="!isDevelopment" align="end" lg="3" md="3" sm="5">
            <v-btn
              rounded
              color="#012130"
              to="/earlyaccess"
              v-if="!authenticated"
              ><span class="header-btn-font-white">EARLY ACCESS</span></v-btn
            >
          </v-col>
          <v-col v-if="isDevelopment" align="end" lg="3" md="3" sm="5">
            <v-menu offset-y v-if="!authenticated">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  rounded
                  color="#012130"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-4"
                  v-if="!authenticated"
                  ><span class="header-btn-font-white">REGISTER</span></v-btn
                >
              </template>
              <v-list>
                <v-list-item
                  :to="{
                    name: 'Register',
                    query: { t: 'PET_SUPERVISOR' },
                  }"
                >
                  <v-list-item-title>Register as Business</v-list-item-title>
                </v-list-item>
                <v-list-item
                  :to="{
                    name: 'Register',
                    query: { t: 'PET_OWNER' },
                  }"
                >
                  <v-list-item-title>Register as Dog Owner</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn outlined to="/login" v-if="!authenticated"
              ><span class="header-btn-font">LOGIN</span></v-btn
            >
            <div v-if="authenticated">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    color="blue darken-1 shrink"
                    size="38"
                    v-bind="attrs"
                    v-on="on"
                    >{{ getNameInitials() }}</v-avatar
                  >
                </template>
                <v-list>
                  <v-list-item to="/profile">
                    <v-list-item-title>My Profile</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-on:click="SignOut">
                    <v-list-item-title>Logout</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
        </v-row>
      </template>
    </v-app-bar>
    <v-app-bar
      v-if="
        authenticated &&
        pawPortalAccount != null &&
        (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm)
      "
      app
      flat
      clipped-left
      color="#cbd9c6"
      :height="headerHeight"
    >
      <template>
        <v-row justify="center">
          <v-col align="start" cols="2">
            <v-app-bar-nav-icon
              @click.stop="showNavigationBar = !showNavigationBar"
            ></v-app-bar-nav-icon>
          </v-col>
          <v-col align="center">
            <router-link :to="'/'">
              <v-img
                to="/"
                :style="{ 'margin-left': '-7rem' }"
                contain
                max-width="120"
                src="https://pawportal.blob.core.windows.net/images/Dog_Top_Black.png"
              ></v-img>
            </router-link>
          </v-col>
        </v-row>
      </template>
    </v-app-bar>
    <v-navigation-drawer
      v-if="authenticated && pawPortalAccount != null"
      app
      v-model="showNavigationBar"
      clipped
      hover
      :permanent="!this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm"
      color="#cbd9c6"
      class="d-flex flex-column pa-0"
    >
      <v-row>
        <v-col>
          <v-col align="center">
            <router-link :to="'/'">
              <v-img
                to="/"
                class="mt-4"
                contain
                max-width="120"
                src="https://pawportal.blob.core.windows.net/images/Dog_Top_Black.png"
              ></v-img>
            </router-link>
          </v-col>
        </v-col>
      </v-row>

      <v-list nav dense class="flex-grow-1 overflow-auto">
        <v-list-item-group v-model="group">
          <v-list-item
            class="nav-bar-font nav-bar-hover"
            v-for="item in navItemsVisible"
            :key="item.title"
            :to="item.to"
          >
            <v-list-item-icon>
              <v-icon color="#012130">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              item.title ? item.title.toUpperCase() : ""
            }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-row no-gutters justify="center" class="footer pa-4">
        <v-col align="center">
          <v-btn
            plain
            text
            v-on:click="SignOut"
            v-if="authenticated"
            class="nav-bottom-row pa-0 ma-0"
          >
            <v-icon color="#012130">mdi-logout</v-icon> Sign Out
          </v-btn>
          <p class="nav-bar-font">Ver 1.0</p>
        </v-col>
      </v-row>
    </v-navigation-drawer>

    <!-- Public  -->
    <v-navigation-drawer
      v-if="!authenticated && !this.$vuetify.breakpoint.mdAndUp"
      app
      v-model="showNavigationBar"
      clipped
      hover
      :permanent="false"
      color="#cbd9c6"
      class="d-flex flex-column pa-0"
    >
      <v-row>
        <v-col>
          <v-col align="center">
            <router-link :to="'/'">
              <v-img
                class="mt-4"
                contain
                max-width="120"
                src="https://pawportal.blob.core.windows.net/images/Dog_Top_Black.png"
              ></v-img>
            </router-link>
          </v-col>
        </v-col>
      </v-row>

      <v-list nav dense class="flex-grow-1 overflow-auto">
        <v-list-item-group v-model="group">
          <v-list-item
            class="nav-bar-font nav-bar-hover"
            v-for="item in navItemsVisible"
            :key="item.title"
            :to="item.to"
          >
            <v-list-item-icon>
              <v-icon color="#012130">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              item.title ? item.title.toUpperCase() : ""
            }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-row no-gutters justify="center" class="footer pa-4">
        <v-col align="center">
          <v-btn
            plain
            text
            v-on:click="SignOut"
            v-if="authenticated"
            class="nav-bottom-row pa-0 ma-0"
          >
            <v-icon color="#012130">mdi-logout</v-icon> Sign Out
          </v-btn>
          <p class="nav-bar-font">Ver 1.0</p>
        </v-col>
      </v-row>
    </v-navigation-drawer>

    <v-main>
      <v-row class="pa-0 ma-0" v-if="displayBanner">
        <v-col class="pa-0 ma-0">
          <v-alert
            :dismissible="bannerDismissible"
            :type="bannerType"
            class="ma-0"
          >
            {{ bannerMessage }}
          </v-alert>
        </v-col>
      </v-row>
      <!-- Banner for selecting and showing company -->
      <v-row
        class="pa-0 ma-0"
        v-if="pawPortalAccount && pawPortalAccount.type === 'PET_SUPERVISOR'"
      >
        <v-col class="pa-0 ma-0">
          <v-sheet
            app
            color="#F4F8F2"
            dense
            class="px-5 py-4 d-flex align-center"
          >
            <v-spacer></v-spacer>
            <div style="text-align: right">
              <span class="header-company-font">{{
                selectedCompany.name ? selectedCompany.name.toUpperCase() : ""
              }}</span>
              <br />
              <span class="header-employee-font">{{
                pawPortalAccount.first_names
              }}</span>
              <br />
              <span
                v-if="companies.length > 1"
                class="description-small pa-0 ma-0"
                style="cursor: pointer; color: blue"
                @click="setShowSelectCompany(true)"
                >Switch Company</span
              >
            </div>
            <v-avatar size="45" class="ml-4">
              <img
                :src="
                  selectedCompany.logo
                    ? selectedCompany.logo
                    : 'https://pawportal.blob.core.windows.net/images/PP_Black.png'
                "
                :alt="selectedCompany.name ? selectedCompany.name : ''"
              />
            </v-avatar>
          </v-sheet>
        </v-col>
      </v-row>
      <v-dialog
        v-model="showSelectedCompanyDialog"
        max-width="400"
        :persistent="selectedCompany.length == 0"
      >
        <v-card>
          <v-card-title>Select a company</v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item
                v-for="company in companies"
                :key="company.id"
                @click="setCompany(company)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ company.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Banner for showing pet owner information -->
      <div class="d-flex flex-column">
        <v-row
          class="pa-0 ma-0"
          v-if="pawPortalAccount && pawPortalAccount.type === 'PET_OWNER'"
        >
          <v-col class="pa-0 ma-0">
            <v-sheet
              app
              color="#F4F8F2"
              dense
              class="px-5 py-6 d-flex align-center"
            >
              <v-spacer></v-spacer>
              <div style="text-align: right">
                <span class="header-company-font">{{
                  (pawPortalAccount.first_names || "") +
                  (pawPortalAccount.surname
                    ? ` ${pawPortalAccount.surname}`
                    : "")
                }}</span>

                <br />
                <span class="header-employee-font">{{
                  pawPortalAccount.email
                }}</span>
              </div>
              <v-avatar size="32" class="ml-4">
                <img
                  :src="
                    pawPortalAccount.profile_picture
                      ? pawPortalAccount.profile_picture
                      : 'https://pawportal.blob.core.windows.net/images/PP_Black.png'
                  "
                  :alt="
                    pawPortalAccount.first_names
                      ? pawPortalAccount.first_names
                      : 'Profile Picture'
                  "
                />
              </v-avatar>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0 flex-grow-1">
          <v-col class="pa-0 ma-0 fill-height">
            <router-view class="ma-0 pa-0"></router-view>
          </v-col>
        </v-row>
      </div>
    </v-main>
    <v-overlay
      opacity="0.6"
      :dark="false"
      v-if="displayCookieBanner && !excludeCookieBanner"
    >
      <v-main v-if="displayCookieManagePreferences">
        <v-row justify="center">
          <v-col cols="12" lg="8" md="10" sm="10">
            <v-sheet
              color="#FCE8D9"
              style="max-height: 80vh; overflow-y: auto"
              data-cy="cookieOverlay"
            >
              <v-row justify="center" class="ma-0 pa-0 pb-6 pt-0 pt-sm-6">
                <v-col cols="12" sm="10">
                  <v-row>
                    <v-col align="center">
                      <v-img
                        eager
                        contain
                        max-width="120"
                        src="https://pawportal.blob.core.windows.net/images/Dog_Top_Black.png"
                      ></v-img>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0 my-0">
                      <b>Manage Cookies</b>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-2 my-0">
                      <span class="description-small"
                        >When you visit any website, it may store or retrieve
                        information on your browser, mostly in the form of
                        cookies. This information might be about you, your
                        preferences or your device and is mostly used to make
                        the site work as you expect it to. Some cookies are
                        essential to provide you with a secure, well-functioning
                        and reliable website. Optional cookies give you a more
                        personalised web experience, depending on your usage. We
                        respect your right to privacy, you can choose not to
                        allow some types of cookies. Click on the different
                        headings below to find out more and change the default
                        settings. However, blocking some types of cookies may
                        impact your experience of the site and the services we
                        are about to offer.
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0 mb-2">
                      <a
                        :href="$router.resolve({ path: '/tos' }).href"
                        target="_blank"
                      >
                        <span
                          class="description-small"
                          style="color: #9aa5b6; cursor: pointer"
                          @click="displayCookieManagePreferences = true"
                          ><u>More Information</u>
                        </span>
                      </a>
                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-col align="center" cols="12" sm="6">
                      <v-btn
                        color="#EA8C3C"
                        depressed
                        :block="this.$vuetify.breakpoint.xs"
                        class="white--text py-5 px-14 mr-6"
                        @click="setAllowTrackingCookies(true)"
                        >ALLOW ALL COOKIES</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="mt-0 mt-sm-2">
                      <b>Manage Preferences</b>
                    </v-col>
                  </v-row>
                  <v-row class="mx-0">
                    <v-col
                      style="
                        border: #9aa5b6;
                        border-style: solid;
                        border-width: 0.01rem;
                      "
                    >
                      <v-row class="d-flex align-center">
                        <v-col class="d-flex align-center py-4" cols="8">
                          <v-icon
                            class="mr-2"
                            @click="
                              cookieExpansion.expandNessessary =
                                !cookieExpansion.expandNessessary
                            "
                          >
                            {{
                              cookieExpansion.expandNessessary
                                ? "mdi-minus"
                                : "mdi-plus"
                            }}
                          </v-icon>
                          <span class="b-small"
                            >Strictly Nessessary Cookies</span
                          >
                        </v-col>
                        <v-col align="end" cols="4">
                          <span class="description-small" style="color: #ef851c"
                            >Always Active</span
                          >
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="cookieExpansion.expandNessessary"
                        class="pt-0 mt-0"
                      >
                        <v-col class="pl-14 pt-0 mt-0">
                          <span class="description-small"
                            >Strictly Necessary Cookies are essential for the
                            basic functionality and security of this website.
                            They enable core features such as page navigation,
                            login, and access to secure areas of the site.</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="mx-0">
                    <v-col
                      style="
                        border: #9aa5b6;
                        border-style: solid;
                        border-top-style: none;
                        border-width: 0.01rem;
                      "
                    >
                      <v-row class="d-flex align-center">
                        <v-col class="d-flex align-center">
                          <v-icon
                            class="mr-2"
                            @click="
                              cookieExpansion.expandPerformance =
                                !cookieExpansion.expandPerformance
                            "
                          >
                            {{
                              cookieExpansion.expandPerformance
                                ? "mdi-minus"
                                : "mdi-plus"
                            }}
                          </v-icon>
                          <span class="b-small">Performance Cookies</span>
                        </v-col>
                        <v-col
                          cols="1"
                          class="pa-0 ma-0 d-flex align-center justify-end"
                        >
                          <v-switch
                            color="#EF851C"
                            class="pa-0 ma-0 mt-4 mr-4"
                            v-model="allowTrackingCookies"
                          ></v-switch>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="cookieExpansion.expandPerformance"
                        class="pt-0 mt-0"
                      >
                        <v-col class="pl-14 pt-0 mt-0">
                          <span class="description-small"
                            >Performance cookies gather anonymous metrics to
                            enhance website functionality and user experience.
                            These cookies assist us in identifying and resolving
                            issues on the site.</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="pr-0 mr-0">
                    <v-col align="end" class="pr-0 mr-0">
                      <v-btn
                        color="#EA8C3C"
                        depressed
                        :block="this.$vuetify.breakpoint.xs"
                        class="white--text py-5 px-14 mr-0"
                        @click="setAllowTrackingCookies(allowTrackingCookies)"
                        >CONFIRM CHOICES</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>
      </v-main>
      <v-footer app class="ma-0 pa-0" v-if="!displayCookieManagePreferences">
        <v-row class="pa-0 ma-0">
          <v-col class="pa-0 ma-0">
            <v-sheet color="#FCE8D9" style="max-height: 80vh; overflow-y: auto">
              <v-row
                justify="start"
                justify-sm="center"
                align-content="center"
                class="ma-0 pa-0 py-6"
              >
                <v-col align="center" cols="12" sm="2" class="ma-0">
                  <v-img
                    src="./assets/cookie_banner/cookie.png"
                    eager
                    contain
                    height="60px"
                  ></v-img>
                </v-col>
                <v-col
                  :class="this.$vuetify.breakpoint.xs ? 'text-center' : ''"
                  cols="12"
                  sm="6"
                >
                  <span class="description">{{ cookieBannerMessage }}</span>
                  <br />
                  <span
                    class="description-small"
                    style="color: #ef851c; cursor: pointer"
                    @click="displayCookieManagePreferences = true"
                    ><u>Manage Preferences</u></span
                  >
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  class="d-flex align-center justify-end ml-6 ml-sm-0"
                >
                  <v-btn
                    color="#EA8C3C"
                    depressed
                    :block="this.$vuetify.breakpoint.xs"
                    class="white--text py-5 px-16 mr-6"
                    @click="setAllowTrackingCookies(true)"
                    >OKAY</v-btn
                  >
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>
      </v-footer>
    </v-overlay>
  </v-app>
  <v-app v-else> Loading.... </v-app>
</template>

<script>
// import Toolbar from "./components/NavigationHeader";
// import SideToolbar from "./components/NavigationBar";

import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    // Toolbar,
    // SideToolbar,
  },
  data() {
    return {
      // process: process,
      // account: undefined,
      cookieExpansion: {
        expandNessessary: false,
        expandPerformance: false,
      },
      cookieBannerMessage:
        "We use third-party cookies to personalise content, ads and improve site performance.",
      allowTrackingCookies: false,
      showNavigationBar: false,
      showNavBar: false,
      group: null,
      pageLoading: true,
      displayCookieManagePreferences: false,
      navItems: [
        {
          title: "Home",
          icon: "mdi-home-outline",
          to: "/",
          condition: () => !this.pawPortalAccount,
        },
        {
          title: "Home / Dashboard",
          icon: "mdi-home-outline",
          to: "/dashboard",
          condition: () => this.pawPortalAccount,
        },
        // {
        //   title: "Invite Code",
        //   icon: "mdi-barcode-scan",
        //   to: "/invitecode",
        //   condition: () => !this.pawPortalAccount,
        // },
        {
          title: "Contact Us",
          icon: "mdi-email-outline",
          to: "/contactus",
          condition: () => true,
        },
        {
          title: "Request Booking",
          icon: "mdi-plus",
          to: "/bookings/create",
          condition: () =>
            this.pawPortalAccount && this.pawPortalAccount.type === "PET_OWNER",
        },
        {
          title: "Bookings",
          icon: "mdi-calendar-check",
          to: "/bookings/overview",
          condition: () => this.pawPortalAccount,
        },
        {
          title: "Customer Invites",
          icon: "mdi-barcode-scan",
          to: "/invites/overview",
          condition: () =>
            this.pawPortalAccount &&
            this.pawPortalAccount.type === "PET_SUPERVISOR",
        },
        {
          title: "Pending Invites",
          icon: "mdi-barcode-scan",
          to: "/invites/overview",
          condition: () =>
            this.pawPortalAccount &&
            this.pawPortalAccount.type === "PET_OWNER" &&
            this.pendingInvites.length > 0,
        },
        {
          title: "Your Pets",
          icon: "mdi-paw",
          to: "/pets",
          condition: () =>
            this.pawPortalAccount && this.pawPortalAccount.type === "PET_OWNER",
        },
        {
          title: "Calendar",
          icon: "mdi-calendar-check",
          to: "/calendar",
          condition: () => this.pawPortalAccount,
        },
        {
          title: "My Services",
          icon: "mdi-cogs",
          to: "/services/overview",
          condition: () =>
            this.pawPortalAccount &&
            this.pawPortalAccount.type === "PET_SUPERVISOR",
        },
        {
          title: "My Customers",
          icon: "mdi-account-group",
          to: "/company/customers",
          condition: () =>
            this.pawPortalAccount &&
            this.pawPortalAccount.type === "PET_SUPERVISOR",
        },
        {
          title: "Update Company",
          icon: "mdi-storefront-edit",
          to: "/company/edit",
          condition: () =>
            this.pawPortalAccount &&
            this.pawPortalAccount.type === "PET_SUPERVISOR",
        },
        {
          title: "My Companies",
          icon: "mdi-account-group",
          to: "/company/companies",
          condition: () =>
            this.pawPortalAccount && this.pawPortalAccount.type === "PET_OWNER",
        },
        {
          title: "Profile",
          icon: "mdi-account",
          to: "/profile",
          condition: () => this.pawPortalAccount,
        },
        {
          title: "Early Access",
          icon: "mdi-account-plus",
          to: "/earlyaccess",
          condition: () => !this.authenticated && !this.isDevelopment,
        },
        {
          title: "Create an Account",
          icon: "mdi-account-plus",
          to: "/register",
          condition: () => !this.authenticated && this.isDevelopment,
        },
        {
          title: "Login",
          icon: "mdi-account",
          to: "/login",
          condition: () => !this.authenticated && this.isDevelopment,
        },
        {
          title: "Complete registration",
          icon: "mdi-account",
          to: "/register",
          condition: () => this.authenticated && !this.pawPortalAccount,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      account: "account/accountDetails",
      authenticated: "auth/isAuthenticated",
      user: "auth/user",
      loginFailed: "auth/loginFailed",
      pawPortalAccount: "account/accountDetails",
      displayBanner: "core/displayBanner",
      bannerMessage: "core/bannerMessage",
      bannerType: "core/bannerType",
      bannerDismissible: "core/bannerDismissible",
      loading: "core/loading",
      pendingInvites: "invites/invites",
      displayCookieBanner: "core/displayCookieBanner",
      selectedCompany: "company/selectedCompany",
      showSelectCompany: "company/showSelectCompany",
    }),
    showSelectedCompanyDialog: {
      get() {
        return this.showSelectCompany;
      },
      set(value) {
        this.setShowSelectCompany(value);
      },
    },
    isDevelopment() {
      if (process.env.VUE_APP_IS_DEVELOPMENT === "true") {
        console.log("IS DEVELOPMENT");
      }
      return process.env.VUE_APP_IS_DEVELOPMENT === "true";
    },
    excludeCookieBanner() {
      let routerPaths = ["/tos", "/privacy"];
      return routerPaths.includes(this.$route.path);
    },
    companies() {
      return this.$store.getters["company/companies"];
    },
    headerHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "60";
        case "sm":
          return "100";
        case "md":
          return "100";
        case "lg":
          return "100";
        case "xl":
          return "100";
        default:
          return "100";
      }
    },
    navBarFooter() {
      console.log("navBarFooter", this.$vuetify.breakpoint.name);
      if (this.$vuetify.breakpoint.xsOnly) {
        return 10 + this.visibleNavItemsCount * 10;
      } else if (this.$vuetify.breakpoint.smOnly) {
        return 10 + this.visibleNavItemsCount * 10;
      } else if (this.$vuetify.breakpoint.mdOnly) {
        return 12 + this.visibleNavItemsCount * 10;
      } else if (this.$vuetify.breakpoint.lgOnly) {
        return 12 + this.visibleNavItemsCount * 10;
      } else if (this.$vuetify.breakpoint.xlOnly) {
        return 12 + this.visibleNavItemsCount * 10;
      } else {
        return 12 + this.visibleNavItemsCount * 10;
      }
    },
    visibleNavItemsCount() {
      return this.navItems.filter((item) => item.condition()).length;
    },
    navItemsVisible() {
      return this.navItems.filter((item) => item.condition());
    },
  },
  async created() {
    // const accounts = this.$store.state.msalInstance.getAllAccounts();
    // if (accounts.length > 0) {
    //   console.log("We found an account, calling refreshToken");
    //   await this.refreshToken();
    //   console.log("Fresh token finished running.");
    // }
  },
  methods: {
    ...mapActions({
      SignOut: "auth/signOut",
      SignIn: "auth/signIn",
      refreshToken: "auth/refreshToken",
      getCompanies: "company/getCompanies",
      setCompany: "company/setCompany",
      getCustomers: "company/getCustomers",
      getServices: "services/getServices",
      setAllowTrackingCookies: "core/setAllowTrackingCookies",
      checkAllowTrackingCookies: "core/checkAllowTrackingCookies",
      getCompanyInvites: "invites/getCompanyInvites",
      setShowSelectCompany: "company/setShowSelectCompany",
    }),
    getNameInitials() {
      // Check if they have registered.
      if (this.pawPortalAccount != null) {
        try {
          return (
            this.pawPortalAccount.first_names.substring(0, 1).toUpperCase() +
            this.pawPortalAccount.surname.substring(0, 1).toUpperCase()
          );
        } catch (error) {
          // console.log(error)
        }
      }
      //Otherwise get their B2C name
      try {
        const nameArray = this.user.name.split(" ");
        if (nameArray.length > 1) {
          // Two name value, return first initial and last initial
          return (
            nameArray[0].substring(0, 1).toUpperCase() +
            nameArray[1].substring(0, 1).toUpperCase()
          );
        } else {
          return (
            nameArray[0].substring(0, 1).toUpperCase() +
            nameArray[0].substring(1, 2).toUpperCase()
          );
        }
      } catch (error) {
        // console.log(error)
      }
    },
  },
  async created() {
    // Dispatch checkAuth
    await this.$store.dispatch("auth/checkAuth");
    this.pageLoading = false;
  },
  async mounted() {
    this.checkAllowTrackingCookies();
  },
  // Watch for authenticated
  watch: {
    authenticated: async function (val) {
      console.log("Authenticated changed: ", val);
    },
    account(val) {
      console.log("Account changed: ", val);
    },
    showNavigationBar(val) {
      console.log("Show navigation bar changed: ", val);
      console.log("authenticated: ", this.authenticated);
    },
    selectedCompany(val) {
      if (val == null) {
        this.setShowSelectCompany(true);
      }
    },
  },
};
</script>

<style>
@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url("./fonts/poppins/Poppins-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url("./fonts/poppins/Poppins-Bold.otf") format("opentype");
}

.header-btn-font {
  font-family: "Poppins-Regular";
  font-size: 0.9rem;
  color: #012130;
  letter-spacing: 0.06rem;
}
.nav-bar-font {
  font-family: "Poppins-Bold";
  font-size: 0.9rem;
  font-weight: 10;
  color: #012130;
  letter-spacing: 0.06rem;
}
.nav-bar-hover:hover {
  background-color: #f4f8f2;
}
.header-company-font {
  font-family: "Poppins-Bold";
  font-size: 0.9rem;
  font-weight: 10;
  color: #012130;
  letter-spacing: 0.06rem;
}
.header-employee-font {
  font-family: "Poppins-Regular";
  font-size: 0.8rem;
  font-weight: 10;
  color: #676e72;
  letter-spacing: 0.06rem;
}
.header-btn-font-white {
  font-family: "Poppins-Regular";
  font-size: 0.9rem;
  color: #ffffff;
}
h1 {
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  font-family: "Poppins-Bold";
}
.h1 {
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  font-family: "Poppins-Bold";
}
.h1-white-text {
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  font-family: "Poppins-Bold";
  color: #ffffff;
}
.h1-orange-text {
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
  font-family: "Poppins-Bold";
  color: #ef851c;
}

/* Smaller screens (e.g., phones) */
@media (max-width: 600px) {
  /* .description-white-text {
    font-size: 0.7rem !important;
    letter-spacing: 0.05rem !important;
  } */
  h1 {
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
  }
  .h1-white-text {
    font-size: 1rem;
    letter-spacing: 0.1rem;
  }
  h4 {
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
  }
  .h3-white-text {
    font-size: 0.9rem !important;
  }
}

/* Medium screens (e.g., tablets) */
@media (min-width: 601px) and (max-width: 1024px) {
  .h1-white-text {
    font-size: 1.75rem;
  }
}

/* Larger screens (e.g., laptops, desktops) */
@media (min-width: 1025px) {
  .h1-white-text {
    font-size: 1.75rem;
  }
}

h2 {
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.12rem;
  font-family: "Poppins-Bold";
}
.h2-white-text {
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.12rem;
  font-family: "Poppins-Bold";
  color: #ffffff;
}
h3 {
  font-size: 1.4rem;
  font-weight: 500;
  font-family: "Poppins-Bold";
}
.h3-white-text {
  font-size: 1.4rem;
  font-weight: 500;
  font-family: "Poppins-Bold";
  color: #ffffff;
}
h4 {
  font-size: 1.3rem;
  letter-spacing: 0.12rem;
  font-weight: 500;
  font-family: "Poppins-Bold";
}
.h4-white-text {
  font-size: 1.3rem;
  letter-spacing: 0.12rem;
  font-weight: 500;
  font-family: "Poppins-Bold";
  color: #ffffff;
}
.h4-orange-text {
  font-size: 1.3rem;
  letter-spacing: 0.12rem;
  font-weight: 500;
  font-family: "Poppins-Bold";
  color: #ef851c;
}
h5 {
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.12rem;
  font-weight: 500;
  font-family: "Poppins-Bold";
}
.h5-white-text {
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.12rem;
  font-weight: 500;
  font-family: "Poppins-Bold";
  color: #ffffff;
}
p {
  font-size: 1rem;
  font-weight: 300;
  font-family: "Poppins-Regular";
}
.description {
  font-size: 1rem;
  font-weight: 300;
  font-family: "Poppins-Regular";
}
.description-white-text {
  font-size: 1rem;
  font-weight: 300;
  font-family: "Poppins-Regular";
  color: #ffffff;
}
.description-small {
  font-size: 0.8rem;
  font-weight: 300;
  font-family: "Poppins-Regular";
}
.description-small-white {
  font-size: 0.8rem;
  font-weight: 300;
  font-family: "Poppins-Regular";
  color: #ffffff;
}
.alert {
  font-size: 1.5rem;
  font-weight: 300;
  color: red;
}
.alert-small {
  font-size: 1rem;
  font-weight: 300;
  color: red;
}
b {
  font-size: 1rem;
  font-weight: 500;
  font-family: "Poppins-Bold";
}
.b2 {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  font-family: "Poppins-Bold";
}
.b-small {
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 0.01rem;
  font-family: "Poppins-Bold";
}
.my-nav-drawer {
  display: flex;
  flex-direction: column;
}

.nav-bottom-row {
  margin-top: auto;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.button-white-text {
  padding: 1rem 1.46875rem !important; /* Vertical 21.5px/16 Horizontal 23.5px/16 */
  text-align: center !important;
  letter-spacing: 0.075rem !important; /* 1.2px / 16 */
  /* font-style: normal !important; */
  /* font-weight: bold !important; */
  font-size: 0.8rem !important; /* 24px / 16 */
  line-height: 2.1875rem !important; /* 35px / 16 */
  font-family: "Poppins-Regular" !important;
  color: #ffffff !important;
  background-color: #ef851c !important;
  border-radius: 0.4rem !important; /* 16px / 16 */
}
</style>
