<template>
  <v-row>
    <v-col class="pa-0">
      <v-container fluid>
        <OverviewOwner v-if="pawPortalAccount.type == 'PET_OWNER'" />
        <OverviewSupervisor v-if="pawPortalAccount.type == 'PET_SUPERVISOR'" />
      </v-container>
    </v-col>
  </v-row>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import OverviewOwner from "../../components/profile/OverviewOwner";
import OverviewSupervisor from "../../components/profile/OverviewSupervisor";

export default {
  name: "Profile",
  data() {
    return {
      tab: null,
    };
  },
  components: {
    OverviewOwner,
    OverviewSupervisor,
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/isAuthenticated",
      user: "auth/user",
      pawPortalAccount: "account/accountDetails",
      account: "account/all",
    }),
  },
  methods: {
    ...mapActions({
      getAccount: "account/getAccount",
    }),
  },
  mounted: function () {},
};
</script>

<style></style>
