import axios from "axios";

export default {
    namespaced: true,

    state: {
        services: {
            services: [],
            loading: false,
            error: false,
        },
        create: {
            loading: false,
            complete: false,
            error: false,
            errorItems: [],
            response: [],
        },
        delete: {
            loading: false,
            complete: false,
            error: false,
            errorItems: [],
        },
        update: {
            loading: false,
            complete: false,
            error: false,
            errorItems: [],
            response: [],
        },
    },

    mutations: {
        RESET_STATE(state) {
            console.log("RESET_STATE services called");
            state.services.services = [];
        },
        RESET_FORM(state) {
            console.log("RESET_FORM services called");
            state.services.loading = false;
            state.create.loading = false;
            state.create.complete = false;
            state.create.error = false;
            state.create.errorItems = [];
            state.create.response = [];
            state.services.error = false;
            state.delete.loading = false;
            state.delete.complete = false;
            state.delete.error = false;
            state.delete.errorItems = [];
            state.update.loading = false;
            state.update.complete = false;
            state.update.error = false;
            state.update.errorItems = [];
        },
        SET_SERVICES(state, val) {
            state.services.services = val;
        },
        SET_SERVICES_LOADING(state, val) {
            state.services.loading = val;
        },
        SET_CREATE_LOADING(state, val) {
            state.create.loading = val;
        },
        SET_CREATE_COMPLETE(state, val) {
            state.create.complete = val;
        },
        SET_CREATE_ERROR(state, val) {
            state.create.error = true;
            state.create.errorItems = [];
            try {
                val.detail.forEach((element) => {
                    const item = {
                        item:
                            element.loc[1].charAt(0).toUpperCase() +
                            element.loc[1].slice(1).replace(/_/g, " "),
                        message:
                            element.msg.charAt(0).toUpperCase() +
                            element.msg.slice(1).replace(/_/g, " "),
                    };
                    state.create.errorItems.push(item);
                });
            } catch (error) {
                console.log("Create Service error message : ", error);
                state.create.errorItems.push({
                    item: "Server error",
                    message: "Please try again later",
                });
            }
        },
        SET_CREATE_RESPONSE(state, val) {
            state.create.response = val;
        },
        SET_SERVICES_ERROR(state, val) {
            state.services.error = val;
        },
        SET_DELETE_LOADING(state, val) {
            state.delete.loading = val;
        },
        SET_DELETE_ERROR(state, val) {
            state.delete.error = true;
            state.delete.errorItems = [];
            try {
                val.detail.forEach((element) => {
                    const item = {
                        item:
                            element.loc[1].charAt(0).toUpperCase() +
                            element.loc[1].slice(1).replace(/_/g, " "),
                        message:
                            element.msg.charAt(0).toUpperCase() +
                            element.msg.slice(1).replace(/_/g, " "),
                    };
                    state.delete.errorItems.push(item);
                });
            } catch (error) {
                console.log("Registration error message : ", error);
                state.delete.errorItems.push({
                    item: "Server error",
                    message: "Please try again later",
                });
            }
        },
        SET_DELETE_COMPLETE(state, val) {
            state.delete.complete = val;
        },
        SET_UPDATE_LOADING(state, val) {
            state.update.loading = val;
        },
        SET_UPDATE_COMPLETE(state, val) {
            state.update.complete = val;
        },
        SET_UPDATE_ERROR(state, val) {
            state.update.error = true;
            state.update.errorItems = [];
            try {
                val.detail.forEach((element) => {
                    const item = {
                        item:
                            element.loc[1].charAt(0).toUpperCase() +
                            element.loc[1].slice(1).replace(/_/g, " "),
                        message:
                            element.msg.charAt(0).toUpperCase() +
                            element.msg.slice(1).replace(/_/g, " "),
                    };
                    state.update.errorItems.push(item);
                });
            } catch (error) {
                console.log("Update Service error message : ", error);
                state.update.errorItems.push({
                    item: "Server error",
                    message: "Please try again later",
                });
            }
        },
        SET_UPDATE_RESPONSE(state, val) {
            state.update.response = val;
        },
    },

    getters: {
        all(state) {
            return state;
        },
        services(state) {
            return state.services.services;
        },
        servicesLoading(state) {
            return state.services.loading;
        },
        createLoading(state) {
            return state.create.loading;
        },
        createComplete(state) {
            return state.create.complete;
        },
        createError(state) {
            return state.create.error;
        },
        createErrorItems(state) {
            return state.create.errorItems;
        },
        createResponse(state) {
            return state.create.response;
        },
        servicesError(state) {
            return state.services.error;
        },
        deleteLoading(state) {
            return state.delete.loading;
        },
        deleteError(state) {
            return state.delete.error;
        },
        deleteErrorItems(state) {
            return state.delete.errorItems;
        },
        deleteComplete(state) {
            return state.delete.complete;
        },
        updateLoading(state) {
            return state.update.loading;
        },
        updateComplete(state) {
            return state.update.complete;
        },
        updateError(state) {
            return state.update.error;
        },
        updateErrorItems(state) {
            return state.update.errorItems;
        },
        updateResponse(state) {
            return state.update.response;
        },
    },

    actions: {
        async getServices({ rootState, commit }, company_id) {
            try {
                commit("SET_SERVICES_ERROR", false);
                commit("SET_SERVICES_LOADING", true);
                await axios
                    .get(`company/${company_id}/service`, {
                        headers: {
                            Authorization: `Bearer ${rootState.auth.token}`,
                        },
                    })
                    .then((resp) => {
                        console.log("services: ", resp.data);
                        commit("SET_SERVICES", resp.data);
                        commit("SET_SERVICES_LOADING", false);
                        // console.log(resp.data);
                    })
                    .catch((error) => {
                        console.log(
                            "ERROR getting Services: ",
                            error.response?.data ?? "Unknown error"
                        );
                        commit("SET_SERVICES", []);
                        commit("SET_SERVICES_LOADING", false);
                        commit("SET_SERVICES_ERROR", true);
                    })
                    .finally(() => { });
            } catch (e) {
                console.log(e);
                commit("SET_SERVICES_LOADING", false);
            }
        },
        async createService({ rootState, commit }, payload) {
            try {
                commit("SET_CREATE_LOADING", true);
                await axios
                    .post(`company/${payload.company_id}/service`, payload, {
                        headers: {
                            Authorization: `Bearer ${rootState.auth.token}`,
                        },
                    })
                    .then((resp) => {
                        console.log("createService response data:", resp.data);
                        commit("SET_CREATE_RESPONSE", resp.data);
                        commit("SET_CREATE_COMPLETE", true);
                        commit("SET_CREATE_LOADING", false);
                    })
                    .catch((error) => {
                        console.log("ERROR creating Service: ", error.response);
                        commit("SET_CREATE_ERROR", error.response?.data ?? "Unknown error");
                        commit("SET_CREATE_COMPLETE", false);
                        commit("SET_CREATE_LOADING", false);
                    })
                    .finally(() => { });
            } catch (e) {
                console.log(e);
                commit("SET_CREATE_LOADING", false);
            }
        },
        async archiveService({ rootState, commit, dispatch }, { serviceId, companyId }) {
            try {
                commit("SET_DELETE_LOADING", true);
                await axios.put(`company/${companyId}/service/${serviceId}/archive`, null, {
                    headers: {
                        Authorization: `Bearer ${rootState.auth.token}`,
                    },
                });

                // Re-fetch the updated services
                await dispatch("getServices", companyId);

                commit("SET_DELETE_COMPLETE", true);
                commit("SET_DELETE_LOADING", false);
            } catch (error) {
                console.log("ERROR deleting service: ", error.response?.data ?? "Unknown error");
                commit("SET_DELETE_LOADING", false);
                commit("SET_DELETE_COMPLETE", false);
                commit("SET_DELETE_ERROR", true);
                commit("SET_DELETE_ERROR", error.response?.data ?? "Unknown error");
            }
        },
        async updateService({ rootState, commit }, payload) {
            try {
                commit("SET_UPDATE_LOADING", true);
                let company_id = payload.company_id;
                let id = payload.id;
                delete payload.company_id;
                delete payload.id;
                await axios
                    .put(`company/${company_id}/service/${id}`, payload, {
                        headers: {
                            Authorization: `Bearer ${rootState.auth.token}`,
                        },
                    })
                    .then((resp) => {
                        console.log("updateService response data:", resp.data);
                        commit("SET_UPDATE_RESPONSE", resp.data);
                        commit("SET_UPDATE_COMPLETE", true);
                        commit("SET_UPDATE_LOADING", false);
                    })
                    .catch((error) => {
                        console.log("ERROR updating Service: ", error.response);
                        commit("SET_UPDATE_ERROR", error.response?.data ?? "Unknown error");
                        commit("SET_UPDATE_COMPLETE", false);
                        commit("SET_UPDATE_LOADING", false);
                    })
                    .finally(() => { });
            } catch (e) {
                console.log(e);
                commit("SET_UPDATE_LOADING", false);
            }
        },
        async resetForm({ commit }) {
            commit("RESET_FORM");
        },
    },
};
