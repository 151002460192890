<template v-if="authenticated">
  <!-- <v-overlay :absolute="true" :opacity="0.2" :value="invitesLoading">
      <v-progress-circular
        :width="4"
        :size="70"
        color="#7db52f"
        indeterminate
      ></v-progress-circular>
    </v-overlay> -->
  <v-sheet>
    <v-row justify="center" class="ma-0 pa-0">
      <v-col md="12" align="center">
        <v-row>
          <v-col>
            <p style="weight: 300; font-size: 2rem">Accept Invite Code</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p style="color: #7db52f; font-size: 1.5rem">
              {{ acceptInviteCode }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="description">
              This invite belongs to
              <b>{{ checkInviteResponse.company_name }}</b>
            </span>
          </v-col>
        </v-row>
        <v-row v-if="checkInviteResponse.type == 'EMPLOYEE'">
          <v-col>
            <span class="description">
              This invite will join your account to the company
            </span>
          </v-col>
        </v-row>
        <v-row
          v-if="
            acceptInviteComplete == false &&
            !(acceptInviteloading || petRelationshipLoading)
          "
        >
          <v-col>
            <v-form>
              <v-row v-if="checkInviteResponse.type == 'CUSTOMER'">
                <v-col>
                  <v-checkbox
                    v-model="allowAllPets"
                    :error-messages="checkboxErrors"
                    required
                  >
                    <div slot="label">
                      I would like
                      {{ checkInviteResponse.company_name }} to have access to
                      all of my pets
                    </div></v-checkbox
                  >
                </v-col>
              </v-row>
              <v-row v-if="!allowAllPets">
                <v-col class="pa-1">
                  <v-card
                    class="mb-2"
                    v-for="pet in dogs"
                    :key="pet.id"
                    elevation="3"
                    @click="removeOrAddFromList(pet)"
                    :color="includedInList(pet.id) ? null : '#dedede'"
                  >
                    <v-card-text>
                      <v-row justify="center" align="center">
                        <v-col
                          md="2"
                          align="center"
                          cols="12"
                          class="px-0 mx-0"
                        >
                          <v-icon
                            :size="40"
                            color="#7db52f"
                            v-if="includedInList(pet.id)"
                            >mdi-check</v-icon
                          >
                          <v-icon
                            :size="40"
                            color="grey"
                            v-if="!includedInList(pet.id)"
                            >mdi-close</v-icon
                          >
                        </v-col>
                        <v-col align="start" md="3" class="pl-0 pr-1 mx-0">
                          <v-img
                            class="pa-4 rounded-xl elevation-6"
                            alt=""
                            max-width="100%"
                            height="100px"
                            src="https://cf.ltkcdn.net/dogs/images/orig/284984-1600x1066-labrador-retriever-breed.jpg"
                          ></v-img>
                        </v-col>
                        <v-col align="start" md="7">
                          <p
                            style="font-size: 1.5rem"
                            class="font-weight-medium pb-0 mb-0"
                          >
                            {{ pet.name }}
                          </p>
                          <p class="pt-0 mt-0 mb-1">
                            {{ pet.breed.breed }}
                          </p>
                          <p class="pb-0 mb-0">
                            <b>GENDER:</b>
                          </p>
                          <p class="pt-0 mt-0 mb-1">
                            {{
                              pet.gender == null
                                ? "Unknown"
                                : pet.gender.charAt(0).toUpperCase() +
                                  pet.gender.toLowerCase().slice(1)
                            }}
                          </p>
                          <p class="pb-0 mb-0">
                            <b>AGE:</b>
                          </p>
                          <p class="py-0 my-0">
                            {{
                              pet.dob == null
                                ? "Unknown"
                                : get_years_and_months(pet.dob)
                            }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn color="#7db52f" @click="submit"> Accept Invite </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      v-if="acceptInviteloading || petRelationshipLoading"
    >
      <v-col align="center">
        <v-row>
          <v-col class="pb-0 mb-0">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mt-0 pt-0"> {{ loadingText }} </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="ma-0 pa-0">
      <v-col md="12">
        <v-alert type="error" :value="acceptInviteError">
          <li v-bind:key="item.msg" v-for="item in acceptInviteErrorItems">
            {{ item.message }}
          </li>
        </v-alert>
        <v-alert
          type="success"
          :value="
            (acceptInviteComplete && petRelationshipComplete) ||
            (acceptInviteComplete && checkInviteResponse.type == 'EMPLOYEE')
          "
        >
          <h3>Invite Accepted!</h3>
          <p class="py-0 my-0" v-if="checkInviteResponse.type == 'EMPLOYEE'">
            You have successfully accepted the invite to
            {{ checkInviteResponse.company_name }}
          </p>
          <p class="py-0 my-0" v-else>
            You can see your linked companies under your account Companies tab.
          </p>
        </v-alert>
        <v-alert type="warning" :value="petRelationshipError">
          <p class="py-0 my-0">There was a problem linking your pets.</p>
          <p class="py-0 my-0">Please go to the pets to try again.</p>
        </v-alert>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import moment from "moment";

export default {
  name: "AcceptInviteDialogue",
  mixins: [validationMixin],
  validations: {
    mobile_number: {
      minLength: minLength(11),
      maxLength: maxLength(11),
      required(v) {
        return this.email || required(v);
      },
    },
  },
  data() {
    return {
      checkboxErrors: "",
      invitesHeaders: [
        {
          text: "Invite Code",
          value: "InviteCode.code",
        },
        {
          text: "Email",
          value: "InviteCode.email",
        },
        {
          text: "Phone Number",
          value: "InviteCode.mobile_number",
        },
      ],
      allowAllPets: true,
      selectedPets: [],
      enabled: true,
      loadingText: "Loading...",
    };
  },
  created() {
    // this.getInviteOwnerName();
  },
  components: {},
  computed: {
    ...mapGetters({
      // invites: "invites/invites",
      acceptInviteComplete: "invites/acceptInviteComplete",
      acceptInviteError: "invites/acceptInviteError",
      acceptInviteErrorItems: "invites/acceptInviteErrorItems",
      invitesLoading: "invites/loading",
      dogs: "pets/dogs",
      checkInviteResponse: "invites/checkInviteResponse",
      petRelationshipError: "pets/petRelationshipError",
      petRelationshipComplete: "pets/petRelationshipComplete",
      petRelationshipLoading: "pets/petRelationshipLoading",
      acceptInviteCode: "invites/acceptInviteCode",
      acceptInviteloading: "invites/acceptInviteloading",
    }),
  },
  methods: {
    ...mapActions({
      createInvite: "invites/createInvite",
      resetInviteForm: "invites/resetFormAcceptInvite",
      resetPetsForm: "pets/resetForm",
      getDogs: "pets/getDogs",
      acceptInvite: "invites/acceptInvite",
      createPetRelationship: "pets/createPetRelationship",
      checkInvite: "invites/checkInvite",
      resetCreatePetRelationship: "pets/resetCreatePetRelationship",
    }),
    removeOrAddFromList(pet) {
      if (this.includedInList(pet.id)) {
        const index = this.selectedPets.findIndex((obj) => obj.id === pet.id);
        this.selectedPets.splice(index, 1);
      } else {
        this.selectedPets.push(pet);
      }
    },
    includedInList(id) {
      var filtered = this.selectedPets.filter((item) => item.id == id);
      if (filtered.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    get_years_and_months(val) {
      var start = moment(val);
      var end = moment();

      var years = end.diff(start, "year");
      start.add(years, "years");

      var months = end.diff(start, "months");
      start.add(months, "months");

      // var days = end.diff(start, "days");

      var ageString = years == 1 ? years + " year, " : years + " years, ";
      ageString += months == 1 ? months + " month " : months + " months";

      return ageString;
    },
    async submit() {
      this.loadingText = "Accepting Invite...";
      console.log("Submit", this.selectedPets);
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("login");
      var petsToLink = { pet_ids: [] };
      if (this.checkInviteResponse.type == "CUSTOMER") {
        this.selectedPets.forEach((pet) => {
          petsToLink["pet_ids"].push(pet.id);
        });
        console.log("pet_ids: ", petsToLink.pet_ids);
      }
      console.log(this.acceptInviteCode);
      await this.acceptInvite({
        recaptchaToken: token,
        inviteCode: this.acceptInviteCode,
      }).then(() => {
        if (this.checkInviteResponse.type == "CUSTOMER") {
          this.loadingText = "Linking Pets...";
          this.createPetRelationship({
            pet_ids: petsToLink.pet_ids,
            company_id: this.checkInviteResponse.company_id,
          });
        }
      });
    },
    resetSelectedPets() {
      this.getDogs().then(() => {
        this.dogs.forEach((element) => {
          this.selectedPets.push(element);
        });
      });
    },
    loadComponant() {
      this.resetInviteForm();
      this.resetCreatePetRelationship();
      // this.resetPetsForm();
      // this.getInvites();
      this.resetSelectedPets();
    },
  },
  mounted: function () {
    this.loadComponant();
  },
  watch: {
    invites(val) {
      console.log("Invites changed");
      console.log(val);
    },
    acceptInviteCode() {
      this.loadComponant();
    },
    allowAllPets(val) {
      if (val == true) {
        this.resetSelectedPets();
      }
    },
    acceptInviteComplete(val) {
      console.log("acceptInviteComplete: ", val);
    },
  },
};
</script>

<style></style>
