<template>
  <v-container>
    <v-row>
      <v-col>
        <h4 v-if="loading">Loading...</h4>
        <h4 v-if="error">Error whilst loading the calendar...</h4>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4">
        <v-row>
          <v-col>
            <CalendarEvents />
          </v-col>
        </v-row>
      </v-col>
      <v-col md="8"><Calendar /></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Calendar from "../components/Calendar";
import CalendarEvents from "../components/CalendarEvents";
import CalendarEventsCreate from "../components/CalendarEventsCreate";

export default {
  name: "CalendarPage",
  data() {
    return {
      dialog: false,
    };
  },
  components: {
    Calendar,
    CalendarEvents,
    CalendarEventsCreate,
  },
  methods: {
    ...mapActions({
      getCalendar: "calendar/getCalendar",
      setCreateEventShowOverlay: "calendar/setCreateEventShowOverlay",
    }),
  },
  computed: {
    ...mapGetters({
      loading: "bookings/bookingsLoading",
      error: "bookings/bookingsError",
      events: "calendar/events",
    }),
  },
  mounted() {},
};
</script>

<style></style>
