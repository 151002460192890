<template>
  <v-card>
    <v-card-title>Upload New Profile Picture</v-card-title>
    <v-card-text>
      <v-form @submit.prevent="submitForm">
        <v-row v-if="!uploadProfilePictureComplete">
          <v-col cols="12">
            <!-- Overlay for loading -->
            <v-overlay
              :absolute="true"
              :opacity="0.2"
              :value="uploadProfilePictureLoading"
            >
              <v-progress-circular
                :width="4"
                :size="70"
                color="#7db52f"
                indeterminate
              ></v-progress-circular>
            </v-overlay>
            <v-file-input
              v-model="newProfilePicture"
              label="Select a file"
              accept="image/*"
              @change="previewImage"
              ref="fileInput"
            ></v-file-input>
          </v-col>
          <v-col cols="12" align="center">
            <v-avatar size="150" v-if="previewSrc">
              <img :src="previewSrc" />
            </v-avatar>
            <v-avatar size="150" v-else>
              <img
                v-if="
                  uploadProfilePictureSelectedPet != null &&
                  uploadProfilePictureSelectedPet.image_url != null &&
                  uploadProfilePictureSelectedPet.name != null
                "
                :src="uploadProfilePictureSelectedPet.image_url"
                :alt="uploadProfilePictureSelectedPet.name"
              />
              <img
                v-else
                src="https://pawportal.blob.core.windows.net/images/PP_Black.png"
                alt="Default"
              />
            </v-avatar>
          </v-col>
          <v-col cols="12">
            <v-btn
              type="submit"
              color="#7db52f"
              :disabled="
                uploadProfilePictureLoading || newProfilePicture == null
              "
              >Update</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-alert type="warning" :value="uploadProfilePictureError">
              <li
                v-bind:key="item.message"
                v-for="item in uploadProfilePictureErrorItems"
              >
                {{ item.item }} -
                {{ item.message }}
              </li>
            </v-alert>
            <v-alert type="success" :value="uploadProfilePictureComplete">
              Profile picture updated successfully!
            </v-alert>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      dialogVisible: true,
      newProfilePicture: null,
      altText: "",
      previewSrc: null,
      file: null,
    };
  },
  computed: {
    ...mapGetters({
      uploadProfilePictureComplete: "pets/uploadProfilePictureComplete",
      uploadProfilePictureInProgress: "pets/uploadProfilePictureInProgress",
      uploadProfilePictureError: "pets/uploadProfilePictureError",
      uploadProfilePictureErrorItems: "pets/uploadProfilePictureErrorItems",
      uploadProfilePictureLoading: "pets/uploadProfilePictureLoading",
      uploadProfilePictureSelectedPet: "pets/uploadProfilePictureSelectedPet",
    }),
  },
  mounted() {
    console.log(
      `uploadProfilePictureLoading: ${this.uploadProfilePictureLoading}`
    );
    console.log(`newProfilePicture: ${this.newProfilePicture}`);
  },
  methods: {
    ...mapActions({
      uploadProfilePicture: "pets/uploadProfilePicture",
    }),
    submitForm() {
      console.log("Profile picture:", this.newProfilePicture);
      if (this.newProfilePicture) {
        this.uploadProfilePicture(this.newProfilePicture)
          .then(() => {
            this.$refs.fileInput.reset(); // Reset the file input after a successful upload
            this.previewSrc = null; // Clear the preview
          })
          .catch((error) => {
            console.error("Error uploading profile picture:", error);
          });
      } else {
        console.warn("No file selected");
      }
    },
    previewImage() {
      // Create a preview of the selected image
      if (this.newProfilePicture) {
        this.previewSrc = URL.createObjectURL(this.newProfilePicture);
      } else {
        this.previewSrc = null;
      }
    },
  },
};
</script>
