<template v-if="authenticated">
  <v-container class="pa-8">
    <!-- Loadng overlay -->
    <v-row class="pa-2" justify="center">
      <v-overlay :value="bookingsLoading || updateLoading" absolute>
        <v-progress-circular
          :width="4"
          :size="70"
          color="#7db52f"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
      <v-col align="center">
        <v-sheet color="white" rounded="xl" class="pa-8">
          <v-row v-if="bookings.length == 0">
            <v-col>
              <v-row>
                <v-col>
                  <p><b>You have no bookings.</b></p>
                  <p>
                    If you are expecting one, please speak with your animal
                    supervisor.
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col>
              <v-row>
                <v-col>
                  <p>Good news! You have {{ bookings.length }} bookings.</p>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-card>
                    <v-card-title>
                      <h3>Bookings</h3>
                    </v-card-title>
                    <v-card-text>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Event Name</th>
                              <th class="text-left">Pet Name</th>
                              <th class="text-left">Start Date</th>
                              <th class="text-left">End Date</th>
                              <th class="text-left">Status</th>
                              <th class="text-left">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="booking in bookings" :key="booking.id">
                              <td>
                                {{
                                  booking.service_booking.calendar_event.name
                                }}
                              </td>
                              <td
                                v-for="pet in booking.service_booking.pets"
                                :key="pet.id"
                              >
                                {{ pet.name }}
                              </td>
                              <td>
                                {{
                                  booking.service_booking.calendar_event.start
                                }}
                              </td>
                              <td>
                                {{ booking.service_booking.calendar_event.end }}
                              </td>
                              <td>
                                {{ titleCase(booking.status) }}
                              </td>
                              <td>
                                <!-- Confirm booking -->
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-if="booking.status !== 'CONFIRMED'"
                                      color="success"
                                      @click="
                                        changeBookingStatus(
                                          booking,
                                          'CONFIRMED'
                                        )
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-check</v-icon
                                    >
                                  </template>

                                  Confirm Booking
                                </v-tooltip>
                                <!-- Cancel Booking -->
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      v-if="
                                        booking.status == 'CONFIRMED' ||
                                        booking.status == 'PENDING'
                                      "
                                      color="error"
                                      @click="
                                        changeBookingStatus(
                                          booking,
                                          'CANCELLED'
                                        )
                                      "
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-close</v-icon
                                    >
                                  </template>
                                  Cancel Booking
                                </v-tooltip>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-snackbar
                v-model="snackbarShow"
                :timeout="snackbarTimeout"
                bottom
                outlined
                rounded="pill"
                color="#EA8C3C"
                class="mb-4 text-center"
              >
                {{ snackbarMessage }}
              </v-snackbar>
            </v-col>
          </v-row>
          <v-row v-if="getbookingsError">
            <v-col>
              <v-alert type="error">
                There was a server error when loading your bookings.
              </v-alert>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Account",
  data() {
    return {
      snackbarMessage: "Booking confirmed!",
      snackbarTimeout: 3000,
      snackbarShow: true,
    };
  },
  computed: {
    ...mapGetters({
      bookings: "bookings/bookings",
      bookingsLoading: "bookings/bookingsLoading",
      getbookingsError: "bookings/bookingsError",
      updateComplete: "bookings/updateComplete",
      updateLoading: "bookings/updateLoading",
      updateError: "bookings/updateError",
    }),
  },
  methods: {
    ...mapActions({
      getbookings: "bookings/getBookings",
      updateBooking: "bookings/updateBooking",
    }),
    changeBookingStatus(booking, status) {
      // Set payload as booking and update status to confirmed
      let payload = {
        id: booking.id,
        pet_ids: booking.service_booking.pets.map((pet) => pet.id),
        start: booking.service_booking.calendar_event.start,
        end: booking.service_booking.calendar_event.end,
        all_day: booking.service_booking.calendar_event.all_day,
        query_field_responses: booking.service_booking.query_field_responses,
        additional_services: booking.service_booking.additional_services.map(
          (service) => {
            return {
              id: service.id,
              additional_service_id: service.id,
              pet_ids: service.pets.map((pet) => pet.id),
              query_field_responses: service.query_field_responses,
            };
          }
        ),
        notes: booking.service_booking.calendar_event.notes,
        status: status,
        on_behalf_of: booking.service_booking.calendar_event.owner_id,
        // has_paid: false, // You need to replace this with the actual payment status
      };
      this.updateBooking(payload);
      // Set the message to the correct status
      this.snackbarMessage = `Booking ${status.toLowerCase()}!`;
    },
    titleCase(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.replace(word[0], word[0].toUpperCase());
        })
        .join(" ");
    },
  },
  mounted: function () {
    this.getbookings({});
  },
  watch: {
    updateComplete: function () {
      this.snackbarShow = true;
    },
  },
};
</script>

<style></style>
