<template v-if="authenticated">
  <v-container class="pa-0 ma-0">
    <v-row justify="center">
      <v-col lg="12" md="12" sm="12" xs="12">
        <v-sheet color="white" rounded="xl" class="pa-1 ma-2">
          <v-row justify="center" class="pt-10">
            <v-col lg="6" md="8" sm="10" xs="12">
              <v-row align="center" class="pa-2">
                <v-col v-if="pawPortalAccount">
                  <h2>Welcome back, {{ pawPortalAccount.first_names }}</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-progress-linear
                    v-if="servicesLoading"
                    color="primary"
                    indeterminate
                  ></v-progress-linear>
                  <v-alert v-if="servicesError" type="error" elevation="2">
                    There was an error. Please try again later...
                  </v-alert>
                </v-col>
              </v-row>
              <v-row v-if="selectedCompany" class="ma-0 pa-0">
                <v-col class="ma-0 pa-0">
                  <v-row align="center" class="pl-2">
                    <v-col align="start">
                      <b>YOUR SERVICES</b>
                      <span class="float-right">
                        <!-- <v-icon>mdi-pencil</v-icon> -->
                      </span>
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                  <v-row v-if="services.length == 0">
                    <v-col> You have no services. </v-col>
                  </v-row>
                  <v-row
                    class="py-0 my-0 pt-0"
                    v-for="service in services"
                    :key="service.id"
                    justify="center"
                  >
                    <v-col class="pb-0 mb-0">
                      <!-- Parent Service -->
                      <v-sheet color="#D2D7DB" rounded="lg" class="pl-1 py-1">
                        <v-row class="my-0 py-0">
                          <v-col class="my-0 py-0">
                            <p class="my-0 py-0">Primary Service</p>
                          </v-col>
                          <v-col cols="4" align="end" class="py-0 my-0">
                            <v-btn
                              class="ma-0 pa-0"
                              color="primary"
                              small
                              icon
                              @click="goToEdit(service.id)"
                            >
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn
                              class="ma-0 pa-0"
                              color="red"
                              small
                              icon
                              @click="showDeleteDialog(service)"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row class="pt-0 mt-0">
                          <v-col cols="1" class="pt-0 mt-0">
                            <v-icon>mdi-cog</v-icon>
                          </v-col>
                          <v-col
                            justify="start"
                            cols="11"
                            class="overflow-auto pt-0 mt-0"
                          >
                            {{ service.name }}
                            <br />
                            <span class="font-weight-light">
                              {{ service.description }}
                            </span>
                            <br />
                            <span class="font-weight-light">
                              <b>Charge Amount:</b> £{{
                                formatLongNumber(
                                  service.current_price.amount_per_unit,
                                  2
                                )
                              }}
                            </span>
                            <br />
                            <span class="font-weight-light">
                              <b>Maximum Pets Per Booking:</b>
                              {{ service.max_pets_per_booking }}
                            </span>
                            <span
                              v-if="service.query_fields.length != null"
                              class="font-weight-light"
                              v-for="(query, key) in service.query_fields"
                              :key="key"
                            >
                              <b>Query:</b>
                              {{ query }}
                              <br />
                            </span>
                          </v-col>
                        </v-row>
                      </v-sheet>
                      <!-- Child Service -->
                      <div
                        v-for="child_service in service.additional_services"
                        :key="child_service.id"
                      >
                        <v-sheet
                          color="#EAEDF0"
                          rounded="lg"
                          class="pl-1 py-1 mt-1 mb-3"
                        >
                          <v-row class="my-0 py-0">
                            <v-col class="my-0 py-0">
                              <p class="my-0 py-0">Additional Service</p>
                            </v-col>
                          </v-row>
                          <v-row class="pt-0 mt-0">
                            <v-col cols="1">
                              <v-icon>mdi-cogs</v-icon>
                            </v-col>
                            <v-col
                              justify="start"
                              cols="10"
                              class="overflow-auto"
                            >
                              {{ child_service.name }}
                              <br />
                              <span class="font-weight-light">
                                {{ child_service.description }}
                              </span>
                              <br />
                              <span class="font-weight-light">
                                <b>Charge Amount:</b> £{{
                                  formatLongNumber(
                                    child_service.current_price.amount_per_unit,
                                    2
                                  )
                                }}
                              </span>
                              <br />
                              <span class="font-weight-light">
                                <b>Maximum Pets Per Booking:</b>
                                {{ child_service.max_per_booking }}
                              </span>
                            </v-col>
                          </v-row>
                        </v-sheet>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <!-- Dialog to delete service -->
              <v-dialog
                v-model="confirmDeleteDialog"
                max-width="400"
                opacity="0.9"
              >
                <v-card>
                  <v-card-title class="headline"> Delete Service </v-card-title>
                  <v-card-text>
                    Are you sure you want to delete the service
                    {{ serviceToDelete?.name }}? This action cannot be undone.
                    <v-progress-linear
                      :indeterminate="serviceDeleteLoading"
                      :color="serviceDeleteLoading ? 'primary' : 'transparent'"
                    ></v-progress-linear>
                    <v-alert v-if="serviceDeleteComplete" type="success">
                      Service successfully deleted.
                    </v-alert>
                    <v-alert v-if="serviceDeleteError" type="warning">
                      An error occurred while deleting the service.
                      <ul>
                        <li
                          v-for="error in serviceDeleteErrorItems"
                          :key="error"
                        >
                          {{ error.item }} - {{ error.message }}
                        </li>
                      </ul>
                    </v-alert>
                  </v-card-text>
                  <v-card-actions
                    v-if="!serviceDeleteComplete && !serviceDeleteLoading"
                  >
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="closeDialog">
                      Cancel
                    </v-btn>
                    <v-btn
                      color="red darken-1"
                      text
                      @click="submitArchiveService"
                    >
                      Delete
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-row>
                <v-col>
                  <!-- Button to create service and navigate to /services/create -->
                  <v-btn
                    color="primary"
                    text
                    @click="$router.push('/services/create')"
                  >
                    <v-icon>mdi-plus</v-icon> Create Service
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Account",
  components: {},
  data() {
    return {
      confirmDeleteDialog: false,
      serviceToDelete: null,
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/isAuthenticated",
      pawPortalAccount: "account/accountDetails",
      services: "services/services",
      servicesLoading: "services/servicesLoading",
      selectedCompany: "company/selectedCompany",
      servicesError: "services/servicesError",
      serviceDeleteComplete: "services/deleteComplete",
      serviceDeleteLoading: "services/deleteLoading",
      serviceDeleteError: "services/deleteError",
      serviceDeleteErrorItems: "services/deleteErrorItems",
    }),
    dialogWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100%";
        case "sm":
          return "80%";
        case "md":
          return "60%";
        case "lg":
          return "40%";
        case "xl":
          return "40%";
      }
      return "100%";
    },
  },
  methods: {
    ...mapActions({
      authLogout: "auth/logout",
      getAccount: "account/getAccount",
      getServices: "services/getServices",
      archiveService: "services/archiveService",
      resetForm: "services/resetForm",
    }),
    submitArchiveService() {
      console.log(
        `Deleting service ${this.serviceToDelete.id} with company ${this.selectedCompany.id}`
      );
      this.archiveService({
        companyId: this.selectedCompany.id,
        serviceId: this.serviceToDelete.id,
      });
    },
    closeDialog() {
      this.$nextTick(() => {
        this.confirmDeleteDialog = false;
      });
    },
    formatLongNumber(number, decimals = 0) {
      return (
        Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals)
      ).toLocaleString(undefined, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      });
    },
    goToEdit(id) {
      this.$router.push({
        path: `/services/create`,
        query: { edit: id },
      });
    },
    showDeleteDialog(service) {
      this.resetForm();
      this.serviceToDelete = service;
      this.confirmDeleteDialog = true;
    },
  },
  mounted: function () {
    // Check if we already have services loaded
    if (this.services.length == 0) {
      this.getServices(this.selectedCompany.id);
    }
  },
  watch: {
    selectedCompany: function (val) {
      this.getServices(val.id);
    },
    serviceDeleteComplete: function (val) {
      if (val) {
        // Wait 2 seconds then close the dialog
        setTimeout(() => {
          this.confirmDeleteDialog = false;
        }, 2000);
      }
    },
  },
};
</script>

<style></style>
