<template>
  <v-container fluid class="pa-0" fill-height>
    <!-- <v-row no-gutters v-if="accountTypeSelection == 'PET_SUPERVISOR'" class="fill-height">
      <v-col>
        <v-img
          src="https://elkevogelsang.com/wp-content/uploads/2021/12/1-Django-2-572x714.jpg"
          contain
        ></v-img>
      </v-col>
      <v-col>
        <RegisterFormSupervisor />
      </v-col>
    </v-row> -->
    <v-row
      class="fill-height pa-0 ma-0"
      v-if="accountTypeSelection == 'PET_SUPERVISOR'"
      no-gutters
    >
      <v-col
        class="ma-0 pa-0 d-flex align-end"
        cols="5"
        style="background: #5ba0c9"
        v-if="!this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm"
      >
        <v-img
          src="https://elkevogelsang.com/wp-content/uploads/2021/12/1-Django-2-572x714.jpg"
          contain
          eager
          class="align-end"
        ></v-img>
      </v-col>
      <v-col class="pa-0 ma-0" style="background: #f3f8f2">
        <RegisterFormSupervisor />
      </v-col>
    </v-row>
    <v-row class="fill-height pa-0 ma-0" v-else no-gutters>
      <v-col
        class="ma-0 pa-0 d-flex align-end"
        cols="5"
        style="background: #5ba0c9"
        v-if="!this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm"
      >
        <v-img
          src="https://elkevogelsang.com/wp-content/uploads/2021/12/1-Django-2-572x714.jpg"
          contain
          eager
          class="align-end"
        ></v-img>
      </v-col>
      <v-col class="pa-0 ma-0" style="background: #f3f8f2">
        <RegisterFormOwner />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RegisterFormOwner from "../../components/registration/RegisterFormOwner.vue";
import RegisterFormSupervisor from "../../components/registration/RegisterFormSupervisor.vue";
// import AccountTypeSelection from "../../components/registration/AccountTypeSelection.vue";
import { mapGetters } from "vuex";
export default {
  name: "Register",
  components: {
    RegisterFormOwner,
    RegisterFormSupervisor,
    // AccountTypeSelection,
  },
  computed: {
    ...mapGetters({
      pawPortalAccount: "account/accountDetails",
    }),
    // Computed value for this.$route.query.t
    t_query() {
      return this.$route.query.t;
    },
  },
  data() {
    return {
      tab: null,
      accountTypeSelection: null,
    };
  },
  mounted() {
    // Check if we are already registered
    if (this.pawPortalAccount) {
      console.log("Already registered");
      // Check if a code has been passed
      console.log(`Code: ${this.$route.query.c}`);
      console.log(`Path: ${this.$route.path}`);
      if (this.$route.query.c) {
        console.log("Code passed, redirecting to invite code page");
        // Push to invite code page
        if (this.$route.path !== "/inviteCode") {
          this.$router.push({
            path: "/inviteCode",
            query: { c: this.$route.query.c },
          });
        }
      } else {
        // If so, redirect to profile page
        this.$router.push("/profile");
      }
    }
    // Get account type from query string
    const account_type = this.$route.query.t;
    // Check if account type is valid
    if (account_type != undefined) {
      if (account_type == "PET_SUPERVISOR" || account_type == "PET_OWNER") {
        // Correct account type, set it
        this.accountTypeSelection = account_type;
      } else {
        // Incorrect account type, default to PET_OWNER
        this.accountTypeSelection = "PET_OWNER";
      }
    } else {
      // No account type, default to PET_OWNER
      this.accountTypeSelection = "PET_OWNER";
    }
  },
  methods: {
    selectionChanged(event) {
      console.log(event);
      this.accountTypeSelection = event;
    },
  },
  watch: {
    t_query(val) {
      console.log("Query changed", val);
      this.accountTypeSelection = val;
    },
    pawPortalAccount() {
      console.log("pawPortalAccount changed");
      if (this.pawPortalAccount) {
        console.log("Already registered");
        // Check if a code has been passed
        console.log(`Code: ${this.$route.query.c}`);
        console.log(`Path: ${this.$route.path}`);
        if (this.$route.query.c) {
          console.log("Code passed, redirecting to invite code page");
          // Push to invite code page
          if (this.$route.path !== "/inviteCode") {
            this.$router.push({
              path: "/inviteCode",
              query: { c: this.$route.query.c },
            });
          }
        } else {
          // If so, redirect to profile page
          this.$router.push("/profile");
        }
      }
    },
    accountTypeSelection(val) {
      // Check if account type is already in query string
      // if (this.$route.query.AccountType == undefined) {
      // No account type in query string, add it
      // history.pushState({}, null, `${this.$route.path}?AccountType=${val}`);
      // this.$router.push({ query: { AccountType: val } })

      let queryParams = Object.assign({}, this.$route.query);
      queryParams.t = val;
      console.log("Params", queryParams);

      // this.$router.push({
      //   query: queryParams,
      // });

      // if (this.$route.query !== queryParams) {
      // this.$router.push({ query: queryParams });
      // Update current query parameters to queryParams
      this.$route.query.t = queryParams.t;
      // }
      // }
      // else (account_type != "PET_SUPERVISOR" && account_type != "PET_OWNER")
      // {
      //   this.$router.push({ query: { AccountType: this.accountTypeSelection } })
      // }
    },
  },
};
</script>

<style></style>
