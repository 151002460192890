<template>
  <v-container fluid class="pa-0 ma-0" fill-height style="height: 85vh">
    <v-row class="fill-height pa-0 ma-0" no-gutters justify="center">
      <v-col class="ma-0 pa-0" align="center">
        <InvitesOwner v-if="pawPortalAccount.type == 'PET_OWNER'" />
        <InvitesSupervisor v-if="pawPortalAccount.type == 'PET_SUPERVISOR'" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import InvitesOwner from "../../components/invites/InvitesOwner.vue";
import InvitesSupervisor from "../../components/invites/InvitesSupervisor.vue";

export default {
  name: "Home",
  computed: {
    ...mapGetters({
      // authenticated: "auth/isAuthenticated",
      pawPortalAccount: "account/accountDetails",
    }),
  },
  components: {
    InvitesOwner,
    InvitesSupervisor,
  },
  methods: {},
  mounted: function () {},
};
</script>

<style></style>
