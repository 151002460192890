<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row class="fill-height pa-0 ma-0" no-gutters justify="center">
      <v-col class="ma-0 pa-0" align="center">
        <CompanyInvites v-if="pawPortalAccount.type == 'PET_SUPERVISOR'" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import CompanyInvites from "../../components/invites/Company.vue";

export default {
  name: "Home",
  computed: {
    ...mapGetters({
      pawPortalAccount: "account/accountDetails",
    }),
  },
  components: {
    CompanyInvites,
  },
  methods: {},
  mounted: function () {},
};
</script>

<style></style>
