<template>
  <ContactUsComponent />
</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import ContactUsComponent from "../components/ContactUs.vue";

export default {
  name: "ContactUsPage",
  data() {
    return {
      dialog: false,
    };
  },
  components: {
    ContactUsComponent,
  },
  methods: {},
  mounted() {},
};
</script>

<style></style>
