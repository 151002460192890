<template>
  <v-container pa-xs-0 pa-sm-10 pa-md-10 pa-lg-10 pa-xl-10>
    <v-row>
      <v-col align="end">
        <v-btn outlined @click="SignOut"
          ><span class="header-btn-font">LOG OUT</span></v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-h4">Almost there...</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <div class="body-1">
          Thank you for choosing Paw Portal. Just some finishing touches before
          we can get you started.
        </div>
      </v-col>
      <v-col class="body-1" align="end">
        <router-link :to="{ name: 'Register', query: { t: 'PER_OWNER' } }"
          >Not a business?</router-link
        >
        <!-- Text link navigating to /something -->
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-stepper elevation="15" rounded="xl" v-model="e1" vertical>
          <v-overlay :absolute="true" :opacity="0.3" :value="getAccountLoading">
            <!-- Show circle loading -->
            <v-progress-circular
              indeterminate
              color="primary"
              size="64"
            ></v-progress-circular>
          </v-overlay>
          <!-- <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1" editable>
              Account Type
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2" editable>
              Profile
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3"> Name of step 3 </v-stepper-step>
          </v-stepper-header> -->

          <v-stepper-step
            :complete="e1 > 1"
            step="1"
            editable
            :rules="validateAboutYou()"
            error-icon="mdi-alert-circle-outline"
            edit-icon="mdi-check"
            :color="validateAboutYou.length == 0 ? 'green' : 'blue'"
          >
            About You
          </v-stepper-step>

          <v-stepper-content step="1">
            <!-- <form> -->
            <v-text-field
              v-model="first_names"
              :error-messages="first_namesErrors"
              :counter="20"
              label="First Name*"
              required
              @input="$v.first_names.$touch()"
              @blur="$v.first_names.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="surname"
              :error-messages="surnameErrors"
              :counter="20"
              label="Surname*"
              required
              @input="$v.surname.$touch()"
              @blur="$v.surname.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="address_line_1"
              :error-messages="address_line_1Errors"
              :counter="50"
              label="Address Line 1"
              @input="$v.address_line_1.$touch()"
              @blur="$v.address_line_1.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="address_line_2"
              :error-messages="address_line_2Errors"
              :counter="50"
              label="Address Line 2"
              @input="$v.address_line_2.$touch()"
              @blur="$v.address_line_2.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="address_city_or_town"
              :error-messages="address_city_or_townErrors"
              :counter="50"
              label="City/Town"
              @input="$v.address_city_or_town.$touch()"
              @blur="$v.address_city_or_town.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="address_county_or_state"
              :error-messages="address_county_or_stateErrors"
              :counter="50"
              label="County/State"
              @input="$v.address_county_or_state.$touch()"
              @blur="$v.address_county_or_state.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="address_postcode"
              :error-messages="address_postcodeErrors"
              label="Postcode/Zip Code"
              @input="$v.address_postcode.$touch()"
              @blur="$v.address_postcode.$touch()"
            ></v-text-field>
            <v-row class="mb-3">
              <v-col>
                <vue-tel-input
                  v-model="phone_1"
                  :showDialCodeInSelection="true"
                  @validate="phone_1_validation = $event"
                  @blur="$v.phone_1_valid.$touch()"
                  v-bind="bindProps"
                ></vue-tel-input>
                <div class="alert-small">{{ phone_1Errors[0] }}</div>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn color="primary" @click="e1 = 2" class="my-2">
                  Continue
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-step
            :complete="e1 > 2"
            step="2"
            editable
            :rules="validateCompanyInformation()"
            error-icon="mdi-alert-circle-outline"
            edit-icon="mdi-check"
            :color="
              validateCompanyInformation.length == 0 && e1 > 2
                ? 'green'
                : 'blue'
            "
          >
            <div
              style="display: flex; justify-content: space-between; width: 100%"
            >
              <div>Company Information</div>
              <div>
                <span
                  @click="resetCompanyInformation"
                  style="cursor: pointer; color: blue"
                  class="description"
                  v-if="
                    (createCompany || joinExistingCompany) &&
                    e1 == 2 &&
                    checkInviteLoading == false
                  "
                  >Change Selection</span
                >
              </div>
            </div>
          </v-stepper-step>

          <!-- Company Information -->
          <v-stepper-content step="2">
            <!-- <form> -->
            <v-row
              align="center"
              v-if="createCompany == false && joinExistingCompany == false"
            >
              <v-col justify="center" align="center">
                <v-sheet
                  elevation="3"
                  class="ma-2"
                  rounded="xl"
                  @click="createCompany = true"
                >
                  <v-img
                    alt="Create Company"
                    src="../../assets/register/create_company.png"
                    contain
                    eager
                    width="200px"
                  ></v-img>
                  Create Company
                </v-sheet>
              </v-col>
              <v-col justify="center" align="center">
                <v-sheet
                  elevation="3"
                  class="ma-2"
                  rounded="xl"
                  @click="joinExistingCompany = true"
                >
                  <v-img
                    alt="Create Company"
                    src="../../assets/register/invite_code.png"
                    contain
                    eager
                    width="200px"
                  ></v-img>
                  Join Existing Company
                </v-sheet>
              </v-col>
            </v-row>
            <v-row
              justify="center"
              class=""
              v-if="showInviteCodeInput && joinExistingCompany"
            >
              <v-col align="center">
                <p>Please enter the company invite code below.</p>
              </v-col>
            </v-row>
            <v-row
              justify="center"
              v-if="showInviteCodeInput && joinExistingCompany"
            >
              <v-col lg="8" md="8" sm="8" xs="12" cols="12">
                <v-row>
                  <v-col>
                    <v-otp-input
                      :length="inviteCodeLength"
                      v-model="enteredInviteCode"
                      :readonly="executed && checkInviteLoading"
                      :disabled="executed && checkInviteLoading"
                      :color="
                        checkInviteValidCode == true
                          ? '#7db52f'
                          : '' || (executed && checkInviteValidCode == false)
                          ? 'red'
                          : ''
                      "
                    ></v-otp-input>
                    Please speak with the company owner to get the invite code.
                    Need help?
                    <router-link to="/contactus">Contact Us</router-link>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col align="center">
                    <v-btn
                      color="primary"
                      @click="
                        () => {
                          e1 = 4;
                          enteredInviteCode = '';
                        }
                      "
                      class="my-2 mx-2"
                      :disabled="executed && checkInviteLoading"
                      small
                    >
                      I don't have a code yet
                    </v-btn>
                  </v-col>
                  <!-- Display error message if Code Error -->
                  <v-alert
                    type="error"
                    :value="executed && checkInviteError"
                    dismissible
                    class="mt-2"
                  >
                    {{ checkInviteErrorMessage }}
                  </v-alert>
                </v-row>
              </v-col>
            </v-row>
            <v-row
              v-if="!showInviteCodeInput && joinExistingCompany"
              justify="center"
            >
              <!-- Text link to edit code -->
              <v-col align="center">
                <v-row class="pa-0 ma-0">
                  <v-col class="pa-0 ma-0">
                    <p>
                      This code belongs to
                      <b>{{ checkInviteResponse.first_names }}</b>
                    </p>
                  </v-col>
                </v-row>
                <v-row class="pa-0 ma-0">
                  <v-col class="pa-0 ma-0">
                    <p class="body-2">
                      <b>{{ enteredInviteCode }}</b>
                      <v-btn
                        text
                        color="primary"
                        @click="showInviteCodeInput = true"
                        >Edit</v-btn
                      >
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row
              justify="center"
              v-if="showInviteCodeInput && joinExistingCompany"
            >
              <v-col
                lg="2"
                md="2"
                sm="2"
                xs="12"
                cols="12"
                align="center"
                class="pa-0 ma-0"
              >
                <v-progress-circular
                  v-if="executed && checkInviteLoading"
                  :width="4"
                  :size="40"
                  color="#7db52f"
                  indeterminate
                ></v-progress-circular>
                <v-icon :size="40" color="#7db52f" v-if="checkInviteValidCode"
                  >mdi-check</v-icon
                >
                <v-icon
                  :size="40"
                  color="red"
                  v-if="
                    executed &&
                    checkInviteLoading == false &&
                    checkInviteValidCode == false
                  "
                  >mdi-close</v-icon
                >
              </v-col>
            </v-row>
            <!-- Create new company form -->
            <v-row v-if="createCompany">
              <v-col>
                <form>
                  <v-text-field
                    v-model="company_name"
                    :error-messages="company_nameErrors"
                    :counter="50"
                    label="Company Name*"
                    required
                    @input="$v.company_name.$touch()"
                    @blur="$v.company_name.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="company_address_line_1"
                    :error-messages="company_address_line_1Errors"
                    :counter="50"
                    label="Address Line 1"
                    @input="$v.company_address_line_1.$touch()"
                    @blur="$v.company_address_line_1.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="company_address_line_2"
                    :error-messages="company_address_line_2Errors"
                    :counter="50"
                    label="Address Line 2"
                    @input="$v.company_address_line_2.$touch()"
                    @blur="$v.company_address_line_2.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="company_address_city_or_town"
                    :error-messages="company_address_city_or_townErrors"
                    :counter="50"
                    label="City/Town"
                    @input="$v.company_address_city_or_town.$touch()"
                    @blur="$v.company_address_city_or_town.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="company_address_county_or_state"
                    :error-messages="company_address_county_or_stateErrors"
                    :counter="50"
                    label="County/State"
                    @input="$v.company_address_county_or_state.$touch()"
                    @blur="$v.company_address_county_or_state.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="company_address_postcode"
                    :error-messages="company_address_postcodeErrors"
                    label="Postcode/Zip Code"
                    @input="$v.company_address_postcode.$touch()"
                    @blur="$v.company_address_postcode.$touch()"
                  ></v-text-field>
                  <v-row class="mb-3">
                    <v-col>
                      <vue-tel-input
                        v-model="company_phone_1"
                        :showDialCodeInSelection="true"
                        @validate="company_phone_1_validation = $event"
                        @blur="$v.company_phone_1_valid.$touch()"
                        v-bind="bindProps"
                      ></vue-tel-input>
                      <div class="alert-small">
                        {{ company_phone_1Errors[0] }}
                      </div>
                    </v-col>
                  </v-row>
                </form>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn
                  color="primary"
                  @click="e1 = createCompany ? 3 : 4"
                  class="my-2"
                  v-if="createCompany || checkInviteValidCode"
                >
                  Continue
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <!-- Stepper for home address and validation -->
          <!-- <v-stepper-step
            :complete="e1 > 3"
            step="3"
            editable
            :rules="validateHomeAddress()"
            error-icon="mdi-alert-circle-outline"
            edit-icon="mdi-check"
            :color="validateHomeAddress.length == 0 ? 'green' : 'blue'"
          >
            Personal Details (optional)
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-btn color="primary" @click="e1 = 4"> Continue </v-btn>
          </v-stepper-content> -->

          <!-- Account Type -->
          <v-stepper-step
            :complete="e1 > 3"
            step="3"
            editable
            :rules="validateHomeAddress()"
            error-icon="mdi-alert-circle-outline"
            edit-icon="mdi-check"
            :color="validateHomeAddress.length == 0 ? 'green' : 'blue'"
            v-if="createCompany"
          >
            Account Type (Only if you create a new company)
          </v-stepper-step>

          <v-stepper-content step="3" v-if="createCompany">
            <v-row>
              <v-col>
                <v-btn color="primary" @click="e1 = 4" class="my-2">
                  Continue
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <!-- Complete - T&C's' -->
          <v-stepper-step
            :complete="e1 > 4 || checkbox == true"
            step="4"
            :editable="!checkInviteLoading"
            edit-icon="mdi-check"
            error-icon="mdi-alert-circle-outline"
            :color="checkbox == true ? 'green' : 'blue'"
          >
            Complete
          </v-stepper-step>
          <v-stepper-content step="4">
            <span class="justify-start"
              >To create your account, please accept our
              <a href=".\tos" target="_blank">Terms and Conditions</a> and
              <a href=".\privacy" target="_blank">Privacy Policy</a></span
            >
            <v-row justify="start" align="start">
              <v-col class="ml-3">
                <v-checkbox
                  v-model="checkbox"
                  :error-messages="checkboxErrors"
                  required
                  @change="$v.checkbox.$touch()"
                  @blur="$v.checkbox.$touch()"
                >
                  <div slot="label">I agree</div></v-checkbox
                >
              </v-col>
            </v-row>
            <v-alert type="warning" :value="registrationError">
              <li v-bind:key="item.msg" v-for="item in registrationErrorItems">
                {{ item.item }} -
                {{ item.message }}
              </li>
            </v-alert>
            <v-alert type="success" :value="registrationComplete">
              Registration Successful!
            </v-alert>
            <v-btn class="primary" @click="submit"> submit </v-btn>

            <v-btn text> Cancel </v-btn>
          </v-stepper-content>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength, helpers } from "vuelidate/lib/validators";

let postcodeRegex = helpers.regex(
  "postcodeRegex",
  /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/
);

export default {
  name: "RegisterSupervisorForm",
  mixins: [validationMixin],
  validations: {
    first_names: { required, maxLength: maxLength(20) },
    surname: { required, maxLength: maxLength(20) },
    checkbox: {
      checked(val) {
        return val;
      },
    },
    phone_1_valid: {
      phone_1_valid(val) {
        return val;
      },
    },
    company_name: {
      required: function (value) {
        if (this.joinExistingCompany === false) {
          return required(value);
        }
        return true;
      },
      maxLength: maxLength(50),
    },
    company_address_line_1: { maxLength: maxLength(50) },
    company_address_line_2: { maxLength: maxLength(50) },
    company_address_city_or_town: { maxLength: maxLength(50) },
    company_address_county_or_state: { maxLength: maxLength(50) },
    company_address_postcode: {
      maxLength: maxLength(8),
      // Regex to match ([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})
      valid: postcodeRegex,
    },
    company_phone_1_valid: {
      company_phone_1_valid(val, vm) {
        // If joining an existing company, the field is automatically considered valid
        if (vm.joinExistingCompany) {
          return true;
        }
        // Otherwise, proceed with the normal validation logic
        // (Replace 'yourValidationFunction' with the actual validation logic)
        return yourValidationFunction(val);
      },
    },
    address_line_1: { maxLength: maxLength(50) },
    address_line_2: { maxLength: maxLength(50) },
    address_city_or_town: { maxLength: maxLength(50) },
    address_county_or_state: { maxLength: maxLength(50) },
    // postcode based on UK postcode regex
    address_postcode: {
      maxLength: maxLength(8),
      // Regex to match ([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})
      valid: postcodeRegex,
    },
  },
  data() {
    return {
      e1: 1,
      valid: true,
      progressBarText: "Please wait while we complete some things",
      registrationSuccess: "Registration successful!",
      loginFailed: false,
      progressBarVisable: false,
      email: null,
      first_names: null,
      surname: null,
      address_line_1: null,
      address_line_2: null,
      address_city_or_town: null,
      address_county_or_state: null,
      address_postcode: null,
      company_name: null,
      company_address_line_1: null,
      company_address_line_2: null,
      company_address_city_or_town: null,
      company_address_county_or_state: null,
      company_address_postcode: null,
      company_phone_1_type: "string",
      company_phone_1: null,
      company_phone_1_validation: null,
      phone_1_type: "string",
      selected_user_type: "PET_SUPERVISOR",
      checkbox: false,
      showInviteCodeInput: true,
      phone_1_type: null,
      phone_1: null,
      phone_1_validation: null,
      bindProps: {
        mode: "international",
        autoFormat: true,
        autoDefaultCountry: true,
      },
      createCompany: false,
      joinExistingCompany: false,
    };
  },
  components: {},
  watch: {
    registrationComplete(val) {
      if (val == true) {
        setTimeout(() => this.$router.push("/profile"), 3000);
      }
    },
  },
  computed: {
    ...mapGetters({
      registrationError: "account/registrationError",
      registrationComplete: "account/registrationComplete",
      registrationErrorItems: "account/registrationErrorItems",
      user: "auth/user",
      getAccountLoading: "account/getAccountLoading",
      inviteCodeLength: "invites/checkInviteInviteCodeLength",
      inviteCode: "invites/checkInviteInviteCode",
      checkInviteInviteCode: "invites/checkInviteInviteCode",
      checkInviteResponse: "invites/checkInviteResponse",
      checkInviteLoading: "invites/checkInviteLoading",
      checkInviteValidCode: "invites/checkInviteValidCode",
      checkInviteError: "invites/checkInviteError",
      checkInviteErrorMessage: "invites/checkInviteErrorMessage",
      getInviteCodeError: "invites/getInviteCodeError",
      getInviteCodeErrorMessage: "invites/getInviteCodeErrorMessage",
    }),
    enteredInviteCode: {
      get() {
        return this.inviteCode;
      },
      set(value) {
        this.$store.commit("invites/SET_CHECK_INVITE_INVITE_CODE", value);
      },
    },
    executed() {
      if (this.inviteCode.length == this.inviteCodeLength) {
        return true;
      } else {
        return false;
      }
    },
    checkboxErrors() {
      let errors = [];
      if (!this.$v.checkbox.$dirty) return errors;
      !this.$v.checkbox.checked && errors.push("You must agree to continue!");
      return errors;
    },
    first_namesErrors() {
      let errors = [];
      if (!this.$v.first_names.$dirty) return errors;
      !this.$v.first_names.maxLength &&
        errors.push("First name must be at most 20 characters long");
      !this.$v.first_names.required && errors.push("Name is required.");
      return errors;
    },
    surnameErrors() {
      let errors = [];
      if (!this.$v.surname.$dirty) return errors;
      !this.$v.surname.maxLength &&
        errors.push("Surname must be at most 20 characters long");
      !this.$v.surname.required && errors.push("Name is required.");
      return errors;
    },
    phone_1Errors() {
      let errors = [];
      if (!this.$v.phone_1_valid.$dirty) return errors;
      this.phone_1_validation == null ||
        (this.phone_1_validation.valid == false &&
          errors.push("This is not a valid phone number"));
      return errors;
    },
    phone_1_valid() {
      let test = this.phone_1Errors;
      // console.log("phone_1_validation", this.phone_1_validation);
      if (
        this.phone_1_validation == null ||
        this.phone_1_validation.valid == false
      ) {
        console.log("Phone 1 is not valid");
        return false;
      } else {
        console.log("Phone 1 is valid");
        return true;
      }
    },
    address_line_1Errors() {
      let errors = [];
      if (!this.$v.address_line_1.$dirty) return errors;
      !this.$v.address_line_1.maxLength &&
        errors.push("Address must be at most 50 characters long");
      return errors;
    },
    address_line_2Errors() {
      let errors = [];
      if (!this.$v.address_line_2.$dirty) return errors;
      !this.$v.address_line_2.maxLength &&
        errors.push("Address must be at most 50 characters long");
      return errors;
    },
    address_city_or_townErrors() {
      let errors = [];
      if (!this.$v.address_city_or_town.$dirty) return errors;
      !this.$v.address_city_or_town.maxLength &&
        errors.push("Address must be at most 50 characters long");
      return errors;
    },
    address_county_or_stateErrors() {
      let errors = [];
      if (!this.$v.address_county_or_state.$dirty) return errors;
      !this.$v.address_county_or_state.maxLength &&
        errors.push("Address must be at most 50 characters long");
      return errors;
    },
    address_postcodeErrors() {
      let errors = [];
      if (!this.$v.address_postcode.$dirty) return errors;
      !this.$v.address_postcode.valid && errors.push("Postcode must be valid");
      return errors;
    },
    company_nameErrors() {
      let errors = [];
      if (!this.$v.company_name.$dirty) return errors;

      // Check required first
      !this.$v.company_name.required && errors.push("Name is required.");

      // Then check maxLength
      !this.$v.company_name.maxLength &&
        errors.push("Company name must be at most 50 characters long");

      return errors;
    },
    company_address_line_1Errors() {
      let errors = [];
      if (!this.$v.company_address_line_1.$dirty) return errors;
      !this.$v.company_address_line_1.maxLength &&
        errors.push("Address must be at most 50 characters long");
      return errors;
    },
    company_address_line_2Errors() {
      let errors = [];
      if (!this.$v.company_address_line_2.$dirty) return errors;
      !this.$v.company_address_line_2.maxLength &&
        errors.push("Address must be at most 50 characters long");
      return errors;
    },
    company_address_city_or_townErrors() {
      let errors = [];
      if (!this.$v.company_address_city_or_town.$dirty) return errors;
      !this.$v.company_address_city_or_town.maxLength &&
        errors.push("Address must be at most 50 characters long");
      return errors;
    },
    company_address_county_or_stateErrors() {
      let errors = [];
      if (!this.$v.company_address_county_or_state.$dirty) return errors;
      !this.$v.company_address_county_or_state.maxLength &&
        errors.push("Address must be at most 50 characters long");
      return errors;
    },
    company_address_postcodeErrors() {
      let errors = [];
      if (!this.$v.company_address_postcode.$dirty) return errors;
      !this.$v.company_address_postcode.valid &&
        errors.push("Postcode must be valid");
      return errors;
    },
    company_phone_1Errors() {
      let errors = [];
      if (!this.$v.company_phone_1_valid.$dirty) return errors;
      this.company_phone_1_validation == null ||
        (this.company_phone_1_validation.valid == false &&
          errors.push("This is not a valid phone number"));
      return errors;
    },
    company_phone_1_valid() {
      let test = this.company_phone_1Errors;
      // console.log("company_phone_1_validation", this.company_phone_1_validation);
      if (
        this.company_phone_1_validation == null ||
        this.company_phone_1_validation.valid == false
      ) {
        console.log("Company Phone 1 is not valid");
        return false;
      } else {
        console.log("Company Phone 1 is valid");
        return true;
      }
    },
  },
  async mounted() {
    console.log(this.user);
    if (this.user.idTokenClaims.emails.length != null) {
      this.email = this.user.idTokenClaims.emails[0];
    }
    let split = this.user.idTokenClaims.name.split(" ");
    if (split.length > 1) {
      let surname = split[split.length - 1];
      this.surname = surname.trim();
      this.first_names = this.user.idTokenClaims.name
        .replace(surname, "")
        .trim();
    } else {
      this.first_names = split[0].trim();
    }
    this.$recaptchaLoaded();
    let code = this.$route.query.c;
    if (code != undefined) {
      this.setCheckInviteInviteCode(code.toUpperCase());
      await this.callCheckInvite();
      this.joinExistingCompany = true;
    }
  },
  methods: {
    ...mapActions({
      register: "account/register",
      SignOut: "auth/signOut",
      checkInvite: "invites/checkInvite",
      acceptInvite: "invites/acceptInvite",
      createNewCompany: "company/createCompany",
      setCheckInviteInviteCode: "invites/setCheckInviteInviteCode",
    }),
    async callCheckInvite() {
      // ALSO USED AT REGISTER FORM
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      let token = await this.$recaptcha("login");
      // console.log(token);
      await this.checkInvite(token);
    },
    async submit() {
      this.$v.$touch();
      console.log("Invalid status: " + this.$v.$invalid);
      console.log("errors:", this.$v);
      if (!this.$v.$invalid) {
        console.log("submit");
        await this.$recaptchaLoaded();
        let token = await this.$recaptcha("login");
        let telephone_numbers = [];
        if (
          this.phone_1_valid == true &&
          this.phone_1_validation.valid !== undefined
        ) {
          telephone_numbers.push({
            number:
              this.phone_1_valid == true ? this.phone_1_validation.number : "",
            type: "Mobile",
          });
        }
        await this.register({
          first_names: this.first_names.trim(),
          surname: this.surname.trim(),
          address_line_1:
            this.address_line_1 == null ? "" : this.address_line_1.trim(),
          address_line_2:
            this.address_line_2 == null ? "" : this.address_line_2.trim(),
          address_line_3:
            this.address_city_or_town == null
              ? ""
              : this.address_city_or_town.trim(),
          address_line_4:
            this.address_county_or_state == null
              ? ""
              : this.address_county_or_state.trim(),
          address_postcode:
            this.address_postcode == null ? "" : this.address_postcode.trim(),
          telephone_numbers: telephone_numbers,
          type: this.selected_user_type,
        }).then(async (val) => {
          // If successful then create company (if required)
          if (this.createCompany) {
            await this.createNewCompany({
              name: this.company_name.trim(),
            }).then((val) => {
              console.log("Company created");
              if (this.registrationComplete && this.checkInviteValidCode) {
                console.log("Registration success");
              }
            });
          }
          // If successful then accept invite code (if required)
          if (this.joinExistingCompany) {
            this.acceptInvite({
              recaptchaToken: token,
              inviteCode: this.inviteCode,
            });
          }
        });
      }
    },
    resetCompanyInformation() {
      this.enteredInviteCode = "";
      this.createCompany = false;
      this.joinExistingCompany = false;
      this.company_name = null;
      this.$v.company_name.$reset();
      this.company_address_line_1 = null;
      this.$v.company_address_line_1.$reset();
      this.company_address_line_2 = null;
      this.$v.company_address_line_2.$reset();
      this.company_address_city_or_town = null;
      this.$v.company_address_city_or_town.$reset();
      this.company_address_county_or_state = null;
      this.$v.company_address_county_or_state.$reset();
      this.company_address_postcode = null;
      this.$v.company_address_postcode.$reset();
      this.company_phone_1 = null;
      this.company_phone_1_validation = null;
      this.$v.company_phone_1_valid.$reset();
    },
    clear() {
      this.$v.$reset();
      this.first_names = "";
      this.surname = "";
      this.checkbox = false;
    },
    validateAboutYou() {
      let errors = [];
      this.first_namesErrors.forEach((error) => errors.push(() => error));
      this.surnameErrors.forEach((error) => errors.push(() => error));
      this.address_line_1Errors.forEach((error) => errors.push(() => error));
      this.address_line_2Errors.forEach((error) => errors.push(() => error));
      this.address_city_or_townErrors.forEach((error) =>
        errors.push(() => error)
      );
      this.address_county_or_stateErrors.forEach((error) =>
        errors.push(() => error)
      );
      this.address_postcodeErrors.forEach((error) => errors.push(() => error));
      if (this.phone_1_valid == false) {
        errors.push(() => "Invalid mobile number");
      }
      return errors;
    },
    validateInviteCode() {
      let errors = [];
      if (
        this.executed &&
        this.checkInviteLoading == false &&
        this.checkInviteValidCode == false
      ) {
        errors.push(() => "Invalid invite code");
      }
      return errors;
    },
    validateHomeAddress() {
      let errors = [];
      this.address_line_1Errors.forEach((error) => errors.push(() => error));
      this.address_line_2Errors.forEach((error) => errors.push(() => error));
      this.address_city_or_townErrors.forEach((error) =>
        errors.push(() => error)
      );
      this.address_county_or_stateErrors.forEach((error) =>
        errors.push(() => error)
      );
      this.address_postcodeErrors.forEach((error) => errors.push(() => error));
      if (this.phone_1_valid == false) {
        errors.push(() => "Invalid mobile number");
      }
      return errors;
    },
    validateCompanyInformation() {
      // Check if create company or join existing company. If create company then validate company information, otherwise validate company invite code
      let errors = [];
      if (this.createCompany) {
        this.company_nameErrors.forEach((error) => errors.push(() => error));
        this.company_address_line_1Errors.forEach((error) =>
          errors.push(() => error)
        );
        this.company_address_line_2Errors.forEach((error) =>
          errors.push(() => error)
        );
        this.company_address_city_or_townErrors.forEach((error) =>
          errors.push(() => error)
        );
        this.company_address_county_or_stateErrors.forEach((error) =>
          errors.push(() => error)
        );
        this.company_address_postcodeErrors.forEach((error) =>
          errors.push(() => error)
        );
        if (this.company_phone_1_valid == false) {
          errors.push(() => "Invalid mobile number");
        }
      } else if (this.joinExistingCompany) {
        this.validateInviteCode().forEach((error) => errors.push(() => error));
      } else if (
        this.createCompany == false &&
        this.joinExistingCompany == false &&
        this.e1 > 2
      ) {
        errors.push(() => "Please select an option");
      }
      return errors;
    },
  },
  watch: {
    checkInviteValidCode(val) {
      if (val === true) {
        console.log("History", history);
        console.log("Current Params", this.$route.query);

        // Make a copy of the current query parameters
        let queryParams = { ...this.$route.query };

        // Check if 'c' parameter already exists and if it's different from the current inviteCode
        if (queryParams.c && queryParams.c !== this.inviteCode) {
          console.log(
            "Updating 'c' from:",
            queryParams.c,
            "to:",
            this.inviteCode
          );
        }

        // Update 'c' with the new inviteCode
        queryParams.c = this.inviteCode;

        // Push the updated query parameters to the router
        // Note: This will replace the current route with the new query params
        // and keep the rest of the route the same
        this.$router
          .push({ path: this.$route.path, query: queryParams })
          .catch((err) => {
            // This catch is to handle the error that's thrown if we push the same route that we're already on
            if (err.name !== "NavigationDuplicated") {
              throw err;
            }
          });

        // Update the UI component state
        this.showInviteCodeInput = false;
      }
    },

    inviteCode(val) {
      this.$store.commit(
        "invites/SET_CHECK_INVITE_INVITE_CODE",
        val.toUpperCase()
      );
      if (val.length == this.inviteCodeLength) {
        this.$store.commit("invites/SET_CHECK_INVITE_LOADING", true);
        // let token = this.$recaptcha("login");
        this.callCheckInvite();
      } else {
        this.$store.commit("invites/SET_CHECK_INVITE_LOADING", false);
        this.$store.commit("invites/SET_CHECK_INVITE_VALID_CODE", false);
      }
    },
    e1(val) {
      // check if we have passed company information and if so then touch the company information fields
      if (val > 2 && this.createCompany) {
        this.$v.company_name.$touch();
        this.$v.company_address_line_1.$touch();
        this.$v.company_address_line_2.$touch();
        this.$v.company_address_city_or_town.$touch();
        this.$v.company_address_county_or_state.$touch();
        this.$v.company_address_postcode.$touch();
        this.$v.company_phone_1_valid.$touch();
      }
    },
    // phone_1_valid: function (val) {
    //   // console.log("phone_1_valid changed: ", val);
    //   this.$v.phone_1.$touch();
    // },
    checkInviteResponse: function (val) {
      if (val.telephone_number != null) {
        this.phone_1_type = "Mobile";
        this.phone_1 = val.telephone_number;
      }
    },
  },
};
</script>

<style scoped>
#registerAsText {
  margin-bottom: 1.5em;
  margin-left: -1em;
}

#registerAs {
  margin-bottom: 0;
  padding-bottom: 0;
}
</style>
