<template>
  <v-container pa-xs-0 pa-sm-10 pa-md-10 pa-lg-10 pa-xl-10>
    <v-row>
      <v-col align="end">
        <v-btn outlined @click="SignOut"
          ><span class="header-btn-font">LOG OUT</span></v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-h4">Almost there...</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <div class="body-1">
          Thank you for signing up to Paw Portal. Just some finishing touches
          before we can get you started.
        </div>
        tel
      </v-col>
      <v-col class="body-1" align="end">
        <router-link :to="{ name: 'Register', query: { t: 'PET_SUPERVISOR' } }"
          >Want to register as a Business?</router-link
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-stepper elevation="15" rounded="xl" v-model="e1" vertical>
          <v-overlay
            :absolute="true"
            :opacity="0.3"
            :value="checkInviteLoading || getAccountLoading"
          >
            <!-- Show circle loading -->
            <v-progress-circular
              indeterminate
              color="primary"
              size="64"
            ></v-progress-circular>
          </v-overlay>
          <!-- <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1" editable>
              Account Type
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2" editable>
              Profile
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3"> Name of step 3 </v-stepper-step>
          </v-stepper-header> -->

          <v-stepper-step
            :complete="e1 > 1"
            step="1"
            editable
            :rules="validateAboutYou()"
            error-icon="mdi-alert-circle-outline"
            edit-icon="mdi-check"
            :color="validateAboutYou.length == 0 ? 'green' : 'blue'"
          >
            About You
          </v-stepper-step>

          <v-stepper-content step="1">
            <!-- <form> -->
            <v-text-field
              v-model="first_names"
              :error-messages="first_namesErrors"
              :counter="20"
              label="First Name*"
              required
              @input="$v.first_names.$touch()"
              @blur="$v.first_names.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="surname"
              :error-messages="surnameErrors"
              :counter="20"
              label="Surname*"
              required
              @input="$v.surname.$touch()"
              @blur="$v.surname.$touch()"
            ></v-text-field>

            <v-btn color="primary" @click="e1 = 2"> Continue </v-btn>
          </v-stepper-content>

          <v-stepper-step
            :complete="e1 > 2 || checkInviteValidCode == true"
            step="2"
            editable
            :rules="validateInviteCode()"
            error-icon="mdi-alert-circle-outline"
            edit-icon="mdi-check"
            :color="checkInviteValidCode == true ? 'green' : 'blue'"
          >
            Invite Code
          </v-stepper-step>

          <!-- Invite Code -->
          <v-stepper-content step="2">
            <!-- <form> -->
            <v-row justify="center" class="" v-if="showInviteCodeInput">
              <v-col align="center">
                <p>If you have an invite code, please enter it below</p>
              </v-col>
            </v-row>
            <v-row justify="center" v-if="showInviteCodeInput">
              <v-col lg="8" md="8" sm="8" xs="12" cols="12">
                <v-otp-input
                  :length="inviteCodeLength"
                  v-model="enteredInviteCode"
                  :readonly="checkInviteLoading"
                  ref="otpinput"
                  :color="
                    checkInviteValidCode == true
                      ? '#7db52f'
                      : '' || (executed && checkInviteValidCode == false)
                      ? 'red'
                      : ''
                  "
                ></v-otp-input>
              </v-col>
            </v-row>
            <v-row v-if="!showInviteCodeInput" justify="center">
              <!-- Text link to edit code -->
              <v-col align="center">
                <v-row class="pa-0 ma-0">
                  <v-col class="pa-0 ma-0">
                    <p>
                      This code belongs to
                      <b>{{ checkInviteResponse.company_name }}</b>
                    </p>
                  </v-col>
                </v-row>
                <v-row class="pa-0 ma-0">
                  <v-col class="pa-0 ma-0">
                    <p class="body-2">
                      <b>{{ enteredInviteCode }}</b>
                      <v-btn
                        text
                        color="primary"
                        @click="showInviteCodeInput = true"
                        >Edit</v-btn
                      >
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col
                lg="2"
                md="2"
                sm="2"
                xs="12"
                cols="12"
                align="center"
                class="pa-0 ma-0"
              >
                <v-progress-circular
                  v-if="executed && checkInviteLoading"
                  :width="4"
                  :size="40"
                  color="#7db52f"
                  indeterminate
                ></v-progress-circular>
                <v-icon :size="40" color="#7db52f" v-if="checkInviteValidCode"
                  >mdi-check</v-icon
                >
                <v-icon
                  :size="40"
                  color="red"
                  v-if="
                    executed &&
                    checkInviteLoading == false &&
                    checkInviteValidCode == false
                  "
                  >mdi-close</v-icon
                >
              </v-col>
            </v-row>

            <v-btn color="primary" @click="e1 = 3"> Continue </v-btn>
          </v-stepper-content>

          <!-- Stepper for home address and validation -->
          <v-stepper-step
            :complete="e1 > 3"
            step="3"
            editable
            :rules="validateHomeAddress()"
            error-icon="mdi-alert-circle-outline"
            edit-icon="mdi-check"
            :color="validateHomeAddress.length == 0 ? 'green' : 'blue'"
          >
            Personal Details
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-text-field
              v-model="address_line_1"
              :error-messages="address_line_1Errors"
              :counter="50"
              label="Address Line 1"
              @input="$v.address_line_1.$touch()"
              @blur="$v.address_line_1.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="address_line_2"
              :error-messages="address_line_2Errors"
              :counter="50"
              label="Address Line 2"
              @input="$v.address_line_2.$touch()"
              @blur="$v.address_line_2.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="address_line_3"
              :error-messages="address_line_3Errors"
              :counter="50"
              label="Address Line 3"
              @input="$v.address_line_3.$touch()"
              @blur="$v.address_line_3.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="address_line_4"
              :error-messages="address_line_4Errors"
              :counter="50"
              label="Address Line 4"
              @input="$v.address_line_4.$touch()"
              @blur="$v.address_line_4.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="address_postcode"
              :error-messages="address_postcodeErrors"
              label="Postcode"
              @input="$v.address_postcode.$touch()"
              @blur="$v.address_postcode.$touch()"
            ></v-text-field>
            <v-row class="mb-3">
              <v-col>
                <vue-tel-input
                  v-model="phone_1"
                  :showDialCodeInSelection="true"
                  @validate="phone_1_validation = $event"
                  @blur="$v.phone_1_valid.$touch()"
                  v-bind="bindProps"
                ></vue-tel-input>
                <div class="alert-small">{{ phone_1Errors[0] }}</div>
              </v-col>
            </v-row>
            <v-btn color="primary" @click="e1 = 4"> Continue </v-btn>
          </v-stepper-content>

          <!-- Complete - T&C's' -->
          <v-stepper-step
            :complete="e1 > 4 || checkbox == true"
            step="4"
            editable
            edit-icon="mdi-check"
            error-icon="mdi-alert-circle-outline"
            :color="checkbox == true ? 'green' : 'blue'"
          >
            Complete
          </v-stepper-step>
          <v-stepper-content step="4">
            <span class="justify-start"
              >To create your account, please accept our
              <a href=".\tos" target="_blank">Terms and Conditions</a> and
              <a href=".\privacy" target="_blank">Privacy Policy</a></span
            >
            <v-row justify="start" align="start">
              <v-col class="ml-3">
                <v-checkbox
                  v-model="checkbox"
                  :error-messages="checkboxErrors"
                  required
                  @change="$v.checkbox.$touch()"
                  @blur="$v.checkbox.$touch()"
                >
                  <div slot="label">I agree</div></v-checkbox
                >
              </v-col>
            </v-row>
            <v-alert type="warning" :value="registrationError">
              <li v-bind:key="item.msg" v-for="item in registrationErrorItems">
                {{ item.item }} -
                {{ item.message }}
              </li>
            </v-alert>
            <v-alert type="success" :value="registrationComplete">
              Registration Successful!
            </v-alert>
            <v-btn class="primary" @click="submit"> submit </v-btn>

            <v-btn text> Cancel </v-btn>
          </v-stepper-content>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, maxLength, helpers } from "vuelidate/lib/validators";

let postcodeRegex = helpers.regex(
  "postcodeRegex",
  /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/
);

export default {
  name: "RegisterOwnerForm",
  mixins: [validationMixin],
  validations: {
    first_names: { required, maxLength: maxLength(20) },
    surname: { required, maxLength: maxLength(20) },
    checkbox: {
      checked(val) {
        return val;
      },
    },
    phone_1_valid: {
      phone_1_valid(val) {
        return val;
      },
    },
    address_line_1: { maxLength: maxLength(50) },
    address_line_2: { maxLength: maxLength(50) },
    address_line_3: { maxLength: maxLength(50) },
    address_line_4: { maxLength: maxLength(50) },
    // postcode based on UK postcode regex
    address_postcode: {
      maxLength: maxLength(8),
      // Regex to match ([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})
      valid: postcodeRegex,
    },
  },
  data() {
    return {
      e1: 1,
      valid: true,
      progressBarText: "Please wait while we complete some things",
      registrationSuccess: "Registration successful!",
      loginFailed: false,
      progressBarVisable: false,
      email: null,
      first_names: null,
      surname: null,
      address_line_1: null,
      address_line_2: null,
      address_line_3: null,
      address_line_4: null,
      address_postcode: null,
      selected_user_type: "PET_OWNER",
      checkbox: false,
      showInviteCodeInput: true,
      phone_1_type: null,
      phone_1: null,
      phone_1_validation: null,
      bindProps: {
        mode: "international",
        autoFormat: true,
        autoDefaultCountry: true,
      },
    };
  },
  components: {},
  watch: {
    registrationComplete(val) {
      if (val == true) {
        setTimeout(() => this.$router.push("/profile"), 3000);
      }
    },
  },
  computed: {
    ...mapGetters({
      registrationError: "account/registrationError",
      registrationComplete: "account/registrationComplete",
      registrationErrorItems: "account/registrationErrorItems",
      user: "auth/user",
      getAccountLoading: "account/getAccountLoading",
      inviteCodeLength: "invites/checkInviteInviteCodeLength",
      inviteCode: "invites/checkInviteInviteCode",
      checkInviteInviteCode: "invites/checkInviteInviteCode",
      checkInviteResponse: "invites/checkInviteResponse",
      checkInviteLoading: "invites/checkInviteLoading",
      checkInviteValidCode: "invites/checkInviteValidCode",
      checkInviteError: "invites/checkInviteError",
      checkInviteErrorMessage: "invites/checkInviteErrorMessage",
    }),
    enteredInviteCode: {
      get() {
        return this.inviteCode;
      },
      set(value) {
        this.$store.commit("invites/SET_CHECK_INVITE_INVITE_CODE", value);
      },
    },
    executed() {
      if (this.inviteCode.length == this.inviteCodeLength) {
        return true;
      } else {
        return false;
      }
    },
    checkboxErrors() {
      let errors = [];
      if (!this.$v.checkbox.$dirty) return errors;
      !this.$v.checkbox.checked && errors.push("You must agree to continue!");
      return errors;
    },
    first_namesErrors() {
      let errors = [];
      if (!this.$v.first_names.$dirty) return errors;
      !this.$v.first_names.maxLength &&
        errors.push("First name must be at most 20 characters long");
      !this.$v.first_names.required && errors.push("Name is required.");
      return errors;
    },
    surnameErrors() {
      let errors = [];
      if (!this.$v.surname.$dirty) return errors;
      !this.$v.surname.maxLength &&
        errors.push("Surname must be at most 20 characters long");
      !this.$v.surname.required && errors.push("Name is required.");
      return errors;
    },
    phone_1Errors() {
      let errors = [];
      if (!this.$v.phone_1_valid.$dirty) return errors;
      this.phone_1_validation == null ||
        (this.phone_1_validation.valid == false &&
          errors.push("This is not a valid phone number"));
      return errors;
    },
    phone_1_valid() {
      let test = this.phone_1Errors;
      // console.log("phone_1_validation", this.phone_1_validation);
      if (
        this.phone_1_validation == null ||
        this.phone_1_validation.valid == false
      ) {
        console.log("Phone 1 is not valid");
        return false;
      } else {
        console.log("Phone 1 is valid");
        return true;
      }
    },
    address_line_1Errors() {
      let errors = [];
      if (!this.$v.address_line_1.$dirty) return errors;
      !this.$v.address_line_1.maxLength &&
        errors.push("Address must be at most 50 characters long");
      return errors;
    },
    address_line_2Errors() {
      let errors = [];
      if (!this.$v.address_line_2.$dirty) return errors;
      !this.$v.address_line_2.maxLength &&
        errors.push("Address must be at most 50 characters long");
      return errors;
    },
    address_line_3Errors() {
      let errors = [];
      if (!this.$v.address_line_3.$dirty) return errors;
      !this.$v.address_line_3.maxLength &&
        errors.push("Address must be at most 50 characters long");
      return errors;
    },
    address_line_4Errors() {
      let errors = [];
      if (!this.$v.address_line_4.$dirty) return errors;
      !this.$v.address_line_4.maxLength &&
        errors.push("Address must be at most 50 characters long");
      return errors;
    },
    address_postcodeErrors() {
      let errors = [];
      if (!this.$v.address_postcode.$dirty) return errors;
      !this.$v.address_postcode.valid && errors.push("Postcode must be valid");
      return errors;
    },
  },
  mounted() {
    console.log(this.user);
    if (this.user.idTokenClaims.emails.length != null) {
      this.email = this.user.idTokenClaims.emails[0];
    }
    let split = this.user.idTokenClaims.name.split(" ");
    if (split.length > 1) {
      let surname = split[split.length - 1];
      this.surname = surname.trim();
      this.first_names = this.user.idTokenClaims.name
        .replace(surname, "")
        .trim();
    } else {
      this.first_names = split[0].trim();
    }
    this.$recaptchaLoaded();
    let code = this.$route.query.c;
    if (code != undefined) {
      this.$store.commit(
        "invites/SET_CHECK_INVITE_INVITE_CODE",
        code.toUpperCase()
      );
    }
  },
  methods: {
    ...mapActions({
      register: "account/register",
      SignOut: "auth/signOut",
      checkInvite: "invites/checkInvite",
      acceptInvite: "invites/acceptInvite",
    }),
    async callCheckInvite() {
      // ALSO USED AT REGISTER FORM
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      let token = await this.$recaptcha("login");
      // console.log(token);
      await this.checkInvite(token);
      this.$refs.otpinput.focus();
    },
    async submit() {
      this.$v.$touch();
      console.log("Invalid status: " + this.$v.$invalid);
      console.log("errors:", this.$v);
      if (!this.$v.$invalid) {
        console.log("submit");
        await this.$recaptchaLoaded();
        let token = await this.$recaptcha("login");
        let telephone_numbers = [];
        if (
          this.phone_1_valid == true &&
          this.phone_1_validation.valid !== undefined
        ) {
          telephone_numbers.push({
            number:
              this.phone_1_valid == true ? this.phone_1_validation.number : "",
            type: "Mobile",
          });
        }
        await this.register({
          first_names: this.first_names.trim(),
          surname: this.surname.trim(),
          // address_line_1:
          //   this.address_line_1 == null ? "" : this.address_line_1.trim(),
          // address_line_2:
          //   this.address_line_2 == null ? "" : this.address_line_2.trim(),
          // address_line_3:
          //   this.address_line_2 == null ? "" : this.address_line_3.trim(),
          // address_line_4:
          //   this.address_line_2 == null ? "" : this.address_line_4.trim(),
          // address_postcode:
          //   this.address_postcode == null ? "" : this.address_postcode.trim(),

          telephone_numbers: telephone_numbers,
          type: this.selected_user_type,
        }).then((val) => {
          if (this.registrationComplete && this.checkInviteValidCode) {
            console.log(
              "Registration success and valid invite code, accept invite"
            );
            // If successful then accept invite code
            this.acceptInvite({
              recaptchaToken: token,
              inviteCode: this.inviteCode,
            });
          }
        });
      }
    },
    clear() {
      this.$v.$reset();
      this.first_names = "";
      this.surname = "";
      this.checkbox = false;
    },
    validateAboutYou() {
      let errors = [];
      this.first_namesErrors.forEach((error) => errors.push(() => error));
      this.surnameErrors.forEach((error) => errors.push(() => error));
      return errors;
    },
    validateInviteCode() {
      let errors = [];
      if (
        this.executed &&
        this.checkInviteLoading == false &&
        this.checkInviteValidCode == false
      ) {
        errors.push(() => "Invalid invite code");
      }
      return errors;
    },
    validateHomeAddress() {
      let errors = [];
      this.address_line_1Errors.forEach((error) => errors.push(() => error));
      this.address_line_2Errors.forEach((error) => errors.push(() => error));
      this.address_line_3Errors.forEach((error) => errors.push(() => error));
      this.address_line_4Errors.forEach((error) => errors.push(() => error));
      this.address_postcodeErrors.forEach((error) => errors.push(() => error));
      if (this.phone_1_valid == false) {
        errors.push(() => "Invalid mobile number");
      }
      return errors;
    },
  },
  watch: {
    checkInviteValidCode(val) {
      // Update showInviteCodeInput
      if (val == true) {
        console.log(history);
        console.log("Params", this.$route.query);
        let queryParams = Object.assign({}, this.$route.query);
        queryParams.c = this.inviteCode;
        console.log("Params", queryParams);

        // this.$router.push({
        //   query: queryParams,
        // });

        // if (!_.isEqual(this.$route.query, queryParams)) {
        this.$router.push({ query: queryParams });
        this.showInviteCodeInput = false;
        // }
      }
    },
    inviteCode(val) {
      this.$store.commit(
        "invites/SET_CHECK_INVITE_INVITE_CODE",
        val.toUpperCase()
      );
      if (val.length == this.inviteCodeLength) {
        this.$store.commit("invites/SET_CHECK_INVITE_LOADING", true);
        // let token = this.$recaptcha("login");
        this.callCheckInvite();
      } else {
        this.$store.commit("invites/SET_CHECK_INVITE_LOADING", false);
        this.$store.commit("invites/SET_CHECK_INVITE_VALID_CODE", false);
      }
    },
    // phone_1_valid: function (val) {
    //   // console.log("phone_1_valid changed: ", val);
    //   this.$v.phone_1.$touch();
    // },
    checkInviteResponse: function (val) {
      if (val.telephone_number != null) {
        this.phone_1_type = "Mobile";
        this.phone_1 = val.telephone_number;
      }
    },
  },
};
</script>

<style scoped>
#registerAsText {
  margin-bottom: 1.5em;
  margin-left: -1em;
}

#registerAs {
  margin-bottom: 0;
  padding-bottom: 0;
}
</style>
