<template>
  <v-row style="background-color: #cbd9c6" class="pt-6 mr-0 pl-6">
    <v-col>
      <v-row justify="start">
        <v-col cols="12" cols-sm="12" md="10" class="pl-8">
          <v-row class="pb-0 mb-0 pb-8">
            <v-col class="pb-0 mb-0" align="start">
              <v-img
                src="https://pawportal.blob.core.windows.net/images/Dog_Top_Black.png"
                max-width="15vh"
                contain
              ></v-img>
            </v-col>
          </v-row>
          <v-row justify="start">
            <v-col v-for="(menu, index) in menus" :key="index">
              <v-row>
                <v-col align="start">
                  <b>{{ menu.title }}</b>
                </v-col>
              </v-row>
              <v-row v-for="(item, itemIndex) in menu.items" :key="itemIndex">
                <v-col align="start" class="py-1">
                  <v-hover v-slot="{ hover }">
                    <div>
                      <router-link
                        :to="item.isComingSoon ? '' : item.link"
                        :class="[
                          'description',
                          'no-link-decoration',
                          'description-footer',
                          { 'coming-soon': item.isComingSoon },
                        ]"
                        :disabled="item.isComingSoon"
                      >
                        {{ item.text }}
                      </router-link>
                      <v-chip v-if="hover && item.isComingSoon" small
                        >Coming Soon</v-chip
                      >
                    </div>
                  </v-hover>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col align="start" class="pl-8 py-10">
          <span class="description description-footer"
            >Copyright 2023 Paw Portal Ltd. All rights reserved.</span
          >
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      menus: [
        {
          title: "About",
          items: [
            {
              text: "About Us",
              link: "/",
              isComingSoon: false,
            },
            {
              text: "Contact Us",
              link: "/contactus",
              isComingSoon: false,
            },
            {
              text: "Terms of Service",
              link: "/tos",
              isComingSoon: false,
            },
            {
              text: "Privacy Policy",
              link: "/privacy",
              isComingSoon: false,
            },
          ],
        },
        {
          title: "Help",
          items: [
            { text: "FAQ", link: "/faq", isComingSoon: true },
            {
              text: "Support",
              link: "/contactus",
              isComingSoon: false,
            },
          ],
        },
        {
          title: "Social",
          items: [
            {
              text: "Facebook",
              link: "#",
              isComingSoon: true,
            },
            { text: "Twitter", link: "#", isComingSoon: true },
            {
              text: "Instagram",
              link: "#",
              isComingSoon: true,
            },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.no-link-decoration {
  text-decoration: none;
  color: inherit; /* or specify a specific color */
}

.coming-soon {
  color: grey;
}
</style>
