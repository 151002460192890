import axios from "axios";

export default {
    namespaced: true,

    state: {
        bookings: {
            bookings: [],
            loading: false,
            error: false,
            errorItems: [],
        },
        create: {
            loading: false,
            complete: false,
            error: false,
            errorItems: [],
            response: [],
        },
        delete: {
            loading: false,
            complete: false,
            error: false,
            errorItems: [],
        },
        update: {
            loading: false,
            complete: false,
            error: false,
            errorMessage: null,
        },
    },

    mutations: {
        RESET_STATE(state) {
            console.log("RESET_STATE bookings called");
            state.bookings.bookings = [];
        },
        RESET_FORM(state) {
            console.log("RESET_FORM bookings called");
            state.bookings.loading = false;
            state.bookings.error = false;
            state.create.loading = false;
            state.create.complete = false;
            state.create.error = false;
            state.create.errorItems = [];
            state.create.response = [];
            state.delete.loading = false;
            state.delete.complete = false;
            state.delete.error = false;
            state.delete.errorItems = [];
        },
        SET_BOOKINGS(state, val) {
            state.bookings.bookings = val;
        },
        SET_BOOKINGS_LOADING(state, val) {
            state.bookings.loading = val;
        },
        SET_CREATE_LOADING(state, val) {
            state.create.loading = val;
        },
        SET_CREATE_COMPLETE(state, val) {
            state.create.complete = val;
        },
        SET_CREATE_ERROR(state, val) {
            state.create.error = true;
            state.create.errorItems = [];
            try {
                val.detail.forEach((element) => {
                    const item = {
                        item:
                            element.loc[1].charAt(0).toUpperCase() +
                            element.loc[1].slice(1).replace(/_/g, " "),
                        message:
                            element.msg.charAt(0).toUpperCase() +
                            element.msg.slice(1).replace(/_/g, " "),
                    };
                    state.create.errorItems.push(item);
                });
            } catch (error) {
                console.log("Create Booking error message : ", error);
                state.create.errorItems.push({
                    item: "Server error",
                    message: "Please try again later",
                });
            }
        },
        SET_CREATE_RESPONSE(state, val) {
            state.create.response = val;
        },
        SET_BOOKINGS_ERROR(state, val) {
            state.bookings.error = val;
        },
        SET_DELETE_LOADING(state, val) {
            state.delete.loading = val;
        },
        SET_DELETE_ERROR(state, val) {
            state.delete.error = true;
            state.delete.errorItems = [];
            try {
                val.detail.forEach((element) => {
                    const item = {
                        item:
                            element.loc[1].charAt(0).toUpperCase() +
                            element.loc[1].slice(1).replace(/_/g, " "),
                        message:
                            element.msg.charAt(0).toUpperCase() +
                            element.msg.slice(1).replace(/_/g, " "),
                    };
                    state.delete.errorItems.push(item);
                });
            } catch (error) {
                console.log("Delete Booking error message : ", error);
                state.delete.errorItems.push({
                    item: "Server error",
                    message: "Please try again later",
                });
            }
        },
        SET_DELETE_COMPLETE(state, val) {
            state.delete.complete = val;
        },
        SET_UPDATE_LOADING(state, val) {
            state.update.loading = val;
        },
        SET_UPDATE_ERROR(state, val) {
            state.update.error = true;
            state.update.errorItems = [];
            try {
                val.detail.forEach((element) => {
                    const item = {
                        item:
                            element.loc[1].charAt(0).toUpperCase() +
                            element.loc[1].slice(1).replace(/_/g, " "),
                        message:
                            element.msg.charAt(0).toUpperCase() +
                            element.msg.slice(1).replace(/_/g, " "),
                    };
                    state.update.errorItems.push(item);
                });
            }
            catch (error) {
                console.log("Update Booking error message : ", error);
                state.update.errorItems.push({
                    item: "Server error",
                    message: "Please try again later",
                });
            }
        },
        SET_UPDATE_COMPLETE(state, val) {
            state.update.complete = val;
        },
    },

    getters: {
        all(state) {
            return state;
        },
        bookings(state) {
            return state.bookings.bookings;
        },
        bookingsLoading(state) {
            return state.bookings.loading;
        },
        createLoading(state) {
            return state.create.loading;
        },
        createComplete(state) {
            return state.create.complete;
        },
        createError(state) {
            return state.create.error;
        },
        createErrorItems(state) {
            return state.create.errorItems;
        },
        createResponse(state) {
            return state.create.response;
        },
        bookingsError(state) {
            return state.bookings.error;
        },
        deleteLoading(state) {
            return state.delete.loading;
        },
        deleteError(state) {
            return state.delete.error;
        },
        deleteErrorItems(state) {
            return state.delete.errorItems;
        },
        deleteComplete(state) {
            return state.delete.complete;
        },
        updateLoading(state) {
            return state.update.loading;
        },
        updateError(state) {
            return state.update.error;
        },
        updateErrorItems(state) {
            return state.update.errorItems;
        },
        updateComplete(state) {
            return state.update.complete;
        },
    },

    actions: {
        async getBookings({ rootState, commit }, payload) {
            try {
                commit("SET_BOOKINGS_ERROR", false);
                commit("SET_BOOKINGS_LOADING", true);

                // Provide default values
                const { company_id = null, type = null } = payload || {};

                var url = 'booking';

                // Create an array to hold query parameters
                let queryParams = [];

                // Check if we have a company_id passed in
                if (company_id) {
                    queryParams.push(`company_id=${company_id}`);
                }
                else {
                    // Check if we have a selected company in state
                    if (rootState.company.selectedCompany != null && rootState.company.selectedCompany.id) {
                        queryParams.push(`company_id=${rootState.company.selectedCompany.id}`);
                    }
                }

                if (type) {
                    queryParams.push(`type=${type}`);
                }

                // If there are any query parameters, append them to the URL
                if (queryParams.length > 0) {
                    url = `${url}?${queryParams.join('&')}`;
                }

                await axios
                    .get(url, {
                        headers: {
                            Authorization: `Bearer ${rootState.auth.token}`,
                        },
                    })
                    .then((resp) => {
                        console.log("bookings: ", resp.data);
                        commit("SET_BOOKINGS", resp.data);
                    })
                    .catch((error) => {
                        console.log(
                            "ERROR getting Bookings: ",
                            error.response?.data ?? "Unknown error"
                        );
                        commit("SET_BOOKINGS", []);
                        commit("SET_BOOKINGS_ERROR", true);
                    })
                    .finally(() => {
                        commit("SET_BOOKINGS_LOADING", false);
                    });
            } catch (e) {
                console.log(e);
                commit("SET_BOOKINGS_LOADING", false);
            }
        },
        async createBooking({ rootState, commit }, payload) {
            try {
                commit("SET_CREATE_LOADING", true);
                await axios
                    .post(`booking/`, payload, {
                        headers: {
                            Authorization: `Bearer ${rootState.auth.token}`,
                        },
                    })
                    .then((resp) => {
                        console.log("createBooking response data:", resp.data);
                        commit("SET_CREATE_RESPONSE", resp.data);
                        commit("SET_CREATE_COMPLETE", true);
                        commit("SET_CREATE_LOADING", false);
                    })
                    .catch((error) => {
                        console.log("ERROR creating Booking: ", error.response);
                        commit("SET_CREATE_ERROR", error.response?.data ?? "Unknown error");
                        commit("SET_CREATE_COMPLETE", false);
                        commit("SET_CREATE_LOADING", false);
                    })
                    .finally(() => { });
            } catch (e) {
                console.log(e);
                commit("SET_CREATE_LOADING", false);
            }
        },
        // async deleteBooking({ rootState, commit, dispatch }, { bookingId, companyId }) {
        //     try {
        //         commit("SET_DELETE_LOADING", true);
        //         await axios.delete(`company/${companyId}/booking/${bookingId}`, {
        //             headers: {
        //                 Authorization: `Bearer ${rootState.auth.token}`,
        //             },
        //         });

        //         // Re-fetch the updated bookings
        //         await dispatch("getBookings", companyId);

        //         commit("SET_DELETE_COMPLETE", true);
        //         commit("SET_DELETE_LOADING", false);
        //     } catch (error) {
        //         console.log("ERROR deleting booking: ", error.response?.data ?? "Unknown error");
        //         commit("SET_DELETE_LOADING", false);
        //         commit("SET_DELETE_COMPLETE", false);
        //         commit("SET_DELETE_ERROR", true);
        //         commit("SET_DELETE_ERROR", error.response?.data ?? "Unknown error");
        //     }
        // },
        async updateBooking({ rootState, commit, dispatch }, payload) {
            try {
                commit("SET_UPDATE_LOADING", true);
                // Extract the booking id from the payload
                let bookingId = payload.id;
                // Remove the id from the payload
                delete payload.id;

                await axios.put(`booking/${bookingId}`, payload, {
                    headers: {
                        Authorization: `Bearer ${rootState.auth.token}`,
                    },
                }).then((resp) => {
                    console.log("updateBooking response data:", resp.data);
                    commit("SET_UPDATE_COMPLETE", true);
                }
                ).catch((error) => {
                    console.log("ERROR updating Booking: ", error.response);
                    commit("SET_UPDATE_ERROR", error.response?.data ?? "Unknown error");
                })
                    .finally(() => {
                        commit("SET_UPDATE_COMPLETE", false);
                        commit("SET_UPDATE_LOADING", false);
                    });

                // Re-fetch the updated bookings
                // Get company id from root state
                let companyId = rootState.company.selectedCompany.id;
                await dispatch("getBookings", { company_id: companyId });
                commit("SET_UPDATE_LOADING", false);
            } catch (error) {
                console.log("ERROR updating booking: ", error.response?.data ?? "Unknown error");
                commit("SET_UPDATE_LOADING", false);
                commit("SET_UPDATE_COMPLETE", false);
                commit("SET_UPDATE_ERROR", true);
                commit("SET_UPDATE_ERROR", error.response?.data ?? "Unknown error");
            }
        },
        async resetForm({ commit }) {
            commit("RESET_FORM");
        },
        async resetForm({ commit }) {
            commit("RESET_FORM");
        },
    },
};
